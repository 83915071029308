import "./checkin.scss";

import useCheckin, { CHECKIN_REMOVE } from "../../hooks/reducers/checkin";

import RushLocateModal from "../groups/rush-locate";

export const Checkin = () => {
  const [{ inputVal, list, cell }, dispatch] = useCheckin();
  const displayCell = cell.name.replace(/_/g, " ");

  const headerText =
    cell.name.length > 0
      ? `Scan orders into ${displayCell.split(" ").pop()}`
      : "Scan a work-cell to begin";

  const rushCheckin =
    list.length > 0
      ? list.find(({ order }) => {
          return (
            order != null &&
            order.rush_located === false &&
            order.is_rush === true
          );
        })
      : null;
  return (
    <>
      <section className="checkin__title">
        <h1>{headerText}</h1>
        <p>To switch active cells scan a different work-cell barcode</p>
      </section>
      <section className="checkin__inputs">
        <input
          className="checkin__inputs-text"
          type="text"
          value={inputVal}
          readOnly={true}
        />
        <article className="checkin__inputs-label">
          {cell.valid === false ? "Invalid Location: " : "Current Location: "}
          <span
            className={`checkin__cell${
              cell.valid === false ? " checkin__error" : ""
            }`}
          >
            {cell.name.length > 0 ? displayCell : "None Selected"}
          </span>
        </article>
      </section>
      <section className="checkin__scans">
        {list.map(({ key, complete, valid }, index) => {
          let accent = "in-progress";
          if (complete) {
            accent = "fail";

            if (valid) {
              accent = "success";
            }
          }
          return (
            <div
              key={index}
              className={`checkin__item checkin__item--${accent}`}
            >
              {key}
            </div>
          );
        })}
      </section>
      <RushLocateModal
        orderData={rushCheckin != null ? rushCheckin.order : null}
        onComplete={(success) => {
          if (success) {
            dispatch({ type: CHECKIN_REMOVE, item: rushCheckin });
          }
        }}
      />
    </>
  );
};

export default Checkin;
