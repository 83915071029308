import { useEffect, useRef, useState } from "react";
import type { Dispatch, SetStateAction } from "react";

// Hooks
import { useLogin } from "../../../../hooks/context/login";
import {
  ShipBalanceDueActionCreators,
  ShipBalanceDueSteps,
} from "../../../../hooks/reducers/admin/ship-balance-due";
// Types
import type {
  TShipBalanceDueAction,
  TShipBalanceDueState,
} from "../../../../hooks/reducers/admin/ship-balance-due";
import type { Api } from "../../../../types/api";
import {
  isFinalShippingLabel,
  isFinalShippingLabelCarrierError,
  isFinalShippingLabelShippoError,
} from "../../../../types/guards";
// Utils
import { printAltSlip, printShippingLabel } from "../../../../utils/dymo-print";
import BaseButton from "../../../base/button";
// Components
import { ErrorArea } from "../../safetyscans/ship-order";
import type { ShipBalanceDueComponentProps } from "./app";
import { useCompleteOrder } from "./hooks/use-complete-order";
import { useShippingError } from "./hooks/use-shipping-error";
import { useShippingLabelApi } from "./hooks/use-shipping-label";

const PrintLabel = ({ onClick }) => {
  const [printing, setPrinting] = useState(false);
  return (
    <section className={"ship-order__print-label-wrapper"}>
      <h1 className={"ship-order__print-label-header"}>
        This order is ready to ship.
      </h1>
      <BaseButton
        className={`ship-order__print-label-button ${printing && "printing"}`}
        children={"Print Label"}
        onClick={async (event) => {
          event.currentTarget.blur();
          setPrinting(true);
          await onClick();
        }}
      />
    </section>
  );
};

const printErrorSlip = async (
  order: TShipBalanceDueState["order"],
  usertoken: string,
  errSource: string,
  errMsg: string,
) => {
  try {
    await printAltSlip(
      usertoken,
      "ERROR",
      errSource,
      order.production_order_id,
      order.access_code,
      order.shipping_address?.lastname,
      errMsg,
    );
  } catch (error) {}
};

/** Print error slips and trigger shipment error hook */
const handleShipmentErrors = (
  order: TShipBalanceDueState["order"],
  shipment:
    | Api.IFinalShippingLabelShippoError
    | Api.IFinalShippingLabelCarrierError,
  usertoken: string,
  setCreateAlert: Dispatch<SetStateAction<boolean>>,
  dispatch: Dispatch<TShipBalanceDueAction>,
) => {
  if (isFinalShippingLabelCarrierError(shipment)) {
    /** Print error slip with source, message */
    const {
      err: { id, source, message },
    } = shipment;
    printErrorSlip(order, usertoken, source, message);
    /** For any errors that aren't API time outs, create an alert */
    if (![7, 11, 12, 26].includes(id)) {
      setCreateAlert(true);
    }
    dispatch(
      ShipBalanceDueActionCreators.createSetErrorAction({
        errMsg: message,
        errState: true,
      }),
    );
  } else if (isFinalShippingLabelShippoError(shipment)) {
    /** General Shippo API errors */
    dispatch(
      ShipBalanceDueActionCreators.createSetErrorAction({
        errMsg: shipment.message,
        errState: true,
      }),
    );

    // print label like so
    printErrorSlip(
      order,
      usertoken,
      "Shippo",
      shipment?.message ?? "Unknown error",
    );
  } else {
    dispatch(
      ShipBalanceDueActionCreators.createSetErrorAction({
        errMsg: "Shippo API error",
        errState: true,
      }),
    );
  }
};

/** Handle getting shipping label and creating alerts */
const useShipBalanceDue = (
  state: TShipBalanceDueState,
  dispatch: Dispatch<TShipBalanceDueAction>,
) => {
  const [{ usertoken }] = useLogin();
  const [getLabel, setGetLabel] = useState(true);
  const usertokenRef = useRef(usertoken);
  const { setCreateAlert } = useShippingError(state.order);
  const shippingLabelApi = useShippingLabelApi(state, getLabel);

  useEffect(() => {
    if (shippingLabelApi.isResolved) {
      if (isFinalShippingLabel(shippingLabelApi.body)) {
        dispatch(
          ShipBalanceDueActionCreators.createSetLabelDataAction(
            shippingLabelApi.body,
          ),
        );
        dispatch(
          ShipBalanceDueActionCreators.createSetErrorAction({
            errState: false,
            errMsg: null,
          }),
        );
      } else {
        handleShipmentErrors(
          state.order,
          shippingLabelApi.body,
          usertokenRef.current,
          setCreateAlert,
          dispatch,
        );
      }
    }
    if (shippingLabelApi.isRejected) {
      dispatch(
        ShipBalanceDueActionCreators.createSetErrorAction({
          errMsg: shippingLabelApi.error?.message,
          errState: true,
        }),
      );
    }
  }, [
    state.order,
    setCreateAlert,
    dispatch,
    shippingLabelApi.body,
    shippingLabelApi.isResolved,
    shippingLabelApi.isRejected,
    shippingLabelApi.error,
  ]);
  return {
    setGetLabel,
  };
};

/** On successful label print, go to label confirm step */
const useLabelPrint = (dispatch: Dispatch<TShipBalanceDueAction>) => {
  const [printSuccess, setPrintSuccess] = useState<boolean>(null);
  useEffect(() => {
    if (printSuccess) {
      dispatch(
        ShipBalanceDueActionCreators.createSetStepNameAction(
          ShipBalanceDueSteps.Confirm,
        ),
      );
    }
  });
  return {
    printSuccess,
    setPrintSuccess,
  };
};

export const BalanceDueShipStepContent = (
  props: ShipBalanceDueComponentProps,
) => {
  const { printSuccess, setPrintSuccess } = useLabelPrint(props.dispatch);
  /** Send order complete request when label prints successfully */
  useCompleteOrder(props.state.order, printSuccess);
  useShipBalanceDue(props.state, props.dispatch);

  /** If Dymo printer fails to print, allow retrying */
  if (printSuccess === false) {
    return (
      <ErrorArea
        errorMessage={"Unable to print shipping label"}
        onClick={() => setPrintSuccess(null)}
        buttonText={"RETRY"}
      />
    );
  }
  /** If order fails to create a shipping label, restart */
  if (
    props.state.labelData?.success !== true &&
    props.state.error.errState === true
  ) {
    return (
      <ErrorArea
        errorMessage={props.state.error.errMsg || "Shippo API error"}
        onClick={() =>
          props.dispatch(ShipBalanceDueActionCreators.createResetAction())
        }
        buttonText={"RESTART"}
      />
    );
  }
  /** If a shipping label was retrieved, allow printing */
  if (
    props.state.labelData?.labelImage != null &&
    props.state.labelData?.success === true
  ) {
    return (
      <PrintLabel
        onClick={async () => {
          const res = await printShippingLabel(
            props.state.labelData.labelImage,
          );
          setPrintSuccess(res.printComplete);
        }}
      />
    );
  }
  return <></>;
};

export const BalanceDueShipStep = (props: ShipBalanceDueComponentProps) => {
  return (
    <div className="ship-balance-due__shipping">
      <BalanceDueShipStepContent {...props} />
    </div>
  );
};

export default BalanceDueShipStep;
