import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import useKeyEvent from "../../../hooks/use-key-event";
import TextInput from "../../base/text-input";

export const useScanOrderId = () => {
  const navigate = useNavigate();
  const { val: order, filled: orderScanned, clearFilled } = useKeyEvent(true);
  const [inputActive, setInputActive] = useState(true);
  const submitButtonRef = useRef(null);

  useEffect(() => {
    if (orderScanned) {
      setInputActive(false);
      submitButtonRef.current.focus();
    }
  }, [orderScanned]);

  /** @param {React.FormEvent<HTMLFormElement>} e - event */
  const onSubmit = (e) => {
    e.preventDefault();
    navigate(`/order/${order}`);
  };
  /** @param {React.MouseEvent<HTMLLabelElement, MouseEvent>} e - event */
  const onClick = (e) => {
    e.preventDefault();
    setInputActive(true);
    clearFilled();
  };

  const inputClass = `order__text-input${
    inputActive ? " order__text-input--active" : ""
  }`;

  return { order, submitButtonRef, onSubmit, onClick, inputClass };
};

export const ScanOrderId = () => {
  const { order, submitButtonRef, onSubmit, onClick, inputClass } =
    useScanOrderId();

  return (
    <section className="order__scan-screen">
      <h1>Enter the Order #</h1>

      <form onSubmit={onSubmit}>
        <label onClick={onClick}>
          Order: <TextInput className={inputClass} val={order} />
        </label>

        <input
          ref={submitButtonRef}
          type="submit"
          className="scan-order__get-order"
          value="Find Order"
        />
      </form>
    </section>
  );
};

export default ScanOrderId;
