import { useRef, useEffect } from "react";

/**
 * Callback function to pass into setInterval.
 * @callback UseIntervalCallback
 */

/**
 * Wraps imperative setInterval code.
 * @param {UseIntervalCallback} callback
 * @param {number} [delay] in milliseconds
 */
export function useInterval(callback, delay = 1000) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;
