import { useState } from "react";

import BaseButton from "../base/button";
import GoAgain, { GoAgainProps } from "./go-again";

import "./alerts-list.scss";

export interface AlertListItem {
  active: boolean;
  alert_id: string;
  reason: string;
}

interface AlertsListProps {
  alerts: AlertListItem[];
  dispatch: GoAgainProps["dispatch"];
}

export const AlertsList = ({ alerts, dispatch }: AlertsListProps) => {
  const [confirmed, setConfirm] = useState(false);

  return !confirmed ? (
    <section className="alerts-list__wrapper">
      <h2 className="alerts-list__subheader">
        This order is not ready to ship.
      </h2>
      <section className="alerts-list__alerts">
        {alerts
          .filter(({ active }) => active)
          .map(({ alert_id, reason }) => (
            <section key={alert_id} className="alerts-list__alert">
              <span className="alerts-list__alert alert">ALERT:</span> {reason}
            </section>
          ))}
      </section>
      <BaseButton
        className="alerts-list__buttons"
        children="GOT IT"
        onClick={() => setConfirm(true)}
      />
    </section>
  ) : (
    <section className="alerts-list__wrapper">
      <GoAgain dispatch={dispatch} />
    </section>
  );
};

export default AlertsList;
