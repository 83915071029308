import { useEffect, useState } from "react";

import { usePrevious } from "./use-previous";

/**
 * Hook responsible for managing
 * current page of a list view.
 */
export interface PagerHook<T> {
  /** Current page number */
  page: number;
  /** Subset of objects currently visible */
  current: T[];
  /** Highest page number */
  lastPage: number;
  /** Callback for previous page */
  previous: () => void;
  /** Callback for next page */
  next: () => void;
}

/**
 * Handles paging a list of data.
 * @param list Full list of items
 * @param pageLength List length per page
 * @param startPage First page number
 * */
export default function usePager<T = any>(
  list: T[],
  pageLength: number,
  startPage: number = 0,
): PagerHook<T> {
  const [page, setPage] = useState<number>(startPage);
  const [items, setItems] = useState([] as T[]);
  const [current, setCurrent] = useState([] as T[]);
  const [lastPage, setLastPage] = useState<number>(0);
  const prevList = usePrevious(list);

  /** Set items from list param */
  useEffect(() => {
    const len = list.length === items.length;
    if (!len) {
      setItems(() => [...list]);
    } else if (len && prevList !== list) {
      setItems(() => [...list]);
    }
  }, [list, items, prevList]);

  /** Set active page */
  useEffect(() => {
    const len = pageLength;
    const activePage = items.slice(page * len, (page + 1) * len);
    setCurrent(activePage);
  }, [items, pageLength, page]);

  /** Set last page */
  useEffect(() => {
    if (items.length === 0) {
      return;
    }
    const last = Math.ceil(items.length / pageLength) - 1;
    setLastPage(last);
  }, [items.length, pageLength]);

  const next = () => {
    if (page <= lastPage) {
      setPage((prev) => prev + 1);
    }
  };
  const previous = () => {
    if (page > 0) {
      setPage((prev) => prev - 1);
    }
  };

  return { page, current, lastPage, previous, next };
}
