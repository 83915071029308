import { useState } from "react";
import { styled } from "../../../stitches.config";
import TouchButton from "../touch-button/touch-button";

const Container = styled("section", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",

  article: {
    flexDirection: "row",
    padding: 0,
    margin: "10px 0",

    "> *:last-child": {
      marginLeft: "10px",
    },
  },
  header: {
    fontSize: "$md",
    height: "25px",
  },
});

export type PassFailToggle = "none" | "pass" | "fail";

export interface PassFailProps {
  toggle: PassFailToggle;
  onPass: () => void;
  onFail: () => void;
  label?: string;
}

export function usePassFailToggle(init: PassFailToggle = "none") {
  return useState<PassFailToggle>(init);
}

export default function PassFail(props: PassFailProps) {
  const { toggle, onPass, onFail, label } = props;
  return (
    <Container>
      <header>{label}</header>
      <article>
        <TouchButton
          onClick={onPass}
          color={toggle === "pass" ? "primary" : "idle"}
          size="sm"
        >
          Pass
        </TouchButton>
        <TouchButton
          onClick={onFail}
          color={toggle === "fail" ? "error" : "idle"}
          size="sm"
        >
          Fail
        </TouchButton>
      </article>
    </Container>
  );
}
