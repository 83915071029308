/**
 * SVG Icon for Southtree.
 * @param {Object} props
 * @param {string} [props.className]
 */
export const Southtree = ({ className = "header-icon" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path id="a" d="M0 .42h675.417V687.83H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect fill="#0096B1" width="1200" height="1200" rx="59.5" />
        <path
          d="M525.205 683.972c3.334 5.038 25.025 4.97 21.623-7.098-3.402-12.07-24.957 2.06-21.623 7.098m196.64-2.257c13.757-22.044-31.322-31.699-47.341-24.054-37.19 17.894 34.958 44.128 47.34 24.054M523.151 657.66c5.257-24.515-30.186-25.69-47.278-19.259-51.635 19.537 41.022 48.884 47.278 19.26m160.867-33.698c27.006 55.938 126.578-58.544 33.144-33.719-14.73 3.975-41.966 15.383-33.144 33.72m-24.977-39.689c-9.479 7.63-15.848 20.671-12.835 25.256 3.123 4.75 17.277 2.108 27.462-5.571 8.863-6.683 14.472-17.805 10.35-23.266-4.288-5.68-16.087-3.572-24.977 3.581m-176.86-8.453c-18.822-9.382-45.302-7.842-48.86.004-3.43 7.56 5.566 29.58 23.753 39.391 19.55 10.546 48.633 8.333 51.922-.849 3.172-8.863-7.991-29.165-26.814-38.546m78.865 38.546c16.653-17.036-27.807-67.36-42.6-43.347-12.8 20.709 28.646 57.522 42.6 43.347m154.352-69.042c-6.956 5.61-11.395 15.337-7.7 20.82 2.648 3.928 13.667 2.633 21.21-3.52 7.025-5.734 10.84-16.056 7.154-20.513-3.46-4.181-13.403-2.643-20.664 3.213m-215.425 20.82c10.664-8.844 3.457-25.046-7.994-32.815-12.161-8.25-28.298-10.846-31.695-5.49-5.896 9.293 28.296 47.751 39.69 38.305m130.067-53.904c-18.965-8.482-42.744 52.31-21.636 58.78 24.06 7.373 38.305-51.326 21.636-58.78m20.836 49.14c27.994 20.674 86.21-66.538 37.855-67.446-24.486-.41-61.822 49.63-37.855 67.445m-104.05-19.268c26.578-19.784-17.333-98.25-42.57-72.184-23.951 24.664 22.335 87.282 42.57 72.184m85.132-48.177c18.83 19.134 44.623-46.978 14.246-33.68-5.022 2.162-29.175 18.452-14.246 33.68m-47.751-72.616c39.592 0 33.133 96.721.52 96.721-33.094 0-31.41-96.721-.52-96.721"
          fill="#FEFEFE"
        />
        <g transform="translate(261.662 256.523)">
          <mask id="b" fill="#fff">
            <use href="#a" />
          </mask>
          <path
            d="M514.068 523.569c-20.292 20.626-43.972 37.664-70.172 50.27-46.07 21.914-101.285-39.843-79.212-211.8l-39.893-29.764 3.356 197.062c-.823 59.344-26.997 66.563-100.109 42.757-22.796-7.338-47.275-28.774-66.678-48.525-45.235-46.06-73.023-109.187-73.09-179.447.067-70.223 27.855-133.37 73.09-179.554C206.592 118.57 268.696 90.26 337.694 90.26c69.005 0 131.076 28.31 176.374 74.308 45.21 46.184 73.057 109.33 73.057 179.554 0 70.26-27.847 133.388-73.057 179.447M337.694.419C151.2.46.092 154.203 0 344.122.092 533.898 151.201 687.83 337.694 687.83c186.519 0 337.691-153.931 337.724-343.707C675.385 154.202 524.213.46 337.694.42"
            fill="#FEFEFE"
            mask="url(#b)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Southtree;
