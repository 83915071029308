const { VITE_APP_API_URI } = import.meta.env;

class HTTPError extends Error {
  response: Response;
  constructor(message: string, response: Response) {
    super(message);
    this.name = "HTTPError";
    this.response = response;
  }
}

export const buildUrl = (path: string) => `https://${VITE_APP_API_URI}/${path}`;

export function useBooleanFetch(
  path: string,
  usertoken: string,
  onSuccess: (data?: any) => void,
  onFail: (data?: any, flag?: any) => void,
  onError: (message: string) => void,
) {
  const url = buildUrl(path);
  const location = localStorage.getItem("location");

  const executeFetch = async () => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "X-Location-Id": location !== null ? location : "Holtzclaw",
        }),
      });

      if (!response.ok) {
        throw new HTTPError("request failure", response);
      }

      if (response.status === 200) {
        const data = await response.json();
        return onSuccess(data);
      } else if (response.status === 204) {
        return onSuccess(response.headers);
      }
    } catch (error) {
      console.log(error);
      if (error instanceof HTTPError) {
        const response = error.response;
        const { errorMessage } = await response.json();

        switch (response.status) {
          case 400:
            return onFail(errorMessage, undefined);
          case 404:
            return onFail(errorMessage, response.headers.get("x-order-flag"));
          case 422:
            return onFail({
              type: "showmodal",
              errorMessage,
            });
          default:
            return onFail(errorMessage, undefined);
        }
      }
      let msg: string = "Something has gone wrong";

      if (error instanceof Error) {
        msg = error.message;
      }
      return onError(msg);
    }
  };

  return executeFetch;
}
export default useBooleanFetch;
