import { SELECT_FORMAT } from "../../hooks/reducers/actions/receiving/category";
import usePager from "../../hooks/use-pager";
import MediaButton from "./media-button";

import "./media-group.scss";

interface MediaGroupProps {
  /** group of buttons to map */
  mediaButtons: { format: string; quantity: number }[];
  /** selected category name */
  selected: string;
  /** click handler */
  onClick: (action: { type: string; selectedFormat: string }) => void;
  /** page size */
  pageSize: number;
  /** header text */
  groupHeader: string;
  /** error state for button */
  error: boolean;
}

export const MediaGroup = ({
  mediaButtons,
  selected,
  onClick,
  pageSize,
  groupHeader,
  error,
}: MediaGroupProps) => {
  const { current } = usePager(mediaButtons, pageSize);

  return (
    <>
      <h1 className="media-group__header">{groupHeader}</h1>
      <section className="media-group__items">
        {current.map((mediaButton) => (
          <MediaButton
            key={mediaButton.format}
            buttonData={mediaButton}
            selected={selected}
            error={error}
            onClick={() =>
              onClick({
                type: SELECT_FORMAT,
                selectedFormat: mediaButton.format,
              })
            }
          />
        ))}
      </section>
    </>
  );
};

export default MediaGroup;
