import { useEffect } from "react";

import type { QaReducerProps } from "../../../hooks/reducers/qa-process";
import type { PassFailToggle } from "../../components/pass-fail/pass-fail";
import ScanItems from "../safetyscans/scan-items";
import type { ScanItemsProps } from "../safetyscans/scan-items";

interface SafetyscansItemsProps extends QaReducerProps {
  dispatchSetItemCount: (itemCount: PassFailToggle) => void;
}

const SafetyscanItems = (props: SafetyscansItemsProps) => {
  const { state, dispatch, dispatchSetItemCount } = props;
  const {
    processing,
    orderInfo: { order },
  } = state;

  const scanCount =
    order?.analogItems?.filter((item) => item.scanned).length ?? -1;
  const itemLen = order?.analogItems?.length;
  const itemCount = processing?.itemCount;

  useEffect(() => {
    if (itemCount === "pass") return;
    if (scanCount === itemLen) {
      dispatchSetItemCount("pass");
    }
  }, [scanCount, itemLen, itemCount, dispatchSetItemCount]);

  const scanItemsProps: ScanItemsProps = {
    orderInfo: order as unknown as ScanItemsProps["orderInfo"],
    // error,
    dispatch: (...ar: any) => {
      if (ar[0].type === "SCAN_ITEM_QA") {
        dispatch(...(ar as [any]));
      } // so we can prevent auto dispatch of PAGE_BTN_ACTIVE inherent to <ScanItems
    },
    isAppQA: true,
  };

  return <ScanItems {...scanItemsProps} />;
};

export default SafetyscanItems;
