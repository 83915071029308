import PagingGroup, { PagingGroupMode } from "./paging-group";

import "./item-list.scss";

interface ItemRowProps {
  textField: string;
  quantity: number;
  isTotalRow?: boolean;
}

export const ItemRow = ({
  textField,
  quantity,
  isTotalRow = false,
}: ItemRowProps) => (
  <section className="item-list__li">
    <article className={`item-list__li-${isTotalRow ? "total" : "wrapper"}`}>
      <div className={`item-list__li-${isTotalRow ? "total-text" : "name"}`}>
        {textField}
      </div>
      <div className={`item-list__li-${isTotalRow ? "total-" : ""}qty`}>
        {quantity}
      </div>
    </article>
  </section>
);

interface ItemListProps<K, T> {
  items: T[];
  textField: K;
  totalQuantity: number;
  pageSize: number;
}

export function ItemList<
  K extends string,
  T extends { [key in K]: string } & { quantity: number },
>({ items, textField, totalQuantity, pageSize }: ItemListProps<K, T>) {
  const listItems = items.map((item) => (
    <ItemRow
      key={item[textField]}
      textField={item[textField]}
      quantity={item.quantity}
    />
  ));

  return (
    <>
      <PagingGroup
        content={listItems}
        initialPage={0}
        pageSize={pageSize}
        contentClassName={"item-list__pager"}
        mode={PagingGroupMode.display}
      />
      <ItemRow
        textField={"Total Items:"}
        quantity={totalQuantity}
        isTotalRow={true}
      />
    </>
  );
}

export default ItemList;
