import { ResetAction, RESET_STATE } from "./reset-action";

export const TRIGGER_ERROR = "TRIGGER_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export interface ErrorState {
  error: boolean;
  message: string;
}

export type ErrorAction =
  | { type: typeof TRIGGER_ERROR; payload: string }
  | { type: typeof CLEAR_ERROR };

export const ErrorInitialState: ErrorState = {
  error: false,
  message: "",
};

export function trigger(message: string): ErrorAction {
  return { type: TRIGGER_ERROR, payload: message };
}
export function clear(): ErrorAction {
  return { type: CLEAR_ERROR };
}

export default function reducer(
  state: ErrorState,
  action: ErrorAction | ResetAction,
): ErrorState {
  switch (action.type) {
    case TRIGGER_ERROR:
      return { ...state, error: true, message: action.payload };
    case CLEAR_ERROR:
    case RESET_STATE:
      return ErrorInitialState;
    default:
      return state;
  }
}
