import Button from "../base/button";

import "./reason-button.scss";

/**
 * Button with long, descriptive text
 */
export const ReasonButton = ({
  reason,
  onClick,
}: {
  reason: string;
  onClick: () => void;
}) => (
  <Button className="reason-button" onClick={onClick}>
    {reason}
  </Button>
);

export default ReasonButton;
