import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useLogin } from "../../../hooks/context/login";
import {
  ADD_DAMAGED_ITEM,
  NOTIFIED_CUSTOMER,
  REMOVE_DAMAGED_ITEM,
} from "../../../hooks/reducers/actions/safetyscans/order-info";
import { COMPLETE_STEPS } from "../../../hooks/reducers/actions/safetyscans/page";
import { PAGE_NAV } from "../../../hooks/reducers/actions/safetyscans/page";
import useError from "../../../hooks/use-error";
import usePager from "../../../hooks/use-pager";
// Hooks
import useScanner from "../../../hooks/use-scanner";
// Components
import BaseButton from "../../base/button";
import GoButton from "../../components/go-button";
import PagerButtons from "../../components/pager-buttons";
import ScanRow from "../../components/scan-row";

import "./scan-damaged-items.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

const defaultItem = {
  text: "",
  err: false,
  errMsg: "",
};

/**
 * Check if in Teams and order has category of items
 * @param {string} category Category name ("Film", e.g.)
 * @param {string} pathname App pathname
 * @param {any[]} categories
 */
const checkTeamsCategory = (category, pathname, categories) =>
  /team|safetyscans/i.test(pathname) &&
  categories.find(({ name }) => name === category);

/**
 * Log damaged items using Timeline API
 * @param {import("../../../hooks/reducers/actions/safetyscans/order-info").OrderInfoState} orderInfo
 * @param {Function} setLogButtonActive
 * @param {string} usertoken
 * @param {string} pathname
 * @param {Function} triggerError
 * @param {import("react").Dispatch<any>} dispatch
 */
const logDamagedItems = async (
  orderInfo,
  setLogButtonActive,
  usertoken,
  pathname,
  triggerError,
  dispatch,
) => {
  const { orderId, salesOrderId, categories, damagedItems } = orderInfo;
  if (damagedItems.length === 0) {
    return;
  }

  setLogButtonActive(false);
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/safetyscans/order/${orderId}/damaged`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          salesOrderId,
          damagedItems,
        }),
      },
    );

    const payload = await response.json();

    if (!response.ok) {
      throw new Error(payload.errorMessage);
    }

    dispatch({ type: NOTIFIED_CUSTOMER });
    const teamsWithFilm = checkTeamsCategory("Film", pathname, categories),
      teamsWithPhotos = checkTeamsCategory("Photos", pathname, categories);
    if (pathname === "film" || teamsWithFilm) {
      dispatch({ type: PAGE_NAV, step: "Scan Film With Sound" });
    } else if (pathname === "photos" || teamsWithPhotos) {
      dispatch({ type: PAGE_NAV, step: "Select Photos Quantity" });
    } else {
      dispatch({
        type: COMPLETE_STEPS,
        step: "Confirm All Delivery Methods",
      });
    }
  } catch (error) {
    const message =
      error.message === undefined ? "Unknown error" : error.message;
    setLogButtonActive(true);
    triggerError(message);
  }
};

/**
 * Navigate to extra steps for Film, Photos
 * @param {Object} props
 * @param {import("../../../hooks/reducers/actions/safetyscans/order-info").SafetyScansCategory[]} props.categories
 * @param {boolean} props.hasLoggedDamagedItems
 * @param {string} props.pathname
 * @param {Function} props.dispatch
 */
const GoToExtraStep = ({
  categories,
  hasLoggedDamagedItems,
  pathname,
  dispatch,
}) => {
  // In the Film or Photos app
  const atFilmOrPhotos = ["film", "photos"].includes(pathname);
  // Check if in Teams app and has a certain category
  const teamsWithFilm = checkTeamsCategory("Film", pathname, categories);
  const teamsWithPhotos = checkTeamsCategory("Photos", pathname, categories);
  // Show go button for navigation for extra workflows
  const showGoButton =
    hasLoggedDamagedItems &&
    (atFilmOrPhotos || teamsWithFilm || teamsWithPhotos);

  const clickFn = () => {
    if (pathname === "film" || teamsWithFilm) {
      dispatch({ type: PAGE_NAV, step: "Scan Film With Sound" });
    } else if (pathname === "photos" || teamsWithPhotos) {
      dispatch({ type: PAGE_NAV, step: "Select Photos Quantity" });
    }
  };
  return <>{showGoButton && <GoButton onClick={() => clickFn()} />}</>;
};

/** Damaged Item scan inputs and Notify Customer button */
export const ScanDamagedItems = ({ orderInfo, dispatch }) => {
  const { orderId, analogItems, damagedItems, hasLoggedDamagedItems } =
    orderInfo;
  const [{ usertoken }] = useLogin();
  const { list } = useScanner();
  const [item, setItem] = useState(defaultItem);
  const [damageReason, setDamageReason] = useState(defaultItem);
  const [deleteItem, setDeleteItem] = useState(null);
  const [logButtonActive, setLogButtonActive] = useState(
    damagedItems.length > 0 && !hasLoggedDamagedItems,
  );
  const prevListLength = useRef(list.length);
  const {
    errState: logError,
    errMsg: logErrMsg,
    clear: clearError,
    trigger: triggerError,
  } = useError();
  const location = useLocation();
  const pathname = location.pathname.substr(1);

  // Damaged Item Scans paging
  const pager = usePager(damagedItems, 3);
  const { page, current, lastPage, next } = pager;
  const logButtonClass = logButtonActive ? "active" : "";

  useEffect(() => {
    setLogButtonActive(damagedItems.length > 0 && !hasLoggedDamagedItems);
  }, [damagedItems.length, hasLoggedDamagedItems]);

  // Add item barcode and damage scan to list
  useEffect(() => {
    if (list.length <= prevListLength.current || list.length <= 0) {
      return;
    }
    const lastScan = list[list.length - 1].code;

    // Set Item Barcode
    if (!item.text || item.err) {
      if (
        analogItems.findIndex(
          ({ item_barcode }) => item_barcode === lastScan,
        ) === -1
      ) {
        setItem({
          text: lastScan,
          err: true,
          errMsg: "This item does not belong to this order",
        });
        return;
      } else if (
        damagedItems.findIndex(({ barcode }) => barcode === lastScan) !== -1
      ) {
        setItem({
          text: lastScan,
          err: true,
          errMsg: "This item was already scanned",
        });
        return;
      } else {
        setItem({
          text: lastScan,
          err: false,
          errMsg: null,
        });
        return;
      }
    }
    // Add barcode, reason pair to damagedItems
    else if (!damageReason.text || damageReason.err) {
      if (!/NOCONVERT_[0-9]{2}/.test(lastScan)) {
        setDamageReason({
          text: lastScan,
          err: true,
          errMsg: "Invalid damage barcode",
        });
        return;
      } else {
        dispatch({
          type: ADD_DAMAGED_ITEM,
          barcode: item.text,
          damageReason: lastScan,
        });
        setItem({
          text: "",
          err: false,
          errMsg: null,
        });
        setDamageReason({
          text: "",
          err: false,
          errMsg: null,
        });
        if (damagedItems.length >= 3 && damagedItems.length % 3 === 0) {
          next();
        }
      }
    }
  }, [
    list,
    item,
    damageReason,
    orderId,
    analogItems,
    damagedItems,
    next,
    dispatch,
  ]);

  useEffect(() => {
    prevListLength.current = list.length;
  }, [list.length]);

  // Remove damaged items scans from list
  useEffect(() => {
    const defaultItem = {
      text: "",
      err: false,
      errMsg: "",
    };

    if (deleteItem === item.text) {
      setItem(defaultItem);
      setDamageReason(defaultItem);
    }
    if (deleteItem === damageReason.text) {
      setDamageReason(defaultItem);
    }
  }, [deleteItem, item, setItem, damageReason, setDamageReason]);

  return damagedItems ? (
    <>
      <section className={"scan-damaged-items__wrapper"}>
        <section className={"scan-damaged-items__scan-items-wrapper"}>
          <div className={"scan-damaged-items__scan-items-header"}>
            <div>Item Number</div>
            <div>Damaged Sticker</div>
          </div>
          {current.map((item) => (
            <ScanRow
              key={item.barcode}
              primary={{ text: item.barcode }}
              secondary={{ text: item.damageReason }}
              onClick={
                !hasLoggedDamagedItems
                  ? (item) => {
                      clearError();
                      dispatch({
                        type: REMOVE_DAMAGED_ITEM,
                        scan: item,
                      });
                    }
                  : () => {}
              }
            />
          ))}
          {page + 1 >= lastPage && !hasLoggedDamagedItems && (
            <ScanRow
              primary={item}
              secondary={damageReason}
              onClick={(item) => setDeleteItem(item)}
            />
          )}
          <PagerButtons {...pager} displayPageNum={damagedItems.length > 3} />
        </section>
        {!hasLoggedDamagedItems && (
          <section className="scan-damaged-items__log-area">
            {logError && (
              <h1 className="scan-damaged-items__log-error">{logErrMsg}</h1>
            )}
            <BaseButton
              onClick={
                logButtonActive
                  ? (event) => {
                      event.currentTarget.blur();
                      logDamagedItems(
                        orderInfo,
                        setLogButtonActive,
                        usertoken,
                        pathname,
                        triggerError,
                        dispatch,
                      );
                    }
                  : () => {}
              }
              className={`scan-damaged-items__buttons-notify ${logButtonClass}`}
            >
              Log and notify customer
            </BaseButton>
          </section>
        )}
        <GoToExtraStep {...orderInfo} pathname={pathname} dispatch={dispatch} />
      </section>
    </>
  ) : (
    <></>
  );
};

export default ScanDamagedItems;
