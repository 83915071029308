import { SetStateAction, useState } from "react";
import { Dispatch } from "react";

import { useLogin } from "../../../hooks/context/login";
import type {
  IDiscReducerProps,
  TDiscCategory,
} from "../../../hooks/reducers/discs";
import usePager from "../../../hooks/use-pager";
import type { Api } from "../../../types/api/discs";
import BaseButton from "../../base/button";

const { VITE_APP_API_URI } = import.meta.env;

/**
 * @param items - Items to be printed
 * @param type - Tech chosen media type
 * @param category - Category of disc template to print
 */
export const filterItems = (
  items: Api.Discs.IDiscItem[],
  type: string,
  category: TDiscCategory,
) => {
  if (category === "Audio") {
    const pattern = /Reel/;
    return type === "Audio-Reels"
      ? items.filter(({ variant }) => pattern.test(variant))
      : items.filter(({ variant }) => !pattern.test(variant));
  }
  return items;
};

interface DiscRequestBody {
  salesOrderId: number;
  productionOrderId: string;
  quantity: number;
  brand: string;
  type: string;
  discs: Api.Discs.IDiscItem[];
  sequence: number;
}

const getFiles =
  (token: string, body: DiscRequestBody, endpoint: string) => () => {
    const req = async () => {
      try {
        const url = `https://${VITE_APP_API_URI}/discs/${endpoint}`;
        const res = await fetch(url, {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify(body),
        });

        if (!res.ok) {
          throw new Error("Failed request");
        }
        const data = await res.blob();
        const dataUrl = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = dataUrl;
        link.setAttribute("download", `${body.salesOrderId}.zip`);
        document.body.appendChild(link);
        link.click();
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    };
    return req();
  };

interface SequenceProps {
  seq: number;
  set: Dispatch<SetStateAction<number>>;
  category: TDiscCategory;
}

export const Sequence = ({ seq, set, category }: SequenceProps) => {
  if (!["Film", "Photos"].includes(category)) {
    return <div />;
  }
  const add = () => set((s) => s + 1);
  const sub = () => set((s) => (s > 0 ? s - 1 : s));
  return (
    <section className="discs__get-files-seq-wrapper">
      <div>How many discs in this set</div>
      <div>
        <BaseButton onClick={sub} className="discs__get-files-seq-button">
          -
        </BaseButton>
        <span className="discs__get-files-seq">{seq}</span>
        <BaseButton onClick={add} className="discs__get-files-seq-button">
          +
        </BaseButton>
      </div>
    </section>
  );
};

export const PrintList = ({ state }: IDiscReducerProps) => {
  const { items, discType, discCategory, order, orderNumber, discQuantity } =
    state;
  const [sequence, setSequence] = useState(1);
  const itemList = filterItems(items, discType, discCategory);
  const { page, current, lastPage, previous, next } = usePager(itemList, 5);
  const [{ usertoken }] = useLogin();

  const displayPage = page + 1;
  const hasNextPage = page < lastPage;
  const hasPrevPage = page > 0;

  let outputType = discType;
  if (discCategory === "Audio") {
    outputType = discType === "Audio-Reels" ? "reels" : "tapes";
  }

  const requestBody: DiscRequestBody = {
    salesOrderId: order.orderId,
    productionOrderId: orderNumber,
    quantity: discQuantity,
    brand: order.brand,
    type: outputType,
    discs: itemList,
    sequence,
  };
  const onClick = getFiles(usertoken, requestBody, discCategory.toLowerCase());

  return (
    <section className="discs__print-list">
      <ul className="discs__print-list-titles">
        {current.map(({ barcode, title }) => (
          <li key={barcode}>
            <span>{barcode}:</span>
            {title}
          </li>
        ))}
      </ul>
      <section className="discs__print-list-pager">
        <article className="discs__print-list-pager-buttons">
          <div>
            {hasPrevPage && (
              <BaseButton
                className="discs__print-list-pager-button"
                onClick={previous}
              >
                {"<"}
              </BaseButton>
            )}
          </div>

          <div>{displayPage}</div>
          <div>
            {hasNextPage && (
              <BaseButton
                className="discs__print-list-pager-button"
                onClick={next}
              >
                {">"}
              </BaseButton>
            )}
          </div>
        </article>
      </section>
      <section className="discs__get-files-wrapper">
        <Sequence seq={sequence} set={setSequence} category={discCategory} />
        <section className="discs__get-files-button-wrapper">
          <BaseButton onClick={onClick} className="discs__get-files-button">
            Build Files
          </BaseButton>
        </section>
      </section>
    </section>
  );
};

export default PrintList;
