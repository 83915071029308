import { type MouseEvent as ReactMouseEvent } from "react";

import { PAGE_GOTO } from "../../hooks/reducers/actions/receiving/page";
import ProcessStepButton from "../components/process-step-button";

import "./progress-bar.scss";

type Step = {
  name: string;
  order: number;
  inProgress: boolean;
  complete: boolean;
};

interface ProgressBarProps {
  /** title steps of each button */
  steps: Step[];
  /** dispatch function for parent reducer */
  dispatch: React.Dispatch<{
    type: typeof PAGE_GOTO;
    step: number;
    progress: boolean;
  }>;
}

/** Progress Top Bar for Digitization workflow steps */
export const ProgressBar = ({ steps, dispatch }: ProgressBarProps) => (
  <ul className="process-steps">
    {steps.map(({ name, order, inProgress, complete }) => (
      <li key={order}>
        <ProcessStepButton
          stepName={name}
          inProgress={inProgress}
          complete={complete}
          onClick={(event: ReactMouseEvent<HTMLButtonElement>) => {
            event.currentTarget.blur();
            dispatch({ type: PAGE_GOTO, step: order, progress: true });
          }}
        />
      </li>
    ))}
  </ul>
);

export default ProgressBar;
