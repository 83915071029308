import { useEffect, useState, Dispatch } from "react";
import "./backup-file.scss";
import { OrderInfoState } from "../../../hooks/reducers/actions/order-info";
import { BackUpFromApi } from "../../../hooks/reducers/order-view";
import { transformBackup } from "../../../utils/order-helper";
import { Tabs } from "../../components/tabs";
import BackupFileTab from "./backup-file-tab";
import GoButton from "../../components/go-button";
import {
  PAGE_BTN_ACTIVE,
  PAGE_STEP_TO_PAYLOAD,
} from "../../../hooks/reducers/actions/safetyscans/page";
import type { MouseEvent } from "react";
type orderInfoWithbackupFiles = OrderInfoState & {
  backupFiles?: BackUpFromApi;
};
interface BackupFilesProps {
  orderInfo: orderInfoWithbackupFiles;
  dispatch: Dispatch<any>;
}

export const usePageState = (errState: boolean, dispatch: Dispatch<any>) => {
  useEffect(() => {
    if (errState) {
      dispatch({ type: PAGE_BTN_ACTIVE, active: false, visable: true });
    }
  }, [errState, dispatch]);
};

export const BackupFiles = ({ orderInfo, dispatch }: BackupFilesProps) => {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isClicked) {
      setIsClicked(false);

      return dispatch({
        type: PAGE_STEP_TO_PAYLOAD,
        payload: "Log Damaged Items",
      });
    }
  }, [isClicked, dispatch]);

  const fileCount = (fileType: string) => {
    const count = transformBackup(orderInfo.backupFiles)?.filter(
      (item) => item.fileType === fileType,
    )?.length;
    return count;
  };

  const onNextPage = (event: MouseEvent<HTMLButtonElement>) => {
    setIsClicked(true);
    event.currentTarget.blur();
  };
  return (
    <>
      <main className="scan-order__wrapper">
        <section className="backup__header">
          <h1>Backup Files</h1>
        </section>
        <Tabs classPrefix="order">
          <BackupFileTab
            label={`Video(${fileCount("video").toString()})`}
            files={transformBackup(orderInfo.backupFiles)?.filter(
              (item) => item.fileType === "video",
            )}
          />
          <BackupFileTab
            label={`Audio(${fileCount("audio").toString()})`}
            files={transformBackup(orderInfo.backupFiles)?.filter(
              (item) => item.fileType === "audio",
            )}
          />
          <BackupFileTab
            label={`Photo(${fileCount("photo").toString()})`}
            files={transformBackup(orderInfo.backupFiles)?.filter(
              (item) => item.fileType === "photo",
            )}
          />
        </Tabs>
      </main>
      <section className="safetyscans-next-button">
        <GoButton onClick={onNextPage} />
      </section>
    </>
  );
};
