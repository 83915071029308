//@ts-nocheck
import type { PropsWithChildren } from "react";

import { styled } from "../../../stitches.config";

const Quantity = styled("span", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  fontWeight: "$light",
  fontSize: "$sm",
  color: "$white",
  height: "50px",
  width: "50px",
  borderRadius: "50px",
  border: "4px solid $white",
  boxShadow: "0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23)",

  variants: {
    color: {
      default: {
        backgroundColor: "$darkGray",
      },
      active: {
        backgroundColor: "$yellow",
      },
    },
  },
});

Quantity.defaultProps = {
  color: "default",
};

const Indicator = styled("article", {
  fontFamily: "$gotham",
  fontSize: "$lg",
  fontWeight: "$bold",
  color: "$white",
  borderRadius: "10px",
  width: "300px",
  height: "100px",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  div: {
    textAlign: "center",
    width: "235px",
  },

  [`& ${Quantity}`]: {
    top: "-18px",
    left: "-18px",
  },

  variants: {
    color: {
      default: {
        backgroundColor: "$darkGray",
      },
      active: {
        backgroundColor: "$green",
      },
    },
  },
});

Indicator.defaultProps = {
  color: "default",
};

export interface IUpgradeIndicatorProps {
  quantity: number;
  active: boolean;
}

export type UpgradeIndicatorProps = PropsWithChildren<IUpgradeIndicatorProps>;

export default function UpgradeIndicator({
  quantity,
  active,
  children,
}: UpgradeIndicatorProps) {
  return (
    <Indicator color={active ? "active" : "default"}>
      <div>{children}</div>
      <Quantity color={quantity > 0 ? "active" : "default"}>
        {quantity}
      </Quantity>
    </Indicator>
  );
}
