import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import { CHECK_VALID_DEPT } from "../../../hooks/reducers/actions/safetyscans/departments";
import {
  DEPTS_COMPLETE,
  type OrderInfoState,
  SET_BACKUP_FILES,
  SET_IS_GDRIVE_VISITED,
  SET_ORDER,
  SET_ORDER_FIELDS,
} from "../../../hooks/reducers/actions/safetyscans/order-info";
import {
  COMPLETE_STEPS,
  PAGE_BTN_ACTIVE,
  PAGE_STEP,
} from "../../../hooks/reducers/actions/safetyscans/page";
import { RESET } from "../../../hooks/reducers/safetyscans";
import useError from "../../../hooks/use-error";
import useKeyEvent from "../../../hooks/use-key-event";
import { appendSafetyScansFields } from "../../../utils/order-helper";
import ScanRow from "../../components/scan-row.js";
import "./scan-order.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

const fetchBackupFiles = async (
  usertoken: string,
  orderId: number,
  customerId: string,
) => {
  if (!customerId) {
    return null;
  }
  const response = await fetch(
    `https://${VITE_APP_API_URI}/production/customers/${customerId}/orders/${orderId}/backup`,
    {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
      }),
    },
  );
  if (response.ok) {
    return response.json();
  } else {
    return {};
  }
};
export const useScanOrder = (
  active: ScanOrderActiveInput,
  setActive: Dispatch<SetStateAction<ScanOrderActiveInput>>,
  dispatch: Dispatch<any>,
) => {
  const [{ usertoken }] = useLogin();

  const {
    val: order,
    filled: orderScanned,
    clearFilled,
    clearInput,
  } = useKeyEvent(active === "order");

  const {
    errState: orderIDError,
    errMsg: orderErrMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();

  useEffect(() => {
    const getOrderInfo = async (lastScan: string) => {
      try {
        setActive("access");
        const response = await fetch(
          `https://${VITE_APP_API_URI}/production/order/${lastScan}`,
          {
            method: "GET",
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
            }),
          },
        );

        const payload = await response.json();
        console.log(JSON.stringify(payload));

        if (!response.ok) {
          throw new Error(payload.errorMessage);
        }

        const safetyscans = appendSafetyScansFields(payload);

        dispatch({ type: SET_ORDER, payload: safetyscans });
        dispatch({ type: SET_IS_GDRIVE_VISITED, payload: false });
        const backupFiles = await fetchBackupFiles(
          usertoken,
          safetyscans.sales_order_id,
          safetyscans.customer_id,
        );
        dispatch({ type: SET_BACKUP_FILES, payload: backupFiles });
        dispatch({
          type: CHECK_VALID_DEPT,
          analogItems: safetyscans.analogItems,
        });
        clearError();
      } catch (error) {
        console.log(error);
        triggerError(`Error: ${error.message}`);
      }
    };
    if (active === "order" && orderScanned) {
      getOrderInfo(order);
    }
  }, [
    active,
    order,
    orderScanned,
    clearError,
    dispatch,
    setActive,
    triggerError,
    usertoken,
  ]);

  return {
    orderScan: order,
    orderIDError,
    orderErrMsg,
    orderReset: () => {
      clearError();
      clearFilled();
      clearInput();
    },
  };
};

export const useScanAccess = (
  active: ScanOrderActiveInput,
  setActive: Dispatch<SetStateAction<ScanOrderActiveInput>>,
  dispatch: Dispatch<any>,
  orderAccess: string,
) => {
  const {
    val: acc,
    filled: accScanned,
    clearFilled,
    clearInput,
  } = useKeyEvent(active === "access");
  const {
    errState: accessError,
    errMsg: accessErrMsg,
    trigger: triggerAccessError,
    clear: clearAccessError,
  } = useError();

  useEffect(() => {
    if (active === "access" && accScanned) {
      // If they've scanned before order info has loaded
      if (orderAccess === null || orderAccess === undefined) {
        clearInput();
        clearFilled();
        return triggerAccessError(
          "Please retry scanning the access code. Order info still loading...",
        );
      }
      // Match
      if (acc.toLowerCase() === orderAccess.toLowerCase()) {
        setActive("next");
        clearAccessError();
      } else {
        triggerAccessError("Access code does not match");
        dispatch({ type: PAGE_BTN_ACTIVE, active: false });
      }
    }
  }, [
    active,
    acc,
    accScanned,
    orderAccess,
    dispatch,
    triggerAccessError,
    clearAccessError,
    clearFilled,
    clearInput,
    setActive,
  ]);

  return {
    accessDisplay: "•".repeat(acc.length),
    accessError,
    accessErrMsg,
    accessReset: () => {
      clearAccessError();
      clearFilled();
      clearInput();
    },
  };
};

export const useNextSteps = (
  active: ScanOrderActiveInput,
  orderInfo: OrderInfoState,
  dispatch: Dispatch<any>,
) => {
  const [{ usertoken }] = useLogin();

  useEffect(() => {
    if (
      orderInfo.categories === null ||
      orderInfo.categories === undefined ||
      orderInfo.categories.length === 0
    ) {
      return;
    }
    const checkShippingAlerts = async () => {
      try {
        const alertResult = await fetch(
          `https://${VITE_APP_API_URI}/shipments/alerts/${orderInfo.salesOrderId}`,
          {
            method: "GET",
            headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
          },
        );

        /** hacky coercion, but this is the return from the api */
        const alerts =
          (await alertResult.json()) as OrderInfoState["shipmentAlerts"] & {
            errorMessage?: string;
          };
        console.log(alerts);

        if (alerts !== null) {
          alerts.alerts = alerts?.alerts?.filter(
            (alert: any) => alert.reason !== "Return Order Departure",
          );
          const notResolvedAlerts = alerts?.alerts?.filter(
            (alert: any) => alert.active === 1,
          );
          if (notResolvedAlerts.length === 0) {
            alerts.hasActiveAlert = false;
          }
        }

        if (!alertResult.ok) {
          throw new Error(alerts.errorMessage ?? "Error fetching alerts");
        }
        // alerts.hasActiveAlert = false;
        if (alerts.hasActiveAlert) {
          dispatch({
            type: SET_ORDER_FIELDS,
            payload: { shipmentAlerts: alerts },
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    /** if on last department scan out, check for alerts */
    if (orderInfo.categories?.filter(({ complete }) => !complete)?.length < 2) {
      checkShippingAlerts();
    }
  }, [orderInfo.salesOrderId, orderInfo.categories, usertoken, dispatch]);

  useEffect(() => {
    const skipToShipping = (): boolean => {
      if (
        orderInfo.categories === null ||
        orderInfo.categories === undefined ||
        orderInfo.categories.length === 0
      ) {
        return false;
      }
      const uncompletedCategories = orderInfo.categories.filter(
        ({ complete }) => !complete,
      );

      return uncompletedCategories.length === 0 && orderInfo.isComplete;
    };
    if (active === "next") {
      dispatch({ type: PAGE_BTN_ACTIVE, active: true });

      if (skipToShipping()) {
        dispatch({
          type: DEPTS_COMPLETE,
          complete: true,
        });
        return dispatch({
          type: COMPLETE_STEPS,
          step: "Review Order",
        });
      }
      dispatch({ type: PAGE_STEP });
    }
  }, [active, orderInfo, dispatch]);
};

export interface ScanOrderProps {
  orderInfo: OrderInfoState;

  dispatch: Dispatch<any>;
}
export type ScanOrderActiveInput = "order" | "access" | "next";
export const ScanOrder = ({ orderInfo, dispatch }: ScanOrderProps) => {
  // order, access, next
  const [active, setActive] = useState<ScanOrderActiveInput>("order");

  const { orderScan, orderIDError, orderErrMsg, orderReset } = useScanOrder(
    active,
    setActive,
    dispatch,
  );

  const { accessDisplay, accessError, accessErrMsg, accessReset } =
    useScanAccess(active, setActive, dispatch, orderInfo.accessCode);

  useNextSteps(active, orderInfo, dispatch);

  return (
    <>
      <section className="scan-order__wrapper">
        <div className="scan-order__header">
          <div>Order Number</div>
          <div>Access Code</div>
        </div>
        <ScanRow
          primary={{
            text: orderScan,
            err: orderIDError,
            errMsg: orderErrMsg,
          }}
          secondary={{
            text: accessDisplay,
            err: accessError,
            errMsg: accessErrMsg,
          }}
          onClick={() => {
            dispatch({ type: RESET });
            setActive("order");
            orderReset();
            accessReset();
          }}
        />
      </section>
    </>
  );
};

export default ScanOrder;
