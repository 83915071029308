import { useState, useEffect, useRef } from "react";
import { useLogin } from "./context/login";
import { useMountEffect } from "./use-mount-effect";
import { useLocation } from "react-router-dom";
import { safeJSONParse } from "../utils/json";

import type { Dispatch, SetStateAction } from "react";

/** Remove expired data from local storage */
const clearExpiredWork = (keyPrefix: string) => {
  const expiredVals = Object.keys(localStorage).filter(
    (key) =>
      key.includes(keyPrefix) &&
      (!key.includes(new Date().toLocaleDateString()) ||
        typeof safeJSONParse<WorkCompletedArray>(localStorage[key]) ===
          "number"),
  );
  expiredVals.forEach((key) => localStorage.removeItem(key));
};

/** Initialize state with local storage data if exists */
const initializeWorkCompleted = (
  key: string,
  setFn: Dispatch<SetStateAction<string>>,
) => {
  const storedVal = localStorage.getItem(key);
  if (storedVal !== null) {
    setFn(storedVal);
  }
};

export const AppKeyPrefixes = {
  "/receiving": "Receiving",
  "/tapes": "OrdersCompleted",
  "/film": "OrdersCompleted",
  "/photos": "OrdersCompleted",
  "/audio": "OrdersCompleted",
  "/teams": "OrdersCompleted",
};

/** JSON stringified array of unique completed work IDs */
type WorkCompletedString = string;
type WorkCompletedArray = string[];
/**
 * Save completed work in localStorage
 * @param {string} prefix Prefix of localStorage key
 */
export const useWorkCompleted = (prefix?: string | undefined) => {
  const [{ username }] = useLogin();
  const location = useLocation();
  const [workCompleted, setWorkCompleted] = useState<WorkCompletedString>(null);
  const workCompletedRef = useRef(workCompleted);
  const keyPrefix = useRef(prefix);
  if (prefix == null) {
    keyPrefix.current =
      AppKeyPrefixes[location.pathname as keyof typeof AppKeyPrefixes];
  }

  const key = `${
    keyPrefix.current
  }_${username}_${new Date().toLocaleDateString()}`;
  const storedVal = localStorage[key];

  const completeWork = (workKey: string) => {
    let oldWorkCompleted =
      safeJSONParse<WorkCompletedArray>(workCompletedRef.current) || [];
    if (oldWorkCompleted.findIndex((val: string) => val === workKey) !== -1) {
      return;
    }
    const newWorkCompleted = JSON.stringify([...oldWorkCompleted, workKey]);
    localStorage.setItem(key, newWorkCompleted);
    setWorkCompleted(newWorkCompleted);
  };

  useMountEffect(() => {
    clearExpiredWork(keyPrefix.current);
    initializeWorkCompleted(key, setWorkCompleted);
  });

  useEffect(() => {
    if (workCompletedRef.current !== workCompleted) {
      workCompletedRef.current = workCompleted;
    }
  }, [workCompleted]);

  // Update workCompleted from localStorage
  useEffect(() => {
    if (workCompletedRef.current !== storedVal) {
      setWorkCompleted(storedVal);
      workCompletedRef.current = storedVal;
    }
  }, [storedVal]);

  const completedWorkData = safeJSONParse<WorkCompletedArray>(workCompleted);
  // This can be simplified once all old data has expired
  let completedCount =
    typeof completedWorkData === "object" ? completedWorkData?.length : null;
  return {
    workCompleted: completedWorkData,
    completedCount,
    completeWork,
  };
};
export default useWorkCompleted;
