import { useEffect, useState } from "react";

// Hooks
import { useLogin } from "../../../hooks/context/login";
import { SET_ORDER_FIELDS } from "../../../hooks/reducers/actions/safetyscans/order-info";
// import BaseButton from "../../base/button";
import {
  COMPLETE_STEPS,
  PAGE_NAV,
} from "../../../hooks/reducers/actions/safetyscans/page";
import useError from "../../../hooks/use-error";
import GoButton from "../../components/go-button";
// Components
import NumberPad from "../../groups/number-pad";

import "./photos-quantity.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

/**
 * Log photos count using Timeline API
 * @param {string} usertoken
 * @param {number} salesOrderId
 * @param {Object} error
 * @param {string} entry
 * @param {Function} setEntry
 * @param {import("react").Dispatch<any>} dispatch
 */
const logPhotosQuantity = async (
  usertoken,
  salesOrderId,
  { clear, trigger },
  entry,
  setEntry,
  dispatch,
) => {
  clear();
  if (entry === "") {
    trigger("Enter quantity of photos");
    setEntry("");
    return;
  }
  const count = Number(entry);
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/timeline/order/${salesOrderId}/photoscount`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          photosQuantity: count,
        }),
      },
    );

    const payload = await response.json();

    if (!response.ok) {
      throw new Error(payload.errorMessage);
    }

    dispatch({
      type: SET_ORDER_FIELDS,
      payload: { photosQuantity: count },
    });
    dispatch({
      type: COMPLETE_STEPS,
      step: "Confirm All Delivery Methods",
    });
  } catch (error) {
    console.log(error);
    trigger(error.message);
  }
};

export const PhotosQuantity = ({ orderInfo, dispatch }) => {
  const [{ usertoken }] = useLogin();
  const [entry, setEntry] = useState("");
  const error = useError();

  useEffect(() => {
    const onlyUnsupported =
      orderInfo.categories.length === 1 &&
      orderInfo.categories.find(({ name }) => name === "Unsupported");
    if (orderInfo.hasLoggedPhotosQuantity === true || onlyUnsupported) {
      dispatch({
        type: COMPLETE_STEPS,
        step: "Confirm All Delivery Methods",
      });
      return;
    }
  }, [orderInfo.hasLoggedPhotosQuantity, orderInfo.categories, dispatch]);

  return (
    <section className={"photos-quantity__wrapper"}>
      {orderInfo.photosQuantity === null ? (
        <NumberPad
          header={"Log the total number of prints, slides, or negatives"}
          entry={entry}
          setEntry={setEntry}
          maxLength={5}
          handleSubmit={() =>
            logPhotosQuantity(
              usertoken,
              orderInfo.salesOrderId,
              error,
              entry,
              setEntry,
              dispatch,
            )
          }
          {...error}
        />
      ) : (
        <>
          <h2 className="photos-quantity__logged-hdr">
            Logged Quantity of Photos
          </h2>
          <h3 className="photos-quantity__logged-msg">
            This order has a total of
            <span className="photos-quantity__logged-count">
              {orderInfo.photosQuantity}
            </span>
            prints, slides, or negatives.
          </h3>
          <GoButton
            onClick={() =>
              dispatch({
                type: PAGE_NAV,
                step: "Confirm All Delivery Methods",
              })
            }
          />
        </>
      )}
    </section>
  );
};

export default PhotosQuantity;
