import "@pages/safety-check/safety-check.scss";

import { store } from "@pages/safety-check/state/store";
import { Provider } from "react-redux";
import { SafetyCheckMain } from "../pages/safety-check/main";

export function SafetyCheck() {
  return (
    <Provider store={store}>
      <SafetyCheckMain />
    </Provider>
  );
}

export default SafetyCheck;
