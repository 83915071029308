/** @type string - Log in option string */
export const LOGIN = "LOGIN";
/** @type string - Log out option string */
export const LOGOUT = "LOGOUT";
export const EXIT = "EXIT";

export interface LoginResponseBody {
  /** user display name */
  username: string;
  /** user access group */
  usergroup: string;
  /** API token */
  token: string;
}

export interface LoginAction {
  /** action type to dispatch */
  type: "LOGIN" | "LOGOUT" | "EXIT";
  /** log in AJAX response body */
  payload?: LoginResponseBody;
}

/** Manages user data and login state */
export interface LoginState {
  /** is user logged in */
  loggedIn: boolean;
  /** user display name */
  username: string;
  /** user access group */
  usergroup: string;
  /** user is admin level */
  isAdminLevel: boolean;
  /** user api token */
  usertoken: string;
  /** redirect to home page after login */
  redirectHome: boolean;
}

/** @type LoginState */
export const initialLoginState: LoginState = {
  loggedIn: false,
  username: "",
  usertoken: "",
  usergroup: "",
  isAdminLevel: false,
  redirectHome: false,
};

export function loginReducer(state: LoginState, action: LoginAction) {
  switch (action.type) {
    case LOGIN:
      const { username, usergroup, token } = action.payload;
      const newState = {
        ...state,
        loggedIn: true,
        username,
        usertoken: token,
        usergroup,
        isAdminLevel: usergroup === "Manager" || usergroup === "Administrator",
        redirectHome: false,
      };

      return newState;
    case LOGOUT:
      return { ...initialLoginState };
    case EXIT:
      return { ...initialLoginState, redirectHome: true };
    default:
      throw new Error("No action specified for Login dispatcher");
  }
}
