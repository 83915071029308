import { nextPage } from "../../../../hooks/reducers/actions/page-step";
import { setOutside } from "../../../../hooks/reducers/actions/qa/processing";
import type { QaReducerProps } from "../../../../hooks/reducers/qa-process";
import { styled } from "../../../../stitches.config";
import PassFail, {
  usePassFailToggle,
} from "../../../components/pass-fail/pass-fail";
import TouchButton from "../../../components/touch-button/touch-button";

const ButtonWrapper = styled("section", {
  marginTop: "20px",
});

export default function Outside({
  dispatch,
  state: { processing },
}: QaReducerProps) {
  const [label, setLabel] = usePassFailToggle(processing?.label ?? "none");
  const [tape, setTape] = usePassFailToggle(processing?.tape ?? "none");
  const [packed, setPacked] = usePassFailToggle(processing?.packed ?? "none");

  const goNext = label !== "none" && tape !== "none" && packed !== "none";

  return (
    <>
      <PassFail
        label="Is the shipping label applied correctly?"
        toggle={label}
        onPass={() => setLabel("pass")}
        onFail={() => setLabel("fail")}
      />
      <PassFail
        label="Is the tape applied correctly?"
        toggle={tape}
        onPass={() => setTape("pass")}
        onFail={() => setTape("fail")}
      />
      <PassFail
        label="Is everything safely packed and organized?"
        toggle={packed}
        onPass={() => setPacked("pass")}
        onFail={() => setPacked("fail")}
      />
      <ButtonWrapper>
        <TouchButton
          size="lg"
          disabled={!goNext}
          onClick={() => {
            dispatch(setOutside(label, tape, packed));
            dispatch(nextPage());
          }}
        >
          Next
        </TouchButton>
      </ButtonWrapper>
    </>
  );
}
