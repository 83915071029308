import Button, { BaseButtonProps } from "../base/button";

import "./go-button.scss";

export const GoButton = ({
  onClick,
  active = true,
}: {
  onClick: BaseButtonProps["onClick"];
  active?: boolean;
}) => (
  <Button
    onClick={active ? onClick : undefined}
    className={`go-button ${!active ? "go-button--disabled" : ""}`}
  >
    ➡
  </Button>
);

export default GoButton;
