import BaseButton from "../base/button";

interface ReceivingOnlyByAdminProps {
  goBack: () => void;
}

export const ReceivingOnlyByAdmin = ({ goBack }: ReceivingOnlyByAdminProps) => {
  return (
    <section className="reprint-slip__wrapper">
      <article className="reprint-slip__header">
        Please contact your manager.
      </article>
      <article className="reprint-slip__explainer">
        Receiving a return order is limited to admins.
      </article>
      <article className="reprint-slip__buttons">
        <BaseButton
          className={"go-again__buttons done"}
          children={"Go Back"}
          onClick={goBack}
        />
      </article>
    </section>
  );
};

export default ReceivingOnlyByAdmin;
