import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useLogin } from "./context/login";
import useFetch from "./use-fetch";

const { VITE_APP_API_URI } = import.meta.env;

/**
 * Update rush_located flag to true
 * @param {any} order
 * @param {string} usertoken
 * @param {React.Dispatch<React.SetStateAction<RushLocateState>>} setFn
 * @param {(boolean) => any | undefined} onComplete
 */
const setRushLocated = async (
  order: any,
  usertoken: string,
  setFn: Dispatch<SetStateAction<RushLocateState>>,
  onComplete: (val: boolean) => void,
) => {
  try {
    let newOrder = {
      production_order_id: order.production_order_id,
      sales_order_id: order.sales_order_id,
      rush_located: true,
    };
    const res = await fetch(
      `https://${VITE_APP_API_URI}/production/order/${order.production_order_id}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          order: newOrder,
        }),
      },
    );
    if (!res.ok) {
      throw new Error(`Fetch Error [${res.status}]: ${res.statusText}`);
    }
    if (onComplete !== undefined) {
      onComplete(true);
    }
    setFn((state) => ({ ...state, locateRush: false }));
  } catch (error) {
    console.error(error);
    if (onComplete !== undefined) {
      onComplete(false);
    }
  }
};

type RushLocateState = {
  isRush: boolean;
  locateRush: boolean;
  order: any;
};

/** @type {RushLocateState} */
const initialRushLocateState: RushLocateState = {
  isRush: null,
  locateRush: null,
  order: null,
};

const getRushLocateState = (order = null): RushLocateState => {
  if (order == null) {
    return initialRushLocateState;
  }
  return {
    order,
    isRush: order.is_rush,
    locateRush: order.is_rush && order.rush_located === false,
  };
};

export const useRushLocate = ({
  productionOrderId = null,
  orderData = null,
}: {
  productionOrderId: string | null;
  orderData: any;
}) => {
  const [{ usertoken }] = useLogin();
  const rushLocateState = getRushLocateState(orderData);
  const [rushState, setRushState] = useState(rushLocateState);
  // Fetch production order data if it hasn't been provided
  const executeFetch =
    orderData == null && productionOrderId != null && productionOrderId !== "";
  const { isLoading, isResolved, isRejected, body } = useFetch(
    `https://${VITE_APP_API_URI}/production/order/${productionOrderId}`,
    {
      headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
    },
    executeFetch,
  );
  useEffect(() => {
    if (body != null) {
      const newRushState = getRushLocateState(body);
      setRushState(newRushState);
    }
  }, [body]);

  useEffect(() => {
    if (orderData != null) {
      const newRushState = getRushLocateState(orderData);
      setRushState(newRushState);
    }
  }, [orderData]);

  return {
    isLoading: isLoading && executeFetch,
    isResolved: isResolved && executeFetch,
    isRejected: isRejected && executeFetch,
    ...rushState,
    setRushLocated: (onComplete: (val: boolean) => void) =>
      setRushLocated(rushState.order, usertoken, setRushState, onComplete),
  };
};
export default useRushLocate;
