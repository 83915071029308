import { useState, useEffect, useCallback, useRef } from "react";

// Components
import TextInput from "../../../base/text-input";
import BaseButton from "../../../base/button";
import GoAgain, { GoAgainActions } from "../../../groups/go-again";
// Hooks
import { useLogin } from "../../../../hooks/context/login";
import { LOGOUT } from "../../../../hooks/reducers/login";
import { useKeyEvent } from "../../../../hooks";
import { useShippingError } from "./hooks/use-shipping-error";
import { useCheckTracking } from "./hooks/use-check-tracking";
import { ShipBalanceDueActionCreators } from "../../../../hooks/reducers/admin/ship-balance-due";
// Assets
import upsGround from "../../../../images/ups-ground.png";
import upsSurepost from "../../../../images/ups-surepost.png";
// Utils
import { printAltSlip } from "../../../../utils/dymo-print";
// Types
import type { TShipBalanceDueState } from "../../../../hooks/reducers/admin/ship-balance-due";
import type { ShipBalanceDueComponentProps } from "./app";
import type { Dispatch } from "react";

/** Initial local state for confirm label step */
const ConfirmLabelInitialState = {
  /** Tracking number has been confirmed, ready to go on truck */
  confirmed: false,
  /** Error state */
  error: {
    /** Number of failed tracking confirm attempts */
    errCount: 0,
    /** Error flag */
    errState: false,
    /** Error message */
    errMsg: "",
  },
  /** Go to hard failure page and force restart */
  showFailPage: false,
  /** Scanned tracking number */
  tracking: "",
};
type TConfirmLabelState = typeof ConfirmLabelInitialState;

const useConfirmLabel = (state: TShipBalanceDueState) => {
  const [localState, setLocalState] = useState<TConfirmLabelState>(
    ConfirmLabelInitialState,
  );
  const { setCreateAlert } = useShippingError(state.order);
  const [{ usertoken }, loginDispatch] = useLogin();
  const usertokenRef = useRef(usertoken);
  const scanner = useKeyEvent(true);
  const { val, filled, clearInput, clearFilled } = scanner;
  const trackingApi = useCheckTracking(
    state.order,
    scanner.val,
    scanner.filled,
  );
  const { isResolved, isRejected, error, actions, apiDispatch } = trackingApi;

  const resetApi = useCallback(() => {
    clearInput();
    clearFilled();
    apiDispatch({ type: actions.RESET });
  }, [clearInput, clearFilled, apiDispatch, actions]);

  /** Set `tracking` state value when scan completes */
  useEffect(() => {
    if (filled) {
      setLocalState((state) => ({ ...state, tracking: val }));
    }
  }, [val, filled]);

  /** Handle label confirm success responses */
  useEffect(() => {
    if (isResolved) {
      setLocalState((state) => ({ ...state, confirmed: true }));
    }
  }, [isResolved]);
  /** Handle label confirm fail responses */
  useEffect(() => {
    if (isRejected) {
      const errMsg = `Tracking ${localState.tracking} does not match this order`;
      setLocalState((state) => ({
        ...state,
        error: {
          ...state.error,
          errState: true,
          errMsg,
          errCount: state.error.errCount + 1,
        },
      }));
      resetApi();
    }
  }, [isRejected, error, localState.tracking, resetApi]);

  /** After 3 failed confirm attempts, print error slip and create shipment alert */
  useEffect(() => {
    if (localState.error.errCount < 3) {
      return;
    }
    printAltSlip(
      usertokenRef.current,
      "ERROR",
      "TRACKING CONFIRM",
      state.order.production_order_id,
      state.order.access_code,
      state.order.shipping_address?.lastname,
      "Could not locate scanned tracking number in our system.",
    );
    setLocalState((state) => ({ ...state, showFailPage: true }));
    setCreateAlert(true);
  }, [
    state.order.production_order_id,
    state.order.access_code,
    state.order.shipping_address,
    localState.error.errCount,
    setCreateAlert,
  ]);
  return {
    ...localState,
    trackingApi,
    scanner,
    loginDispatch,
  };
};

export const ConfirmLabel = (props: ShipBalanceDueComponentProps) => {
  const { confirmed, showFailPage, scanner, error, loginDispatch } =
    useConfirmLabel(props.state);

  /** Reset app action for Go Again */
  const restartDispatch = () =>
    props.dispatch(ShipBalanceDueActionCreators.createRestartAction());
  return (
    <section className={"ship-balance-due__confirm-label"}>
      <>
        {!confirmed && !showFailPage && (
          <>
            <h1 className={"confirm-label__header"}>Scan tracking barcode</h1>
            <TextInput
              val={scanner.val}
              className={"confirm-label__tracking-input"}
              error={error.errState}
              errorMsg={error.errMsg}
            />
            <section className="confirm-label__label-examples">
              <img src={upsGround} alt="UPS Ground Label" />
              <img src={upsSurepost} alt="UPS Surepost Label" />
            </section>
          </>
        )}
        {confirmed && (
          <>
            <h1 className={"confirm-label__header"}>
              Shipping label confirmed
            </h1>
            <h2 className={"confirm-label__subheader"}>
              This order is ready to go on the truck.
            </h2>
            <GoAgain
              isRunning={confirmed}
              header={"Would you like to ship another order?"}
              dispatch={restartDispatch as Dispatch<GoAgainActions>}
            />
          </>
        )}
        {showFailPage && (
          <>
            <h1 className={"confirm-label__header error"}>
              Tracking Number Mismatch
            </h1>
            <h2 className={"confirm-label__subheader error"}>
              Unable to confirm shipping label
            </h2>
            <BaseButton
              className={"confirm-label__buttons error"}
              children={"GOT IT"}
              onClick={() => loginDispatch({ type: LOGOUT })}
            />
          </>
        )}
      </>
    </section>
  );
};

export default ConfirmLabel;
