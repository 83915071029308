import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import BaseButton from "../../base/button";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

interface GetStartedScreenProps {
  onClick: () => void;
}
export const GetStartedScreen = ({ onClick }: GetStartedScreenProps) => (
  <>
    <section className="departure__title departure__title--start">
      Ready to start checking out customer orders?
    </section>
    <section className="departure__tracking-input-wrapper">
      <BaseButton
        onClick={onClick}
        className="departure__button departure__button--start"
      >
        Get Started
      </BaseButton>
    </section>
  </>
);

interface DepartmentLocation {
  name: string;
  id: string;
}

export const getLocationNames = async (
  usertoken: string,
  setFn: Dispatch<SetStateAction<DepartmentLocation[]>>,
) => {
  const response = await fetch(`https://${VITE_APP_API_URI}/departments/`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${usertoken}`,
    }),
  });

  if (!response.ok) {
    throw new Error("Stations not found");
  }

  const data = (await response.json()) as DepartmentLocation[];

  const locations: DepartmentLocation[] = data.filter(({ id }) => {
    if (id === undefined) return false;
    return !["Digitizing", "Queues", "Shipping", "Receiving"].includes(id);
  });
  setFn(() => locations);
};

interface GetLocationParam {
  set: (param: string) => void;
}
export const GetLocation = ({ set }: GetLocationParam) => {
  const [{ usertoken }] = useLogin();
  const [locations, setLocations] = useState<DepartmentLocation[]>([]);

  useEffect(() => {
    getLocationNames(usertoken, setLocations);
  }, [setLocations, usertoken]);
  return (
    <>
      <section className="departure__title departure__title--start">
        Please select Location
        <br />
        {locations.length > 0 &&
          locations.map(({ name }, idx) => (
            <BaseButton
              key={idx}
              onClick={() => {
                localStorage.setItem("location", name);
                set(name);
              }}
              className="departure__warehouse-button"
            >
              {name}
            </BaseButton>
          ))}
      </section>
    </>
  );
};

interface GetStartedProps {
  onClick: () => void;
}

export const GetStarted = ({ onClick }: GetStartedProps) => {
  const [location, setLocation] = useState(localStorage.getItem("location"));

  return location !== null ? (
    <GetStartedScreen onClick={onClick} />
  ) : (
    <GetLocation set={setLocation} />
  );
};

export default GetStarted;
