import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  token: string;
}

const initialState: AuthState = {
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<AuthState["token"]>) => {
      state.token = action.payload;
    },
  },
});

const { actions, reducer } = authSlice;

export const { setToken } = actions;
export default reducer;
