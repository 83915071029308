import { useEffect, useState } from "react";
import type { Dispatch } from "react";
import { useLocation } from "react-router-dom";

// Hooks
import { useLogin } from "../../../../hooks/context/login";
import { SET_CELL } from "../../../../hooks/reducers/actions/safetyscans/departments";
import { PAGE_GOTO } from "../../../../hooks/reducers/actions/safetyscans/page";
import useError from "../../../../hooks/use-error";
import { getLocalStorageLocation } from "../../../../utils/local-storage";
import BaseButton from "../../../base/button";
import { LocationOptions, getLocations } from "../../../groups/checkin-cell";
import NumberPad from "../../../groups/number-pad";
import { StationScan } from "./station-scan";
import "./station-select.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

type TapesStationProps = {
  setCell: (cell: string) => void;
};
/**
 * Select 'A' or 'B' station for Tapes
 * @param {Object} props
 * @param {Function} props.setCell
 */
const TapesStation = ({ setCell }: TapesStationProps) => (
  <section className="station-select__cell-selector">
    <h1 className="station-select__cell-header">Select station letter</h1>
    <section className="station-select__cell-buttons-wrapper">
      <BaseButton
        className="station-select__cell-button left"
        onClick={() => setCell("A")}
      >
        A
      </BaseButton>
      <BaseButton
        className={"station-select__cell-button right"}
        onClick={() => setCell("B")}
      >
        B
      </BaseButton>
    </section>
  </section>
);

/**
 * Get a list of possible stations based on app
 * @param {string} appPath
 * @param {string} usertoken
 * @param {Function} setFn Set cell options fn
 */
const getCells = async (
  appPath: string,
  location: string,
  usertoken: string,
  setFn: (cells: TLocation[]) => void,
) => {
  const appName = [appPath.charAt(0).toUpperCase(), appPath.slice(1)].join("");

  let url = `https://${VITE_APP_API_URI}/departments/`;
  // TODO remove this choice, teams is not an app anymore
  if (appName !== "Teams") {
    url = `${url}Digitizing/${appName}`;
  } else if (appName === "Teams") {
    url = `${url}${location}/Digitizing`;
  }

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${usertoken}`,
    }),
  });

  if (!response.ok) {
    throw new Error(`${appName} Cells not found`);
  }

  const data = await response.json();
  if (data && data.cells && data.cells.length > 0) {
    let cells = data.cells as TLocation[];
    if (appPath.toLowerCase().includes("audio")) {
      cells = cells.filter(({ name }) => name.toLowerCase().includes("audio"));
    } else {
      cells = cells.filter(({ name }) => !name.toLowerCase().includes("audio"));
    }
    setFn(cells);
  } else {
    throw new Error("Unable to get list of stations");
  }
};

type TLocation = {
  id: string;
  name: string;
};

type TLocationState = {
  cellOptions: TLocation[];
  locationOptions: TLocation[];
  location: TLocation;
};

type StationSelectProps = {
  dispatch: Dispatch<any>;
};

/** @deprecated */
export const StationSelect = ({ dispatch }: StationSelectProps) => {
  const [{ usertoken }] = useLogin();
  const { pathname } = useLocation();
  const appPath = pathname.substr(1);
  const [entry, setEntry] = useState("");
  const [showABOption, setShowABOption] = useState(null);
  const [locationState, setLocationState] = useState<TLocationState>({
    cellOptions: [],
    locationOptions: [],
    location: getLocalStorageLocation("location"),
  });
  const [stationNumber, setStationNumber] = useState(null);
  const [cellLetter, setCell] = useState(null);
  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();

  /** Station number pad submit button handler */
  const submit = () => {
    clearError();

    const row = Number(entry);

    if (row < 1) {
      triggerError("Invalid station number");
      setEntry("");
    }
    setStationNumber(row);
  };

  /** Stopgap check until we move all departments to scan */
  const isScanpath = (path: string): boolean =>
    /team|safetyscans/i.test(path) || ["audio"].includes(path);

  useEffect(() => {
    if (isScanpath(appPath) && locationState.location === null) {
      getLocations(usertoken, setLocationState);
    } else if (appPath === "balance-due") {
      dispatch({
        type: SET_CELL,
        cell: {
          name: "",
          id: "Queues_Balance-Due",
        },
      });
    } else {
      const id =
        (locationState.location && locationState.location.id) || "Digitizing";
      getCells(appPath, id, usertoken, (cellOptions: any) =>
        setLocationState((state) => ({ ...state, cellOptions })),
      );
    }
  }, [appPath, locationState.location, usertoken, setLocationState, dispatch]);

  // Select station number
  useEffect(() => {
    if (stationNumber === null || locationState.cellOptions.length === 0) {
      return;
    }
    const regex = new RegExp(`([A-Za-z])+(-?${stationNumber}[A-Z]?)$`);
    const cellIndex = locationState.cellOptions.findIndex(({ name }) =>
      regex.test(name),
    );
    if (cellIndex === -1) {
      triggerError("Invalid station number");
      setStationNumber(null);
      setEntry("");
      return;
    } else {
      if (appPath === "tapes") {
        // Show A - B selector
        setShowABOption(true);
      } else {
        dispatch({
          type: SET_CELL,
          cell: locationState.cellOptions[cellIndex],
        });
        dispatch({ type: PAGE_GOTO, step: 1 });
      }
    }
  }, [
    stationNumber,
    appPath,
    locationState.cellOptions,
    setEntry,
    setStationNumber,
    setShowABOption,
    triggerError,
    dispatch,
  ]);

  // Select station letter (Tapes only)
  useEffect(() => {
    if (cellLetter === null || stationNumber === null) {
      return;
    }

    const nameRegex = new RegExp(
      `([A-Za-z])+(-${stationNumber}${cellLetter})$`,
    );
    const cellIndex = locationState.cellOptions.findIndex(({ name }) =>
      nameRegex.test(name),
    );
    if (cellIndex === -1) {
      triggerError("Invalid station name");
      setStationNumber(null);
      setEntry("");
      setCell(null);
      return;
    } else {
      dispatch({ type: SET_CELL, cell: locationState.cellOptions[cellIndex] });
      dispatch({ type: PAGE_GOTO, step: 1 });
    }
  }, [
    cellLetter,
    locationState.cellOptions,
    stationNumber,
    setStationNumber,
    setEntry,
    setCell,
    dispatch,
    triggerError,
  ]);

  useEffect(() => {
    if (locationState.location !== null) {
      console.log(locationState.location);
      localStorage.setItem("location", locationState.location.id);
    }
  }, [locationState.location, usertoken, setLocationState]);

  return (
    <>
      {!["safetyscans", "audio"].includes(appPath) ? (
        <>
          {locationState.location === null && appPath === "teams" ? (
            <>
              <LocationOptions
                options={locationState.locationOptions}
                header="Select Location"
                setFn={(location: TLocation) =>
                  setLocationState((state) => ({ ...state, location }))
                }
              />
            </>
          ) : (
            <section className={"station-select__wrapper"}>
              {!showABOption ? (
                <>
                  <h1 className={"station-select__cell-header"}>
                    Select station number
                  </h1>
                  <NumberPad
                    header={""}
                    entry={entry}
                    setEntry={setEntry}
                    maxLength={3}
                    handleSubmit={submit}
                    errState={errState}
                    errMsg={errMsg}
                  />
                </>
              ) : (
                <TapesStation setCell={setCell} />
              )}
            </section>
          )}
        </>
      ) : (
        <StationScan dispatch={dispatch} />
      )}
    </>
  );
};

export default StationSelect;
