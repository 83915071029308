// Style Assets
// Components
import ReasonButton from "./reason-button";

import "./reason-group.scss";

interface ReasonGroupProps {
  headerText: string;
  reasons: { id: string; reason: string }[];
  onClick: (id: string) => void;
}

/**
 * Group of ReasonButtons with header and wrapper
 */
export const ReasonGroup = ({
  headerText,
  reasons,
  onClick,
}: ReasonGroupProps) => (
  <>
    <h1 className="reason-group__header">{headerText}</h1>
    <div className="reason-group__buttons-wrapper">
      {reasons.map(({ id, reason }) => (
        <ReasonButton key={id} reason={reason} onClick={() => onClick(id)} />
      ))}
    </div>
  </>
);

export default ReasonGroup;
