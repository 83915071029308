import {
  configureStore,
  type ThunkAction,
  type Action,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import pageReducer from "./slice/page";
import { listenerMiddleware } from "./listener";
import { api } from "./services/api";
import authReducer from "./slice/auth";
import dataReducer from "./slice/data";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    page: pageReducer,
    data: dataReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat(api.middleware),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();
