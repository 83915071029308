import { AdminApps } from "../admin";
import AppButton from "../../components/app-button";

export const AdminHome = () => {
  return (
    <section className="admin__apps-wrapper">
      {AdminApps.filter((r) => r.name !== "Admin-Home").map((route, idx) => (
        <AppButton key={idx} {...route} />
      ))}
    </section>
  );
};

export default AdminHome;
