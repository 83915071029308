import { useEffect, useState } from "react";

import useKeyEvent, { IKeyEventHook, OnKeyEventFilled } from "./use-key-event";

export const isBarcodeMatchingOrderId = (
  barcode: string,
  orderId: string,
): IsValidOrderState => {
  const validatorRE = /[0-9A-Z]+-[0-9]+/;
  const validatorREThumbdrive = /[0-9A-Z]+-TD$/;
  const [id] = barcode.split("-");

  if (id !== orderId) {
    return orderId.slice(1) === id ? "partial-id" : "id-mismatch";
  } else if (
    validatorRE.test(barcode) === false &&
    validatorREThumbdrive.test(barcode) === false
  ) {
    return "bad-scan";
  }
  return "valid";
};

type IsValidOrderState =
  | "waiting"
  | "valid"
  | "bad-scan"
  | "partial-id"
  | "id-mismatch";

interface IKeyEventBarcodeHook extends IKeyEventHook {
  isValidOrder: IsValidOrderState;
}

export const useKeyEventBarcode = (
  active: boolean,
  orderId: string,
  onFilled: OnKeyEventFilled = undefined,
): IKeyEventBarcodeHook => {
  if (typeof orderId !== "string") {
    throw Error(
      `useKeyEventBarcode requires orderId to be string but got ${typeof orderId}`,
    );
  }

  const [isValidOrder, setIsValidOrder] =
    useState<IsValidOrderState>("waiting");
  const { val, filled, clearInput, clearFilled } = useKeyEvent(
    active,
    onFilled,
  );

  useEffect(() => {
    if (active) {
      setIsValidOrder(isBarcodeMatchingOrderId(val, orderId));
    }
  }, [active, val, orderId, setIsValidOrder]);

  return {
    isValidOrder,
    val,
    filled,
    clearInput: () => {
      setIsValidOrder("waiting");
      clearInput();
    },
    clearFilled: () => {
      setIsValidOrder("waiting");
      clearFilled();
    },
    clearAll: () => {}, // stubbed functionality
  };
};

export default useKeyEventBarcode;
