import { useEffect, useRef } from "react";
import { styled } from "../../../stitches.config";

import ClearButton from "../clear-button/clear-button";

import type { MouseEvent as rme } from "react";

// import "./readonly.scss";

const Input = styled("input", {
  fontFamily: "$gothamBook",
  fontSize: "$lg",
  borderRadius: "10px",
  border: "1px solid $black",
  height: "50px",
  width: "100%",
  textAlign: "center",
  marginTop: "1px",

  ":active": {
    outline: 0,
  },
  ":focus": {
    outline: 0,
    borderWidth: "2px",
    borderColor: "$green",
  },

  "&.input-error": {
    borderWidth: "2px",
    borderColor: "$red",
    marginTop: 0,
  },
});

const ReadonlyWrapper = styled("label", {
  margin: "0 auto",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  maxWidth: "375px",
  minHeight: "80px",
  fontFamily: "$gothamBook",
  textAlign: "center",
  flexDirection: "column",

  span: {
    alignSelf: "flex-start",
    marginLeft: "1.3rem",
    color: "$darkGray",
  },

  "div.input-error-msg": {
    color: "$red",
  },
  "div.readonly-input__with-clear": {
    position: "relative",
  },
  "button.readonly-input__clear-button": {
    position: "absolute",
    top: "10%",
    right: "0",
  },
});

export interface ReadonlyInputProps {
  /** Optional label element */
  label?: string;
  /** Managed display value */
  value: string;
  /** Error state */
  isError?: boolean;
  /** Error Message */
  errorMsg?: string;
  /** Focus state */
  isFocus?: boolean;
  /** onClick handler for label focus */
  onClick?: (event: rme<HTMLLabelElement, MouseEvent>) => void;
  /** Clear button state */
  isClearButton?: boolean;
  /** Optional click handler for clearing input */
  onClearClick?: () => void;
}

export function ReadonlyInput(props: ReadonlyInputProps) {
  const {
    value = "",
    isError = false,
    isFocus = false,
    label = "",
    errorMsg = "",
    onClick = () => {},
    isClearButton = false,
    onClearClick,
  } = props;
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (isFocus) {
      ref.current.focus();
    } else {
      ref.current.blur();
    }
  });

  return (
    <ReadonlyWrapper onClick={onClick}>
      {label.length > 0 && <span>{label}: </span>}
      <div className="readonly-input__with-clear">
        <Input
          className={isError ? "input-error" : ""}
          type="text"
          value={value}
          readOnly={true}
          onBlur={() => {
            if (isFocus) {
              ref.current.focus();
              return;
            }
            ref.current.blur();
          }}
          ref={ref}
        />
        {isClearButton && (
          <ClearButton
            className="readonly-input__clear-button"
            onClick={onClearClick}
          />
        )}
      </div>

      <div className={isError ? "input-error-msg" : ""}>{errorMsg}</div>
    </ReadonlyWrapper>
  );
}

export default ReadonlyInput;
