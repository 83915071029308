import { useEffect, useState } from "react";
import { styled } from "../../../../stitches.config";
import PagedList from "../../../components/paged-list/paged-list";
import TouchButton from "../../../components/touch-button/touch-button";
import useApi from "../../../../hooks/use-api";
import { resetState } from "../../../../hooks/reducers/actions/reset-action";

// Types
import type {
  QaReducerProps,
  TQaProcessPages,
} from "../../../../hooks/reducers/qa-process";
import type { Api } from "../../../../types/api";
import { setPage } from "../../../../hooks/reducers/actions/page-step";

interface flattenedItem {
  name: string;
  quantity: number;
}

export function getInputs(items: Api.IProductionOrderItem[]): flattenedItem[] {
  return items.reduce<flattenedItem[]>((group, current) => {
    if (current.product_type_id !== 1) {
      return group;
    }
    const name = `${current.product_name} ${current.variant_name}`;
    const idx = group.findIndex((i) => i.name === name);
    if (idx === -1) {
      return [
        ...group,
        {
          name: `${current.product_name} ${current.variant_name}`,
          quantity: 1,
        },
      ];
    }

    return [
      ...group.slice(0, idx),
      { ...group[idx], quantity: group[idx].quantity + 1 },
      ...group.slice(idx + 1),
    ];
  }, []);
}

export function getOutputs(items: Api.IProductionOrderItem[]): flattenedItem[] {
  return items.reduce<flattenedItem[]>((group, current) => {
    if (current.product_type_id !== 2) {
      return group;
    }

    const product = current.product_name.replace(/Kodak Digitizing/i, "");

    return [
      ...group,
      {
        name: `${product} ${current.variant_name}`,
        quantity: current.quantity,
      },
    ];
  }, []);
}
const Row = styled("article", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "95%",
});

export function Item({ name, quantity }: flattenedItem) {
  return (
    <Row>
      {name} <span>{quantity}</span>
    </Row>
  );
}

const Wrapper = styled("section", {
  flexDirection: "row",
  width: "100%",
});

const SubmitSection = styled("section", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "2rem",
});

export default function Summary({
  state,
  dispatch,
  resetOrderApi,
}: QaReducerProps) {
  const {
    orderInfo: { order },
    processing,
  } = state;
  const finalDepartment = order.items.reduce((dept, item) => {
    // if completed by person who completed order or not an input return dept
    if (item.process_completed_by.toString() !== order.completed_by) {
      return dept;
    } else if (item.product_type_id !== 1) {
      return dept;
    }
    return item.product_name;
  }, "");

  const [send, setSend] = useState<boolean>(false);
  const { isPending, isResolved, isRejected } = useApi(
    "production/order/qa/process",
    "post",
    send,
    { "Content-Type": "application/json" },
    JSON.stringify({
      UserId: `${order.completed_by}`,
      productionOrderId: order.production_order_id,
      salesOrderId: order.sales_order_id,
      finalDepartment,
      ...processing,
    }),
  );
  const inputs = getInputs(order.items);
  const outputs = getOutputs(order.items);
  const stats = Object.keys(processing).map((key, idx) => {
    const stat = processing[key];
    return (
      <Row key={idx}>
        {key.replace(/Files/i, "")}:<span>{stat}</span>
      </Row>
    );
  });

  useEffect(() => {
    if (isResolved) {
      dispatch(resetState());
      resetOrderApi();
    } else if (isRejected) {
      // need error handling here
      console.log("Something went wrong with qa submit");
    }
  }, [isResolved, isRejected, resetOrderApi, dispatch]);

  return (
    <>
      <Wrapper>
        <PagedList
          list={inputs.map((input, idx) => (
            <Item key={idx} {...input} />
          ))}
        />
        <PagedList
          list={outputs.map((output, idx) => (
            <Item key={idx} {...output} />
          ))}
        />
        <PagedList list={stats} />
      </Wrapper>
      <SubmitSection>
        <TouchButton
          disabled={isPending}
          onClick={() => {
            dispatch(setPage<TQaProcessPages>("files"));
          }}
        >
          Back
        </TouchButton>
        <span style={{ width: "2em" }}></span>
        <TouchButton
          disabled={isPending}
          onClick={() => {
            console.log("click");
            setSend(true);
          }}
        >
          Submit Review
        </TouchButton>
      </SubmitSection>
    </>
  );
}
