// Action Types for category
export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const SELECT_FORMAT = "SELECT_FORMAT";
export const ADD_FORMAT_ITEM = "ADD_FORMAT_ITEM";
export const REMOVE_FORMAT_ITEM = "REMOVE_FORMAT_ITEM";
export const SET_ITEM_COUNT = "SET_ITEM_COUNT";
export const RESET_ITEMS = "RESET_ITEMS";

export interface BarcodeItem {
  id: string;
  code: string;
  error?: string;
}

interface InputFormat {
  format: string;
  quantity: number;
  items: BarcodeItem[];
  variantId: number;
}

export interface InputCategory {
  formats: InputFormat[];
  category: string;
  quantity: number;
  productId: number;
}

type ScannedBarcode = Partial<BarcodeItem>;

export interface CategoryState {
  selectedCategory: string;
  selectedFormat: string;
  categories: InputCategory[];
  totalItemCount: number;
  barcodes: ScannedBarcode[];
  // activeStep: number;
}

export const getItemCount = (categories: InputCategory[]): number => {
  let count = 0;
  categories.forEach((category) => {
    count += category.quantity;
  });
  return count;
};

export const selectCategory = (
  state: CategoryState,
  action: SelectCategoryAction,
): {
  selectedCategory: string;
  selectedFormat: string;
} => {
  let newFormat = action.selectedFormat;
  if (state.selectedCategory !== action.selectedCategory) {
    newFormat = "";
  }
  return {
    selectedCategory: action.selectedCategory,
    selectedFormat: newFormat,
  };
};

export const selectFormat = (
  _: CategoryState,
  { selectedFormat }: Partial<CategoryState>,
): Partial<CategoryState> => ({ selectedFormat });

export const addFormatItem = (
  { categories, barcodes }: CategoryState,
  { category, format, item }: AddFormatItemAction,
): Partial<CategoryState> => {
  // get category index or return current state
  const catIndex = categories.findIndex((c) => c.category === category);
  if (catIndex === -1) {
    return {};
  }

  // get format index in category or return current state
  const formatIndex = categories[catIndex].formats.findIndex(
    (f) => f.format === format,
  );
  if (formatIndex === -1) {
    return {};
  }

  // map new category data
  const newCat = categories.map((current, index) => {
    if (index !== catIndex) {
      return current;
    }
    // map new format data
    const newFormats = current.formats.map((c, i) => {
      if (i !== formatIndex) {
        return c;
      }
      return { ...c, quantity: c.quantity + 1, items: [...c.items, item] };
    });
    return { ...current, quantity: current.quantity + 1, formats: newFormats };
  });
  return {
    categories: newCat as CategoryState["categories"],
    barcodes: [...barcodes, item] as CategoryState["barcodes"],
  };
};

export const removeFormatItem = (
  { categories, barcodes }: CategoryState,
  {
    category,
    format,
    item,
  }: { category: string; format: string; item: string },
): Partial<CategoryState> => {
  // get category index or return current state
  const catIndex = categories.findIndex((c) => c.category === category);
  if (catIndex === -1) {
    return {};
  }

  // get format index in category or return current state
  const formatIndex = categories[catIndex].formats.findIndex(
    (f) => f.format === format,
  );
  if (formatIndex === -1) {
    return {};
  }

  // get last item index (to delete errors first)
  // in format or return current state
  const items = categories[catIndex].formats[formatIndex].items;
  const itemIndex = Math.max(
    items.findIndex((i) => i.code === item),
    items.findIndex((i) => i.code === item && i.error),
  );
  if (itemIndex === -1) {
    return {};
  }

  // get last barcode index (to delete errors first) in barcodes
  const barcodeIndex = Math.max(
    barcodes.findIndex((b) => b.code === item),
    barcodes.findIndex((b) => b.code === item && b.error),
  );

  // map new category data
  const newCat = categories.map((current, index) => {
    if (index !== catIndex) {
      return current;
    }
    // map new format data
    const newFormats = current.formats.map((c, i) => {
      if (i !== formatIndex) {
        return c;
      }
      return {
        ...c,
        quantity: c.quantity - 1,
        items: [
          ...c.items.slice(0, itemIndex),
          ...c.items.slice(itemIndex + 1),
        ],
      };
    });
    return { ...current, quantity: current.quantity - 1, formats: newFormats };
  });

  const newBarcodes = [
    ...barcodes.slice(0, barcodeIndex),
    ...barcodes.slice(barcodeIndex + 1),
  ];

  return {
    categories: newCat as CategoryState["categories"],
    barcodes: newBarcodes as CategoryState["barcodes"],
  };
};

export const reset = () => categoryInitialState;

export const categoryInitialState: CategoryState = {
  selectedCategory: "",
  selectedFormat: "",
  categories: [
    {
      formats: [
        {
          format: "VHS",
          quantity: 0,
          items: [],
          variantId: 213,
        },
        {
          format: "VHS-C",
          quantity: 0,
          items: [],
          variantId: 214,
        },
        {
          format: "MiniDV",
          quantity: 0,
          items: [],
          variantId: 215,
        },
        {
          format: "8mm",
          quantity: 0,
          items: [],
          variantId: 216,
        },
        {
          format: "Beta",
          quantity: 0,
          items: [],
          variantId: 217,
        },
        {
          format: "MicroMV",
          quantity: 0,
          items: [],
          variantId: 218,
        },
      ],
      category: "Tapes",
      quantity: 0,
      productId: 47,
    },
    {
      formats: [
        {
          format: '8mm - 3"',
          quantity: 0,
          items: [],
          variantId: 220,
        },
        {
          format: '16mm - 3"',
          quantity: 0,
          items: [],
          variantId: 221,
        },
        {
          format: '8mm - 5"',
          quantity: 0,
          items: [],
          variantId: 222,
        },
        {
          format: '16mm - 5"',
          quantity: 0,
          items: [],
          variantId: 223,
        },
        {
          format: '8mm - 7"',
          quantity: 0,
          items: [],
          variantId: 224,
        },
        {
          format: '16mm - 7"',
          quantity: 0,
          items: [],
          variantId: 225,
        },
      ],
      category: "Film",
      quantity: 0,
      productId: 48,
    },
    {
      formats: [
        {
          format: "Prints",
          quantity: 0,
          items: [],
          variantId: 226,
        },
        {
          format: "Slides",
          quantity: 0,
          items: [],
          variantId: 227,
        },
        {
          format: "Negatives",
          quantity: 0,
          items: [],
          variantId: 228,
        },
      ],
      category: "Photos",
      quantity: 0,
      productId: 49,
    },
    {
      formats: [
        {
          format: "Cassette",
          quantity: 0,
          items: [],
          variantId: 229,
        },
        {
          format: "Small Reel",
          quantity: 0,
          items: [],
          variantId: 230,
        },
        {
          format: "Large Reel",
          quantity: 0,
          items: [],
          variantId: 231,
        },
        {
          format: "Micro Cassette",
          quantity: 0,
          items: [],
          variantId: 232,
        },
      ],
      category: "Audio",
      quantity: 0,
      productId: 50,
    },
    {
      formats: [
        {
          format: "Tapes",
          quantity: 0,
          items: [],
          variantId: 327,
        },
        {
          format: "Film",
          quantity: 0,
          items: [],
          variantId: 328,
        },
        {
          format: "Photos",
          quantity: 0,
          items: [],
          variantId: 329,
        },
        {
          format: "Audio",
          quantity: 0,
          items: [],
          variantId: 330,
        },
        {
          format: "Other",
          quantity: 0,
          items: [],
          variantId: 331,
        },
      ],
      category: "Unsupported",
      quantity: 0,
      productId: 51,
    },
  ],
  totalItemCount: null,
  barcodes: [],
};

export const setTotalItemCount = (
  _: CategoryState,
  { count }: { count: number },
): { totalItemCount: number } => {
  return { totalItemCount: count };
};

export interface SelectFormatAction {
  type: "SELECT_FORMAT";
}
export interface SelectCategoryAction {
  type: "SELECT_CATEGORY";
  selectedCategory: CategoryState["selectedCategory"];
  selectedFormat: CategoryState["selectedFormat"];
}

interface FormatItem {
  code: string;
  error?: string;
}

export interface AddFormatItemAction {
  type: "ADD_FORMAT_ITEM";
  category: string;
  format: string;
  item: FormatItem;
}
export interface RemoveFormatItemAction {
  type: "REMOVE_FORMAT_ITEM" | "ADD_FORMAT_ITEM";
  category: string;
  format: string;
  item: FormatItem | string;
}

export interface SetItemCountAction {
  type: "SET_ITEM_COUNT";
  count: number;
}

export type CategoryActions =
  | SelectFormatAction
  | SelectCategoryAction
  | AddFormatItemAction
  | RemoveFormatItemAction
  | SetItemCountAction;

export const categoryActions = {
  SELECT_CATEGORY: selectCategory,
  SELECT_FORMAT: selectFormat,
  ADD_FORMAT_ITEM: addFormatItem,
  REMOVE_FORMAT_ITEM: removeFormatItem,
  SET_ITEM_COUNT: setTotalItemCount,
  RESET_ITEMS: reset,
};
