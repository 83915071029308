import "./show-error.scss";

// Components
import OrderError from "../../groups/order-error";

// Hooks
import { RESET } from "../../../hooks/reducers/safetyscans";

export const ShowError = ({
  errMsg,
  errHeader,
  orderInfo,
  departmentsData,
  dispatch,
}) => {
  errHeader = errHeader ?? "";
  errMsg = errMsg ?? "Please chat with your manager to see what to do next.";

  if (orderInfo.isShipped) {
    errHeader = "This order has already been tagged as shipped in our system.";
  } else if (departmentsData.validForOrder === false) {
    const { departmentName } = departmentsData;

    errHeader = `This order has no analog items for ${departmentName}.`;
  }

  return (
    <section className={"show-error__wrapper"}>
      <OrderError
        header={errHeader}
        message={errMsg}
        onClick={() => {
          dispatch({ type: RESET });
        }}
      />
    </section>
  );
};

export default ShowError;
