import YesNoButtons from "../../../components/yes-no-buttons";

interface IncludedPromptProps {
  onClick: (bool: boolean) => void;
  isYesDisabled?: boolean;
}

const IncludedPrompt = ({ onClick, isYesDisabled }: IncludedPromptProps) => (
  <>
    <h1 className="deliverables__subheader">
      Are these all included in the order?
    </h1>
    <YesNoButtons
      isYesDisabled={isYesDisabled}
      yesClick={() => onClick(true)}
      noClick={() => onClick(false)}
    />
  </>
);

export default IncludedPrompt;
