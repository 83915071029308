import { SetStateAction, useState } from "react";
import type { Dispatch } from "react";

import { KeyContextProvider } from "../../../../hooks/context/scan";
import {
  OrderViewActions,
  RESOLVE_ALERT,
} from "../../../../hooks/reducers/order-view";
import useError from "../../../../hooks/use-error";
import { css, styled } from "../../../../stitches.config";
import type { Api } from "../../../../types/api";
import Button from "../../../components/button/button";
import NumberPad from "../../../groups/number-pad/pad";

const alertsCode = import.meta.env.VITE_APP_ALERT_CODE;
export interface ResolveItemProps {
  order: Api.IProductionOrder;
  dispatch: Dispatch<OrderViewActions>;
  usertoken: string;
  alert: Api.IShipmentAlert;
}

const itemSection = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  border: "2px solid $lightGray",
  backgroundColor: "$white",
  borderRadius: "10px",
  padding: "5px 20px",
  height: "8vh",
  width: "90%",
  margin: "5px 0",
});

const ResolveButton = styled(Button, {
  height: "6vh",
  width: "6vh",
  backgroundColor: "$green",
  fontSize: "$md",
  color: "$white",
  border: "2px solid $white",
  borderRadius: "50%",
  //util
  boxShadow: `0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 6px rgba(0, 0, 0, 0.23)`,
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#33bfa8",
  },

  variants: {
    resolving: {
      true: {
        backgroundColor: "$darkGray",
      },
    },
  },
});

async function handleSubmit(
  orderId: string,
  id: number,
  usertoken: string,
  dispatch: Dispatch<OrderViewActions>,
  onComplete: () => void,
) {
  const res = await fetch(
    `https://${
      import.meta.env.VITE_APP_API_URI
    }/shipments/alerts/${orderId}/${id}`,
    {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
      }),
    },
  );
  if (!res.ok) {
    throw new Error(`Fetch Error [${res.status}]: ${res.statusText}`);
  }
  dispatch({ type: RESOLVE_ALERT, id });
  onComplete();
}

export function ResolveItem({
  order,
  usertoken,
  alert,
  dispatch,
}: ResolveItemProps) {
  const [status, setStatus] = useState<"idle" | "resolving" | "resolved">(
    "idle",
  );

  const handler = () => {
    if (status !== "idle") {
      return;
    }
    setStatus("resolving");
    handleSubmit(
      order.production_order_id,
      alert.alert_id,
      usertoken,
      dispatch,
      () => setStatus("resolved"),
    );
  };
  return (
    <section className={itemSection()}>
      <article>{alert.reason}</article>
      <ResolveButton onClick={handler} resolving={status === "resolving"}>
        ✓
      </ResolveButton>
    </section>
  );
}

const alertsAuthContainer = css({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "2vh",
});

export interface AlertsAuthProps {
  onSubmit: Dispatch<SetStateAction<boolean>>;
}
export function AlertsAuth({ onSubmit }: AlertsAuthProps) {
  const { trigger, clear, ...err } = useError();

  const submit = (value: string) => {
    clear();

    if (import.meta.env.MODE !== "production") {
      console.log(value, alertsCode, value === alertsCode);
    }

    if (value === alertsCode) {
      return onSubmit(true);
    }
    onSubmit(false);
    trigger("Code does not match");
  };

  return (
    <section className={alertsAuthContainer()}>
      <NumberPad
        header="Input Auth Code"
        onSubmit={submit}
        maxLength={5}
        onClear={clear}
        {...err}
      />
    </section>
  );
}

const resolveAlerts = css({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  height: "100%",
  alignItems: "flex-start",
  justifyContent: "center",
  marginTop: "2vh",

  header: {
    padding: "10px",
    width: "100%",
    fontSize: "$md",
    color: "$darkGray",
  },

  ".resolve-list-box": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxHeight: "80%",
    overflowY: "scroll",
  },
});

export interface AlertsResolveProps {
  order: Api.IProductionOrder;
  dispatch: Dispatch<OrderViewActions>;
  usertoken: string;
  alerts: Api.IShipmentAlert[];
}
export function AlertsResolve({ alerts, ...props }: AlertsResolveProps) {
  const [auth, setAuth] = useState(false);

  const items = alerts.filter((alert) => alert.active);

  if (!auth) {
    return (
      <KeyContextProvider>
        <AlertsAuth onSubmit={setAuth} />
      </KeyContextProvider>
    );
  }

  return (
    <section className={resolveAlerts()}>
      <header>Resolve Alerts</header>
      <article className="resolve-list-box">
        {items.map((alert) => (
          <ResolveItem key={alert.alert_id} alert={alert} {...props} />
        ))}
      </article>
    </section>
  );
}
