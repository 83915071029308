import { DisplayGoButton } from "../../safetyscans";
import ScanItems from "../../safetyscans/scan-items";
import type { ReshipActivePageProps } from "../reship";

export const ScanReshipItems = ({ state, dispatch }: ReshipActivePageProps) => {
  const currentStep = state.steps.find((s) => s.name === state.activePage);
  let active = false;
  if (currentStep !== undefined) {
    active = currentStep.canAdvance;
  }
  return (
    <>
      <ScanItems {...state} dispatch={dispatch} />
      <section className="reship-order__scan-items__go-btn">
        <DisplayGoButton visible={true} active={active} dispatch={dispatch} />
      </section>
    </>
  );
};
export default ScanReshipItems;
