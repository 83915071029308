import { useState } from "react";
import "./user-header.scss";
import Icon from "../base/icon";
import AppDrawer from "./app-drawer";

import { useLogin } from "../../hooks/context/login";

import type * as React from "react";

type MenuEvent = React.MouseEvent<HTMLDivElement>;

/**
 * User info container
 */
export const UserHeader = () => {
  const [{ username }] = useLogin();
  const [showMenu, setShowMenu] = useState(false);
  const menuHandler = (_event: MenuEvent) => {
    if (username === undefined || username === "") {
      return;
    }
    setShowMenu((s) => !s);
  };
  return (
    <>
      <article className="user-header">
        <div className="user-header__username">{username}</div>
        <div className="user-header__avatar" onClick={menuHandler}>
          <Icon name="avatar" />
        </div>
      </article>
      <AppDrawer show={showMenu} setShow={setShowMenu} />
    </>
  );
};

export default UserHeader;
