// Hooks
import { useState } from "react";
import useApi from "../../../../../hooks/use-api";
// Types
import type { TShipBalanceDueState } from "../../../../../hooks/reducers/admin/ship-balance-due";

/** Create shipment alerts */
export const useShippingError = (order: TShipBalanceDueState["order"]) => {
  const [createAlert, setCreateAlert] = useState(false);
  useApi<any>(
    `shipments/alerts/${order.sales_order_id}`,
    "post",
    createAlert,
    { "Content-Type": "application/json" },
    JSON.stringify({
      alertReasonId: 3,
    }),
  );

  return {
    setCreateAlert,
  };
};
