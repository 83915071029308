import { dateTimeFormat } from "../../../utils/date-helper";
import Card from "../../groups/card";

// move types to reducer when it's converted to TS

interface Checkin {
  action: string;
  location: string;
  timestamp: string;
  userId: string;
}

interface CheckinsLocationsProps {
  state: {
    checkinData?: {
      checkins: Checkin[];
    };
  };
  checkinLoaded: boolean;
}

const shortenLocation = (location: string): string => {
  let displayLocation = location;

  const parts = location.split(" ");

  if (parts[2] !== undefined) {
    displayLocation = parts[2];
  }
  return displayLocation;
};

export const CheckinsLocations = ({
  state,
  checkinLoaded = true,
}: CheckinsLocationsProps) => {
  const { checkins = [] } = state.checkinData ?? {};

  if (!checkinLoaded) {
    return <article>Getting checkins...</article>;
  }

  if (checkins.length === 0) {
    return <article>No checkins found...</article>;
  }

  return (
    <Card header="Checkins" size="regular">
      <ul className="card__list">
        {checkins.map((checkin) => (
          <li key={checkin.timestamp}>
            <span className="left_wrapper">
              <span className="checkin__location">
                {shortenLocation(checkin.location)}
              </span>
            </span>
            <span className="right_wrapper">
              <span className="checkin__action">{checkin.action}</span>
              <span className="checkin__timestamp">
                {dateTimeFormat(checkin.timestamp)}
              </span>
            </span>
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default CheckinsLocations;
