import { HeaderGroup } from "react-table";
import classNames from "classnames";

export interface TableHeaderProps<HeaderType extends object> {
  headerGroups: HeaderGroup<HeaderType>[];
  classPrefix: string;
}
export function TableHeader<HeaderType extends object>(
  props: TableHeaderProps<HeaderType>,
) {
  return (
    <thead>
      {props.headerGroups.map((headerGroup: HeaderGroup<HeaderType>) => (
        <tr
          className={`${props.classPrefix}__table-header-row`}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column: HeaderGroup<HeaderType>) => (
            <th
              key={column.id}
              className={`${props.classPrefix}__table-header`}
            >
              <header {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span
                  className={classNames(
                    `${props.classPrefix}__table-sort-indicator`,
                    {
                      isSorted: column.isSorted,
                      isSortedDesc: column.isSortedDesc,
                    },
                  )}
                >
                  {column.isSorted ? (column.isSortedDesc ? "▼" : "▲") : ""}
                </span>
              </header>
              <div className={`${props.classPrefix}__table-header-filter`}>
                {column.canFilter ? column.render("Filter") : null}
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}
export default TableHeader;
