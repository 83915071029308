import Avatar from "./icons/avatar";
import HomeIcon from "./icons/home";
import Kodak from "./icons/kodak";
import Legacybox from "./icons/legacybox";
import Southtree from "./icons/southtree";

import "./icon.scss";

/**
 *
 * @param {Object} props
 * @param {string} props.name Name of icon to retrieve.
 */
export const Icon = ({ name }: { name: string }) => {
  switch (name) {
    case "southtree":
      return <Southtree />;
    case "legacybox":
      return <Legacybox />;
    case "kodak":
      return <Kodak />;
    case "avatar":
      return <Avatar className="avatar-icon" />;
    case "home":
      return <HomeIcon />;
    default:
      return <Southtree />;
  }
};

export default Icon;
