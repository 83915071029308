import { useState } from "react";

import { useLogin } from "../../../../hooks/context/login";
import {
  RESET_ORDER,
  STEP_BACK_DISPLAY,
} from "../../../../hooks/reducers/admin/edit-formats";
import {
  printQRCodeThumbdriveSlip,
  reprintSlip,
} from "../../../../utils/dymo-print";
import BaseButton from "../../../base/button";
import ItemList from "../../../components/item-list";
import Tabs from "../../../components/tabs";

const { VITE_APP_API_URI } = import.meta.env;

/**
 * @param {Object} props
 * @param {?string} props.label - label for tabs interface
 * @param {any} props.list - category list
 */
const ReviewPage = ({ list }) => {
  const itemCount = list.reduce((total, { quantity }) => total + quantity, 0);
  return (
    <section className="edit-items__review-step">
      <article className="edit-items__review-lists">
        <div className="review-order__list">
          <h1 className="review-order__list-header">Analog Items</h1>
          <ItemList
            items={list}
            textField="format"
            totalQuantity={itemCount}
            pageSize={10}
          />
        </div>
      </article>
    </section>
  );
};

/**
 * @param {Object} props
 * @param {?string} props.label - label for tabs interface
 * @param {Object.<string,FlattenedUpdate[]>} props.lists
 */
export const UpdatePage = ({ lists: { unchanged, edited, newList } }) => {
  return (
    <section className="edit-items__update-step">
      <article className="edit-items__update-column edit-items__same">
        <h2>Unchanged Items</h2>
        {unchanged.length > 0 && (
          <ItemList
            items={unchanged}
            textField="display"
            totalQuantity={unchanged.length}
            pageSize={10}
          />
        )}
      </article>
      <article className="edit-items__update-column edit-items__new">
        <h2>Newly Scanned</h2>
        {newList.length > 0 && (
          <ItemList
            items={newList}
            textField="display"
            totalQuantity={newList.length}
            pageSize={10}
          />
        )}
      </article>
      <article className="edit-items__update-column edit-items__changed">
        <h2>Updated Item Types</h2>
        {edited.length > 0 && (
          <ItemList
            items={edited}
            textField="newDisplay"
            totalQuantity={edited.length}
            pageSize={10}
          />
        )}
      </article>
    </section>
  );
};

/**
 * @typedef {Object} FlattenedScans
 * @property {string} category - readable category
 * @property {string} format - readable format
 * @property {number} productId - DB category
 * @property {number} variantId - DB variant
 * @property {number} quantity - number of barcodes in this format
 * @property {Array.<{code:string}>} items - group of scanned barcodes
 */

/**
 * @typedef {Object} FlattenedUpdate
 * @property {string} category - readable category
 * @property {string} format - readable format
 * @property {number} productId - DB category
 * @property {number} variantId - DB variant
 * @property {string} item_barcode - Scanned code for item
 * @property {number} quantity - Display quantity for item list
 */

/**
 * Takes initial flattened step one level deeper to handle
 * change tracking for the update screen
 * @param {FlattenedScans[]} group
 * @returns {FlattenedUpdate[]}
 */
export const flattenUpdateList = (group) =>
  group.reduce(
    /**
     * @param {FlattenedUpdate[]} c
     * @param {FlattenedScans} a
     */
    (c, a) => {
      const { items } = a;
      /** @type {FlattenedUpdate[]} */
      const list = [];
      items.forEach(({ code }) => {
        const flatItem = { ...a, item_barcode: code };
        delete flatItem.items;
        flatItem.quantity = 1;
        list.push(flatItem);
      });
      return [...c, ...list];
    },
    [],
  );

/**
 * Build updates record to send to backend.
 * @param {FlattenedScans[]} list
 * @param {import('../../../../hooks/reducers/admin/edit-formats').EditFormatsState} state
 */
export const formatUpdates = (list, { currentItems }) => {
  const unchanged = [];
  const edited = [];
  const items = flattenUpdateList(list);

  const newList = items.reduce((group, cur) => {
    // find matched barcode
    const sameItem = currentItems.find(
      (i) => i.item_barcode === cur.item_barcode,
    );
    // if no match add to new item list
    if (sameItem === undefined) {
      return [
        ...group,
        { ...cur, display: `${cur.item_barcode}: ${cur.format}` },
      ];
      // if scanned item same as originally received add to unchanged list
    } else if (sameItem.variant_id === cur.variantId) {
      unchanged.push({
        ...sameItem,
        display: `${sameItem.item_barcode}: ${sameItem.variant}`,
      });
      return group;
    }
    console.log(cur);
    // build edited item.
    const editedItem = {
      ...sameItem,
      newVariantId: cur.variantId,
      newProductId: cur.productId,
      newProduct: cur.category,
      newVariant: cur.format,
      newDisplay: (
        <span className="edit-items__updated-text">
          {cur.item_barcode}:{" "}
          <span className="edit-items__updated-text--rm">
            {sameItem.variant}
          </span>
          {" ->"}
          <span className="edit-items__updated-text--add">{cur.format}</span>
        </span>
      ),
    };
    edited.push(editedItem);
    return group;
  }, []);

  return { unchanged, edited, newList };
};

const debugForceDelay = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), 2500);
  });

export const handlePost = async (
  { unchanged, edited, newList },
  usertoken,
  state,
  dispatch,
  setComplete,
) => {
  const { orderId } = state;
  // remove display react element.
  try {
    const editedItems = edited.map((item) => {
      delete item.newDisplay;
      return item;
    });
    const response = await fetch(
      `https://${VITE_APP_API_URI}/admin/${orderId}/edit-items`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          unchanged: [...unchanged, ...state.outputs],
          edited: editedItems,
          newList,
        }),
      },
    );
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    // delay printing - workaround against possible race condition
    await debugForceDelay();

    const printMsg = await reprintSlip(usertoken, orderId);
    console.log(printMsg);

    if (printMsg.printComplete) {
      await printQRCodeThumbdriveSlip(usertoken, orderId);
    }
    dispatch({ type: RESET_ORDER });
  } catch (error) {
    setComplete(false);
    console.log(error.message);
  }
};

export const Review = ({ state, dispatch }) => {
  const [{ usertoken }] = useLogin();
  const { categories } = state;
  const [complete, setComplete] = useState(false);

  /** @type {FlattenedScans[]} */
  const formatsList = categories.flatMap(({ productId, category, formats }) =>
    formats
      .filter(({ quantity }) => quantity > 0)
      .map((i) => ({ ...i, productId, category })),
  );

  const updateLists = formatUpdates(formatsList, state);

  return (
    <section className="edit-items__review">
      <Tabs classPrefix={"edit-items"}>
        <ReviewPage label="Review" list={formatsList} />
        <UpdatePage label="Update" lists={updateLists} />
      </Tabs>
      <section className="edit-items__review-buttons">
        <BaseButton
          className="edit-items__prev-page"
          onClick={() => dispatch({ type: STEP_BACK_DISPLAY })}
        >
          Back
        </BaseButton>
        <BaseButton
          className={`edit-items__next-page${complete ? " inactive" : ""}`}
          onClick={
            !complete
              ? () => {
                  setComplete(true);
                  handlePost(
                    updateLists,
                    usertoken,
                    state,
                    dispatch,
                    setComplete,
                  );
                }
              : undefined
          }
        >
          Save
        </BaseButton>
      </section>
    </section>
  );
};

export default Review;
