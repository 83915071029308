import { useEffect, useState } from "react";
import type { Dispatch, MouseEvent as rme } from "react";

import { useLogin } from "../../../hooks/context/login";
import {
  SET_ORDER,
  SET_RETURN,
  SET_TRACKING_ID,
} from "../../../hooks/reducers/actions/receiving/order-info";
import {
  PAGE_BTN_ACTIVE,
  PAGE_GOTO,
} from "../../../hooks/reducers/actions/receiving/page";
import { RESET_ORDER } from "../../../hooks/reducers/receiving";
import useError from "../../../hooks/use-error";
import useKeyEvent from "../../../hooks/use-key-event";
import useTimeout from "../../../hooks/use-timeout";
import type { Api } from "../../../types/api";
import { printErrorSlip } from "../../../utils/dymo-print";
import BaseButton from "../../base/button";
import TextInput from "../../base/text-input";

import "./scan-tracking.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

type ClearButtonProps = {
  clear: () => void;
};
const ClearButton = ({ clear }: ClearButtonProps) => {
  const click = (event: rme<HTMLButtonElement, MouseEvent>) => {
    clear();
    event.currentTarget.blur();
  };

  return (
    <BaseButton onClick={click} className="scan-tracking__clear-button">
      X
    </BaseButton>
  );
};

type FailButtonProps = {
  onClick: () => void;
};
const FailButton = ({ onClick }: FailButtonProps) => {
  const click = (event: rme<HTMLButtonElement, MouseEvent>) => {
    onClick();
    event.currentTarget.blur();
  };

  return (
    <BaseButton onClick={click} className="scan-tracking__fail-button">
      UNABLE TO SCAN
    </BaseButton>
  );
};

type TrackingInputProps = {
  className: string;
  clear: () => void;
  error: boolean;
  errorMsg: string;
  showClearButton: boolean;
  val: string;
};
const TrackingInput = ({
  className,
  error,
  errorMsg,
  val,
  clear,
  showClearButton,
}: TrackingInputProps) => {
  return (
    <div className="scan-tracking__tracking-input-wrapper">
      <TextInput
        className={className}
        val={val}
        error={error}
        errorMsg={errorMsg}
      />
      {showClearButton && <ClearButton clear={clear} />}
    </div>
  );
};

type ScanTrackingProps = {
  orderInfo: any;
  checkinData: any;
  dispatch: Dispatch<any>;
};
export const ScanTracking = ({
  orderInfo,
  checkinData,
  dispatch,
}: ScanTrackingProps) => {
  const [{ isAdminLevel, usertoken }] = useLogin();
  const [gotoTrackingFail, setGotoTrackingFail] = useState(false);
  const [alreadyReceived, setAlreadyReceived] = useState(false);
  const [unactivated, setUnactivated] = useState(false);
  const { val: scan, filled, clearAll: clearScan } = useKeyEvent(true);

  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();

  // If scan error go to tracking fail bypass page
  useTimeout(
    () => dispatch({ type: PAGE_GOTO, step: 6 }),
    gotoTrackingFail,
    1000,
  );

  // If already received, reset receiving
  useTimeout(
    () => {
      clearError();
      dispatch({ type: RESET_ORDER });
      setAlreadyReceived(false);
      clearScan();
    },
    alreadyReceived,
    1000,
  );

  useTimeout(
    () => {
      clearError();
      clearScan();
      setUnactivated(false);
      dispatch({ type: RESET_ORDER });
    },
    unactivated,
    1000,
  );

  useEffect(() => {
    if (unactivated) {
      const orderInfoText =
        orderInfo && orderInfo.orderId
          ? ` \n${orderInfo.orderId}: ${orderInfo.name}`
          : "";
      printErrorSlip(
        "CANNOT RECEIVE UNACTIVATED ORDER",
        `Missing customer information${orderInfoText}`,
      );
    }
  }, [unactivated, orderInfo]);

  useEffect(() => {
    // early return if tracking scan complete.
    if (orderInfo && orderInfo.trackingId) {
      return;
    }

    const getOrder = async (entry: string) => {
      try {
        const encode = encodeURIComponent(
          JSON.stringify({
            type: "scan",
            data: JSON.parse(entry),
          }),
        );
        const response = await fetch(
          `https://${VITE_APP_API_URI}/digitization/receiving/start/${encode}`,
          {
            method: "GET",
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
            }),
          },
        );

        const isOrderFlagCyberWeek =
          response.headers.get("x-order-flag") === "CYBER WEEK";

        if (isOrderFlagCyberWeek) {
          dispatch({ type: PAGE_GOTO, step: 13 });
          return;
        }

        if (!response.ok) {
          throw new Error("Tracking Not Found");
        }

        const { order }: { order: Api.IProductionOrder } =
          await response.json();

        if (order.is_received) {
          triggerError("Order has already been received");
          setAlreadyReceived(true);
          return;
        } else if (order.shipping_address.email.startsWith("activate@")) {
          triggerError("Order is missing information.");
          setUnactivated(true);
          return;
        }
        // Add data
        dispatch({ type: SET_TRACKING_ID, trackingId: entry });
        dispatch({ type: SET_ORDER, payload: order });

        // Return order flow
        if (order.is_return) {
          if (!isAdminLevel) {
            return dispatch({ type: PAGE_GOTO, step: 12 });
          }
          const res = await fetch(
            `https://${VITE_APP_API_URI}/digitization/receiving/order/${order.production_order_id}/return-note`,
            {
              method: "GET",
              headers: new Headers({
                Authorization: `Bearer ${usertoken}`,
              }),
            },
          );

          if (!res.ok) {
            dispatch({ type: PAGE_GOTO, step: 8 });
            dispatch({
              type: SET_RETURN,
              payload: {
                returnNote: "No note in system.",
                returnReasons: "Unknown",
              },
            });
            return;
          }
          const retData = await res.json();

          dispatch({ type: PAGE_GOTO, step: 8 });
          dispatch({
            type: SET_RETURN,
            payload: {
              returnNote: retData.return_note,
              returnReason: retData.return_reason,
            },
          });
        }

        if (checkinData.workflow === "Receiving") {
          fetch(`https://${VITE_APP_API_URI}/departments/checkin`, {
            method: "POST",
            body: JSON.stringify({
              scan: checkinData.cell.id,
              orderId: order.production_order_id,
              status: "IN",
            }),
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
              "Content-Type": "application/json",
            }),
          });
        }

        // Standard order flow.
        dispatch({ type: PAGE_BTN_ACTIVE, active: true });
      } catch (error) {
        console.log(error);
        let message = "Error";
        if (error instanceof Error) {
          message = error.message;
        }
        triggerError(message);
        setGotoTrackingFail(true);
      }
    };

    const getReceivedOrder = async (entry: string) => {
      try {
        const response = await fetch(
          `https://${VITE_APP_API_URI}/digitization/receiving/order/${entry}`,
          {
            method: "GET",
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
            }),
          },
        );

        if (!response.ok) {
          throw new Error("Order Not Found");
        }

        dispatch({ type: PAGE_BTN_ACTIVE, active: true });

        const data = await response.json();
        const payload = JSON.parse(data);

        dispatch({ type: SET_ORDER, payload: payload });
        dispatch({ type: PAGE_GOTO, step: 9 });
      } catch (error) {
        console.log(error);
        triggerError((error as Error).message);
      }
    };

    if (filled && orderInfo.trackingId === null) {
      console.log("trigger-api-req");
      // move this out of here into admin console?
      // admin re-receive order functionality
      if (
        isAdminLevel &&
        scan.charAt(0) !== "{" &&
        scan.length < 12 &&
        !scan.startsWith("1Z")
      ) {
        getReceivedOrder(scan);
      } else {
        console.log("order-scan");
        getOrder(scan);
      }
    }
  }, [
    orderInfo,
    dispatch,
    triggerError,
    usertoken,
    setUnactivated,
    isAdminLevel,
    checkinData,
    filled,
    scan,
  ]);

  const textInputClassName =
    orderInfo && orderInfo.trackingId && !errState
      ? "scan-tracking__text-input-valid"
      : "scan-tracking__text-input";

  return (
    <>
      <h1 className="scan-tracking__header">
        Start by scanning tracking label QR code
      </h1>
      <div className="scan-tracking__message">
        Located at the bottom center of label.
      </div>
      <TrackingInput
        className={textInputClassName}
        val={(orderInfo && orderInfo.trackingId) || scan}
        error={errState}
        errorMsg={errMsg}
        clear={clearScan}
        showClearButton={orderInfo.trackingId === null}
      />
      {orderInfo.trackingId === null ? (
        <FailButton onClick={() => setGotoTrackingFail(true)} />
      ) : (
        <></>
      )}
    </>
  );
};

export default ScanTracking;
