import { useEffect, useState } from "react";

import { useLogin } from "../../hooks/context/login";
import { SET_PRODUCTION_ORDER } from "../../hooks/reducers/order-view";
import BaseButton from "../base/button";

import "./address-edit.scss";

const { VITE_APP_API_URI } = import.meta.env;

const AddressInput = ({ label, value, onChange, clear, required = true }) => {
  const requiredClass = required && !value ? " invalid" : "";
  return (
    <section className={`edit-address__input-wrapper${requiredClass}`}>
      <label className={`edit-address__input-label${requiredClass}`}>
        {required && !value ? `${label} (required)` : label}
      </label>
      <input
        tabIndex={1}
        type="text"
        className={`edit-address__input`}
        value={value}
        onChange={onChange}
      />
      <button
        tabIndex={0}
        className={"edit-address__clear-btn"}
        onClick={clear}
      >
        {"✕"}
      </button>
    </section>
  );
};
const Fields = [
  { label: "First name", field: "firstname" },
  { label: "Last name", field: "lastname" },
  { label: "Address", field: "address" },
  {
    label: "Apartment, Suite, Unit (optional)",
    field: "address1",
    required: false,
  },
  { label: "City", field: "city" },
  { label: "State", field: "state" },
  { label: "ZIP", field: "zip" },
];

const handleSubmit = async (
  event,
  order,
  usertoken,
  shippingAddress,
  dispatch,
  onComplete,
) => {
  event.preventDefault();
  const shipping_address = Object.keys(shippingAddress).reduce((all, key) => {
    return { ...all, [key]: all[key].trim() };
  }, shippingAddress);
  const res = await fetch(
    `https://${VITE_APP_API_URI}/production/order/${order.production_order_id}`,
    {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        order: {
          id: order.id,
          production_order_id: order.production_order_id,
          sales_order_id: order.sales_order_id,
          brand_id: order.brand_id,
          shipping_address,
        },
      }),
    },
  );
  if (!res.ok) {
    throw new Error(`Fetch Error [${res.status}]: ${res.statusText}`);
  }
  const json = await res.json();
  dispatch({ type: SET_PRODUCTION_ORDER, payload: json });
  onComplete();
};

// Check all required fields are not empty
const isSubmitInactive = (address) =>
  Object.keys(address).filter(
    (key) =>
      key !== "address1" && (address[key] === "" || address[key] === null),
  ).length > 0;

export const AddressEdit = ({ dispatch, order, address, show, setShow }) => {
  const [editedAddress, setEditedAddress] = useState(address);
  const [{ usertoken }] = useLogin();

  useEffect(() => {
    if (!show) {
      setEditedAddress(address);
    }
  }, [show, address]);
  const submitInactive = isSubmitInactive(editedAddress);
  return (
    <>
      {show ? (
        <form className="edit-address__form">
          <header className="edit-address__header">Shipping Address</header>
          {Fields.map(({ label, field, required }) => (
            <AddressInput
              label={label}
              key={field}
              value={editedAddress[field]}
              required={required}
              onChange={(event) => {
                let value = event.target.value;
                setEditedAddress((state) => ({
                  ...state,
                  [field]: value,
                }));
              }}
              clear={(event) => {
                event.preventDefault();
                setEditedAddress((state) => ({
                  ...state,
                  [field]: "",
                }));
              }}
            />
          ))}
          <BaseButton
            children="Submit"
            className={`edit-address__submit-btn${
              submitInactive ? " inactive" : ""
            }`}
            onClick={
              !submitInactive
                ? (event) =>
                    handleSubmit(
                      event,
                      order,
                      usertoken,
                      editedAddress,
                      dispatch,
                      () => setShow(false),
                    )
                : (event) => {
                    event.preventDefault();
                  }
            }
          />
        </form>
      ) : (
        <></>
      )}
    </>
  );
};
export default AddressEdit;
