import { useReducer } from "react";

import { pageInitialState, pageActions } from "./actions/receiving/page";
import {
  orderInfoInitialState,
  orderInfoActions,
} from "./actions/receiving/order-info";
import {
  categoryInitialState,
  categoryActions,
} from "./actions/receiving/category";
import {
  upgradeInitialState,
  upgradeActions,
} from "./actions/receiving/upgrades";

export const RESET_ORDER = "RESET_ORDER";

export const InitialState = {
  ...pageInitialState,
  ...orderInfoInitialState,
  ...categoryInitialState,
  ...upgradeInitialState,
};

export const Actions = {
  ...pageActions,
  ...orderInfoActions,
  ...categoryActions,
  ...upgradeActions,
  RESET_ORDER: () => InitialState,
};

export const reducer = (state, action) => {
  const act = Actions[action.type];
  const update = act(state, action);

  // Order complete reset to initial.
  if (action.type === RESET_ORDER) {
    return update;
  }

  return { ...state, ...update };
};

export const useReceivingReducer = () => useReducer(reducer, InitialState);
export default useReceivingReducer;
