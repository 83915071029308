import { useCallback, useEffect, useState } from "react";

import {
  KeyActions,
  KeyContextProvider,
  type KeyStreamEvent,
  useKeyContext,
} from "src/hooks/context/scan";
import TextInput from "src/ui/base/text-input";

interface ConfirmOrderScanProps {
  itemList: string[];
  onConfirm: (confirm: boolean) => void;
  err: { state: boolean; message: string };
}

function ScanBox({ itemList, onConfirm, err }: ConfirmOrderScanProps) {
  const subscribe = useKeyContext();
  const [scan, setScan] = useState("");

  const keyHandler = useCallback(
    (event: KeyStreamEvent) => {
      switch (event.type) {
        case KeyActions.key:
          return setScan((e) => e + event.key);
        case KeyActions.delete:
          return setScan((e) => e.slice(0, -1));
        case KeyActions.enter:
          console.log(scan);
          if (itemList.includes(scan)) {
            onConfirm(true);
          } else {
            onConfirm(false);
          }
          setScan("");
          return;
      }
    },
    [scan, itemList, onConfirm],
  );

  useEffect(() => {
    const unsubscribe = subscribe(keyHandler);

    return () => {
      unsubscribe();
    };
  }, [subscribe, keyHandler]);
  return <TextInput val={scan} error={err.state} errorMsg={err.message} />;
}

function ConfirmOrderScan(props: ConfirmOrderScanProps) {
  return (
    <KeyContextProvider>
      <section className="ship-order__print-label-wrapper">
        <h1 className="ship-order__print-label-header">Confirm Order Scan</h1>
        <ScanBox {...props} />
      </section>
    </KeyContextProvider>
  );
}

export default ConfirmOrderScan;
