export const SET_SHIPPING = "SET_SHIPPING";
export const RESET_SHIPPING = "RESET_SHIPPING";
export const SET_LABEL = "SET_LABEL";

export interface ShippingInfoState {
  finalShippingMethod: string | null;
  finalCarrierName: string | null;
  finalShippingServiceLevelID: string | null;
  finalServiceLevel: string | null;
  finalToken: string | null;
  signatureRequired: boolean | null;
}

export interface ShippingLabelState {
  labelPrinted: boolean | null;
  labelImage: string | null;
  success: boolean | null;
  err: any;
}

export interface ShippingState {
  shippingInfo: ShippingInfoState;
  labelData: ShippingLabelState;
}

export type SetShippingAction = {
  type: typeof SET_SHIPPING;
  payload: any;
};

export const setShippingInfo = (
  { shippingInfo }: ShippingState,
  { payload }: SetShippingAction,
) => ({
  shippingInfo: {
    ...shippingInfo,
    finalShippingMethod: payload.final_shipping_method,
    finalCarrierName: payload.final_carrier_name,
    finalShippingServiceLevelID: payload.final_shipping_service_level_id,
    finalServiceLevel: payload.final_service_level,
    finalToken: payload.final_token,
    signatureRequired: payload.signature_required,
  },
});

export const resetShippingInfo = () => {
  return { ...shippingInitialState };
};

export type SetLabelAction = {
  type: typeof SET_LABEL;
  payload: any;
};

export const setShippingLabel = (
  state: ShippingState,
  { payload }: SetLabelAction,
) => ({
  ...state,
  labelData: {
    ...state.labelData,
    ...payload,
  },
});

export const shippingInitialState: ShippingState = {
  shippingInfo: {
    finalShippingMethod: null,
    finalCarrierName: null,
    finalShippingServiceLevelID: null,
    finalServiceLevel: null,
    finalToken: null,
    signatureRequired: null,
  },
  labelData: {
    labelPrinted: null,
    labelImage: null,
    success: null,
    err: null,
  },
};

export const shippingActions = {
  SET_SHIPPING: setShippingInfo,
  RESET_SHIPPING: resetShippingInfo,
  SET_LABEL: setShippingLabel,
};
