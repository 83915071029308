import "./indicator-light.scss";

/**
 * Basic indicator light with wrapper for positioning
 * @param {Object} props
 * @param {string} props.light - Type of light to use
 * @param {string} props.symbol - Descriptive symbol for light (ex: 'X' for error)
 * @param {boolean} props.active - True if light in 'On' state
 * @param {?string} [props.wrapper] - Classname for wrapper element
 */
interface IndicatorLightProps {
  light: string;
  symbol: string;
  active: boolean;
  wrapper?: string;
}

export const IndicatorLight = ({
  light,
  symbol,
  active,
  wrapper = null,
}: IndicatorLightProps) => {
  const lightClass = `indicator-light__${light}${active ? "--active" : ""}`;
  return (
    <section className={wrapper !== null ? wrapper : ""}>
      <article className={`indicator-light ${lightClass}`}>{symbol}</article>
    </section>
  );
};

export default IndicatorLight;
