import type { ReactElement } from "react";

import useReshipOrderReducer from "../../../hooks/reducers/admin/reship-order";
import type { Api } from "../../../types/api";
import AlertsList from "../../groups/alerts-list";
import { GoAgainProps } from "../../groups/go-again";
import ScanReshipItems from "./reship-order/items-scan";
import ReshipOrderScan from "./reship-order/order-scan";
import ReshipOutputs from "./reship-order/outputs";
import ShipOrder from "./reship-order/ship";

import "./reship.scss";

type ActivePage =
  | "Find Order"
  | "Scan Items"
  | "Confirm All Delivery Methods"
  | "Ship Order";

interface GetActivePageState {
  activePage: ActivePage;
  orderInfo: Api.IReship;
  steps: {
    name: string;
    canAdvance: boolean;
  }[];
}

export interface ReshipActivePageProps {
  state: GetActivePageState;
  dispatch: GoAgainProps["dispatch"];
}

const getActivePage = (
  state: ReshipActivePageProps["state"],
  dispatch: ReshipActivePageProps["dispatch"],
): ReactElement => {
  const { activePage, orderInfo } = state;

  if (orderInfo.shipmentAlerts) {
    return (
      <AlertsList
        alerts={orderInfo.shipmentAlerts.alerts}
        dispatch={dispatch}
      />
    );
  }
  switch (activePage) {
    case "Find Order":
      return <ReshipOrderScan state={state} dispatch={dispatch} />;
    case "Scan Items":
      return <ScanReshipItems state={state} dispatch={dispatch} />;
    case "Confirm All Delivery Methods":
      return <ReshipOutputs state={state} dispatch={dispatch} />;
    case "Ship Order":
      return <ShipOrder state={state} dispatch={dispatch} />;
    default:
      return <></>;
  }
};

export const Reship = () => {
  const [state, dispatch] = useReshipOrderReducer();
  const page = getActivePage(state, dispatch);
  return <section className="reship-order">{page}</section>;
};
export default Reship;
