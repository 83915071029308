import { useEffect } from "react";
// Hooks
import {
  ShipBalanceDueActionCreators,
  ShipBalanceDueSteps,
} from "../../../../hooks/reducers/admin/ship-balance-due";
import { useBalanceDueStatusCheck } from "../../safetyscans";
import { useGetOrderData } from "./hooks/use-get-order-data";
import { useGetShippingInfo } from "./hooks/use-get-shipping-info";
import { useGetShippingAlerts } from "./hooks/use-get-shipping-alerts";
// Components
import ScanOrder from "../scan-order";
import { Button } from "../../../components/button/button";
// Types
import type { ShipBalanceDueComponentProps } from "./app";
import type {
  TShipBalanceDueAction,
  TShipBalanceDueState,
} from "../../../../hooks/reducers/admin/ship-balance-due";

import type { Dispatch } from "react";

/**
 * Check if an order actually has balance due status
 * and can be shipped from this app
 */
const useCheckBalanceDue = (salesOrderId: number) =>
  useBalanceDueStatusCheck("Balance-Due", salesOrderId, salesOrderId != null);
type BalanceDueState = ReturnType<typeof useCheckBalanceDue>;

const useGoToNextStep = (
  balanceDueState: BalanceDueState,
  state: TShipBalanceDueState,
  dispatch: Dispatch<TShipBalanceDueAction>,
) => {
  const { shippingInfo, shippingAlerts } = state;
  const {
    isResolved: balanceDueCheckResolved,
    hasBalanceDueStatusError,
    balanceDueStatusError,
  } = balanceDueState;
  useEffect(() => {
    if (
      shippingInfo == null ||
      shippingAlerts == null ||
      shippingAlerts?.hasActiveAlert === true
    ) {
      return;
    }
    if (
      balanceDueCheckResolved === true &&
      hasBalanceDueStatusError === true &&
      balanceDueStatusError != null
    ) {
      return;
    }

    /** Standard shipping packages need to be weighed, otherwise go directly to ship */
    const hasExpeditedShipping = Number(shippingInfo.final_shipping_method) > 5;
    const nextStep = hasExpeditedShipping
      ? ShipBalanceDueSteps.Ship
      : ShipBalanceDueSteps.Weigh;
    /** Go to next step */
    dispatch(ShipBalanceDueActionCreators.createSetStepNameAction(nextStep));
  }, [
    balanceDueCheckResolved,
    hasBalanceDueStatusError,
    balanceDueStatusError,
    shippingInfo,
    shippingAlerts,
    dispatch,
  ]);
};

type ResetButtonProps = {
  dispatch: Dispatch<TShipBalanceDueAction>;
};
export function ResetButton(props: ResetButtonProps) {
  const onClick = () =>
    props.dispatch(ShipBalanceDueActionCreators.createResetAction());
  return <Button onClick={onClick}>Go Again</Button>;
}
type OrderErrorProps = {
  errMsg: string;
  dispatch: Dispatch<TShipBalanceDueAction>;
};
/** Show error message and reset button for hard status errors */
export function OrderError(props: OrderErrorProps) {
  return (
    <section className="ship-balance-due__order-error__section">
      <article>{props.errMsg}</article>
      <ResetButton dispatch={props.dispatch} />
    </section>
  );
}

export type ScanBalanceDueOrderState = {
  productionOrderId: string;
  accessCode: string;
};
/**
 * • Scan order ID and access code to retrieve order data
 * • Check order is in balance due can be shipped out of this app
 * • Retrieve shipping info
 */
export function ScanBalanceDueOrder(props: ShipBalanceDueComponentProps) {
  const { updateOrderData } = useGetOrderData(props.dispatch);
  const balanceDue = useCheckBalanceDue(props.state?.order?.sales_order_id);
  const {
    isResolved: balanceDueCheckResolved,
    hasBalanceDueStatusError,
    balanceDueStatusError,
  } = balanceDue;

  useGetShippingInfo(props.state, props.dispatch);
  useGetShippingAlerts(props.state, props.dispatch);
  useGoToNextStep(balanceDue, props.state, props.dispatch);

  /** Show error message over scan order component  */
  const scanOrderHeader = props.state.error.errState
    ? props.state.error.errMsg
    : null;
  const hasError =
    balanceDueCheckResolved === true &&
    hasBalanceDueStatusError === true &&
    balanceDueStatusError != null;
  return hasError ? (
    <OrderError
      errMsg={balanceDueStatusError?.errMsg}
      dispatch={props.dispatch}
    />
  ) : (
    <ScanOrder
      header={scanOrderHeader}
      submitFn={async (productionOrderId, accessCode) => {
        updateOrderData({ productionOrderId, accessCode });
      }}
    />
  );
}
