import { useEffect, useReducer, useRef } from "react";

import { useLogin } from "../context/login";

const { VITE_APP_API_URI } = import.meta.env;

export const UPDATE_INPUT = "UPDATE_INPUT";
export const SET_INPUT = "SET_INPUT";
export const CHECKIN_FAIL = "CHECKIN_FAIL";
export const CHECKIN_SUCCESS = "CHECKIN_SUCCESS";
export const CHECKIN_REMOVE = "CHECKIN_REMOVE";
export const CHECKIN_SENT = "CHECKIN_SENT";
export const SET_CELL = "SET_CELL";

/**
 * Rush order data
 * @typedef {Object} CheckinOrderData
 * @property {string} production_order_id
 * @property {number} sales_order_id
 * @property {boolean} is_rush
 * @property {boolean} rush_located
 */
/**
 * Checkin scan data for a location OR an order
 * @typedef {Object} CheckinItem
 * @property {string} cell - Active work cell name
 * @property {boolean} valid - Location is valid or checkin successful
 * @property {string} [key] - Scanned order ID
 * @property {boolean} [sent] - Checkin sent for logging
 * @property {boolean} [complete] - Checkin log complete
 * @property {CheckinOrderData} [order] Production order data
 */
/**
 * @typedef {Object} CheckinCell
 * @property {string} name - Cell location name
 * @property {boolean} valid - Checkin cell exists
 */

/**
 * @typedef {Object} CheckinState
 * @property {string} inputVal - Combined keypress event scans
 * @property {CheckinItem[]} list - List of scanned items in process
 * @property {CheckinCell} cell - Active work cell
 */

/**
 * @typedef {Object} CheckinAction
 * @property {string} type - Action type to dispatch
 * @property {string} [key] - Event key from keypress
 * @property {CheckinItem} [item] - Checkin item for CHECKIN related triggers
 */

/** @type {CheckinState} */
export const initialState = {
  inputVal: "",
  list: [],
  cell: {
    name: "",
    valid: null,
  },
};

/**
 * Update the inputVal property
 * @param {CheckinState} state
 * @param {CheckinAction} action
 * @returns {CheckinState}
 */
const updateInput = (state, action) => ({
  ...state,
  inputVal: `${state.inputVal}${action.key}`,
});

/**
 * Completes a given input and adds it to Cell definition
 * or CheckinItem list depending on scan type
 *
 * @param {CheckinState} state
 * @returns {CheckinState}
 */
const setInput = (state) => {
  const { inputVal, list, cell } = state;

  if (inputVal.includes("Digitizing") || inputVal.includes("Queues")) {
    return { ...state, inputVal: "", cell: { name: inputVal, valid: null } };
  }
  if (cell.name.length === 0) {
    return { ...state, inputVal: "" };
  }
  const validOrder = /^\d{4,}/.test(inputVal);
  if (!cell.valid || !validOrder) {
    return { ...state, inputVal: "" };
  }
  const scan = {
    key: inputVal,
    cell: cell.name,
    sent: false,
    complete: false,
    valid: false,
  };

  const duplicateIndex = list.findIndex(({ key }) => key === inputVal);
  if (duplicateIndex !== -1) {
    return { ...state, inputVal: "" };
  }

  return { ...state, list: [...list, scan], inputVal: "" };
};

/**
 * Update checkin item as sent to prevent duplicate AJAX requests
 * @param {CheckinState} state
 * @param {CheckinAction} action
 * @returns {CheckinState}
 */
const checkinSent = (state, { item }) => {
  const { list } = state;
  const id = list.findIndex((single) => single.key === item.key);
  if (id === -1) {
    return state;
  }
  const newItem = { ...list[id], sent: true };

  return {
    ...state,
    list: [...list.slice(0, id), newItem, ...list.slice(id + 1)],
  };
};

/**
 * Update checkin item to complete with a failed status
 * @param {CheckinState} state
 * @param {CheckinAction} action
 * @returns {CheckinState}
 */
const checkinFail = (state, { item }) => {
  const { list } = state;
  const id = list.findIndex((single) => single.key === item.key);
  if (id === -1) {
    return state;
  }
  const newItem = { ...list[id], complete: true };

  return {
    ...state,
    list: [...list.slice(0, id), newItem, ...list.slice(id + 1)],
  };
};

/**
 * Update checkin item to complete with a success status
 * @param {CheckinState} state
 * @param {CheckinAction} action
 * @returns {CheckinState}
 */
const checkinSuccess = (state, { item }) => {
  const { list } = state;
  const id = list.findIndex((single) => single.key === item.key);
  if (id === -1) {
    return state;
  }
  const newItem = {
    ...list[id],
    complete: true,
    valid: true,
    order: item.order,
  };

  return {
    ...state,
    list: [...list.slice(0, id), newItem, ...list.slice(id + 1)],
  };
};

/**
 * Remove completed checkin item
 * @param {CheckinState} state
 * @param {CheckinAction} action
 * @returns {CheckinState}
 */
const setCell = (state, { item }) => {
  if (!item) {
    return state;
  }
  const { cell, valid } = item;
  return { ...state, cell: { name: cell, valid } };
};

/**
 * Remove completed checkin item
 * @param {CheckinState} state
 * @param {CheckinAction} action
 * @returns {CheckinState}
 */
const checkinRemove = (state, { item }) => {
  const { list } = state;
  const id = list.findIndex((single) => single.key === item.key);
  if (id === -1) {
    return state;
  }

  return {
    ...state,
    list: [...list.slice(0, id), ...list.slice(id + 1)],
  };
};

/**
 * Reducer for dispatching checkin actions
 * @param {CheckinState} state
 * @param {CheckinAction} action
 */
const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_INPUT:
      return updateInput(state, action);

    case SET_INPUT:
      return setInput(state);

    case CHECKIN_SENT:
      return checkinSent(state, action);

    case CHECKIN_FAIL:
      return checkinFail(state, action);

    case CHECKIN_SUCCESS:
      return checkinSuccess(state, action);

    case CHECKIN_REMOVE:
      return checkinRemove(state, action);

    case SET_CELL:
      return setCell(state, action);

    default:
      throw new Error("Unmatched action type in checkin reducer");
  }
};

/**
 * AJAX call to backend checkin service.
 * @param {CheckinItem} item
 * @param {string} token
 * @param {React.Dispatch<CheckinAction>} dispatch
 */
const logCheckin = async (item, token, dispatch) => {
  const url = `https://${VITE_APP_API_URI}/departments/checkin`;
  const body = {
    scan: item.cell,
    orderId: item.key,
    status: "IN",
  };

  try {
    // Update item status to sent and make request.
    dispatch({ type: CHECKIN_SENT, item });
    const res = await fetch(url, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(body),
    });
    if (!res.ok) {
      throw new Error("Checkin Failed");
    }
    const data = await res.json();
    // Update status for success.
    dispatch({ type: CHECKIN_SUCCESS, item: { ...item, order: data.order } });

    // Set timer to remove completed item.
    if (data.order == null || data.order.rush_located !== false) {
      setTimeout(() => {
        dispatch({ type: CHECKIN_REMOVE, item });
      }, 5000);
    }
  } catch (error) {
    console.log(error);
    //  Update status for failed request.
    dispatch({ type: CHECKIN_FAIL, item });
  }
};

/**
 * @param {string} cellName
 * @param {string} token
 * @param {React.Dispatch<CheckinAction>} dispatch
 */
const checkValidCell = async (cellName, token, dispatch) => {
  const [location, workflow, cell] = cellName.split("_");
  const url = `https://${VITE_APP_API_URI}/departments/${location}/${workflow}/${cell}`;

  try {
    let valid = null;
    const res = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });
    if (!res.ok) {
      valid = false;
    } else {
      valid = true;
    }
    dispatch({ type: SET_CELL, item: { cell: cellName, valid } });
  } catch (error) {
    console.log(error);
    dispatch({ type: SET_CELL, item: { cell: cellName, valid: false } });
  }
};

/**
 * Custom hook wrapper around checkin processing logic.
 * Handles input listener and sending requests.
 * @returns {[CheckinState, React.Dispatch<CheckinAction>]}
 */
export const useCheckin = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [{ usertoken }] = useLogin();

  const tokenRef = useRef();

  useEffect(() => {
    tokenRef.current = usertoken;
  }, [usertoken]);

  useEffect(() => {
    const handleChange = (event) => {
      event.preventDefault();
      const key = event.key;

      if (key === "Enter") {
        dispatch({ type: SET_INPUT });
        return;
      }
      dispatch({ type: UPDATE_INPUT, key });
    };

    document.addEventListener("keypress", handleChange);

    return () => {
      document.removeEventListener("keypress", handleChange);
    };
  }, [dispatch]);

  useEffect(() => {
    if (state.list.length === 0) {
      return;
    }
    const unsent = state.list.filter(({ sent }) => sent === false);

    if (unsent.length === 0) {
      return;
    }

    unsent.forEach((single) => {
      logCheckin(single, tokenRef.current, dispatch);
    });
  }, [dispatch, state.list, tokenRef]);

  useEffect(() => {
    if (state.cell.name !== "" && state.cell.valid === null) {
      checkValidCell(state.cell.name, tokenRef.current, dispatch);
    }
  }, [dispatch, state.cell, tokenRef]);

  return [state, dispatch];
};

export default useCheckin;
