import { useEffect, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import usePager from "../../../hooks/use-pager";
import { useWindowSize } from "../../../hooks/use-window-size";
import BaseButton from "../../base/button";
import PagerButtons from "../../components/pager-buttons";
import { UpgradeIndicator } from "../../components/upgrade-indicator";
import AddOutputs from "../../groups/add-outputs";
import Card from "../../groups/card";
import SlidePanel from "../../groups/slide-panel";

import "./items.scss";

const getPageSize = (height) => Math.min(Math.floor(height / 160), 5);

const getInputs = (items) =>
  (items || [])
    .filter((item) => item.product_type_id === 1)
    .sort((a, b) => {
      const [, aId] = a.item_barcode.split("-");
      const [, bId] = b.item_barcode.split("-");
      return Number(aId) - Number(bId);
    });

const getOutputs = (items) =>
  (items || []).filter((item) => item.product_type_id === 2);

const CountByCategory = ({ items }) => {
  if (items?.length) {
    const groups = {};
    items.forEach((item) => {
      groups[item.product_name] = groups[item.product_name]
        ? groups[item.product_name] + 1
        : 1;
    });
    return (
      <div className="order-items__categorycount">
        <div className="order-items__categorycount-items">
          {Object.keys(groups).map((itemProductName) => (
            <div
              key={itemProductName}
              className="order-items__categorycount-item"
            >
              <span className="order-items__categorycount-item-name">
                {`${itemProductName}: `}
              </span>
              <span className="order-items__categorycount-item-count">
                {groups[itemProductName]}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return <></>;
};

/** @param {any} props */
export const ItemView = ({ state, dispatch }) => {
  const [inputs] = useState(getInputs(state.orderData.items));
  const [outputs, setOutputs] = useState(getOutputs(state.orderData.items));
  const windowSize = useWindowSize();
  const pager = usePager(inputs, getPageSize(windowSize.height), 0);
  const [{ usergroup }] = useLogin();
  const [addOutputs, setAddOutputs] = useState(false);

  useEffect(() => {
    setOutputs(getOutputs(state.orderData.items));
  }, [state.orderData.items]);

  const outputList = outputs.map((output) => ({
    id: output.id,
    name:
      output?.product_name?.replace(/Kodak Digitizing/i, "") ??
      output.output_type ??
      "Unknown",
    quantity: output.quantity,
  }));

  return (
    <section className="order__content-container">
      <section className="order__main order__main--full">
        <Card header="Delivery Options" size="short">
          <ul className="order-items__output-list">
            {outputList.map((output) => (
              <UpgradeIndicator
                key={output.id}
                textContent={output.name}
                quantity={output.quantity}
                upgrade={true}
              />
            ))}
          </ul>
          {(usergroup === "Manager" || usergroup === "Administrator") && (
            <>
              <BaseButton
                onClick={() => setAddOutputs(true)}
                className="order-items__add-btn"
              >
                +
              </BaseButton>
              <SlidePanel show={addOutputs} setShow={setAddOutputs}>
                <AddOutputs
                  order={state.orderData}
                  outputs={outputs}
                  dispatch={dispatch}
                  show={addOutputs}
                  setShow={setAddOutputs}
                />
              </SlidePanel>
            </>
          )}
        </Card>
        <Card header="Count by Category" size="short-countbycategory">
          <CountByCategory items={getInputs(state.orderData.items)} />
        </Card>
        <Card header="Analog Items" size="med-analogitems">
          <table className="order-items__input-list">
            <tbody className="order-items__input-wrapper">
              {pager.current.map((input) => (
                <tr className="order-items__input" key={input.id}>
                  <td>{input.item_barcode}</td>
                  <td>{input.product_name} </td>
                  <td>{input.variant_name}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              {inputs.length > 5 && (
                <tr>
                  <td className="order-items__pager" colSpan={3}>
                    <PagerButtons {...pager} />
                  </td>
                </tr>
              )}
            </tfoot>
          </table>
        </Card>
      </section>
    </section>
  );
};
export default ItemView;
