import { isThumbdriveScanRequired } from "./order-helper";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

const barcodeXML = () => {
  return `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips">
    <PaperOrientation>Landscape</PaperOrientation>
    <Id>Small30346</Id>
    <PaperName>30346 1/2 in x 1-7/8 in</PaperName>
    <DrawCommands>
      <RoundRectangle X="0" Y="0" Width="720" Height="2700" Rx="180" Ry="180"/>
    </DrawCommands>
    <ObjectInfo>
      <BarcodeObject>
        <Name>BARCODE</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="255" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <Text>BARCODE</Text>
        <Type>Code128Auto</Type>
        <Size>Small</Size>
        <TextPosition>Bottom</TextPosition>
        <TextFont Family="Helvetica" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
        <CheckSumFont Family="Helvetica" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
        <TextEmbedding>None</TextEmbedding>
        <ECLevel>0</ECLevel>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <QuietZonesPadding Left="0" Right="0" Top="0" Bottom="0"/>
      </BarcodeObject>
      <Bounds X="326.3998" Y="57.60002" Width="2289.6" Height="580.7999"/>
    </ObjectInfo>
  </DieCutLabel>`;
};

/**
 *
 * @param {{orderId: string, lastName: string}}} params
 * @returns
 */
const QRCodeThumbdriveXML = ({ orderId, lastName }) => {
  return /*xml*/ `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips">
    <PaperOrientation>Landscape</PaperOrientation>
    <Id>PriceTag</Id>
    <PaperName>30373 Price Tag Label - offset</PaperName>
    <DrawCommands>
      <Path>
        <FillMode>EvenOdd</FillMode>
        <Line X1="180" Y1="0" X2="1171" Y2="0"/>
        <Arc CenterX="1171" CenterY="180" Radius="180" StartAngle="90" EndAngle="0"/>
        <LineTo X="1351" Y="1351"/>
        <LineTo X="517" Y="1351"/>
        <Arc CenterX="517" CenterY="1463" Radius="112" StartAngle="90" EndAngle="180"/>
        <LineTo X="405" Y="2685"/>
        <LineTo X="293" Y="2880"/>
        <LineTo X="181" Y="2685"/>
        <LineTo X="181" Y="1463"/>
        <Arc CenterX="69" CenterY="1463" Radius="112" StartAngle="0" EndAngle="90"/>
        <LineTo X="0" Y="1351"/>
        <LineTo X="0" Y="180"/>
        <Arc CenterX="180" CenterY="180" Radius="180" StartAngle="180" EndAngle="90"/>
      </Path>
    </DrawCommands>
    <ObjectInfo>
      <TextObject>
        <Name>TEXT</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Center</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>AlwaysFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>${orderId}</String>
            <Attributes>
              <Font Family="Helvetica" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="14.4" Y="774.5531" Width="1121.981" Height="365.5875"/>
    </ObjectInfo>
    <ObjectInfo>
      <BarcodeObject>
        <Name>BARCODE</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="255" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation180</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <Text>${orderId}-TD</Text>
        <Type>QRCode</Type>
        <Size>Small</Size>
        <TextPosition>None</TextPosition>
        <TextFont Family="Helvetica" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
        <CheckSumFont Family="Helvetica" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
        <TextEmbedding>None</TextEmbedding>
        <ECLevel>0</ECLevel>
        <HorizontalAlignment>Center</HorizontalAlignment>
        <QuietZonesPadding Left="0" Right="0" Top="0" Bottom="0"/>
      </BarcodeObject>
      <Bounds X="96.19686" Y="57.59995" Width="928.9344" Height="548.5938"/>
    </ObjectInfo>
    <ObjectInfo>
      <TextObject>
        <Name>TEXT_1</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Center</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>ShrinkToFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>${lastName}</String>
            <Attributes>
              <Font Family="Helvetica" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="14.4" Y="1077.875" Width="1146.591" Height="218.125"/>
    </ObjectInfo>
  </DieCutLabel>`;
};

const getErrorXML = (header, note) => {
  return `<?xml version="1.0" encoding="utf-8"?>
      <DieCutLabel Version="8.0" Units="twips">
        <PaperOrientation>Landscape</PaperOrientation>
        <Id>Shipping4x6</Id>
        <PaperName>1744907 4 in x 6 in</PaperName>
        <DrawCommands>
          <RoundRectangle X="0" Y="0" Width="5918.4" Height="9038.4" Rx="270" Ry="270"/>
        </DrawCommands>
        <ObjectInfo>
          <TextObject>
            <Name>TEXT</Name>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
            <LinkedObjectName></LinkedObjectName>
            <Rotation>Rotation0</Rotation>
            <IsMirrored>False</IsMirrored>
            <IsVariable>False</IsVariable>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <TextFitMode>ShrinkToFit</TextFitMode>
            <UseFullFontHeight>True</UseFullFontHeight>
            <Verticalized>False</Verticalized>
            <StyledText>
              <Element>
                <String>${header}</String>
                <Attributes>
                  <Font Family="Helvetica" Size="24" Bold="True" Italic="False" Underline="False" Strikeout="False"/>
                  <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
                </Attributes>
              </Element>
            </StyledText>
          </TextObject>
          <Bounds X="327.0843" Y="433.6176" Width="8468.582" Height="942.3112"/>
        </ObjectInfo>
        <ObjectInfo>
          <TextObject>
            <Name>TEXT_1</Name>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
            <LinkedObjectName></LinkedObjectName>
            <Rotation>Rotation0</Rotation>
            <IsMirrored>False</IsMirrored>
            <IsVariable>False</IsVariable>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Top</VerticalAlignment>
            <TextFitMode>ShrinkToFit</TextFitMode>
            <UseFullFontHeight>True</UseFullFontHeight>
            <Verticalized>False</Verticalized>
            <StyledText>
              <Element>
                <String>${note}</String>
                <Attributes>
                  <Font Family="Helvetica" Size="18" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                  <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
                </Attributes>
              </Element>
            </StyledText>
          </TextObject>
          <Bounds X="353.0229" Y="1632.558" Width="8425.417" Height="3874.77"/>
        </ObjectInfo>
      </DieCutLabel>
  `;
};

const getShippingXML = (labelImage) => {
  return `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips">
      <PaperOrientation>Portrait</PaperOrientation>
      <Id>Shipping4x6</Id>
      <PaperName>1744907 4 in x 6 in</PaperName>
      <DrawCommands>
          <RoundRectangle X="0" Y="0" Width="5918.4" Height="9038.4" Rx="270" Ry="270"/>
      </DrawCommands>
      <ObjectInfo>
        <ImageObject>
          <Name>GRAPHIC</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <Image>${labelImage}</Image>
          <ScaleMode>Uniform</ScaleMode>
          <BorderWidth>0</BorderWidth>
          <BorderColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Center</VerticalAlignment>
        </ImageObject>
        <Bounds X="81.6" Y="316.8005" Width="5760" Height="8640"/>
      </ObjectInfo>
  </DieCutLabel>
  `;
};

export const printQRCodeThumbdriveSlip = async (usertoken, orderId) => {
  // fetches shipping lastname
  const response = await fetch(
    `https://${VITE_APP_API_URI}/production/order/${orderId}`,
    {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
      }),
    },
  );

  const payload = await response.json();
  const hasThumbdrive = isThumbdriveScanRequired(payload);

  if (!hasThumbdrive) return;

  const data = QRCodeThumbdriveXML({
    orderId,
    lastName: payload?.shipping_address?.lastname ?? "",
  });
  console.log(data);

  // @ts-ignore
  const Dymo = window.dymo.label.framework;
  const printer = await getPrinter(Dymo, "dymo-receiving-td");
  if (!printer) {
    throw new Error("Unable to connect to DYMO printer: 'dymo-receiving-td' ");
  }
  console.log(printer);

  const frame = Dymo.openLabelXml(data);

  const { items } = payload;

  const labelSetBuilder = new Dymo.LabelSetBuilder();

  // when totalThumbdrives is truthy, the function caller is coming from receiving
  const printAsyncTimes =
    items.find((item) => item.output_type === "Thumb Drive")?.quantity ?? 1; // defaults to 1

  for (let labelCount = 0; labelCount < printAsyncTimes; labelCount++) {
    const record = labelSetBuilder.addRecord();
    record.setText("TEXT", orderId);
  }
  const fnPrint = async () =>
    frame.printAsync(printer.name, "", labelSetBuilder);
  await fnPrint();
};

/**
 * Print thumbdrive tags at Receiving
 * @param {string} orderId Production order ID
 * @param {string} lastname Customer last name
 * @param {number} numberOfTimes Thumbdrive quantity = number of tag to print
 */
export const printQRCodeThumbdriveSlipReceiving = async (
  orderId,
  lastName,
  numberOfTimes,
) => {
  // @ts-ignore
  const Dymo = window.dymo.label.framework;
  const printer = await getPrinter(Dymo, "dymo-receiving-td");
  /** Get thumb drive tag label XML */
  const data = QRCodeThumbdriveXML({
    orderId,
    lastName,
  });

  /** Open  label XML */
  const frame = Dymo.openLabelXml(data);
  /** Create a label set with a record for every thumbdrive */
  const labelSetBuilder = new Dymo.LabelSetBuilder();
  for (let labelCount = 0; labelCount < numberOfTimes; labelCount++) {
    const record = labelSetBuilder.addRecord();
    record.setText("TEXT", orderId);
  }

  await frame.printAsync(printer.name, "", labelSetBuilder);
};

/**
 * Get array of barcode item suffixes (e.g. '-01')
 * @param {number} min
 * @param {number} max
 * @returns {string[]} Two digit item numbers with leading '-'
 */
export const getBarcodeItemNumbers = (min, max) => {
  let barcodeItemNumbers = [],
    prefix = "-";
  for (var barcodeCount = min; barcodeCount <= max; barcodeCount++) {
    prefix += barcodeCount < 10 ? "0" : "";
    barcodeItemNumbers.push(prefix + barcodeCount);
    prefix = "-";
  }
  return barcodeItemNumbers;
};

/**
 * Prints a range of barcodes from min to max (inclusive)
 * @param {number} min
 * @param {number} max
 * @param {string} orderId
 */
export const printItemBarcodes = async (min, max, orderId) => {
  try {
    // @ts-ignore
    const Dymo = window.dymo.label.framework;

    const printers = await Dymo.getPrintersAsync();
    const printer = Array.from(printers)
      .filter(({ name }) => {
        return name.includes("dymo-receiving-barcodes");
      })
      .pop();
    if (!printer) {
      throw new Error(
        "Unable to connect to DYMO printer: 'dymo-receiving-barcodes' ",
      );
    }

    console.log(printer);
    var labelSetBuilder = new Dymo.LabelSetBuilder();

    // Get item number suffixes for barcodes
    const barcodeItemNumbers = getBarcodeItemNumbers(min, max);
    // Build label set
    barcodeItemNumbers.forEach((item) => {
      var record = labelSetBuilder.addRecord();
      record.setText("BARCODE", orderId + item);
    });

    console.log(barcodeXML());
    const label = Dymo.openLabelXml(barcodeXML());
    console.log(label);
    console.log(labelSetBuilder);
    const result = await label.printAsync(printer.name, "", labelSetBuilder);
    console.log(result);

    return { printComplete: true, err: null };
  } catch (err) {
    console.log(err);
    return {
      printComplete: false,
      err,
    };
  }
};

/**
 * Get label XML and print the slip
 * @param {string} usertoken user API token
 * @param {object} slipInfo map of slip info
 * @param {string} slipInfo.orderId
 * @param {string} slipInfo.name
 * @param {Date} slipInfo.receivedDate
 * @param {boolean} slipInfo.isCanceled
 * @param {boolean} slipInfo.isReturn
 * @param {string} slipInfo.returnNote
 * @param {object[]} slipInfo.formats  flat map of analog items
 * @param {object[]} slipInfo.barcodes array of input barcodes
 * @param {object[]} slipInfo.outputs
 * @param {number} slipInfo.thumbdriveQty # of thumbdrive outputs
 * @param {string} slipInfo.thumbdriveCode unique barcode for thumbdrives
 * @param {string} slipInfo.access access code
 */
export const printSlip = async (slipInfo, shouldDelayPrint = false) => {
  try {
    // @ts-ignore
    const Dymo = window.dymo.label.framework;
    const printers = await Dymo.getPrintersAsync();
    if (printers.length === 0) {
      throw new Error("Unable to connect to DYMO printers");
    }
    const printer = Array.from(printers)
      .filter(({ name }) => {
        return name.includes("dymo-receiving-slip");
      })
      .pop();

    if (!printer) {
      throw new Error(
        "Unable to connect to DYMO printer: 'dymo-receiving-slip' ",
      );
    }

    console.log(printer);
    const frame = Dymo.openLabelXml(slipInfo);

    if (true === shouldDelayPrint) {
      return {
        err: null,
        canPrint: true,
        printAsyncFn: async () => await frame.printAsync(printer.name),
      };
    }

    frame.printAsync(printer.name);

    return {
      printComplete: true,
      err: null,
    };
  } catch (error) {
    return {
      printComplete: false,
      err: error,
    };
  }
};

export const printSlipDelay = async (slipInfo) => {
  return printSlip(slipInfo, true);
};

/**
 * Reprints a receiving slip, back end provides necessary info
 * @param {string} usertoken - user API token
 * @param {string} productionOrderId
 */
export const reprintSlip = async (usertoken, productionOrderId) => {
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/digitization/receiving/reprint/${productionOrderId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
      },
    );

    if (!response.ok) {
      let jsonResponse = null;
      try {
        jsonResponse = await response.json();
      } catch (e) {
        console.log("bad json", e.message);
      }
      console.log("backend jsonResponse", jsonResponse);
      return {
        statusCode: response.status,
        printComplete: false,
        err: jsonResponse,
      };
    }

    const data = await response.text();
    console.log(data);
    // @ts-ignore
    const Dymo = window.dymo.label.framework;
    const printers = await Dymo.getPrintersAsync();
    const printer = Array.from(printers)
      .filter(({ name }) => {
        return name.includes("dymo-receiving-slip");
      })
      .pop();
    if (!printer) {
      throw new Error(
        "Unable to connect to DYMO printer: 'dymo-receiving-slip' ",
      );
    }
    console.log(printer);
    const frame = Dymo.openLabelXml(data);
    frame.printAsync(printer.name);
    return { printComplete: true, err: null };
  } catch (error) {
    return {
      printComplete: false,
      err: error,
    };
  }
};

/**
 * Prints an error slip
 * @param {string} errorHeader
 * @param {string} errorNote
 */
export const printErrorSlip = async (errorHeader, errorNote) => {
  try {
    // @ts-ignore
    const Dymo = window.dymo.label.framework;
    const printers = await Dymo.getPrintersAsync();
    const printer = Array.from(printers)
      .filter(({ name }) => {
        return (
          name.includes("dymo-receiving-slip") || name.includes("dymo-shipping")
        );
      })
      .pop();
    if (!printer) {
      throw new Error("Unable to connect to DYMO printer");
    }
    console.log(printer);
    const errorXML = getErrorXML(errorHeader, errorNote);
    const frame = Dymo.openLabelXml(errorXML);
    frame.printAsync(printer.name);
    return { printComplete: true, err: null };
  } catch (error) {
    return {
      printComplete: false,
      err: error,
    };
  }
};

/**
 * Prints an error slip
 * @param {string} usertoken user API token
 * @param {"ERROR" | "ALT"} slipType ERROR or ALT slip
 * @param {string} status Large header text
 * @param {string} orderId
 * @param {string} accessCode
 * @param {string} name Customer name
 * @param {?string} [message] Descriptive message text for error slips
 */
export const printAltSlip = async (
  usertoken,
  slipType,
  status,
  orderId,
  accessCode,
  name,
  message = "",
) => {
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/safetyscans/altslip`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          slipType: slipType.toUpperCase(),
          status,
          message,
          orderId,
          accessCode,
          name,
        }),
      },
    );
    const data = await response.text();

    // @ts-ignore
    const Dymo = window.dymo.label.framework;
    const printers = await Dymo.getPrintersAsync();
    const printer = Array.from(printers)
      .filter(({ name }) => {
        return name.includes("dymo-shipping");
      })
      .pop();
    if (!printer) {
      throw new Error("Unable to connect to DYMO printer: 'dymo-shipping' ");
    }
    console.log(printer);
    const frame = Dymo.openLabelXml(data);
    frame.printAsync(printer.name);
    return { printComplete: true, err: null };
  } catch (error) {
    return {
      printComplete: false,
      err: error,
    };
  }
};

/**
 * Prints a shipping label
 * @param {string} labelData
 */
export const printShippingLabel = async (labelData) => {
  try {
    console.log(labelData);
    // @ts-ignore
    const Dymo = window.dymo.label.framework;
    const printers = await Dymo.getPrintersAsync();
    const printer = Array.from(printers)
      .filter(({ name }) => {
        return name.includes("dymo-shipping");
      })
      .pop();
    if (!printer) {
      throw new Error("Unable to connect to DYMO printer");
    }

    const shippingXML = getShippingXML(labelData);

    const frame = Dymo.openLabelXml(shippingXML);
    frame.printAsync(printer.name);
    return { printComplete: true, err: null };
  } catch (error) {
    return {
      printComplete: false,
      err: error,
    };
  }
};

export const getPrinterList = async (dymo) => {
  const printers = await dymo.getPrintersAsync();
  return Array.from(printers);
};

/**
 * Get a printer named with a string prefix
 * @param {string} printerPrefix
 * @returns
 */
export const getPrinter = async (dymo, printerPrefix) => {
  const printers = await getPrinterList(dymo);
  return printers.find(({ name }) => name.includes(printerPrefix));
};
