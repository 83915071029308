// Hooks
import { useEffect } from "react";
import { ShipBalanceDueActionCreators } from "../../../../../hooks/reducers/admin/ship-balance-due";
import useApi from "../../../../../hooks/use-api";
// Types
import type { Api } from "../../../../../types/api";
import type {
  TShipBalanceDueState,
  TShipBalanceDueAction,
} from "../../../../../hooks/reducers/admin/ship-balance-due";
import type { Dispatch } from "react";

/**
 * Once order data is loaded and we do
 * not have a balance due status error
 * get shipping info from Shipments API
 * and go to package weigh or shipping steps
 * */
export const useGetShippingInfo = (
  state: TShipBalanceDueState,
  dispatch: Dispatch<TShipBalanceDueAction>,
) => {
  /** Get order shipping info */
  const shippingInfoApi = useApi<Api.IFinalShippingInfo>(
    `shipments/${state.order?.sales_order_id}`,
    "get",
    state.order?.sales_order_id != null,
  );

  useEffect(() => {
    if (shippingInfoApi.isResolved) {
      /** Set shipping info in state */
      dispatch(
        ShipBalanceDueActionCreators.createSetShippingInfoAction(
          shippingInfoApi.body,
        ),
      );
    }
  }, [shippingInfoApi.isResolved, shippingInfoApi.body, dispatch]);
  /** Handle shipment info API failure  */
  useEffect(() => {
    if (shippingInfoApi.isRejected) {
      /** Set shipping info in state */
      dispatch(
        ShipBalanceDueActionCreators.createSetErrorAction({
          errMsg: "Unable to retrieve shipment alert data",
          errState: true,
        }),
      );
    }
  }, [shippingInfoApi.isRejected, dispatch]);
};
