import "./show-error.scss";
import YesNoButtons from "../../components/yes-no-buttons";
import { RESET } from "../../../hooks/reducers/safetyscans";
import BaseButton from "../../base/button";
import { useState } from "react";
import { PAGE_NAV } from "../../../hooks/reducers/actions/safetyscans/page";

export const ShowPALCheck = ({ dispatch, sendReorder }) => {
  const [confirmed, setConfirmed] = useState(true);

  const GoBackButton = () => {
    return (
      <section className={"show-error__wrapper"}>
        <>
          <article className="upgrades__explainer">
            Please chat with your manager to ensure the order goes to PAL to
            continue being processed.
          </article>
        </>
        <BaseButton
          onClick={(event) => {
            dispatch({ type: RESET });
            event.currentTarget.blur();
          }}
          className="order-error__go-back-button"
        >
          Go Again
        </BaseButton>
      </section>
    );
  };

  if (confirmed) {
    return (
      <section className={"show-error__wrapper"}>
        <>
          <article className="upgrades__explainer">
            Does this order have PAL tape items?
          </article>
        </>
        <YesNoButtons
          yesText="Yes"
          noText="No"
          yesClick={() => {
            setConfirmed(false);
          }}
          noClick={() =>
            sendReorder === true
              ? dispatch({ type: PAGE_NAV, step: "Reorder Activate" })
              : dispatch({ type: PAGE_NAV, step: "Ship Order" })
          }
        />
      </section>
    );
  } else {
    return (
      <>
        <GoBackButton />
      </>
    );
  }
};
export default ShowPALCheck;
