import useRushLocate from "../../hooks/use-rush-locate";
import Button from "../base/button";
import Card from "./card";

import "./rush-locate.scss";

/**
 * Use orderData if it already exists, otherwise fetch data by productionOrderID
 * @param {object} props
 * @param {object} [props.orderData]
 * @param {string} [props.productionOrderId]
 * @param {string} [props.productionOrderId]
 * @param {(boolean) => any} [props.onComplete]
 */
interface RushLocateModalProps {
  orderData?: any;
  productionOrderId?: string;
  onComplete?: (val: boolean) => void;
}

export const RushLocateModal = ({
  orderData = null,
  productionOrderId = null,
  onComplete = () => {},
}: RushLocateModalProps) => {
  const { locateRush, order, setRushLocated } = useRushLocate({
    productionOrderId,
    orderData,
  });

  if (!locateRush) {
    return null;
  }
  return (
    <>
      <div className="rush-locate__overlay"></div>
      <section className="rush-locate__wrapper">
        <section className="rush-locate__card">
          <Card header="Rush Order">
            <section className="rush-locate__content">
              <article>{`Order ${order.production_order_id} has been added to the rush list.`}</article>
              <article>Please add a rush sticker.</article>

              <Button
                onClick={() => setRushLocated(onComplete)}
                className="rush-locate__confirm-btn"
              >
                DONE
              </Button>
            </section>
          </Card>
        </section>
      </section>
    </>
  );
};
export default RushLocateModal;
