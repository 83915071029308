import { useEffect, useRef, useState } from "react";

// Hooks
import { useLogin } from "../../hooks/context/login";
import ReasonGroup from "../components/reason-group";
import PrintOptions from "./print-options";

import "./print-barcodes.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

export const printReasons = [
  { id: 85, reason: "Customer incorrectly applied barcodes" },
  { id: 68, reason: "Arrived without barcodes" },
  { id: 69, reason: "Damaged or unscannable barcodes" },
  { id: 70, reason: "Additional items in box" },
];

/**
 * Sends request for activity log of barcode printing
 * @param {string} usertoken
 * @param {object} orderInfo
 * @param {number} printReasonId
 * @param {object} printRange
 * @param {number} printRange.min
 * @param {number} printRange.max
 */
const sendPrintLog = async (
  usertoken,
  orderInfo,
  printReasonId,
  printRange,
) => {
  const { reason } = printReasons.filter(
    (reason) => reason.id === printReasonId,
  )[0];
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/timeline-v2/receiving/printbarcodeslog`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          order_info: orderInfo,
          note: reason,
          print_range: printRange,
        }),
      },
    );

    if (!response.ok) {
      throw new Error("Unable to log barcode print reason.");
    }
  } catch (error) {
    console.log(error);
  }
};

export const PrintBarcodes = ({ orderInfo }) => {
  const [{ usertoken }] = useLogin();
  const [printReason, setPrintReason] = useState(0);
  const [printRange, setPrintRange] = useState(null);
  const prevPrintReason = useRef(printReason);

  useEffect(() => {
    if (printRange) {
      sendPrintLog(usertoken, orderInfo, printReason, printRange);
      setPrintRange(null);
    }
  }, [printReason, printRange, orderInfo, usertoken, setPrintRange]);

  useEffect(() => {
    prevPrintReason.current = printReason;
  });

  return !printReason ? (
    <ReasonGroup
      headerText={"Select print reason"}
      reasons={printReasons}
      onClick={setPrintReason}
    />
  ) : (
    <PrintOptions orderInfo={orderInfo} setPrintRange={setPrintRange} />
  );
};

export default PrintBarcodes;
