// Lib
import { useReducer, useEffect, Dispatch } from "react";
import combineReducers from "react-combine-reducers";

// Types
import type { Api } from "../../types/api";

// Hooks
import useApi from "../use-api";

// Reducers
import ProcessingReducer, {
  QaProcessingState,
  QaProcessingAction,
  QaProcessInitialState,
} from "./actions/qa/processing";
import OrderInfoReducer, {
  OrderInfoState,
  OrderInfoAction,
  OrderInitialState,
  orderNotFound,
  orderFound,
} from "./actions/order-info";
import SetScanReducer, {
  SetScanInitialState,
  SetScanAction,
  SetScanState,
} from "./actions/qa/set-scan";
import ErrorReducer, {
  ErrorInitialState,
  ErrorAction,
  ErrorState,
  trigger as triggerError,
} from "./actions/error";
import PageReducer, {
  PageStepAction,
  PageStepState,
  setPage,
} from "./actions/page-step";
import { ResetAction } from "./actions/reset-action";
import { appendSafetyScansFields } from "../../utils/order-helper";

const QaProcessPages = [
  "scan",
  "outside",
  // "packaging",
  "outputs",
  "files",
  "summary",
] as const;
export type TQaProcessPages = (typeof QaProcessPages)[number];

export interface QaState {
  error: ErrorState;
  page: PageStepState<TQaProcessPages>;
  orderInfo: OrderInfoState;
  trackingScan: SetScanState;
  processing?: QaProcessingState;
}
export type QaAnyAction = { type: string; payload?: any };

export type QaAction =
  | QaProcessingAction
  | OrderInfoAction
  | SetScanAction
  | ErrorAction
  | PageStepAction<TQaProcessPages>
  | ResetAction;

export type QaReducer = (state: QaState, action: QaAnyAction) => QaState;

export interface QaReducerProps {
  state: QaState;
  dispatch: Dispatch<QaAction>;
  resetOrderApi: () => void;
}

function useCombinedReducers() {
  // Can't define this in page file as it is a generic.
  const PageInitialState: PageStepState<TQaProcessPages> = {
    pageStep: "scan",
  };

  let group: { [K in keyof QaState]: [any, any] } = {
    error: [ErrorReducer, ErrorInitialState],
    page: [
      PageReducer<TQaProcessPages>(QaProcessPages, PageInitialState),
      PageInitialState,
    ],
    orderInfo: [OrderInfoReducer, OrderInitialState],
    trackingScan: [SetScanReducer, SetScanInitialState],
    processing: [ProcessingReducer, QaProcessInitialState],
  };
  const [reducer, initialState] = combineReducers<QaReducer>(group);
  return useReducer(reducer, initialState);
}

export default function useQa(): QaReducerProps {
  const [state, dispatch] = useCombinedReducers();
  const { orderInfo, trackingScan } = state;
  const fetchOrder =
    trackingScan.scan !== null && orderInfo.fetchOrder === "idle";

  const {
    status,
    loadingStates,
    responseStatusCode,
    actions,
    body,
    apiDispatch,
  } = useApi<Api.IProductionOrder>(
    `production/order/tracking/${trackingScan.scan}`,
    "get",
    fetchOrder,
  );

  useEffect(() => {
    if (status === loadingStates.RESOLVED) {
      if (!body.is_complete) {
        dispatch(triggerError("This order has not been marked ready to ship"));
        dispatch(orderNotFound());
        return;
      }
      dispatch(orderFound(appendSafetyScansFields(body)));
      dispatch(setPage<TQaProcessPages>("outside"));
    } else if (status === loadingStates.REJECTED) {
      const msg: string =
        responseStatusCode < 500 ? "Order not found" : "Server error";
      dispatch(orderNotFound());
      dispatch(triggerError(msg));
    }
  }, [status, loadingStates, responseStatusCode, body, dispatch]);

  const resetOrderApi = () => apiDispatch({ type: actions.RESET });

  return { state, dispatch, resetOrderApi };
}
