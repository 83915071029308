import { useState } from "react";
import { useLogin } from "../../../hooks/context/login";
import { SlidePanel } from "../../groups/slide-panel";
import { styled } from "../../../stitches.config";

import type { Dispatch } from "react";
import type {
  OrderViewActions,
  OrderViewState,
} from "../../../hooks/reducers/order-view";
import Button from "../../components/button/button";
import { AlertsCard } from "./alerts/card";
import { AlertsResolve } from "./alerts/resolve";

const ClearButton = styled(Button, {
  height: "4vh",
  minHeight: "20px",
  width: "10vw",
  minWidth: "120px",
  backgroundColor: "$green",
  fontSize: "$sm",
  color: "$white",
  borderRadius: "10px",
  border: "2px solid $white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "flex-end",
  position: "absolute",
  bottom: 0,
  right: 0,
});

export interface AlertsProps {
  state: OrderViewState;
  dispatch: Dispatch<OrderViewActions>;
  alertsLoaded: boolean;
}

export function Alerts({ state, alertsLoaded, dispatch }: AlertsProps) {
  const { alertData, orderData } = state;
  const [showPanel, setShowPanel] = useState(false);
  const [{ usertoken, isAdminLevel }] = useLogin();

  const showClearBtn =
    isAdminLevel && alertsLoaded && alertData?.hasActiveAlert;

  return (
    <>
      <SlidePanel
        show={showPanel && alertData && alertData.hasActiveAlert}
        setShow={setShowPanel}
      >
        <AlertsResolve
          order={orderData}
          dispatch={dispatch}
          usertoken={usertoken}
          alerts={
            alertsLoaded && alertData.alerts.length > 0 ? alertData.alerts : []
          }
        />
      </SlidePanel>
      <AlertsCard alertsLoaded={alertsLoaded} alerts={alertData?.alerts}>
        {showClearBtn && (
          <ClearButton onClick={() => setShowPanel(true)}>Resolve</ClearButton>
        )}
      </AlertsCard>
    </>
  );
}

export default Alerts;
