import { useEffect, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import BaseButton from "../../base/button";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

export const GetStartedScreen = ({ onClick }: { onClick: () => void }) => (
  <>
    <section className="departure__title departure__title--start">
      Ready to start checking out customer orders?
    </section>
    <section className="departure__tracking-input-wrapper">
      <BaseButton
        onClick={onClick}
        className="departure__button departure__button--start"
      >
        Get Started
      </BaseButton>
    </section>
  </>
);

export const getLocationNames = async (usertoken, setFn) => {
  try {
    const response = await fetch(`https://${VITE_APP_API_URI}/departments/`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
      }),
    });

    if (!response.ok) {
      throw new Error("Stations not found");
    }

    const data = await response.json();

    const locations = data.filter(({ id }) => {
      if (id === undefined) return false;
      return !["Digitizing", "Queues", "Shipping", "Receiving"].includes(id);
    });
    setFn((state) => locations);
  } catch (error) {}
};

export const GetLocation = ({ set }) => {
  const [{ usertoken }] = useLogin();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getLocationNames(usertoken, setLocations);
  }, [setLocations, usertoken]);
  return (
    <>
      <section className="departure__title departure__title--start">
        Please select Location
        <br />
        {locations.length > 0 &&
          locations.map(({ name }, idx) => (
            <BaseButton
              key={idx}
              onClick={() => {
                localStorage.setItem("location", name);
                set(name);
              }}
              className="departure__warehouse-button"
            >
              {name}
            </BaseButton>
          ))}
      </section>
    </>
  );
};

export const GetStarted = ({ onClick }) => {
  const [location, setLocation] = useState(localStorage.getItem("location"));

  return location !== null ? (
    <GetStartedScreen onClick={onClick} />
  ) : (
    <GetLocation set={setLocation} />
  );
};

export default GetStarted;
