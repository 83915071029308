import OrderHeader from "../groups/order-header";
import UserHeader from "../groups/user-header";

import "./unauthorized.scss";

export const Unauthorized = () => {
  return (
    <>
      <section className="unauthorized__header">
        <OrderHeader />
        <UserHeader isRunning={false} />
      </section>
      <section className="unauthorized__method">
        <h1>You do not have the correct permissions to view this page</h1>
      </section>
    </>
  );
};

export default Unauthorized;
