import { getItemCount } from "./category";

// import { InitialState } from "../../receiving";

// Action Types for Page
export const PAGE_GOTO = "PAGE_GOTO";
export const PAGE_IN_PROGRESS = "PAGE_IN_PROGRESS";
export const PAGE_COMPLETE = "PAGE_COMPLETE";
export const PAGE_STEP = "PAGE_STEP";
export const PAGE_BTN_ACTIVE = "PAGE_BTN_ACTIVE";

/**
 * Container for data on single step in workflow.
 * @typedef {Object} ProcessStep
 * @property {string} name - Name of step
 * @property {number} order - Place of step in sequence
 * @property {boolean} inProgress - Is ProcessStep active
 * @property {boolean} canAdvance - Should go forward button be active
 * @property {boolean} complete - Is ProcessStep complete
 */

/**
 * @typedef {Object} PageState
 * @property {number} activePage - Current display page
 * @property {ProcessStep[]} steps - Process step list
 */

/**
 * Update in progress status
 * @param {number} currentIndex
 * @param {number} nextIndex
 * @param {ProcessStep[]} steps
 */
export const setNewInProgress = (currentIndex, nextIndex, steps) =>
  steps.map((item, index) => {
    if (![nextIndex, currentIndex].includes(index)) {
      return item;
    }

    if (index === currentIndex) {
      return { ...item, inProgress: false };
    } else if (index === nextIndex) {
      return { ...item, inProgress: true };
    }
    return item;
  });

/**
 * Handles navigation to a specific page
 * @param {Object} state
 * @param {number} state.activePage
 * @param {ProcessStep[]} state.steps
 * @param {Object} action
 * @param {number} action.step
 * @param {boolean} [action.progress]
 */
export const goto = ({ activePage, steps }, action) => {
  let checkProgress = false;
  const { step: gotoStep } = action;

  if ("progress" in action) {
    checkProgress = action.progress;
  }

  const newStep = { activePage: gotoStep };

  if (!checkProgress) {
    return newStep;
  }
  const inProgress = steps.find((step) => step.inProgress === true);
  const activeButton =
    inProgress.order !== 2 ? inProgress.order > gotoStep : true;
  if (gotoStep <= inProgress.order) {
    return { ...newStep, goButtonActive: activeButton };
  }

  return { activePage: activePage };
};

/**
 * Move in progress step
 * @param {Object} state
 * @param {ProcessStep[]} state.steps
 * @param {Object} action
 * @param {number} action.inProgressStep
 */
export const inProgress = ({ steps }, { inProgressStep }) => {
  const nextIndex = steps.findIndex((step) => step.order === inProgressStep);
  const currentIndex = steps.findIndex((step) => step.inProgress === true);

  if (nextIndex === -1 || nextIndex === currentIndex) {
    return { steps };
  }

  const newSteps = setNewInProgress(currentIndex, nextIndex, steps);

  return { steps: newSteps };
};

/**
 * Completes page
 * @param {Object} state
 * @param {number} state.activePage
 * @param {ProcessStep[]} state.steps
 * @param {Object} action
 */
export const complete = ({ activePage, steps }, _action) => {
  const nextPage = activePage + 1;
  const nextIndex = steps.findIndex((step) => step.order === nextPage);
  const currentIndex = steps.findIndex((step) => step.order === activePage);

  // Skip setting In Progress on steps that have already been completed
  const reverseIndex = [...steps]
    .reverse()
    .findIndex((step) => step.inProgress === true);
  const lastInProgress = steps.length - reverseIndex - 1;
  if (nextIndex < lastInProgress) {
    return { steps, activePage: nextPage < 6 ? nextPage : activePage };
  }

  if (nextPage < 6 && (nextIndex === -1 || currentIndex === -1)) {
    return { steps };
  }

  const newInProgress = setNewInProgress(currentIndex, nextIndex, steps);

  const newSteps = newInProgress.map((item, index) => {
    if (index !== currentIndex) {
      return item;
    }
    return { ...item, complete: true };
  });

  return { activePage: nextPage < 6 ? nextPage : activePage, steps: newSteps };
};

/**
 *
 * @param {any} state
 * @param {any} action
 */
const completeStep = (state, action) => {
  const page = "step" in action ? action.step : state.activePage;
  const stateUpdate = { ...state, activePage: page };

  if (
    state.steps[page] &&
    state.steps[page].complete &&
    page < state.activePage
  ) {
    return state;
  }

  switch (page) {
    case 1:
      // if no tracking info do not update state.
      if (!state.orderInfo.trackingId) {
        return {};
      }
      return complete(stateUpdate, action);
    case 2:
    case 3:
      return complete(stateUpdate, action);
    case 4:
      if (getItemCount(state.categories) > 0) {
        return complete(stateUpdate, action);
      }
      return {};
    case 5:
      if (
        getItemCount(state.categories) > 0 &&
        state.orderInfo.trackingId &&
        state.totalItemCount != null
      ) {
        return complete(stateUpdate, action);
      }
      return {};
    case 7:
      return { activePage: 1 };
    case 8:
      return complete(stateUpdate, action);
    default:
      return {};
  }
};

const setGoButton = ({ activePage, steps }, { active }) => {
  const index = steps.findIndex((s) => s.order === activePage);
  if (index < 0) {
    return {};
  }
  const step = { ...steps[index], canAdvance: active };
  return { steps: [...steps.slice(0, index), step, ...steps.slice(index + 1)] };
};

export const pageActions = {
  PAGE_GOTO: goto,
  PAGE_IN_PROGRESS: inProgress,
  PAGE_COMPLETE: complete,
  PAGE_STEP: completeStep,
  PAGE_BTN_ACTIVE: setGoButton,
};

/** @type {PageState} */
export const pageInitialState = {
  activePage: 1,
  steps: [
    {
      name: "Get Started",
      order: 1,
      inProgress: true,
      canAdvance: false,
      complete: false,
    },
    {
      name: "Add Upgrades",
      order: 2,
      inProgress: false,
      canAdvance: true,
      complete: false,
    },
    {
      name: "Count",
      order: 3,
      inProgress: false,
      canAdvance: true,
      complete: false,
    },
    {
      name: "Log Items",
      order: 4,
      inProgress: false,
      canAdvance: false,
      complete: false,
    },
    {
      name: "Review ",
      order: 5,
      inProgress: false,
      canAdvance: false,
      complete: false,
    },
  ],
};
