import type { HTMLAttributes } from "react";
import { ColumnInstance } from "react-table";

export interface TableFilterSelectProps<SelectType extends object> {
  options: Array<{ value: string; text: string }>;
  label: string;
  name: string;
  column: ColumnInstance<SelectType>;
  defaultValue?: HTMLAttributes<HTMLSelectElement>["defaultValue"];
}

export function TableFilterSelect<SelectType extends object>({
  options,
  label,
  name,
  column: { filterValue, setFilter },
  defaultValue,
}: TableFilterSelectProps<SelectType>) {
  return (
    <>
      <label className="table-filter__select-label" htmlFor={name}>
        {label}
      </label>
      <select
        className="table-filter__select"
        name={name}
        onChange={(e) => {
          const val = e.target.value;
          setFilter(val);
        }}
        defaultValue={defaultValue}
      >
        {options.map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </select>
    </>
  );
}
export default TableFilterSelect;
