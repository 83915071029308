import { useState } from "react";
import { format } from "date-fns/format";
import { differenceInDays } from "date-fns/differenceInDays";
import "./main.scss";
import CheckinsLocations from "./checkins-locations";
import Alerts from "./alerts";
import Card from "../../groups/card";
import BaseButton from "../../base/button";
import { SlidePanel } from "../../groups/slide-panel";
import { AddressEdit } from "../../groups/address-edit";
import { useLogin } from "../../../hooks/context/login";
import classnames from "classnames";
import { ManuallyClearFromDataList } from "./manually-clear-from-data-list";

export const AddressBox = ({ order, address, dispatch }) => {
  const [editAddress, setEditAddress] = useState(false);
  const [{ usergroup }] = useLogin();
  return (
    <>
      {(usergroup === "Manager" || usergroup === "Administrator") && (
        <>
          <Card header="Address">
            <section className="order-main__address">
              <ul>
                <li>
                  {address.firstname} {address.lastname}
                </li>
                <li>
                  {address.address} {address.address1}
                </li>
                <li>
                  {address.city}, {address.state} {address.zip}
                </li>
              </ul>
              <BaseButton
                onClick={() => setEditAddress(true)}
                className="order-main__edit-btn order-main__edit-btn--address"
              >
                Edit
              </BaseButton>
            </section>
          </Card>
          <SlidePanel show={editAddress} setShow={setEditAddress}>
            <AddressEdit
              order={order}
              address={address}
              dispatch={dispatch}
              show={editAddress}
              setShow={setEditAddress}
            />
          </SlidePanel>
        </>
      )}
    </>
  );
};

const CloudLink = ({ children, disabled, className, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={classnames(className, {
        disabled,
      })}
      onClick={(e) => {
        if (disabled) {
          console.log("No drive link for this order");
          return e.preventDefault();
        }
      }}
    >
      {children}
    </a>
  );
};

export const BasicInfo = ({ order }) => {
  const inPlantDays = order.arrive_date
    ? differenceInDays(new Date(), Date.parse(order.arrive_date))
    : "?";
  const hasCloudLink = order.drive_link?.length > 0;

  return (
    <Card header="Info">
      <ul className="order__basic-info-list">
        <li className="order__basic-info-item">
          <strong>Return Order:</strong>
          {order.is_return ? "Yes" : "No"}
        </li>
        <li className="order__basic-info-item">
          <strong>Rush Order:</strong>
          {order.is_rush ? "Yes" : "No"}
        </li>
        <li className="order__basic-info-item">
          <strong>Priority List:</strong>
          {order.is_priority ? "Yes" : "No"}
        </li>
        <li className="order__basic-info-item">
          <strong>In Plant:</strong>
          {order.has_arrived && !order.is_final_shipped
            ? `${inPlantDays} days`
            : "No"}
        </li>
        {order.has_arrived && (
          <li className="order__basic-info-item">
            <strong>Arrived:</strong>
            {format(Date.parse(order.arrive_date), "MM/dd/yyyy")}
          </li>
        )}
        {order.is_received && (
          <li className="order__basic-info-item">
            <strong>Received:</strong>
            {format(Date.parse(order.receive_date), "MM/dd/yyyy")}
          </li>
        )}
        <li className="order__basic-info-btn">
          <CloudLink
            link={order.drive_link}
            disabled={!hasCloudLink}
            children="View Cloud Files"
            className="order-main__view-cloudbtn"
          />
        </li>
      </ul>
    </Card>
  );
};

/**
 * @param {any} props
 */
export const Main = ({ state, dispatch, checkinLoaded, alertsLoaded }) => {
  return (
    <>
      <section className="order__content-container--main">
        <section className="order__mainleft">
          <div className="order__rowinfo">
            <BasicInfo order={state.orderData} />
            <AddressBox
              address={state.orderData.shipping_address}
              order={state.orderData}
              dispatch={dispatch}
            />
          </div>
          <div className="order__rowalerts">
            <Alerts
              state={state}
              dispatch={dispatch}
              alertsLoaded={alertsLoaded}
            />
          </div>
        </section>
        <section className="order__mainright">
          <CheckinsLocations state={state} checkinLoaded={checkinLoaded} />
        </section>
        <ManuallyClearFromDataList {...state} />
      </section>
    </>
  );
};

export default Main;
