import { type ReactElement, useState } from "react";

import cn from "classnames";

interface TabProp {
  activeTab: string;
  label: string;
  prefix: string;
  onClick: () => void;
}

export const Tab = ({ activeTab, label, prefix, onClick }: TabProp) => {
  const klass = cn(`${prefix}__tab-item`, {
    [`${prefix}__tab-item-active`]: activeTab === label,
  });

  return (
    <li className={klass} onClick={onClick}>
      {label}
    </li>
  );
};

interface TabsProps<T> {
  children: ReactElement<T>[];
  classPrefix: string;
}

export function Tabs<T extends { label: string }>({
  children,
  classPrefix,
}: TabsProps<T>) {
  const initialState = children.length > 0 ? children[0].props.label : "";
  const [active, setActive] = useState(initialState);

  const onClick = (tab: string) => () => setActive(tab);

  return (
    <section className={`${classPrefix}__tabs`}>
      <ol className={`${classPrefix}__tab-list`}>
        {children.map((child) => {
          const { label } = child.props;

          return (
            <Tab
              key={label}
              activeTab={active}
              label={label}
              prefix={classPrefix}
              onClick={onClick(label)}
            />
          );
        })}
      </ol>
      <section className={`${classPrefix}__tab-content`}>
        {children.map((child) => {
          if (child.props.label !== active) {
            return undefined;
          }
          return child;
        })}
      </section>
    </section>
  );
}

export default Tabs;
