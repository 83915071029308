import { useEffect } from "react";

import { SET_CHECKIN } from "../../hooks/reducers/actions/receiving/order-info";
import {
  PAGE_GOTO,
  PAGE_STEP,
} from "../../hooks/reducers/actions/receiving/page";
import useReceivingReducer from "../../hooks/reducers/receiving";
import { useDymoService } from "../../hooks/use-dymo-service";
import useWorkCompleted from "../../hooks/use-work-completed";
import GoButton from "../components/go-button";
import PrintBarcodesButton from "../components/print-barcodes-button";
import CheckinCell from "../groups/checkin-cell";
import OrderHeader from "../groups/order-header";
import PrintBarcodes from "../groups/print-barcodes";
import PrintError from "../groups/print-error";
import ProgressBar from "../groups/progress-bar";
import ReceivingAdminOnlyError from "../groups/receiving-admin-only-error";
import ReprintSlip from "../groups/reprint-slip";
import AnalogItems from "./receiving/analog-items";
import CountItems from "./receiving/count-items";
import ReturnPrint from "./receiving/return-print";
import ReviewOrder from "./receiving/review-order";
import ScanTracking from "./receiving/scan-tracking";
import TrackingFail from "./receiving/tracking-fail";
import Upgrades from "./receiving/upgrades";

import "./receiving.scss";

const DisplayGoButton = ({ visible, active, dispatch }) => {
  if (!visible) {
    // If not visible (last page) send back empty fragment
    return <></>;
  }

  return (
    <section className="receiving-next-button">
      <GoButton
        onClick={(event) => {
          dispatch({ type: PAGE_STEP });
          event.currentTarget.blur();
        }}
        active={active}
      />
    </section>
  );
};

const getActivePage = (state, dispatch) => {
  const { activePage, orderInfo } = state;

  switch (activePage) {
    case 1:
      return (
        <>
          <section className="scan-tracking__wrapper">
            <ScanTracking {...state} dispatch={dispatch} />
          </section>
          <section className="scan-tracking__barcodes-button-wrapper">
            {orderInfo && orderInfo.trackingId && (
              <PrintBarcodesButton dispatch={dispatch} />
            )}
          </section>
        </>
      );

    case 2:
      return <Upgrades {...state} dispatch={dispatch} />;

    case 3:
      return <CountItems {...state} dispatch={dispatch} />;
    case 4:
      return (
        <AnalogItems
          {...state}
          state={state}
          orderId={orderInfo.orderId}
          dispatch={dispatch}
        />
      );

    case 5:
      return <ReviewOrder {...state} dispatch={dispatch} />;

    case 6:
      return (
        <section className="tracking-fail__wrapper">
          <TrackingFail {...state} dispatch={dispatch} />
        </section>
      );

    case 7:
      return (
        <section className="print-barcodes__wrapper">
          <PrintBarcodes orderInfo={orderInfo} />
        </section>
      );

    case 8: // Returns sticker page
      return (
        <section className="return-print__wrapper">
          <ReturnPrint
            productionOrderId={orderInfo.orderId}
            dispatch={dispatch}
          />
        </section>
      );
    case 9: // Reprint slip page
      return (
        <ReprintSlip
          productionOrderId={orderInfo.orderId}
          dispatch={dispatch}
        />
      );
    case 10:
      return <CheckinCell dispatch={dispatch} />;

    case 11:
      return <PrintError />;

    case 12:
      return (
        <ReceivingAdminOnlyError
          goBack={() => {
            dispatch({ type: PAGE_GOTO, step: 1 });
          }}
        />
      );

    case 13:
      return (
        <section className="tracking-fail__wrapper">
          <TrackingFail
            {...state}
            isOrderFlagCyberWeek={true}
            dispatch={dispatch}
          />
        </section>
      );

    default:
      throw new Error("Invalid Page Specified");
  }
};

export const Receiving = () => {
  /** @type {[any, React.Dispatch<any>]} */
  const [state, dispatch] = useReceivingReducer();
  const { completedCount } = useWorkCompleted("Receiving");
  const { activePage, steps, orderInfo, checkinData } = state;
  console.log(state);
  const page = getActivePage(state, dispatch);
  let active = true;
  useDymoService(
    import.meta.env.PROD
      ? () => dispatch({ type: PAGE_GOTO, step: 11 })
      : () => {},
  );

  if (activePage <= 4) {
    const currentStep = steps.find((s) => s.order === activePage);
    if (currentStep !== undefined) {
      active = currentStep.canAdvance;
    }
  }

  useEffect(() => {
    if (checkinData.cell === null) {
      const storedCell = JSON.parse(localStorage.getItem("receivingCell"));
      const location = localStorage.getItem("location");
      const workflow = localStorage.getItem("workflow");
      const oldTeamsLocation = /Receiving_([a-zA-Z]+)_Team/.test(
        storedCell && storedCell.id,
      );
      if (oldTeamsLocation) {
        localStorage.removeItem("location");
        localStorage.removeItem("workflow");
        localStorage.removeItem("receivingCell");
      }
      if (storedCell && !oldTeamsLocation) {
        dispatch({
          type: SET_CHECKIN,
          payload: { cell: storedCell, location, workflow },
        });
      } else {
        dispatch({ type: PAGE_GOTO, step: 10 });
      }
    }
  }, [checkinData, dispatch]);

  return (
    <>
      <section className="receiving-header">
        <OrderHeader {...orderInfo} />
      </section>
      {checkinData.cell != null && (
        <section className="receiving-dept-data">
          {checkinData.cell.name}
        </section>
      )}
      <section className="receiving-progress-bar">
        <ProgressBar steps={steps} dispatch={dispatch} />
      </section>
      {page}
      <DisplayGoButton
        visible={
          ![2, 3, 5, 8, 11].includes(activePage) ||
          (activePage === 3 && state.totalItemCount !== null)
        }
        active={active}
        dispatch={dispatch}
      />
      {completedCount != null ? (
        <section className="user-header__work-completed">
          <article className="user-header__work-completed-count">
            {completedCount}
          </article>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default Receiving;
