// Hooks
import useApi from "../../../../../hooks/use-api";
// Types
import type { Api } from "../../../../../types/api";
import type { TShipBalanceDueState } from "../../../../../hooks/reducers/admin/ship-balance-due";

/** Request final shipping labels on load */
export const useShippingLabelApi = (
  state: TShipBalanceDueState,
  getLabel: boolean,
) =>
  useApi<
    | Api.IFinalShippingLabel
    | Api.IFinalShippingLabelShippoError
    | Api.IFinalShippingLabelCarrierError
  >(
    `shipments/${state.order.sales_order_id}/label`,
    "post",
    getLabel,
    {
      "Content-Type": "application/json",
    },
    JSON.stringify({
      productionOrderId: state.order.production_order_id,
      shipmentType: state.shippingInfo.shipment_type_id,
      signatureRequired: state.shippingInfo.signature_required,
    }),
  );
