import { ResetAction, RESET_STATE } from "../reset-action";

export const SET_SCAN = "SET_SCAN";
export const RESET_SCAN = "RESET_SCAN";

export type SetScanAction =
  | {
      type: typeof SET_SCAN;
      payload: string;
    }
  | { type: typeof RESET_SCAN };

export interface SetScanState {
  scan: string | null;
}

export const SetScanInitialState: SetScanState = {
  scan: null,
};

export const setScan = (val: string): SetScanAction => ({
  type: SET_SCAN,
  payload: val,
});

export const resetScan = (): SetScanAction => ({
  type: RESET_SCAN,
});

export default function reducer(
  state: SetScanState,
  action: SetScanAction | ResetAction,
): SetScanState {
  switch (action.type) {
    case SET_SCAN:
      return { ...state, scan: action.payload };
    case RESET_SCAN:
    case RESET_STATE:
      return SetScanInitialState;
    default:
      return state;
  }
}
