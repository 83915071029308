import { useCallback, useEffect, useRef, useState } from "react";
import type { FormEvent } from "react";

import useError from "../../../hooks/use-error";
import useKeyEvent from "../../../hooks/use-key-event";
import { isBarcodeMatchingOrderId } from "../../../hooks/use-key-event-barcode";
import { usePrevious } from "../../../hooks/use-previous";
import NumberPad from "../../groups/number-pad";
import { TrackingInput } from "../arrival/scan-box";
import type { ScanOrderProps } from "./edit-items/scan-order";
import type { AdminScanOrderRESTErrorMap } from "./scan-order";

import "./scan-order.scss";

type ActiveStates = "order" | "order_item" | "app_password" | "fetch";

const ScanOrder = ({
  submitFn,
  header = "Start by scanning order and item, then input pin:",
  buttonText = "Submit",
}: ScanOrderProps) => {
  const [active, setActive] = useState<ActiveStates>("order");

  const {
    errState: orderIDError,
    errMsg: orderErrMsg,
    trigger: triggerOrderIDError,
    clear: clearOrderIDError,
  } = useError();

  const {
    errState: orderItemError,
    errMsg: orderItemErrMsg,
    trigger: triggerOrderItemError,
    clear: clearOrderItemError,
  } = useError();

  const {
    errState: pinError,
    errMsg: pinErrMsg,
    trigger: triggerPinError,
    clear: clearPinError,
  } = useError();

  const onOrderIDScan = () => {
    if (prevOrderID !== order) {
      clearError();
    }
  };

  const {
    val: order,
    filled: orderScanned,
    clearInput: clearOrderInput,
    clearFilled: clearOrderFilled,
  } = useKeyEvent(active === "order", onOrderIDScan);

  let prevOrderID = usePrevious(order);
  const {
    val: orderItem,
    filled: orderItemScanned,
    clearInput: clearOrderItemInput,
    clearFilled: clearOrderItemFilled,
  } = useKeyEvent(active === "order_item");
  const [pin, setPin] = useState("");
  const submitButtonRef = useRef(null);

  const onPrevStep = useCallback(() => {
    switch (active) {
      case "app_password":
        setActive("order");
        break;

      default:
        break;
    }
  }, [active, setActive]);

  const clearError = () => {
    if (!orderIDError) return;
    clearOrderIDError();
    clearOrderItemError();
  };

  const defaultBarcodeError = "Barcode Order ID mismatch";

  const submitOrderAndBarcode = useCallback(() => {
    const isValidOrder = isBarcodeMatchingOrderId(orderItem, order);
    if (isValidOrder === "valid") {
      setActive("app_password");

      if (orderItemError) {
        clearOrderItemError();
      }

      return true;
    } else {
      triggerOrderItemError(defaultBarcodeError);
      clearOrderItemFilled();
      return false;
    }
  }, [
    order,
    orderItem,
    setActive,
    triggerOrderItemError,
    clearOrderItemFilled,
    orderItemError,
    clearOrderItemError,
  ]);

  const orderClass = `scan-order__order-input${
    active === "order" ? " scan-order__order-input--active" : ""
  }`;

  const orderItemClass = `scan-order__order-input${
    active === "order_item" ? " scan-order__order-input--active" : ""
  }`;

  const hardcodedValidPin = "2684";

  /* return false - form is invalid */
  const validatePin = useCallback(() => {
    if (pin !== hardcodedValidPin) {
      triggerPinError("Wrong Pin.");
      return false;
    }

    return true;
  }, [pin, triggerPinError]);

  const onSubmit = useCallback(
    async (event?: FormEvent): Promise<void> => {
      event?.preventDefault();

      if (!validatePin()) return;

      const restResponseType = await submitFn(order, "");
      const { printComplete, errorMsg } = (restResponseType ??
        {}) as AdminScanOrderRESTErrorMap;

      if (!printComplete) {
        onPrevStep();
        triggerOrderIDError(errorMsg ?? "Print failure.");
      }

      setPin("");
      clearOrderInput();
      clearOrderFilled();
      clearPinError();
      clearOrderItemInput();
      clearOrderItemFilled();
      setActive("order");
    },
    [
      order,
      validatePin,
      submitFn,
      onPrevStep,
      triggerOrderIDError,
      setPin,
      clearOrderInput,
      clearOrderFilled,
      clearPinError,
      clearOrderItemInput,
      clearOrderItemFilled,
      setActive,
    ],
  );

  const handleFocus = (focus: ActiveStates): void => {
    if (focus === "order" && active === "order_item") {
      clearOrderItemInput();
      clearOrderItemFilled();
      clearOrderInput();
      clearOrderFilled();
    }
    setActive(focus);
  };

  const onNextStep = useCallback(() => {
    switch (active) {
      case "order":
        setActive("order_item");
        break;

      case "order_item":
        submitOrderAndBarcode();
        break;

      case "app_password":
        onSubmit();
        break;

      default:
        break;
    }
  }, [active, setActive, submitOrderAndBarcode, onSubmit]);

  const prevPin = usePrevious(pin);
  useEffect(() => {
    if (orderItemScanned && active === "order_item") {
      onNextStep();
    } else if (orderScanned && active === "order") {
      onNextStep();
    } else if (pinError && prevPin !== pin) {
      clearPinError();
    }
  }, [
    active,
    orderScanned,
    orderItemScanned,
    onNextStep,
    pin,
    pinError,
    clearPinError,
    prevPin,
  ]);

  if (active === "app_password") {
    return (
      <section className="scan-order__order">
        <NumberPad
          header={"Enter Pin:"}
          entry={`${pin}`}
          setEntry={setPin}
          maxLength={4}
          handleSubmit={() => {
            onNextStep();
          }}
          errState={pinError}
          errMsg={pinErrMsg}
        />
      </section>
    );
  }

  return (
    <section className="scan-order__order">
      <h1>{header}</h1>

      <form onSubmit={onNextStep}>
        <label onClick={() => handleFocus("order")}>
          Order:{" "}
          <TrackingInput
            error={orderIDError}
            errorMsg={orderErrMsg}
            className={orderClass}
            val={order}
          />
        </label>
        <label onClick={() => handleFocus("order_item")}>
          Item:{" "}
          <TrackingInput
            error={orderItemError}
            errorMsg={orderItemErrMsg}
            val={orderItem}
            className={orderItemClass}
          />
        </label>
        <input
          ref={submitButtonRef}
          type="submit"
          className="scan-order__get-order"
          value={buttonText}
        />
      </form>
    </section>
  );
};

export default ScanOrder;
