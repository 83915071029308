import { type Dispatch, useCallback, useEffect, useRef, useState } from "react";

import { RESET, useKeyEventBarcode, useMountEffect } from "../../../hooks";
import { useLogin } from "../../../hooks/context/login";
import { SafetyScansErrorActionTypes } from "../../../hooks/reducers/actions/safetyscans/error";
import {
  SCAN_ITEM,
  getSafetyscansDisplayPageNum,
} from "../../../hooks/reducers/actions/safetyscans/order-info";
import type { OrderInfoState } from "../../../hooks/reducers/actions/safetyscans/order-info";
import {
  PAGE_BTN_ACTIVE,
  PAGE_STEP,
} from "../../../hooks/reducers/actions/safetyscans/page";
import useError, { type UseErrorState } from "../../../hooks/use-error";
import usePager from "../../../hooks/use-pager";
import type { Api } from "../../../types/api";
import BaseButton from "../../base/button";
import PagerButtons from "../../components/pager-buttons";
import { ItemInputs } from "../../components/scan-item";
import "./scan-items.scss";

const { VITE_APP_API_URI } = import.meta.env;

const createAlert = async (id: string, usertoken: string, errMsg: string) => {
  let res;
  try {
    const note = `Mismatched SafetyScan: ${errMsg}`;
    res = await Promise.all([
      fetch(`https://${VITE_APP_API_URI}/shipments/alerts/${id}`, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          alertReasonId: 8,
        }),
      }),
      fetch(
        `https://${VITE_APP_API_URI}/timeline-v2/safetyscans/${id}/custom`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${usertoken}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ note }),
        },
      ),
    ]);
  } catch (e) {
    console.error(e);
  }
  if (!import.meta.env.PROD) {
    console.log("debug res", res, errMsg);
  }
};

type ErrorAreaProps = {
  error: {
    errState: UseErrorState["errState"];
    errMsg: UseErrorState["errMsg"];
  };
  id: string;
  onClick: () => void;
  /** Hard error that requires stopping shipping */
  stopOnError: boolean;
};
/** Show hard errors (scanned a item from another order) or soft (scanned random text accidentally) */
const ErrorArea = ({ error, onClick, stopOnError }: ErrorAreaProps) => {
  const { errState, errMsg } = error;

  return errState ? (
    <div className="scan-items__error-area">
      <div className="scan-items__error-message">
        Oops! You scanned <strong>{errMsg}</strong>. That item doesn&apos;t
        belong to this order.
      </div>
      <BaseButton onClick={onClick} className="scan-items__error-button">
        {stopOnError ? "GO AGAIN" : "CLEAR ERROR"}
      </BaseButton>
    </div>
  ) : (
    <></>
  );
};

export const usePageState = (
  orderInfo: ScanItemsOrderInfo,
  errState: boolean,
  dispatch: Dispatch<any>,
) => {
  const unscannedLength = useRef(
    orderInfo.analogItems.filter(({ scanned }) => !scanned).length,
  );

  // Set Go Button inactive on page load
  useMountEffect(() => {
    dispatch({ type: PAGE_BTN_ACTIVE, active: false, visable: true });
  });

  // Set Go button active if all items are scanned
  useEffect(() => {
    if (errState) {
      dispatch({ type: PAGE_BTN_ACTIVE, active: false, visable: true });
    }
  }, [errState, dispatch]);

  useEffect(() => {
    if (errState) {
      return;
    }

    const unscanned = orderInfo.analogItems.filter(({ scanned }) => !scanned);
    if (unscanned.length === 0) {
      dispatch({ type: PAGE_BTN_ACTIVE, active: true });

      if (unscannedLength.current !== unscanned.length) {
        dispatch({ type: PAGE_STEP });
      }
    }
  }, [errState, dispatch, orderInfo.analogItems]);

  useEffect(() => {
    unscannedLength.current = orderInfo.analogItems.filter(
      ({ scanned }) => !scanned,
    ).length;
  }, [orderInfo.analogItems]);
};

/** Hook for handling scanner input and hard or soft barcode scan error */
export const useScanItems = (
  orderInfo: ScanItemsOrderInfo,
  dispatch: Dispatch<any>,
  usertoken: string,
  error?: { errState: boolean; errMsg: string },
  isAppQA?: boolean,
) => {
  const [list, setList] = useState([]);
  const [analogItems] = useState(
    orderInfo.analogItems.map((item) => item.item_barcode),
  );
  const { val, filled, clearFilled, clearInput, isValidOrder } =
    useKeyEventBarcode(error?.errState !== true, orderInfo.orderId);
  // Show error state saved in safety scans reducer if exists
  const useErrorProps: [boolean, string] =
    error !== undefined ? [error.errState, error.errMsg] : [false, ""];
  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  }: UseErrorState = useError(...useErrorProps);

  const checkSkipScan = useCallback(
    (value: string) => {
      const shouldSkip =
        list.includes(value) ||
        value === orderInfo.accessCode ||
        (!isAppQA &&
          (value === orderInfo.orderId || /NOCONVERT_[0-9]{2}/.test(value)));
      console.log("checkSkipScan", shouldSkip);
      return shouldSkip;
    },
    [list, isAppQA, orderInfo.accessCode, orderInfo.orderId],
  );

  const dispatchItemScanAction = useCallback(
    (scanVal: string) => {
      dispatch({
        type: isAppQA ? "SCAN_ITEM_QA" : SCAN_ITEM,
        scan: scanVal,
        isAppQA,
      });
    },
    [isAppQA, dispatch],
  );

  const dispatchBadScanAction = useCallback(
    (scanVal: string) => {
      if (!isAppQA) {
        createAlert(orderInfo.orderId, usertoken, scanVal);
      }
      dispatch({
        type: SafetyScansErrorActionTypes.SET_ERROR,
        errState: true,
        errMsg: scanVal,
      });
    },
    [isAppQA, dispatch, orderInfo.orderId, usertoken],
  );

  const clearAll = useCallback(() => {
    clearFilled();
    clearInput();
  }, [clearFilled, clearInput]);

  useEffect(() => {
    console.log("useEffect", val ?? "empty", filled);
    if (!filled) {
      return;
    }
    if (checkSkipScan(val)) {
      return clearAll();
    }

    const scanVal = val;
    setList((c) => [...c, scanVal]);
    clearAll();

    if (
      analogItems.findIndex((item_barcode) => item_barcode === scanVal) > -1
    ) {
      return dispatchItemScanAction(scanVal);
    }

    // Scan is valid barcode format but not on this order - hard error
    if (isValidOrder === "bad-scan" || isValidOrder === "id-mismatch") {
      return dispatchBadScanAction(scanVal);
    } else if (isValidOrder === "partial-id") {
      // Invalid random scan - soft error
      triggerError(`Partial ID ${scanVal}`);
    } else {
      // Invalid random scan - soft error
      triggerError(scanVal);
    }
  }, [filled, val, triggerError, analogItems, isValidOrder]);

  return { list, errState, errMsg, clearError };
};

type ScanItemsOrderInfo = OrderInfoState | Api.IReship;
export interface ScanItemsProps {
  orderInfo: ScanItemsOrderInfo;
  dispatch: Dispatch<any>;
  error?: { errState: boolean; errMsg: string };
  isAppQA?: boolean;
}

export default function ScanItems({
  orderInfo,
  dispatch,
  error,
  isAppQA = false,
}: ScanItemsProps) {
  const [{ usertoken }] = useLogin();
  const scanItemsState = useScanItems(
    orderInfo,
    dispatch,
    usertoken,
    error,
    isAppQA,
  );

  // Item scans paging
  const pager = usePager(
    orderInfo.analogItems,
    getSafetyscansDisplayPageNum(isAppQA),
  );

  usePageState(orderInfo, scanItemsState.errState, dispatch);

  const reset = () => {
    dispatch({ type: RESET });
  };

  const clearQAAppError = () => {
    dispatch({
      type: SafetyScansErrorActionTypes.SET_ERROR,
      errState: false,
      errMsg: "",
    });
  };

  let onClickFn = error?.errState === true ? reset : scanItemsState.clearError;
  if (isAppQA) {
    onClickFn =
      error?.errState === false ? scanItemsState.clearError : clearQAAppError;
  }

  return (
    <>
      <section className={"scan-items__wrapper"}>
        <div
          className={"scan-items__header"}
        >{`Safety scan all of ${orderInfo.name}'s items`}</div>
        <ItemInputs itemScans={pager.current} />
        <PagerButtons
          {...pager}
          displayPageNum={
            orderInfo.analogItems.length > getSafetyscansDisplayPageNum(isAppQA)
          }
        />
      </section>
      <ErrorArea
        error={error?.errState === true ? error : scanItemsState}
        id={orderInfo.orderId}
        onClick={onClickFn}
        stopOnError={error?.errState === true}
      />
    </>
  );
}
