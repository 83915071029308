import { useEffect, useState } from "react";

import useApi from "../../../../../hooks/use-api";

export const useCalculateShippingWeight = (
  salesOrderId: number | string,
): boolean | undefined => {
  const [weightOver, setWeightOver] = useState<boolean | undefined>(undefined);

  const api = useApi<{ weight: number }>(
    `production/order/${salesOrderId}/calculateweight`,
    "get",
    true,
    { "Content-Type": "application/json" },
  );

  useEffect(() => {
    if (api.isResolved) {
      setWeightOver(api?.body?.weight > 11);
    }
  }, [api.isResolved, api.body]);

  return weightOver;
};

export type Leg3ShipmentTypes =
  | { key: 1; type: "rush"; level: "fedex_2_day" }
  | { key: 1; type: "standard"; level: "fedex_smart_post" }
  | { key: 5; type: "standard5to9"; level: "ups_surepost" };

/** hack for main_product info from api */
interface BoxInfo {
  name: string;
  variant: string;
  /** other stuff not important here */
}
export function useFixedCalculateShippingWeight(
  salesOrderId: number | string,
  boxInfo?: BoxInfo,
): Leg3ShipmentTypes | undefined {
  /** Check if order is rush box, and give special shipping */
  const hasRushBox = boxInfo && boxInfo.name === "Rush Box";
  let initialType: Leg3ShipmentTypes = hasRushBox
    ? { key: 1, type: "rush", level: "fedex_2_day" }
    : undefined;
  console.log(JSON.stringify(initialType));
  const [type, setType] = useState<Leg3ShipmentTypes>(initialType);

  const { isResolved, body } = useApi<{ weight: number }>(
    `production/order/${salesOrderId}/calculateweight`,
    "get",
    !hasRushBox,
    { "Content-Type": "application/json" },
  );

  useEffect(() => {
    if (!hasRushBox && isResolved) {
      let weight = body.weight;

      if (weight < 5) {
        setType({ key: 1, type: "standard", level: "fedex_smart_post" });
      } else if (weight >= 5 && weight <= 9) {
        setType({ key: 5, type: "standard5to9", level: "ups_surepost" });
      } else if (weight > 9) {
        setType({ key: 1, type: "standard", level: "fedex_smart_post" });
      }
    }
  }, [isResolved, body, hasRushBox]);

  return type;
}
