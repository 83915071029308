import {
  nextPage,
  setPage,
} from "../../../../hooks/reducers/actions/page-step";
import {
  setItemCount,
  setOutputs,
} from "../../../../hooks/reducers/actions/qa/processing";
import type {
  QaReducerProps,
  TQaProcessPages,
} from "../../../../hooks/reducers/qa-process";
import { styled } from "../../../../stitches.config";
import { usePassFailToggle } from "../../../components/pass-fail/pass-fail";
import type { PassFailToggle } from "../../../components/pass-fail/pass-fail";
import { TouchButton } from "../../../components/touch-button/touch-button";
import SafetyscanItems from "../safetyscan-items";
import Outputs from "./outputs";

const ButtonWrapper = styled("section", {
  marginTop: "20px",
});

const OutputsSafetyscan = (props: QaReducerProps) => {
  const { dispatch, state } = props;
  const {
    processing: { itemCount, outputs },
  } = state;
  const [hasOutputs, setHasOutputs] = usePassFailToggle(outputs);

  const dispatchSetItemCount = (value: PassFailToggle) =>
    dispatch(setItemCount(value));

  return (
    <>
      <Outputs
        {...props}
        hasOutputs={hasOutputs}
        setHasOutputs={setHasOutputs}
      />
      <br />
      <SafetyscanItems {...props} dispatchSetItemCount={dispatchSetItemCount} />
      <ButtonWrapper>
        <TouchButton
          size="md"
          onClick={() => {
            dispatch(setPage<TQaProcessPages>("outside"));
          }}
        >
          Back
        </TouchButton>
        <span style={{ width: "2em" }}></span>
        <TouchButton
          size="md"
          disabled={hasOutputs === "none"}
          color={itemCount === "fail" ? "warn" : "primary"}
          onClick={() => {
            dispatch(setOutputs(hasOutputs));
            dispatch(nextPage());
          }}
        >
          Next
        </TouchButton>
      </ButtonWrapper>
    </>
  );
};

export default OutputsSafetyscan;
