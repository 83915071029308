// Action Types for Page
export const PAGE_GOTO = "PAGE_GOTO";
export const PAGE_NAV = "PAGE_NAV";
export const PAGE_STEP = "PAGE_STEP";
export const PAGE_BTN_ACTIVE = "PAGE_BTN_ACTIVE";
export const RESET_PAGES = "RESET_PAGES";
export const COMPLETE_STEPS = "COMPLETE_STEPS";
export const PAGE_STEP_TO_PAYLOAD = "PAGE_STEP_TO_PAYLOAD";

export const progressBarGoto = ({ activePage, steps }, action) => {
  if (activePage === "Ship Order") {
    return;
  }

  const { step: gotoStep } = action;
  const gotoPage = steps.find((s) => s.order === gotoStep);
  const newStep = { activePage: gotoPage.name };

  if (!(gotoPage.inProgress || gotoPage.complete)) {
    return;
  }
  return newStep;
};

export const goto = (state, action) => {
  const { step: gotoStep } = action;
  const newStep = { activePage: gotoStep };
  return newStep;
};

export const nextStep = ({ activePage, steps }) => {
  const currentStep = steps.find((s) => s.name === activePage);
  if (!currentStep) {
    return { activePage };
  }
  const nextStep = steps.find((s) => s.order === currentStep.order + 1);
  const index = currentStep.order - 1;
  if (!nextStep) {
    return { activePage };
  }
  const newCurrentStep = {
    ...currentStep,
    inProgress: false,
    complete: true,
  };
  const newNextStep = {
    ...nextStep,
    inProgress: !nextStep.complete,
    complete: currentStep.complete && nextStep.complete,
  };
  return {
    activePage: nextStep.name,
    steps: [
      ...steps.slice(0, index),
      newCurrentStep,
      newNextStep,
      ...steps.slice(index + 2),
    ],
  };
};

export const completeSteps = ({ steps }, action) => {
  const { step: gotoStep } = action;
  const gotoPage = steps.find((s) => s.name === gotoStep);
  const newSteps = steps.map((step) => {
    if (step.order < gotoPage.order) {
      return { ...step, inProgress: false, complete: true };
    } else if (step.order === gotoPage.order) {
      return { ...step, inProgress: !step.complete };
    } else {
      return step;
    }
  });
  return {
    activePage: gotoStep,
    steps: newSteps,
  };
};

const setGoButton = ({ activePage, steps }, { active, visable = true }) => {
  const index = steps.findIndex((s) => s.name === activePage);
  if (index < 0) {
    return {};
  }
  const step = { ...steps[index], canAdvance: active, showGoBtn: visable };
  return { steps: [...steps.slice(0, index), step, ...steps.slice(index + 1)] };
};

export const resetPages = () => {
  return { ...pageInitialState };
};

export const pageStepToPayload = (state, action) => {
  return {
    ...state,
    activePage: action.payload,
  };
};

export const pageInitialState = {
  activePage: "Get Started",
  steps: [
    {
      name: "Get Started",
      order: 1,
      inProgress: true,
      canAdvance: false,
      complete: false,
      showGoBtn: false,
    },
    {
      name: "Scan Items",
      order: 2,
      inProgress: false,
      canAdvance: false,
      complete: false,
      showGoBtn: true,
    },
    {
      name: "Log Damaged Items",
      order: 3,
      inProgress: false,
      canAdvance: false,
      complete: false,
      showGoBtn: false,
    },
    {
      name: "Confirm All Delivery Methods",
      order: 4,
      inProgress: false,
      canAdvance: false,
      complete: false,
      showGoBtn: false,
    },
    {
      name: "Review Order",
      order: 5,
      inProgress: false,
      canAdvance: false,
      complete: false,
      showGoBtn: false,
    },
  ],
};

export const pageActions = {
  PAGE_GOTO: progressBarGoto,
  PAGE_NAV: goto,
  PAGE_STEP: nextStep,
  PAGE_BTN_ACTIVE: setGoButton,
  RESET_PAGES: resetPages,
  COMPLETE_STEPS: completeSteps,
  PAGE_STEP_TO_PAYLOAD: pageStepToPayload,
};
