import type { Api } from "./api";

export function isFinalShippingLabelShippoError(
  data: unknown,
): data is Api.IFinalShippingLabelShippoError {
  const err = data as Api.IFinalShippingLabelShippoError;
  return err?.type === "ShippoAPIError";
}
export function isFinalShippingLabelCarrierError(
  data: unknown,
): data is Api.IFinalShippingLabelCarrierError {
  const err = data as Api.IFinalShippingLabelCarrierError;
  return err?.transactionId != null && err?.success === false;
}
export function isFinalShippingLabel(
  data: unknown,
): data is Api.IFinalShippingLabel {
  const shipment = data as Api.IFinalShippingLabel;
  return shipment?.transactionId != null && shipment?.success === true;
}
