import "./timeline.scss";
import Card from "../../groups/card";

const BackupItem = ({ item }) => {
  return (
    <article key={item.barcode} className="log-table__row">
      <div className="log-table__date-col">{item.barcode}</div>
      <div
        className="log-table__message-col"
        dangerouslySetInnerHTML={{
          __html: `${item.fileType}`,
        }}
      />
    </article>
  );
};

export const BackupOrderLog = ({ items }) => {
  return (
    <section className="log-table">
      <header className="log-table__header">
        <div>File Name</div>
        <div>File Type</div>
      </header>
      <section className="log-table__scroll-wrapper">
        {items?.map((item) => {
          return <BackupItem key={item.barcode} item={item} />;
        })}
      </section>
    </section>
  );
};

/** @param {any} props */
export const BackupOrder = ({ state, isLoading, isResolved }) => {
  return (
    <section className="order__content-container">
      <section className="order__main">
        <Card header="Backup Order" size="full">
          {isLoading && <article>Getting backup order log</article>}
          {isResolved && <BackupOrderLog items={state.backupOrderData} />}
        </Card>
      </section>
    </section>
  );
};

export default BackupOrder;
