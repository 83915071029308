import { useState, useEffect } from "react";

export const useCustomRedirect = (loggedIn, location) => {
  const [redirectPath, setRedirectPath] = useState("/");
  const [redirectState, setRedirectState] = useState(false);

  useEffect(() => {
    if (location === null || location === undefined) {
      return;
    }

    if (
      "state" in location &&
      location.state !== undefined &&
      location.state !== null &&
      "isRedirect" in location.state
    ) {
      setRedirectPath(location.state.from.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (loggedIn === true) {
      setRedirectState(true);
    }
  }, [loggedIn]);

  return { redirectPath, redirectState };
};

export default useCustomRedirect;
