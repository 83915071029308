interface NewVersionToastProps {
  onClick: () => void;
}

export const NewVersionToast = ({ onClick }: NewVersionToastProps) => (
  <section>
    A new version is available.
    <br />
    <button onClick={onClick}>Refresh</button>
  </section>
);
