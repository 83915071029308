import { useEffect, useState } from "react";

import { useLogin } from "../../hooks/context/login";
import { SET_PRODUCTION_ORDER } from "../../hooks/reducers/order-view";
import BaseButton from "../base/button";
import { Toggle } from "../components/toggle";
import UpgradeIndicator from "../components/upgrade-indicator";

import "./add-outputs.scss";

const { VITE_APP_API_URI } = import.meta.env;

const InitialOutputs = [
  { outputType: "DVD Set", quantity: 0, addQuantity: 0 },
  { outputType: "Thumb Drive", quantity: 0, addQuantity: 0 },
  { outputType: "Digital Download", quantity: 0, addQuantity: 0 },
];

const resetEditOutputs = (outputs) =>
  InitialOutputs.map((output) => {
    let existingOutput = outputs.find(
      ({ output_type }) => output_type === output.outputType,
    );
    if (existingOutput !== undefined) {
      return {
        ...output,
        quantity: existingOutput.quantity,
      };
    }
    return output;
  });
const OutputEditor = ({ item, setOutputs }) => {
  const updateQty = (updateFn) =>
    setOutputs((outputs) => {
      let index = outputs.findIndex(
        ({ outputType }) => outputType === item.outputType,
      );
      return [
        ...outputs.slice(0, index),
        {
          ...outputs[index],
          addQuantity: updateFn(outputs[index].addQuantity),
        },
        ...outputs.slice(++index),
      ];
    });
  return (
    <section className="add-outputs__output-editor">
      <UpgradeIndicator
        key={item.outputType}
        textContent={item.outputType}
        quantity={item.quantity + item.addQuantity}
        upgrade={true}
      />
      <section className="add-outputs__quantity-editor">
        <BaseButton
          onClick={() => updateQty((qty) => (qty > 0 ? --qty : qty))}
          className="add-outputs__quantity-btn add-outputs__quantity-btn--sub"
        >
          -
        </BaseButton>
        <BaseButton
          onClick={() => updateQty((qty) => qty + 1)}
          className="add-outputs__quantity-btn add-outputs__quantity-btn--add"
        >
          +
        </BaseButton>
      </section>
    </section>
  );
};
const OutputEditGroup = ({
  invoice,
  setInvoice,
  editOutputs,
  setEditOutputs,
  submit,
}) => {
  const submitInactive =
    editOutputs.filter(({ addQuantity }) => addQuantity > 0).length === 0;
  return (
    <section className="add-outputs__wrapper">
      <header className="add-outputs__header">Edit Outputs</header>
      <section className="add-outputs__edits">
        {editOutputs.map((item) => (
          <OutputEditor
            key={item.outputType}
            item={item}
            setOutputs={setEditOutputs}
          />
        ))}
      </section>
      <section className="add-outputs__invoice">
        <header>{invoice ? "Send Invoice" : "No Charge"}</header>
        <Toggle state={invoice} setState={setInvoice} />
      </section>
      <BaseButton
        onClick={!submitInactive ? submit : () => {}}
        className={`add-outputs__submit${submitInactive ? " inactive" : ""}`}
      >
        Submit
      </BaseButton>
    </section>
  );
};

const ConfirmUpdate = ({ invoice, updates, submit }) => {
  return (
    <section className="add-outputs__wrapper">
      <header className="add-outputs__header">Edit Outputs</header>
      <section className="add-outputs__confirm">
        <article>Are you sure you want to add these outputs?</article>
        {updates
          .filter(({ addQuantity }) => addQuantity > 0)
          .map((item) => (
            <UpgradeIndicator
              key={item.outputType}
              textContent={item.outputType}
              quantity={item.addQuantity}
              upgrade={true}
            />
          ))}
        {invoice ? (
          <article>The customer will be billed for these items.</article>
        ) : (
          <></>
        )}
        <BaseButton onClick={submit} className="add-outputs__submit">
          Confirm
        </BaseButton>
      </section>
    </section>
  );
};

const handleSubmit = async (
  order,
  usertoken,
  editedOutputs,
  invoice,
  dispatch,
  onComplete,
) => {
  const items = editedOutputs
    .map(({ outputType, addQuantity }) => ({
      outputType,
      quantity: addQuantity,
    }))
    .filter(({ quantity }) => quantity > 0);
  const res = await fetch(
    `https://${VITE_APP_API_URI}/production/order/${order.production_order_id}/outputs`,
    {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        items,
        invoice,
      }),
    },
  );
  if (!res.ok) {
    throw new Error(`Fetch Error [${res.status}]: ${res.statusText}`);
  }
  const json = await res.json();
  dispatch({ type: SET_PRODUCTION_ORDER, payload: json });
  onComplete();
};

export const AddOutputs = ({ order, outputs, show, setShow, dispatch }) => {
  const [{ usertoken }] = useLogin();
  const [editing, setEditing] = useState(true);
  const [invoice, setInvoice] = useState(true);
  const [canSubmit, setCanSubmit] = useState(true);
  const [editOutputs, setEditOutputs] = useState(resetEditOutputs(outputs));

  useEffect(() => {
    if (!show) {
      setEditing(true);
      setInvoice(true);
      setCanSubmit(true);
      setEditOutputs(resetEditOutputs(outputs));
    }
  }, [show, outputs]);
  return (
    <>
      {editing ? (
        <OutputEditGroup
          editOutputs={editOutputs}
          setEditOutputs={setEditOutputs}
          invoice={invoice}
          setInvoice={setInvoice}
          submit={() => setEditing(false)}
        />
      ) : (
        <ConfirmUpdate
          invoice={invoice}
          updates={editOutputs}
          submit={
            canSubmit
              ? () => {
                  setCanSubmit(false);
                  handleSubmit(
                    order,
                    usertoken,
                    editOutputs,
                    invoice,
                    dispatch,
                    () => setShow(false),
                  );
                }
              : () => {}
          }
        />
      )}
    </>
  );
};
export default AddOutputs;
