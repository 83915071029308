import reactTable from "react-table";
import TableHeader from "./header";
import TableBody from "./body";

export interface TableProps<TableType extends object>
  extends reactTable.TableInstance<TableType> {
  classPrefix: string;
}
export function Table<TableType extends object>(props: TableProps<TableType>) {
  return (
    <table className={`${props.classPrefix}__table`} {...props.getTableProps()}>
      <TableHeader
        headerGroups={props.headerGroups}
        classPrefix={props.classPrefix}
      />
      <TableBody<TableType> {...props} classPrefix={props.classPrefix} />
    </table>
  );
}
export default Table;
