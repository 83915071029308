import BaseButton from "../base/button";

import "./pager-buttons.scss";

/** Generic paging buttons to use with usePager hook */
interface PagerButtonProps {
  page: number;
  lastPage: number;
  next: () => void;
  previous: () => void;
  displayPageNum?: boolean;
}

export const PagerButtons = ({
  page,
  lastPage,
  next,
  previous,
  displayPageNum = true,
}: PagerButtonProps) => {
  const hasNextPage = page < lastPage;
  const hasPrevPage = page > 0;
  return (
    <section className="pager__wrapper">
      <div className="pager__pager-buttons">
        {hasPrevPage && (
          <BaseButton className="pager__pager-button prev" onClick={previous}>
            {"<"}
          </BaseButton>
        )}
      </div>

      {displayPageNum && <div className="pager__pager-page">{page + 1}</div>}
      <div className="pager__pager-buttons">
        {hasNextPage && (
          <BaseButton className="pager__pager-button next" onClick={next}>
            {">"}
          </BaseButton>
        )}
      </div>
    </section>
  );
};

export default PagerButtons;
