import { useReducer } from "react";

import { pageInitialState, pageActions } from "./actions/safetyscans/page";
import {
  orderInfoInitialState,
  orderInfoActions,
} from "./actions/safetyscans/order-info";
import {
  departmentsInitialState,
  departmentsActions,
} from "./actions/safetyscans/departments";
import {
  shippingInitialState,
  shippingActions,
} from "./actions/safetyscans/shipping";
import {
  SafetyScansErrorInitialState,
  SafetyScansErrorActions,
} from "./actions/safetyscans/error";

export const RESET = "RESET";

export const InitialState = {
  ...pageInitialState,
  ...orderInfoInitialState,
  ...shippingInitialState,
  ...departmentsInitialState,
  ...SafetyScansErrorInitialState,
};

/** Retain station information */
export const ResetState = {
  ...pageInitialState,
  ...orderInfoInitialState,
  ...shippingInitialState,
  ...SafetyScansErrorInitialState,
};
export const Actions = {
  ...pageActions,
  ...orderInfoActions,
  ...shippingActions,
  ...departmentsActions,
  ...SafetyScansErrorActions,
};

export const reducer = (state, action) => {
  // Completed safety scans, reset for another order.
  if (action.type === RESET) {
    console.log("Resetting...");
    return {
      ...state,
      ...ResetState,
      departmentsData: {
        ...state.departmentsData,
        validForOrder: null,
      },
    };
  }

  const act = Actions[action.type];
  const update = act(state, action);

  return { ...state, ...update };
};

export const useSafetyScansReducer = () => useReducer(reducer, InitialState);
export default useSafetyScansReducer;
