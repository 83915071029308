import "./timeline.scss";

import Card from "../../groups/card";

export const Log = ({ item }) => {
  return (
    <article className="log-table__row">
      <div className="log-table__date-col">{item.displayDate}</div>
      <div
        className="log-table__message-col"
        dangerouslySetInnerHTML={{
          __html: `${item.note_title !== null ? item.note_title : ""} ${
            item.custom_text !== null ? item.custom_text : ""
          }`,
        }}
      />
    </article>
  );
};

export const TimelineLog = ({ log }) => {
  return (
    <section className="log-table">
      <header className="log-table__header">
        <div>Date</div>
        <div>Message</div>
      </header>
      <section className="log-table__scroll-wrapper">
        {log.map((item, idx) => (
          <Log item={item} key={idx} />
        ))}
      </section>
    </section>
  );
};

/** @param {any} props */
export const Timeline = ({ state, isLoading, isResolved }) => (
  <section className="order__content-container">
    <section className="order__main">
      <Card header="Timeline" size="full">
        {isLoading && <article>Getting timeline</article>}
        {isResolved && <TimelineLog log={state.timelineData} />}
      </Card>
    </section>
  </section>
);

export default Timeline;
