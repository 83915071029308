import cn from "classnames";

import "./upgrade-indicator.scss";

/**
 * Output upgrade visual indicator
 * @param {Object} props
 * @param {string} props.textContent - Text upgrade description
 * @param {number} props.quantity - Total quantity (ordered + upgraded)
 * @param {boolean} [props.upgrade] - Is quantity from upgrade
 */
interface UpgradeIndicatorProps {
  textContent: string;
  quantity: number;
  upgrade?: boolean;
}

export const UpgradeIndicator = ({
  textContent,
  quantity,
  upgrade = false,
}: UpgradeIndicatorProps) => {
  const indicatorClass = cn("upgrade-indicator", {
    "upgrade-indicator--active": upgrade,
  });
  const numberClass = cn("upgrade-indicator__number", {
    "upgrade-indicator__number--active": quantity > 0,
  });

  return (
    <>
      <div className={indicatorClass}>
        <div className="upgrade-indicator__text">{textContent}</div>
        <span className={numberClass}>{quantity}</span>
      </div>
    </>
  );
};

export default UpgradeIndicator;
