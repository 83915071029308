import { useEffect, useRef, useState } from "react";

import {
  ADD_FORMAT_ITEM,
  REMOVE_FORMAT_ITEM,
  SELECT_CATEGORY,
} from "../../../hooks/reducers/actions/receiving/category";
import { PAGE_BTN_ACTIVE } from "../../../hooks/reducers/actions/receiving/page";
import useItemError from "../../../hooks/use-item-error";
import useKeyEventBarcode from "../../../hooks/use-key-event-barcode";
import BaseButton from "../../base/button";
import CategoryGroup from "../../components/category-group";
import InputsGroup from "../../components/inputs-group";
import MediaGroup from "../../components/media-group";

import "./analog-items.scss";

export const Formats = ({
  selectedCategory,
  selectedFormat,
  activeFormats,
  error,
  onClick,
}) =>
  selectedCategory && selectedCategory.length > 0 ? (
    <MediaGroup
      mediaButtons={activeFormats}
      selected={selectedFormat}
      onClick={onClick}
      error={error}
      initialPage={0}
      pageSize={8}
      groupHeader={"2. Select Media Type"}
    />
  ) : (
    <>{null}</>
  );

export const Items = ({
  selectedCategory,
  selectedFormat,
  barcodes,
  prevItems,
  error,
  triggerError,
  dispatch,
  orderInfo,
}) => {
  const {
    val,
    filled: scanDone,
    clearInput,
    clearFilled,
    isValidOrder,
  } = useKeyEventBarcode(true, orderInfo.orderId);
  const { orderId } = orderInfo;
  const activeItem = [{ id: "active", code: val }];
  const activeItems = prevItems.concat(activeItem);

  useEffect(() => {
    if (!scanDone) {
      return;
    }
    let item = { code: val };

    const scanError = (item, errMsg) => {
      item = { ...item, error: errMsg };
      triggerError(errMsg);
      dispatch({ type: PAGE_BTN_ACTIVE, active: false });
      return item;
    };

    if (!["valid", "waiting"].includes(isValidOrder)) {
      let msg;

      switch (isValidOrder) {
        case "bad-scan":
          msg = "Invalid characters in barcode scan";
          break;
        case "partial-id":
          msg = `Scan has partial order ID ${val}`;
          break;
        case "id-mismatch":
          msg = "Order Id mismatch in scan";
          break;
        default:
          msg = "Barcode scan error";
      }

      item = scanError(item, msg);
    }

    // Barcode already scanned
    else if (barcodes.findIndex((b) => b.code === val) !== -1) {
      item = scanError(item, "Barcode already scanned");
    }
    dispatch({
      type: ADD_FORMAT_ITEM,
      category: selectedCategory,
      format: selectedFormat,
      item: item,
    });
    clearInput();
    clearFilled();
  }, [
    val,
    scanDone,
    clearInput,
    clearFilled,
    barcodes,
    orderId,
    selectedCategory,
    selectedFormat,
    dispatch,
    triggerError,
    isValidOrder,
  ]);

  return (
    <>
      <h1 className="analog-items__inputs-header">3. Scan Analog Items</h1>
      <InputsGroup
        textInputs={activeItems}
        initialPage={Math.floor(activeItems.length / 12)}
        pageSize={12}
        groupHeader={""}
        onClick={(item) =>
          dispatch({
            type: REMOVE_FORMAT_ITEM,
            category: selectedCategory,
            format: selectedFormat,
            item: item,
          })
        }
        error={error}
      />
    </>
  );
};

export const AnalogItems = ({ state, dispatch }) => {
  const {
    selectedCategory,
    selectedFormat,
    categories,
    barcodes,
    totalItemCount,
  } = state;
  const [activeFormats, setActiveFormats] = useState([]);
  const { errState, errMsg, triggerError, filterErrors, setFilterErrors } =
    useItemError(barcodes, totalItemCount, dispatch);

  const [prevItems, setPrevItems] = useState([]);
  const prevItemsLength = useRef(prevItems.length);

  // Show formats for category
  useEffect(() => {
    console.log(categories);
    console.log(selectedCategory);
    console.log(selectedFormat);

    if (selectedCategory && selectedCategory.length > 0) {
      const formats = categories.find(
        (cat) => cat.category === selectedCategory,
      )["formats"];
      setActiveFormats(formats);
      if (selectedFormat && selectedFormat.length > 0) {
        setPrevItems(
          formats.find((frmt) => frmt.format === selectedFormat)["items"],
        );
      }
    }
  }, [selectedCategory, categories, selectedFormat]);

  // Show only bad barcode scans
  useEffect(() => {
    if (!selectedFormat || activeFormats.length === 0) return;

    if (filterErrors) {
      setPrevItems(() => {
        const format = activeFormats.find(
          (frmt) => frmt.format === selectedFormat,
        );

        if (format === undefined) {
          return [];
        }
        return format.items.filter((item) => item.error);
      });
    } else {
      setPrevItems(
        () =>
          activeFormats.find((frmt) => frmt.format === selectedFormat)[
            "items"
          ] || [],
      );
    }
  }, [filterErrors, selectedFormat, activeFormats]);

  useEffect(() => {
    prevItemsLength.current = prevItems.length;
  }, [prevItems.length]);

  return (
    <>
      <section className="analog-items__category-wrapper">
        <CategoryGroup
          categoryButtons={categories}
          selected={selectedCategory}
          error={errState}
          onClick={
            errState
              ? () => {}
              : (val) =>
                  dispatch({ type: SELECT_CATEGORY, selectedCategory: val })
          }
        />
      </section>
      <section className="analog-items__format-wrapper">
        <Formats
          selectedCategory={selectedCategory}
          selectedFormat={selectedFormat}
          activeFormats={activeFormats}
          error={errState}
          onClick={errState ? () => {} : dispatch}
        />
      </section>
      {selectedFormat !== "" && (
        <section className="analog-items__inputs-wrapper">
          <Items
            {...state}
            prevItems={prevItems}
            error={errState}
            triggerError={triggerError}
            dispatch={dispatch}
          />
        </section>
      )}
      {errState ? (
        <div className="analog-items__scan-error-wrapper">
          <div className="analog-items__scan-error-message">{errMsg}</div>
          <BaseButton
            onClick={(event) => {
              setFilterErrors((e) => !e);
              event.currentTarget.blur();
            }}
            className="analog-items__show-error-button"
          >
            Show {filterErrors ? "All" : "Errors"}
          </BaseButton>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AnalogItems;
