import { useEffect, useRef, useState } from "react";
import type { FormEvent } from "react";

import useError from "../../../hooks/use-error";
import useKeyEvent from "../../../hooks/use-key-event";
import { usePrevious } from "../../../hooks/use-previous";
import TextInput from "../../base/text-input";
import { TrackingInput } from "../arrival/scan-box";
import type { ScanOrderProps } from "./edit-items/scan-order";

import "./scan-order.scss";

type ActiveStates = "order" | "access" | "fetch";

export interface AdminScanOrderRESTErrorMap {
  printComplete?: boolean;
  restEndpoint: "RECEIVING_REPRINT";
  errorMsg: string;
}

const ScanOrder = ({
  submitFn,
  header = "Start by scanning order and access #s:",
  buttonText = "Find Order",
}: ScanOrderProps) => {
  const [active, setActive] = useState<ActiveStates>("order");

  const {
    errState: orderIDError,
    errMsg: orderErrMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();

  const onOrderIDScan = () => {
    if (prevOrderID !== order) {
      clearOrderIDError();
    }
  };

  const { val: order, filled: orderScanned } = useKeyEvent(
    active === "order",
    onOrderIDScan,
  );
  let prevOrderID = usePrevious(order);
  const { val: access, filled: accessScanned } = useKeyEvent(
    active === "access",
  );
  const submitButtonRef = useRef(null);

  const clearOrderIDError = () => {
    if (!orderIDError) return;
    clearError();
  };

  useEffect(() => {
    if (orderScanned) {
      setActive("access");
    }
  }, [orderScanned]);

  useEffect(() => {
    if (accessScanned) {
      setActive("fetch");
      submitButtonRef.current.focus();
    }
  }, [accessScanned, submitButtonRef]);

  const orderClass = `scan-order__order-input${
    active === "order" ? " scan-order__order-input--active" : ""
  }`;
  const accessClass = `scan-order__order-input${
    active === "access" ? " scan-order__order-input--active" : ""
  }`;

  const onSubmit = async (event: FormEvent): Promise<void> => {
    clearError();
    if (event) {
      event.preventDefault();
    }
    const restResponseType = await submitFn(order, access);
    const { restEndpoint, errorMsg } = (restResponseType ??
      {}) as AdminScanOrderRESTErrorMap;
    if (restEndpoint === "RECEIVING_REPRINT") {
      triggerError(errorMsg);
    }
  };

  const handleFocus = (focus: ActiveStates): void => {
    setActive(focus);
  };

  return (
    <section className="scan-order__order">
      <h1>{header}</h1>

      <form onSubmit={onSubmit}>
        <label onClick={() => handleFocus("order")}>
          Order:{" "}
          <TrackingInput
            error={orderIDError}
            errorMsg={orderErrMsg}
            className={orderClass}
            val={order}
          />
        </label>
        <label onClick={() => handleFocus("access")}>
          Access: <TextInput className={accessClass} val={access} />
        </label>
        <input
          ref={submitButtonRef}
          type="submit"
          className="scan-order__get-order"
          value={buttonText}
        />
      </form>
    </section>
  );
};

export default ScanOrder;
