import { useCallback, useEffect, useReducer, useRef, useState } from "react";

import { useLogin } from "../../hooks/context/login";
import {
  ARRIVAL_FLAG,
  ITEM_SCANNED,
  SCAN_EVENT,
  SCAN_FAILURE,
  SCAN_SUCCESS,
  initialState,
  makeReducer,
} from "../../hooks/reducers/arrival";
import useBooleanFetch from "../../hooks/use-boolean-fetch";
import useError from "../../hooks/use-error";
import useKeyEvent from "../../hooks/use-key-event";
import upsGround from "../../images/ups-ground.png";
import upsSurepost from "../../images/ups-surepost.png";
import BaseButton from "../base/button";
import AppName from "../components/app-name";
import IndicatorLight from "../components/indicator-light";
import "./arrival.scss";
import GetStarted from "./arrival/get-started";
import { TrackingInput } from "./arrival/scan-box";

const useMailReducer = () => {
  const [{ usertoken }] = useLogin();
  const { val, filled, clearFilled, clearInput } = useKeyEvent(true);
  const reducer = makeReducer(clearFilled, clearInput);
  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { list, entry } = state;
  const prevListLength = useRef(list.length);

  const onSuccess = useCallback(
    (headers) => {
      const orderFlag = headers.get("x-order-flag");
      if (orderFlag !== null && orderFlag.toUpperCase() !== "NONE") {
        dispatch({ type: ARRIVAL_FLAG, payload: orderFlag.toUpperCase() });
      } else {
        dispatch({ type: ARRIVAL_FLAG, payload: "" });
      }
      clearInput();
      clearError();
      dispatch({ type: SCAN_SUCCESS });
    },
    [dispatch, clearError, clearInput],
  );

  const onFail = useCallback(
    (errMsg) => {
      dispatch({ type: ARRIVAL_FLAG, payload: "" });
      dispatch({ type: SCAN_FAILURE });

      clearInput();
      triggerError(errMsg);
    },
    [dispatch, triggerError, clearInput],
  );

  const triggerFetch = useBooleanFetch(
    `arrival/mail-check/${entry}`,
    usertoken,
    onSuccess,
    onFail,
    triggerError,
  );

  // logs value for input
  useEffect(() => {
    dispatch({ type: SCAN_EVENT, payload: val });
  }, [val, dispatch]);

  // adds completed scan input to list.
  useEffect(() => {
    if (filled) {
      if (list.findIndex((item) => item === entry) > -1) {
        triggerError("Item already scanned");
      }
      clearFilled();
      dispatch({ type: ITEM_SCANNED });
    }
  }, [filled, clearFilled, triggerError, entry, list, dispatch]);

  // trigger fetch
  useEffect(() => {
    if (list.length > prevListLength.current) {
      triggerFetch();
    }
  }, [list.length, prevListLength, triggerFetch]);

  // set list ref
  useEffect(() => {
    prevListLength.current = list.length;
  });

  return { state, dispatch, errState, errMsg };
};

export const ScanBox = ({ onClick }) => {
  const { state, errState, errMsg } = useMailReducer();
  return (
    <>
      <section className="arrival__title">
        Check if order is Rush
        <h1>SCAN TRACKING #</h1>
      </section>
      <IndicatorLight
        light="fail"
        symbol={"✖"}
        active={errState}
        wrapper="arrival__fail"
      />
      <TrackingInput
        val={state.entry}
        className="arrival__scan-input"
        error={errState}
        errorMsg={errMsg}
      />
      <IndicatorLight
        light="pass"
        symbol={"✓"}
        active={state.passScan && !errState}
        wrapper="arrival__pass"
      />
      <section className="arrival__order-flag">{state.orderFlag}</section>
      <section className="arrival__label-examples">
        <img src={upsGround} alt="UPS Ground Label" />
        <img src={upsSurepost} alt="UPS Surepost Label" />
      </section>
      <section className="arrival__total-scanned">
        <span className="arrival__scan arrival__scan--good">
          Scans: {state.scans}
        </span>
        <span className="arrival__scan arrival__scan--fail">
          Errors: {state.errors}
        </span>
        <span className="arrival__scan arrival__scan--total">
          Total: {state.total}
        </span>
      </section>
      <section className="arrival__done-scanning">
        <BaseButton onClick={onClick} className="arrival__button">
          Finished Scanning
        </BaseButton>
      </section>
    </>
  );
};

export const MailCheck = () => {
  const [active, setActive] = useState(false);

  const activateClick = () => {
    setActive(true);
  };
  const finishedClick = () => {
    setActive(false);
  };

  return (
    <>
      <AppName>Mail Check</AppName>
      {active ? (
        <ScanBox onClick={finishedClick} />
      ) : (
        <GetStarted onClick={activateClick} />
      )}
    </>
  );
};

export default MailCheck;
