import { Cell } from "react-table";

export interface TableCellProps<CellType extends {}> {
  cell: Cell<CellType>;
  classPrefix: string;
}
export function TableCell<CellType extends {}>(
  props: TableCellProps<CellType>,
) {
  return (
    <td
      className={`${props.classPrefix}__table-cell`}
      {...props.cell.getCellProps()}
    >
      {props.cell.render("Cell")}
    </td>
  );
}
export default TableCell;
