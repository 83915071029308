import { useState } from "react";

import { useDepartureReducer } from "../../../hooks/reducers/departure";
import useError from "../../../hooks/use-error";
import upsGround from "../../../images/ups-ground.png";
import upsSurepost from "../../../images/ups-surepost.png";
import BaseButton from "../../base/button";
import TextInput from "../../base/text-input";
import IndicatorLight from "../../components/indicator-light";

interface TrackingInputProps {
  className: string;
  error: boolean;
  errorMsg: string;
  val: string;
}

export const TrackingInput = ({
  className,
  error,
  errorMsg,
  val,
}: TrackingInputProps) => {
  return (
    <div className="departure__tracking-input-wrapper">
      <TextInput
        className={className}
        val={val}
        error={error}
        errorMsg={errorMsg}
      />
    </div>
  );
};

const ScanBox = ({ onClick }: { onClick: () => void }) => {
  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();
  const [showModal, setShowModal] = useState(false);
  const [showReturnOrderModal, setShowReturnOrderModal] = useState(false);

  const triggerModal = () => setShowModal(true);
  const triggerReturnModal = () => setShowReturnOrderModal(true);

  const { state, manageModalReset } = useDepartureReducer(
    triggerError,
    clearError,
    triggerModal,
    triggerReturnModal,
  );
  const { scans, total, errors, entry, light, flag } = state;

  const onScanAgain = (type?: string) => {
    if (type === "returnModal") {
      setShowReturnOrderModal(false);
    } else {
      setShowModal(false);
    }
    manageModalReset();
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="departure__overlay"></div>
          <section className="departure__modal">
            <section className="departure__modal-error__wrapper">
              <div className="departure__modal-error__header">Stop</div>
              <div className="departure__modal-error__explainer">
                This order has had outputs added since it was marked complete
              </div>

              <BaseButton
                className="go-again__buttons again"
                onClick={() => onScanAgain()}
              >
                SCAN AGAIN
              </BaseButton>
            </section>
          </section>
        </>
      ) : (
        <></>
      )}
      {showReturnOrderModal ? (
        <>
          <div className="departure__overlay"></div>
          <section className="departure__modal">
            <section className="departure__modal-error__wrapper">
              <div className="departure__modal-error__header">Stop</div>
              <div className="departure__modal">
                <br />
                {flag} found.
              </div>
              <div className="departure__modal">
                <p>Please bring to QA for review.</p>
              </div>
              <div>
                <BaseButton
                  className="go-again__buttons again"
                  onClick={() => onScanAgain("returnModal")}
                >
                  OK
                </BaseButton>
              </div>
            </section>
          </section>
        </>
      ) : (
        <></>
      )}
      <section className="departure__title">
        Log that these orders are going on the truck
        <h1>SCAN TRACKING #</h1>
      </section>
      <IndicatorLight
        light="fail"
        symbol="✖"
        active={light === "fail"}
        wrapper="departure__fail"
      />
      <TrackingInput
        val={entry}
        className="departure__scan-input"
        error={errState}
        errorMsg={errMsg}
      />
      <IndicatorLight
        light="pass"
        symbol="✓"
        active={light === "pass"}
        wrapper="departure__pass"
      />
      <section className="arrival__order-flag">{flag}</section>
      <section className="departure__label-examples">
        <img src={upsGround} alt="UPS Ground Label" />
        <img src={upsSurepost} alt="UPS Surepost Label" />
      </section>
      <section className="departure__total-scanned">
        <span className="departure__scan departure__scan--good">
          Scans: {scans}
        </span>
        <span className="departure__scan departure__scan--fail">
          Errors: {errors}
        </span>
        <span className="departure__scan departure__scan--total">
          Total: {total}
        </span>
      </section>
      <section className="departure__done-scanning">
        <BaseButton onClick={onClick} className="departure__button">
          Finished Scanning
        </BaseButton>
      </section>
    </>
  );
};

export default ScanBox;
