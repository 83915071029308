type ValidCategory = "Tapes" | "Photos" | "Film" | "Audio";

function isValidCategory(category: string): category is ValidCategory {
  return ["Tapes", "Photos", "Film", "Audio"].includes(category);
}

interface ImageIconProps {
  category: ValidCategory;
}

function ImageIcon({ category }: ImageIconProps) {
  return (
    <img
      src={`/icons/${category.toLowerCase()}.svg`}
      alt={`${category}-Icon`}
      className="category-button__icon"
    />
  );
}

export const CategoryIcon = ({ category }: { category: string }) =>
  isValidCategory(category) ? <ImageIcon category={category} /> : null;

export default CategoryIcon;
