import { ResetAction, RESET_STATE } from "./reset-action";

export const PAGE_STEP = "PAGE_STEP";
export const PAGE_NEXT = "PAGE_NEXT";

export type PageStepAction<T = any> =
  | {
      type: typeof PAGE_STEP;
      payload: T;
    }
  | { type: typeof PAGE_NEXT };

export interface PageStepState<T = any> {
  pageStep: T;
}

export const setPage = <T extends string>(page: T): PageStepAction<T> => ({
  type: PAGE_STEP,
  payload: page,
});

export const nextPage = (): PageStepAction => ({ type: PAGE_NEXT });

export default function makeReducer<T>(
  pages: readonly T[],
  initialState: PageStepState<T>,
) {
  return function reducer(
    state: PageStepState<T>,
    action: PageStepAction<T> | ResetAction,
  ): PageStepState<T> {
    switch (action.type) {
      case PAGE_STEP:
        return { ...state, pageStep: action.payload };
      case PAGE_NEXT:
        const idx = pages.findIndex((page) => page === state.pageStep);

        // key not found, or no next key return current state.
        if (idx === -1) {
          return state;
        } else if (pages[idx + 1] === undefined) {
          return state;
        }
        return { ...state, pageStep: pages[idx + 1] };
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  };
}
