// Components
import AlertsList from "../../../groups/alerts-list";
// Hooks
import { ShipBalanceDueActionCreators } from "../../../../hooks/reducers/admin/ship-balance-due";
// Types
import type { ShipBalanceDueComponentProps } from "./app";

export function ShipmentAlerts(props: ShipBalanceDueComponentProps) {
  const alerts =
    props.state.shippingAlerts?.alerts?.map((alert) => ({
      active: !!alert.active,
      alert_id: alert.alert_id.toString(),
      reason: alert.reason,
    })) || [];
  /** Reset app action for Go Again */
  const restartDispatch = () =>
    props.dispatch(ShipBalanceDueActionCreators.createRestartAction());
  return (
    <section className="ship-balance-due__shipment-alerts">
      <AlertsList alerts={alerts} dispatch={restartDispatch} />
    </section>
  );
}
export default ShipmentAlerts;
