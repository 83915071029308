import type { Dispatch } from "react";

import { EditFormatsActions } from "../../../hooks/reducers/admin/edit-formats";
import useSplitOrderReducer, {
  SplitOrderState,
  TSplitOrderActions,
} from "../../../hooks/reducers/admin/split-order";
import Formats from "./edit-items/formats";
import ItemCount from "./split-order/item-count";
import Review from "./split-order/review";
import ScanOrder from "./split-order/scan-order";

import "./split-order.scss";

export interface StepDisplayProps {
  state: SplitOrderState;
  dispatch: Dispatch<TSplitOrderActions>;
}

const StepDisplay = (props: StepDisplayProps) => {
  const { activeStep } = props.state;
  const formatsProps = {
    ...props,
    dispatch: props.dispatch as Dispatch<EditFormatsActions>,
  };

  const steps = [
    <ScanOrder {...props} />,
    <ItemCount {...props} />,
    <Formats {...formatsProps} />,
    <Review {...props} />,
  ];

  return steps[activeStep];
};

export const SplitOrder = () => {
  const [state, dispatch] = useSplitOrderReducer();
  return (
    <section className="split-order">
      <StepDisplay state={state} dispatch={dispatch} />
    </section>
  );
};

export default SplitOrder;
