import { useState } from "react";

export interface ItemTriggerErrorCB {
  (msg: string): void;
}
export interface ItemClearErrorCB {
  (): void;
}
export interface UseErrorState {
  errState: boolean;
  errMsg: string;
  trigger: ItemTriggerErrorCB;
  clear: ItemClearErrorCB;
}

/** Manage local error state */
export default function useError(
  defaultState: boolean = false,
  defaultMsg: string = "",
) {
  const [errState, setErrState] = useState<boolean>(defaultState);
  const [errMsg, setErrMsg] = useState<string>(defaultMsg);

  /** Clear error state */
  const clear: ItemClearErrorCB = () => {
    setErrMsg("");
    setErrState(false);
  };

  /** Trigger error state */
  const trigger: ItemTriggerErrorCB = (msg: string) => {
    setErrMsg(msg);
    setErrState(true);
  };

  return { errState, errMsg, trigger, clear };
}
