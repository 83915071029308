import {
  Dispatch,
  type ReactNode,
  createContext,
  useContext,
  useReducer,
} from "react";

import {
  LoginAction,
  LoginState,
  initialLoginState,
  loginReducer,
} from "../reducers/login";

export const LoginContext =
  createContext<[LoginState, Dispatch<LoginAction>]>(null);

/** HOC for Providing Login Details to App */
export function LoginProvider({ children }: { children: ReactNode }) {
  let state = initialLoginState;

  const contextValue = useReducer(loginReducer, state);

  return (
    <LoginContext.Provider value={contextValue}>
      {children}
    </LoginContext.Provider>
  );
}

/**
 * Context consumer
 */
export function useLogin() {
  const contextValue = useContext(LoginContext);

  if (contextValue === null) {
    throw new Error("Login hook can only be used within a LoginContext.");
  }

  return contextValue;
}
