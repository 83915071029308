import { useEffect, useRef, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import { KeyContextProvider } from "../../../hooks/context/scan";
import useError from "../../../hooks/use-error";
import useKeyEvent from "../../../hooks/use-key-event";
import { printShippingLabel } from "../../../utils/dymo-print";
import BaseButton from "../../base/button";
import TextInput from "../../base/text-input";
import NumberPad from "../../groups/number-pad/pad";

import "./rts.scss";

const { VITE_APP_API_URI, VITE_APP_RTS_CODE: authCode } = import.meta.env;

const getOrderData = async (usertoken, val) => {
  const response = await fetch(
    `https://${VITE_APP_API_URI}/admin/order?code=${val}`,
    {
      headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
    },
  );

  if (!response.ok) {
    let message;
    switch (response.status) {
      case 400:
        message = "Order hasn't final shipped";
        break;
      case 404:
        message = "Order not found";
        break;
      case 500:
      default:
        message = "Something went wrong";
    }
    throw new Error(message);
  }
  return await response.json();
};

const getLabelData = async (usertoken, order) => {
  const response = await fetch(
    `https://${VITE_APP_API_URI}/shipments/${order.sales_order_id}/label`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        productionOrderId: order.production_order_id,
        shipmentType: order.is_return === 1 ? 5 : 3,
        signatureRequired: order.signature_required,
      }),
    },
  );
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.errorMessage);
  }

  return data.labelImage;
};

const logShipment = async (usertoken, { sales_order_id }) => {
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/admin/${sales_order_id}/return-to-sender`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
      },
    );
    if (!response.ok) {
      throw new Error("Order Log failed");
    }
  } catch (error) {
    console.log(error.message);
  }
};

/**
 * @param {string} usertoken
 * @param {string} val
 * @param {(msg:string) => void} triggerError
 * @param {() => void} reset
 */
const handlePrint = async (usertoken, val, triggerError, reset) => {
  try {
    const orderData = await getOrderData(usertoken, val);
    const labelImage = await getLabelData(usertoken, orderData);
    const [res] = await Promise.all([
      printShippingLabel(labelImage),
      logShipment(usertoken, orderData),
    ]);
    if (res.err !== null) {
      throw new Error(res.err);
    }
    reset();
  } catch (error) {
    triggerError(error.message);
  }
};

export const Rts = () => {
  const [auth, setAuth] = useState(false);
  const { errState, errMsg, trigger, clear } = useError();
  const [{ usertoken }] = useLogin();
  const [active, setActive] = useState("input");
  const [submitActive, setSubmitActive] = useState(true);
  const { val, filled, clearInput } = useKeyEvent(auth && active === "input");
  const submitButtonRef = useRef(null);

  const resetForm = () => {
    setActive("input");
    if (errState) {
      clear();
    }
    setSubmitActive(true);
    clearInput();
  };

  useEffect(() => {
    if (filled) {
      setActive("fetch");
      submitButtonRef.current.focus();
    }
  }, [filled, submitButtonRef]);

  const inputClass = `rts__input${
    active === "input" ? " rts__input--active" : ""
  }`;

  /** @param {React.FormEvent<HTMLFormElement>} event */
  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setSubmitActive(false);
    handlePrint(usertoken, val, trigger, resetForm);
  };

  /** @param {React.MouseEvent<HTMLButtonElement>} event */
  const handleError = (event) => {
    if (event) {
      event.preventDefault();
    }
    console.log(event);
    clear();
    clearInput();
    setSubmitActive(true);
  };

  if (!auth) {
    return (
      <KeyContextProvider>
        <NumberPad
          header="Input Auth Code"
          onSubmit={(value) => {
            clear();
            if (value === authCode) {
              return setAuth(true);
            }
            trigger("Code does not match");
          }}
          maxLength={5}
          onClear={clear}
          errState={errState}
          errMsg={errMsg}
        />
      </KeyContextProvider>
    );
  }

  return (
    <section className="rts">
      <h1>Scan safety barcode for order</h1>
      <h2>Remember this tool generates a new label</h2>
      <form onSubmit={onSubmit}>
        <label onClick={() => setActive("input")}>
          Barcode:{" "}
          <TextInput
            className={inputClass}
            val={val}
            error={errState}
            errorMsg={errMsg}
          />
        </label>
        {!errState ? (
          <input
            ref={submitButtonRef}
            type="submit"
            className={`rts__submit${
              !submitActive ? " rts__submit--disabled" : ""
            }`}
            value={submitActive ? "Print Shipping Label" : "Working..."}
            disabled={!submitActive}
          />
        ) : (
          <BaseButton onClick={handleError} className="rts__clear-error">
            Clear Error
          </BaseButton>
        )}
      </form>
    </section>
  );
};

export default Rts;
