import Icon from "../base/icon";

import "./order-header.scss";

interface OrderHeaderProps {
  /** brand name for determining Icon */
  brand?: string;
  /** customer name */
  name?: string;
  /** production order id */
  orderId?: string;
  /** order has rush processing */
  isRush?: boolean;
}

/** Order info container */
export const OrderHeader = ({
  brand = null,
  name = null,
  orderId = null,
  isRush = null,
}: OrderHeaderProps) => {
  const displayBrand = brand !== null && brand !== "";
  const displayHeader =
    orderId !== null && orderId !== "" && name !== null && name !== "";
  return (
    <article className="order-header">
      {displayBrand && <Icon name={brand} />}
      {displayHeader && (
        <div className="order-header__details">
          <h1>{name}</h1>
          ORDER # {orderId}
        </div>
      )}
      {isRush === true && (
        <span className="order-header__rush-indicator">RUSH</span>
      )}
    </article>
  );
};

export default OrderHeader;
