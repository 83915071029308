import useDiscReducer, { IDiscReducerProps } from "../../hooks/reducers/discs";

import "./discs.scss";
import RushLocateModal from "../groups/rush-locate";
import AppName from "../components/app-name";
import ScanOrderId from "./discs/scan-order";
import PickFormat from "./discs/pick-format";
import PrintList from "./discs/print-list";

const StepDisplay = (props: IDiscReducerProps) => {
  const { activeStep } = props.state;
  const steps = [
    <PickFormat {...props} />,
    <ScanOrderId {...props} />,
    <PrintList {...props} />,
  ];
  return steps[activeStep];
};

export const Discs = () => {
  const [state, dispatch] = useDiscReducer();

  return (
    <>
      <AppName>Burn Discs</AppName>
      <main className="discs__main">
        <StepDisplay state={state} dispatch={dispatch} />
      </main>
      <RushLocateModal productionOrderId={`${state.order.orderId}`} />
    </>
  );
};

export default Discs;
