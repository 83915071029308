import { useEffect, useState } from "react";

// Hooks
import { useLogin } from "../../hooks/context/login";
import { SET_CHECKIN } from "../../hooks/reducers/actions/receiving/order-info";
import { PAGE_GOTO } from "../../hooks/reducers/actions/receiving/page";
import usePager from "../../hooks/use-pager";
import { getLocalStorageLocation } from "../../utils/local-storage";
import BaseButton from "../base/button";
import PagerButtons from "../components/pager-buttons";

import "./checkin-cell.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

const workFlowOptions = [
  { id: "Receiving", name: "Receiving" },
  { id: "Teams", name: "Teams" },
];

const CellButton = ({ cell, setCell }) => (
  <BaseButton
    className={"checkin-cell__cell-button"}
    children={cell.name}
    onClick={() => setCell(cell)}
  />
);
export const getLocations = async (usertoken, setFn) => {
  try {
    const response = await fetch(`https://${VITE_APP_API_URI}/departments/`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
      }),
    });

    if (!response.ok) {
      throw new Error("Stations not found");
    }

    const data = await response.json();
    const locations = data.filter(({ id }) => {
      if (id === undefined) return false;
      return !["Digitizing", "Queues", "Shipping", "Receiving"].includes(id);
    });
    setFn((state) => ({ ...state, locationOptions: locations }));
  } catch (error) {}
};

export const LocationOptions = ({ options, header, setFn }) => {
  const pager = usePager(options, 16);
  return (
    <section className={"checkin-cell__wrapper"}>
      <div className={"checkin-cell__header"}>{header}</div>
      <div className={"checkin-cell__explainer"} />
      <div className={"checkin-cell__cells"}>
        {pager.current.map((loc) => (
          <CellButton key={loc.id} cell={loc} setCell={setFn} />
        ))}
      </div>
      <PagerButtons {...pager} displayPageNum={options.length > 16} />
    </section>
  );
};

const getCells = async (usertoken, location, workflow, setFn) => {
  try {
    let url = `https://${VITE_APP_API_URI}/departments/`;
    if (workflow === "Receiving") {
      url = `${url}Receiving/Checkins`;
    } else if (workflow === "Teams") {
      url = `${url}${location}/Digitizing`;
    }
    const response = await fetch(url, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
      }),
    });

    if (!response.ok) {
      throw new Error("Receiving Cells not found");
    }

    const data = await response.json();
    if (data && data.cells && data.cells.length > 0) {
      setFn(
        data.cells.sort((a, b) =>
          a.name.localeCompare(b.name, "en", { numeric: true }),
        ),
      );
    } else {
      throw new Error("Unable to get list of receiving stations");
    }
  } catch (error) {}
};

export const CheckinCell = ({ dispatch }) => {
  const [{ usertoken }] = useLogin();
  const [locationState, setLocationState] = useState({
    cellOptions: [],
    cell: null,
    locationOptions: [],
    location: getLocalStorageLocation("location"),
    workflow: getLocalStorageLocation("workflow"),
    workFlowOptions,
  });

  useEffect(() => {
    getLocations(usertoken, setLocationState);
  }, [usertoken]);

  useEffect(() => {
    if (locationState.location !== null) {
      localStorage.setItem("location", locationState.location.id);
    }
  }, [locationState.location, usertoken, setLocationState]);

  useEffect(() => {
    if (
      locationState.location !== null &&
      locationState.workflow !== null &&
      locationState.cell === null &&
      locationState.cellOptions.length === 0
    ) {
      localStorage.setItem("workflow", locationState.workflow.id);
      getCells(
        usertoken,
        locationState.location.id,
        locationState.workflow.id,
        (cellOptions) =>
          setLocationState((state) => ({ ...state, cellOptions })),
      );
    }
  }, [locationState, usertoken]);

  useEffect(() => {
    if (locationState.cell !== null) {
      localStorage.setItem("receivingCell", JSON.stringify(locationState.cell));
      const { location, workflow, cell } = locationState;
      dispatch({ type: SET_CHECKIN, payload: { location, workflow, cell } });
      dispatch({ type: PAGE_GOTO, step: 1 });
    }
  }, [locationState, dispatch]);

  let header = "",
    options = [],
    setFn = () => {};
  if (locationState.location === null) {
    header = "Select Location";
    options = locationState.locationOptions;
    setFn = (location) => setLocationState((state) => ({ ...state, location }));
  } else if (locationState.workflow === null) {
    header = "Select Workflow";
    options = locationState.workFlowOptions;
    setFn = (workflow) => setLocationState((state) => ({ ...state, workflow }));
  } else {
    header = "Select Receiving Station";
    options = locationState.cellOptions;
    setFn = (cell) => setLocationState((state) => ({ ...state, cell }));
  }

  return <LocationOptions options={options} header={header} setFn={setFn} />;
};

export default CheckinCell;
