import { Dispatch, SetStateAction, useEffect, useState } from "react";

// Utilities
import { printItemBarcodes } from "../../utils/dymo-print";
// Components
import BaseButton from "../base/button";
import { Toggle } from "../components/toggle";
import NumberPad from "./number-pad";

import "./print-options.scss";

interface PrintModeSelectorProps {
  mode: "single" | "range";
  setPrintMode: Dispatch<SetStateAction<PrintMode>>;
}

const PrintModeSelector = ({ mode, setPrintMode }: PrintModeSelectorProps) => {
  return (
    <div className="print-options__mode-selector">
      <div className="print-options__mode-text">{`${mode}`}</div>
      <Toggle
        state={mode === "range"}
        setState={() =>
          setPrintMode((printMode) => ({
            ...printMode,
            mode: mode === "single" ? "range" : "single",
          }))
        }
      />
    </div>
  );
};

export interface PrintOrderInfo {
  orderId: string;
  salesOrderId: string;
}

export interface PrintRange {
  min: string;
  max: string;
}

interface PrintMode {
  mode: "single" | "range";
  headerText: string;
}

interface PrintOptionsProps {
  orderInfo: PrintOrderInfo;
  setPrintRange: Dispatch<SetStateAction<PrintRange>>;
}
interface SelectedBarcodesProps {
  mode: PrintMode["mode"];
  min: string;
  max: string;
  activeRangeInput: string;
  setFn: (param: string) => void;
}
const SelectedBarcodes = ({
  mode,
  min,
  max,
  activeRangeInput,
  setFn,
}: SelectedBarcodesProps) => {
  return (
    <>
      <div className={`selectedbarcodes__wrapper`}>
        {mode === "range" && (
          <>
            <div
              className={`selectedbarcodes__item`}
              onClick={() => setFn("min")}
            >
              <span className={`selectedbarcodes__label`}>
                {mode === "range" ? "First" : "Item Number"}
              </span>
              <span
                className={`selectedbarcodes__val ${
                  activeRangeInput === "min" ? "active" : ""
                }`}
              >
                {min}
              </span>
            </div>
            <div
              className={`selectedbarcodes__item }`}
              onClick={() => setFn("max")}
            >
              <span className={`selectedbarcodes__label`}>Last</span>
              <span
                className={`selectedbarcodes__val ${
                  activeRangeInput === "max" ? "active" : ""
                }`}
              >
                {max}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const PrintRangeDefault: PrintRange = {
  min: "",
  max: "",
};

export const PrintOptions = ({
  orderInfo,
  setPrintRange,
}: PrintOptionsProps) => {
  const [print, setPrint] = useState(false);
  const [activeRangeInput, setActiveRangeInput] = useState("min");
  const [printMode, setPrintMode] = useState<PrintMode>({
    mode: "single",
    headerText: "Item Number",
  });
  const [{ min, max }, setRange] = useState<PrintRange>(PrintRangeDefault);
  const { orderId } = orderInfo;

  useEffect(() => {
    if (!print) {
      return;
    }
    if (
      min === "" ||
      (printMode.mode === "range" && Number(max) < Number(min))
    ) {
      setPrint(false);
      return;
    }
    if (printMode.mode === "range") {
      printItemBarcodes(Number(min), Number(max), orderId);
      setPrintRange({ min, max });
    } else if (printMode.mode === "single") {
      printItemBarcodes(Number(min), Number(min), orderId);
      setPrintRange({ min, max: min });
    }
    setRange(PrintRangeDefault);
    setActiveRangeInput("min");

    const timeout = setTimeout(() => setPrint(false), 500);

    return () => clearTimeout(timeout);
  }, [print, printMode, min, max, orderId, setPrintRange]);

  useEffect(() => {
    if (printMode.mode === "single") {
      setActiveRangeInput("min");
    }
  }, [printMode.mode]);

  const handleNumberPad = (val: string) =>
    setRange((range) => ({ ...range, [activeRangeInput]: Number(val) }));

  const padHeader = () => {
    if (printMode.mode === "single") {
      return "Enter item number";
    } else {
      if (activeRangeInput === "min") {
        return "Enter first item number";
      }
      return "Enter last item number";
    }
  };
  return (
    <>
      <div className={`print-options__wrapper`}>
        <div className={`print-options__mode-area`}>
          <h1 className={`print-options__header`}>Select Print Mode</h1>
          <PrintModeSelector
            mode={printMode.mode}
            setPrintMode={setPrintMode}
          />
        </div>
        <div className={`print-options__pad-area`}>
          <NumberPad
            header={padHeader()}
            entry={activeRangeInput === "min" ? `${min}` : `${max}`}
            setEntry={handleNumberPad}
            maxLength={3}
            handleSubmit={
              printMode.mode === "range"
                ? () =>
                    setActiveRangeInput((active) =>
                      active === "max" ? "min" : "max",
                    )
                : () => {}
            }
            errState={false}
            errMsg={""}
          />
        </div>
        <div className={`print-options__info-area`}>
          <SelectedBarcodes
            mode={printMode.mode}
            min={min}
            max={max}
            activeRangeInput={activeRangeInput}
            setFn={setActiveRangeInput}
          />
          <BaseButton
            children={"Print"}
            onClick={() => setPrint(true)}
            className={`print-options__btn print ${print ? "printing" : ""}`}
          />
        </div>
      </div>
    </>
  );
};

export default PrintOptions;
