import Button from "../base/button";

import "./complete-button.scss";

export const CompleteButton = ({
  onClick,
  active = true,
}: {
  onClick: () => void;
  active: boolean;
}) => (
  <Button
    onClick={active ? onClick : undefined}
    className={`complete-button${!active ? " disabled" : ""}`}
  >
    Complete Order
  </Button>
);

export default CompleteButton;
