import { useEffect, useState } from "react";

import { useKeyEvent } from "../../../hooks";
import { useLogin } from "../../../hooks/context/login";
import {
  SET_ORDER,
  SET_RETURN,
  SET_TRACKING_ID,
} from "../../../hooks/reducers/actions/receiving/order-info";
// Actions
import {
  PAGE_BTN_ACTIVE,
  PAGE_GOTO,
  PAGE_STEP,
} from "../../../hooks/reducers/actions/receiving/page";
// Hooks
import useError from "../../../hooks/use-error";
import useTimeout from "../../../hooks/use-timeout";
import { printErrorSlip } from "../../../utils/dymo-print";
import BaseButton from "../../base/button";
import TextInput from "../../base/text-input";

import "./tracking-fail.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

export const failReasons = [
  { noteId: 57, reason: "Tracking # failed" },
  { noteId: 58, reason: "Customer supplied shipping label" },
  { noteId: 59, reason: "Two order numbers in single box" },
  { noteId: 60, reason: "Tracking # failed, no item barcodes" },
];

/**
 *
 * @param {Array.<{code: string}>} barcodes - array of barcode objects
 * @returns {boolean} true when order IDs match
 */
const checkOrderId = (barcodes) => {
  const orderId = barcodes[0] && barcodes[0].code.split("-")[0];
  if (orderId === null || orderId === undefined) return false;

  const mismatchId = barcodes.findIndex(
    (b) => b.code.split("-")[0] !== orderId,
  );
  if (mismatchId !== -1) return false;

  return true;
};

export const ReasonButton = ({ reason, onClick }) => (
  <BaseButton className="tracking-fail__reasons-button" onClick={onClick}>
    {reason}
  </BaseButton>
);

export const SelectReason = ({ setState }) => (
  <>
    <h1 className="tracking-fail__header">
      SELECT REASON FOR TRACKING CHECK FAILURE
    </h1>
    <div className="tracking-fail__reasons-wrapper">
      {failReasons.map(({ noteId, reason }, index) => (
        <ReasonButton
          key={index}
          reason={reason}
          onClick={(event) => {
            setState(noteId);
            event.currentTarget.blur();
          }}
        />
      ))}
    </div>
  </>
);

export const MatchBarcodes = ({ activeItems, header, err = false }) => {
  let items = activeItems;

  if (err) {
    items = items.map((i) => ({ ...i, error: true }));
  }

  return (
    <div className="tracking-fail__barcodes-wrapper">
      <h1 className="tracking-fail__header">{header}</h1>
      {items.map((item, index) => (
        <TextInput
          key={`${item.code}${index}`}
          val={item.code}
          error={err}
          className="tracking-fail__barcodes-items"
        />
      ))}
    </div>
  );
};

/**
 * @param {Object} props
 * @param {any} props.orderInfo
 * @param {any} props.checkinData
 * @param {Function} props.dispatch - reducer dispatch
 */
export const TrackingFail = ({
  orderInfo,
  checkinData,
  isOrderFlagCyberWeek,
  dispatch,
}) => {
  const [{ usertoken }] = useLogin();
  const [complete, setComplete] = useState(false);
  const [unactivated, setUnactivated] = useState(false);
  const [failReason, setFailReason] = useState(isOrderFlagCyberWeek ? 109 : 0);
  const [skipScanReason, setSkipScanReason] = useState("");
  const [list, setList] = useState([]);
  const { val, filled, clearInput, clearFilled } = useKeyEvent(
    failReason !== 0,
  );

  const activeItems = [...list, { id: "active", code: val }].slice(0, 2);

  const { errState, errMsg, trigger } = useError();
  // If order check success trigger complete step action
  useTimeout(() => dispatch({ type: PAGE_STEP, step: 1 }), complete, 500);

  // Handle error redirect
  useTimeout(() => dispatch({ type: PAGE_GOTO, step: 1 }), errState, 1000);

  useEffect(() => {
    if (filled) {
      setList((list) => [...list, { code: val }]);
      clearInput();
      clearFilled();
    }
  }, [val, filled, clearFilled, clearInput]);

  useEffect(() => {
    if (unactivated) {
      const orderInfoText =
        orderInfo && orderInfo.orderId
          ? ` \n${orderInfo.orderId}: ${orderInfo.name}`
          : "";
      printErrorSlip(
        "CANNOT RECEIVE UNACTIVATED ORDER",
        `Missing customer information${orderInfoText}`,
      );
      dispatch({ type: PAGE_GOTO, step: 1 });
    }
  }, [unactivated, orderInfo, dispatch]);

  useEffect(() => {
    // early return for Cyber Week orders
    if (list.length < 2) {
      return;
    } else if (!checkOrderId(list)) {
      trigger("Scanned barcodes do not match");
      return;
    } else if (new Set(list.map((l) => l.code)).size < 2) {
      trigger("Scanned barcodes aren't unique");
      return;
    }

    let entry = list[0].code.split("-")[0];

    const getOrder = async (entry) => {
      try {
        const response = await fetch(
          `https://${VITE_APP_API_URI}/digitization/receiving/order/tracking-fail`,
          {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
              "Content-Type": "application/json",
            }),
            body: JSON.stringify({
              orderId: entry,
              reasonId: failReason,
            }),
          },
        );

        /** Order booklet sent to multiple customers */
        const isDuplicateBooklet =
          response.headers.get("x-order-flag") === "CYBER DUPLICATE";

        if (isDuplicateBooklet) {
          printErrorSlip(
            "CYBER WEEK DUPLICATE BOOKLET",
            `${entry} – Cannot receive`,
          );
          dispatch({ type: PAGE_GOTO, step: 1 });
          return;
        }

        if (!response.ok) {
          throw new Error("Order ID not found");
        }

        const { order } = await response.json();
        if (order.is_received) {
          trigger("Order has already been received");
          return;
        } else if (order.shipping_address.email.startsWith("activate@")) {
          setUnactivated(true);
          return;
        }

        dispatch({ type: SET_TRACKING_ID, trackingId: "tracking-fail" });
        dispatch({ type: SET_ORDER, payload: order });

        if (order.is_return) {
          const res = await fetch(
            `https://${VITE_APP_API_URI}/digitization/receiving/order/${order.production_order_id}/return-note`,
            {
              method: "GET",
              headers: new Headers({
                Authorization: `Bearer ${usertoken}`,
              }),
            },
          );
          dispatch({ type: PAGE_BTN_ACTIVE, active: true });

          if (!res.ok) {
            dispatch({
              type: SET_RETURN,
              payload: {
                returnNote: "No note in system.",
                returnReasons: "Unknown",
              },
            });
            dispatch({ type: PAGE_GOTO, step: 8 });
            return;
          }
          const retData = await res.json();
          dispatch({
            type: SET_RETURN,
            payload: {
              returnNote: retData.return_note,
              returnReason: retData.return_reason,
            },
          });
          dispatch({ type: PAGE_GOTO, step: 8 });
          return;
        }

        if (checkinData.workflow === "Receiving") {
          fetch(`https://${VITE_APP_API_URI}/departments/checkin`, {
            method: "POST",
            body: JSON.stringify({
              scan: checkinData.cell.id,
              orderId: order.production_order_id.toString(),
              status: "IN",
            }),
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
              "Content-Type": "application/json",
            }),
          });
        }

        dispatch({ type: PAGE_BTN_ACTIVE, active: true });
        setComplete(true);
      } catch (error) {
        trigger(error.message);
      }
    };

    getOrder(entry);
  }, [
    list,
    failReason,
    dispatch,
    trigger,
    usertoken,
    orderInfo,
    checkinData,
    unactivated,
  ]);

  useEffect(() => {
    if (failReason === 60) {
      const errorNote = failReasons.find(
        (reason) => reason.noteId === failReason,
      ).reason;
      setSkipScanReason(errorNote);
    }
  }, [failReason]);

  useTimeout(
    () => dispatch({ type: PAGE_GOTO, step: 1 }),
    skipScanReason.length > 0,
    1000,
  );

  return failReason === 0 ? (
    <SelectReason setState={setFailReason} />
  ) : (
    <MatchBarcodes
      activeItems={activeItems}
      header={!errState ? `Scan 2 unique barcodes to confirm order` : errMsg}
      err={errState}
    />
  );
};

export default TrackingFail;
