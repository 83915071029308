import { useCallback, useEffect } from "react";

export const useDymoService = (errorCallback, active = true) => {
  const cb = useCallback(errorCallback, [errorCallback]);
  if (import.meta.env.DEV) {
    // @ts-ignore
    window.dymo.label.framework.trace = 1;
  }
  useEffect(() => {
    const loadFn = async () => {
      try {
        // @ts-ignore
        await window.dymo.label.framework.init();

        // @ts-ignore
        const dymoEnv = window.dymo.label.framework.checkEnvironment();
        if (!dymoEnv.isWebServicePresent || !dymoEnv.isFrameworkInstalled) {
          const msg =
            dymoEnv.errorDetails.length > 0
              ? dymoEnv.errorDetails
              : "Dymo didn't connect";
          throw new Error(msg);
        }
      } catch (error) {
        console.log(error.message);
        cb();
      }
    };
    if (active) {
      loadFn();
    }
  }, [cb]);
};
