import { useState } from "react";

import { STEP_DISPLAY } from "../../../../hooks/reducers/admin/edit-formats";
// Hooks
import useError from "../../../../hooks/use-error";
import { printItemBarcodes } from "../../../../utils/dymo-print";
// Components
import NumberPad from "../../../groups/number-pad";
import type { StepDisplayProps } from "../split-order";

export const ItemCount = ({ state, dispatch }: StepDisplayProps) => {
  const { errState, errMsg, clear, trigger } = useError();
  const [qty, setQty] = useState("");

  const submit = async (): Promise<void> => {
    clear();

    const amount = Number(qty);
    if (amount < 1) {
      trigger("Must request barcodes");
      setQty("");
      return;
    }

    const { printComplete } = await printItemBarcodes(1, amount, state.orderId);
    if (printComplete) {
      dispatch({ type: STEP_DISPLAY });
    } else {
      trigger("Unable to print");
      setQty("");
    }
  };

  return (
    <section className={"split-order__item-count-wrapper"}>
      <NumberPad
        header={"Select the number of analog items"}
        entry={qty}
        setEntry={setQty}
        maxLength={3}
        handleSubmit={submit}
        errState={errState}
        errMsg={errMsg}
      />
    </section>
  );
};

export default ItemCount;
