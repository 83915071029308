import { useEffect, useRef, useState } from "react";

import { useLogin } from "../../hooks/context/login";
import { LOGOUT } from "../../hooks/reducers/login";
import useError from "../../hooks/use-error";
// Hooks
import useScanner from "../../hooks/use-scanner";
import upsGround from "../../images/ups-ground.png";
import upsSurepost from "../../images/ups-surepost.png";
// Utils
import { printAltSlip } from "../../utils/dymo-print";
import BaseButton from "../base/button";
// Components
import TextInput from "../base/text-input";
import GoAgain from "../groups/go-again";

import "./confirm-label.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

export const ConfirmLabel = ({ orderInfo, dispatch }) => {
  const [{ usertoken }, loginDispatch] = useLogin();
  const [tracking, setTracking] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [errCount, setErrCount] = useState(0);
  const [orderMismatch, setOrderMismatch] = useState(false);
  const [showFailPage, setShowFailPage] = useState(false);
  const { scan, list } = useScanner();
  const prevListLength = useRef(list.length);
  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();

  useEffect(() => {
    if (list.length <= prevListLength.current || list.length <= 0) {
      return;
    }

    const lastScan = list[list.length - 1].code;

    const checkTracking = async (lastScan) => {
      try {
        const response = await fetch(
          `https://${VITE_APP_API_URI}/shipments/tracking/${lastScan}/confirm`,
          {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
              "Content-Type": "application/json",
            }),
            body: JSON.stringify({
              salesOrderId: orderInfo.salesOrderId,
              productionOrderId: orderInfo.orderId,
              shipmentLeg: orderInfo.isReturn ? 5 : 3,
            }),
          },
        );

        const payload = await response.json();
        if (!response.ok) {
          throw new Error(payload.errorMessage);
        }

        clearError();
        setConfirmed(true);
      } catch (error) {
        console.log(error);
        triggerError(error.message);
        if (
          error.message.includes(
            "Tracking number does not match sales order ID",
          )
        ) {
          setOrderMismatch(true);
        } else {
          setErrCount((prev) => prev + 1);
        }
      }
    };

    setTracking(lastScan);
    checkTracking(lastScan);
  }, [
    clearError,
    orderInfo.salesOrderId,
    orderInfo.isReturn,
    orderInfo.orderId,
    triggerError,
    list,
    usertoken,
    setOrderMismatch,
    setErrCount,
  ]);

  // Print error slip immediately if tracking number matches another order
  useEffect(() => {
    if (!orderMismatch) {
      return;
    }

    printAltSlip(
      usertoken,
      "ERROR",
      "TRACKING CONFIRM",
      orderInfo.orderId,
      orderInfo.accessCode,
      orderInfo.name,
      "Scanned tracking number for shipping label confirmation did not match this order.",
    );
    setShowFailPage(true);
  }, [
    orderMismatch,
    usertoken,
    orderInfo.orderId,
    orderInfo.accessCode,
    orderInfo.name,
    setShowFailPage,
  ]);

  // Print error slip if after three tries the tracking number doesn't match
  useEffect(() => {
    if (errCount < 3) {
      return;
    }

    printAltSlip(
      usertoken,
      "ERROR",
      "TRACKING CONFIRM",
      orderInfo.orderId,
      orderInfo.accessCode,
      orderInfo.name,
      "Could not locate scanned tracking number in our system.",
    );
    setShowFailPage(true);
  }, [
    errCount,
    usertoken,
    orderInfo.orderId,
    orderInfo.accessCode,
    orderInfo.name,
    setShowFailPage,
  ]);

  // Create shipping alert if tracking number doesn't match
  useEffect(() => {
    if (!showFailPage) {
      return;
    }

    const shippingError = async () => {
      try {
        const req = await fetch(
          `https://${VITE_APP_API_URI}/shipments/alerts/${orderInfo.salesOrderId}`,
          {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
              "Content-Type": "application/json",
            }),
            body: JSON.stringify({
              alertReasonId: 3,
            }),
          },
        );
        const payload = await req.json();

        if (!req.ok) {
          console.log(payload.errorMessage);
        }
      } catch (error) {
        console.log(error);
      }
    };

    shippingError();
  }, [showFailPage, orderInfo.salesOrderId, usertoken]);

  useEffect(() => {
    prevListLength.current = list.length;
  }, [list.length]);

  return (
    <section className="confirm-label__wrapper">
      <>
        {!confirmed && !showFailPage && (
          <>
            <h1 className="confirm-label__header">Scan tracking barcode</h1>
            <TextInput
              val={tracking || scan}
              className="confirm-label__tracking-input"
              error={errState}
              errorMsg={errMsg}
            />
            <section className="confirm-label__label-examples">
              <img src={upsGround} alt="UPS Ground Label" />
              <img src={upsSurepost} alt="UPS Surepost Label" />
            </section>
          </>
        )}
        {confirmed && (
          <>
            <h1 className="confirm-label__header">Shipping label confirmed</h1>
            <h2 className="confirm-label__subheader">
              This order is ready to go on the truck.
            </h2>
            <GoAgain isRunning={confirmed} dispatch={dispatch} />
          </>
        )}
        {showFailPage && (
          <>
            <h1 className="confirm-label__header error">
              Tracking Number Mismatch
            </h1>
            <h2 className="confirm-label__subheader error">
              Unable to confirm shipping label
            </h2>
            <BaseButton
              className="confirm-label__buttons error"
              onClick={() => loginDispatch({ type: LOGOUT })}
            >
              GOT IT
            </BaseButton>
          </>
        )}
      </>
    </section>
  );
};

export default ConfirmLabel;
