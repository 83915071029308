import { KeyActions, useKeyContext } from "@/hooks/context/scan";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAccessScan,
  clearOrderScan,
  selectAccessScan,
  selectActiveScan,
  selectOrderId,
  setAccessScan,
  setActiveScan,
  setOrderId,
} from "./state/slice/data";
import { unwrapErrorMessage, useGetOrderByIdQuery } from "./state/services/api";
import { ScanRow, ScanRowInputItem } from "./components/scan-row";
import GoButton from "@/ui/components/go-button";
import { stepForward } from "./state/slice/page";
import useTimeout from "@/hooks/use-timeout";

export function SafetyCheckScanOrder() {
  const [active, setActive] = useState<"order" | "access" | "next">("order");
  const orderIdScan = useSelector(selectOrderId);
  const accessScan = useSelector(selectAccessScan);
  const activeScan = useSelector(selectActiveScan);
  const { data, error, isLoading } = useGetOrderByIdQuery(orderIdScan, {
    skip: orderIdScan === "",
  });
  const subscribe = useKeyContext();
  const dispatch = useDispatch();

  const orderError = {
    error: error && "status" in error ? true : false,
    msg: unwrapErrorMessage(error),
  };

  const orderAccessCode = data && data.access_code;
  // const orderAccessCode = "test";
  const accessError =
    accessScan && orderAccessCode?.toLowerCase() !== accessScan.toLowerCase();

  useEffect(() => {
    if (active === "order") {
      if (orderIdScan !== "") {
        setActive("access");
      }

      const unsubscribe = subscribe((event) => {
        switch (event.type) {
          case KeyActions.key:
            return dispatch(setActiveScan(event.key));
          case KeyActions.enter:
            dispatch(setOrderId());
            return setActive("access");
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [active]);

  useEffect(() => {
    if (active === "access") {
      if (accessScan !== "") {
        setActive("next");
      }

      const unsubscribe = subscribe((event) => {
        switch (event.type) {
          case KeyActions.key:
            if (!data) return;

            return dispatch(setActiveScan(event.key));
          case KeyActions.enter:
            if (!data) return;

            dispatch(setAccessScan());
            return setActive("next");
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [active, data]);

  const readyToAdvance =
    active === "next" && !accessError && !isLoading && data !== undefined;
  useTimeout(
    () => {
      dispatch(stepForward());
    },
    readyToAdvance,
    750,
  );

  const scanError = orderError.error
    ? orderError.msg
    : accessError
      ? "Access code does not match"
      : "";

  return (
    <section className="scan-order__wrapper">
      <div className="scan-order__header">
        <div>Order Number</div>
        <div>Access Code</div>
      </div>
      <ScanRow
        checkActive={active === "next" && !accessError}
        error={scanError}
      >
        <ScanRowInputItem
          text={active === "order" ? activeScan : orderIdScan}
          status={orderError.error ? "error" : "valid"}
          onClick={() => {
            dispatch(clearOrderScan());
            if (accessScan) {
              dispatch(clearAccessScan());
            }
            setActive("order");
          }}
        />
        <ScanRowInputItem
          text={active === "access" ? activeScan : accessScan}
          status={accessError ? "error" : "valid"}
          onClick={() => {
            dispatch(clearAccessScan());
            setActive("access");
          }}
        />
      </ScanRow>
    </section>
  );
}
