import type { Dispatch, SetStateAction } from "react";

import "./toggle.scss";

export interface ToggleProps {
  /** Active/checked state flag */
  state: boolean;
  /** Function to setState */
  setState: Dispatch<SetStateAction<boolean>>;
}

export const Toggle = ({ state, setState }) => (
  <input
    className="toggle"
    type="checkbox"
    checked={state}
    onChange={(event) => setState(event.target.checked)}
  />
);

export default Toggle;
