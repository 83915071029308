// Hooks
import {
  ShipBalanceDueActionCreators,
  ShipBalanceDueSteps,
} from "../../../../hooks/reducers/admin/ship-balance-due";
// Components
import AppPassword from "../../../components/app-password";
// Types
import type { ShipBalanceDueComponentProps } from "./app";

export function Passcode(props: ShipBalanceDueComponentProps) {
  return (
    <AppPassword
      {...props.state.app}
      onSuccess={() =>
        props.dispatch(
          ShipBalanceDueActionCreators.createSetStepNameAction(
            ShipBalanceDueSteps.ScanOrder,
          ),
        )
      }
    />
  );
}
