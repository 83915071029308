export class HttpError extends Error {
  statusCode: number;

  constructor(status: number, message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.statusCode = status;
  }
}

export class UnauthorizedError extends HttpError {
  constructor(message: string) {
    super(401, message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
