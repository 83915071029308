import { Row } from "react-table";
import { TableCell } from "./cell";

export interface TableRowProps<RowType extends {}> {
  row: Row<RowType>;
  classPrefix: string;
}
export function TableRow<RowType extends object>(
  props: TableRowProps<RowType>,
) {
  return (
    <tr
      className={`${props.classPrefix}__table-row`}
      {...props.row.getRowProps()}
    >
      {props.row.cells.map((cell, index) => (
        <TableCell<RowType>
          key={`${cell.row.index}-${index}`}
          cell={cell}
          classPrefix={props.classPrefix}
        />
      ))}
    </tr>
  );
}
export default TableRow;
