import type { HTMLAttributes } from "react";
import { styled } from "../../../stitches.config";
import Button from "../button/button";

const CB = styled(Button, {
  height: "44px",
  width: "15%",
  color: "$white",
  backgroundColor: "$darkGray",
});

export type ClearButtonProps = HTMLAttributes<HTMLButtonElement>;

export default function ClearButton({
  onClick,
  className = "",
}: ClearButtonProps) {
  return (
    <CB className={className} onClick={onClick}>
      X
    </CB>
  );
}
