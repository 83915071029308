import { useEffect, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import useFetch from "../../../hooks/use-fetch";
import PrintOptions, {
  PrintOrderInfo,
  PrintRange,
} from "../../groups/print-options";
import RushLocateModal from "../../groups/rush-locate";
import ScanOrder from "./scan-order";

import "./reprint-barcodes.scss";

const { VITE_APP_API_URI } = import.meta.env;

const useOrderFetch = (
  usertoken: string,
  productionOrderId: FetchOptions["productionOrderId"],
  access: FetchOptions["access"],
  executeFetch: FetchOptions["execute"],
) => {
  const { isLoading, isResolved, isRejected, body } = useFetch(
    `https://${VITE_APP_API_URI}/production/order/${productionOrderId}`,
    {
      headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
    },
    executeFetch,
  );
  let error: string = null,
    orderInfo: PrintOrderInfo = null;
  if (isRejected) {
    error = "Unable to find order";
  } else if (isResolved && body != null) {
    if (body.access_code !== access) {
      error = "Access code mismatch";
    } else {
      orderInfo = {
        orderId: body.production_order_id,
        salesOrderId: body.sales_order_id,
      };
    }
  }
  return {
    isLoading,
    isResolved,
    isRejected,
    orderInfo,
    error,
  };
};

const sendPrintLog = async (
  usertoken: string,
  orderInfo: PrintOrderInfo,
  printRange: PrintRange,
): Promise<void> => {
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/timeline-v2/receiving/printbarcodeslog`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          order_info: orderInfo,
          note: "Admin",
          print_range: printRange,
        }),
      },
    );

    if (!response.ok) {
      throw new Error("Unable to log barcode print reason.");
    }
  } catch (error) {
    console.log(error);
  }
};

interface FetchOptions {
  productionOrderId: string;
  access: string;
  execute: boolean;
}

const initialFetchOptions: FetchOptions = {
  productionOrderId: null,
  access: null,
  execute: false,
};

export const ReprintBarcodes = () => {
  const [{ usertoken }] = useLogin();
  const [{ productionOrderId, access, execute }, setFetchOptions] =
    useState(initialFetchOptions);
  const { orderInfo, error } = useOrderFetch(
    usertoken,
    productionOrderId,
    access,
    execute,
  );
  const [printRange, setPrintRange] = useState<PrintRange>(null);

  useEffect(() => {
    if (printRange) {
      sendPrintLog(usertoken, orderInfo, printRange);
      setPrintRange(null);
    }
  }, [printRange, orderInfo, usertoken, setPrintRange]);

  return (
    <>
      <section className="reprint-barcodes">
        {orderInfo === null ? (
          <ScanOrder
            submitFn={async (productionOrderId, access) =>
              setFetchOptions({ productionOrderId, access, execute: true })
            }
            header={
              error || "Scan order ID and access code to reprint item barcodes"
            }
          />
        ) : (
          <PrintOptions orderInfo={orderInfo} setPrintRange={setPrintRange} />
        )}
      </section>
      <RushLocateModal productionOrderId={productionOrderId} />
    </>
  );
};

export default ReprintBarcodes;
