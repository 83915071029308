import { useState, useEffect } from "react";
import "./reprint-slip.scss";

import BaseButton from "../base/button";

// Hooks
import { useLogin } from "../../hooks/context/login";
import { RESET_ORDER } from "../../hooks/reducers/receiving";

// Utilities
import { reprintSlip } from "../../utils/dymo-print";

/**
 *
 * @param {object} props
 * @param {string} props.productionOrderId
 * @param {Function} props.dispatch
 */
export const ReprintSlip = ({ productionOrderId, dispatch }) => {
  const [{ usertoken, usergroup }] = useLogin();
  const [print, setPrint] = useState(false);

  useEffect(() => {
    if (!print) {
      return;
    }
    const getReprintSlip = async (usertoken, productionOrderId) => {
      const printError = await reprintSlip(usertoken, productionOrderId);
      if (printError) {
        console.log(printError);
      }
    };

    if (usergroup === "Manager" || usergroup === "Administrator") {
      getReprintSlip(usertoken, productionOrderId);
    }

    setPrint(false);
    dispatch({ type: RESET_ORDER });
  }, [print, setPrint, usertoken, usergroup, productionOrderId, dispatch]);

  return (
    <section className="reprint-slip__wrapper">
      <article className="reprint-slip__header">Order already received</article>
      <article className="reprint-slip__explainer">
        Would you like to reprint the receiving slip?
      </article>
      <article className="reprint-slip__buttons">
        <BaseButton
          children={"Yes"}
          onClick={() => setPrint(true)}
          className={"reprint-slip__button reprint-slip__button-yes"}
        />
        <BaseButton
          children={"No"}
          onClick={() => dispatch({ type: RESET_ORDER })}
          className={"reprint-slip__button reprint-slip__button-no"}
        />
      </article>
    </section>
  );
};

export default ReprintSlip;
