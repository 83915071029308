import { PAGE_GOTO } from "../../hooks/reducers/actions/receiving/page";
import BaseButton from "../base/button";

import "./print-barcodes-button.scss";

export const PrintBarcodesButton = ({
  dispatch,
}: {
  dispatch: (entry: { type: typeof PAGE_GOTO; step: number }) => void;
}) => {
  const click = () => {
    dispatch({ type: PAGE_GOTO, step: 7 });
  };

  return (
    <BaseButton onClick={click} className="print-barcodes-button">
      Print Barcodes
    </BaseButton>
  );
};

export default PrintBarcodesButton;
