import { Api } from "../../../../types/api";

// Action Types for Page
export const SET_TRACKING_ID = "SET_TRACKING_ID";
export const SET_ORDER = "SET_ORDER";
export const SET_RETURN = "SET_RETURN";
export const SET_CHECKIN = "SET_CHECKIN";
export const SET_ORDER_FIELDS = "SET_ORDER_FIELDS";

export type OutputProductRelations = {
  /** Output product ID*/
  productId: number;
  /** Output variant ID*/
  variantId: number;
  /** Name of output*/
  type: string;
};

export type OrderInfoState = {
  brand: string /** Name of brand order placed for */;
  hasBackup?: boolean /** Order has an active cloud backup subscription */;
  holidayScan: string /** Holiday card barcode scan text */;
  isCanceled: boolean /** Order canceled by customer */;
  isHoliday: boolean /** Order is in holiday card window */;
  isPriority: boolean /** Order has priority */;
  isReceived: boolean /** Order already received */;
  isReturn: boolean /** Order is a return order */;
  isRush: boolean /** Order is paid rush */;
  isValidOrder: boolean /** Order is valid for receiving */;
  itemData: Api.IProductionOrder["items"] /** Order item data */;
  hasDownloadOutput: boolean /** Has a digital download output or upgrade */;
  mainProduct: Api.IProductionOrder["main_product"] /** Kit product data */;
  name: string /** Customer Name */;
  orderId: string /** Production Order ID */;
  outputRelations: OutputProductRelations[] /** Outputs that pair with purchased box product */;
  salesOrderId: number /** Originating SalesOrder ID */;
  trackingId: string /** Shipment tracking code */;
};

export type ReturnInfoState = {
  returnNote: string;
  returnReason: string;
};

export type CheckinInfoState = {
  cell: string;
  location: string;
  workflow: string;
};

export const setTrackingId = (
  { orderInfo }: { orderInfo: OrderInfoState },
  { trackingId }: { trackingId: string },
) => {
  return { orderInfo: { ...orderInfo, trackingId } };
};

export const getBrand = (brandId: number) => {
  switch (brandId) {
    case 1:
      return "southtree";
    case 2:
      return "legacybox";
    case 3:
      return "kodak";
    default:
      return "southtree";
  }
};

const checkDigitalDownload = (items: OrderInfoInitialPayload["items"]) =>
  items.some((item) => item.output_type === "Digital Download");

type OrderInfoActionPayload<T> = { payload: T };
type OrderInfoInitialPayload = Api.IProductionOrder & {
  outputRelations: OutputProductRelations[];
  is_priority: boolean;
};
type OrderInfoPayload = OrderInfoActionPayload<OrderInfoInitialPayload>;
export const setOrderInfo = (
  { orderInfo }: { orderInfo: OrderInfoState },
  { payload }: OrderInfoPayload,
): { orderInfo: OrderInfoState } => ({
  orderInfo: {
    ...orderInfo,
    brand: getBrand(payload.brand_id),
    hasDownloadOutput: checkDigitalDownload(payload.items),
    hasBackup: payload.has_backup,
    isCanceled: payload.is_canceled,
    isHoliday: payload.is_holiday,
    isPriority: payload.is_priority,
    isReceived: payload.is_received,
    isReturn: payload.is_return,
    isRush: payload.is_rush,
    isValidOrder: payload.is_valid && !payload.is_canceled,
    itemData: payload.items,
    mainProduct: payload.main_product,
    name: `${payload.shipping_address.firstname} ${payload.shipping_address.lastname}`,
    orderId: payload.production_order_id,
    outputRelations: payload.outputRelations,
    salesOrderId: payload.sales_order_id,
  },
});

type ReturnDataPayload = OrderInfoActionPayload<ReturnInfoState>;
const setReturnData = (
  _: any,
  { payload: { returnNote, returnReason } }: ReturnDataPayload,
) => ({
  returnData: { returnNote, returnReason },
});
type CheckinInfoPayload = OrderInfoActionPayload<CheckinInfoState>;
const setCheckinData = (_: any, { payload }: CheckinInfoPayload) => ({
  checkinData: payload,
});

const setOrderInfoFields = (
  { orderInfo }: { orderInfo: OrderInfoState },
  { payload }: { payload: OrderInfoState },
) => {
  return {
    orderInfo: {
      ...orderInfo,
      ...payload,
    },
  };
};

export type OrderState = {
  orderInfo: OrderInfoState;
  returnData: ReturnInfoState;
  checkinData: CheckinInfoState;
};
export const orderInfoInitialState: OrderState = {
  orderInfo: {
    brand: null,
    hasDownloadOutput: null,
    holidayScan: null,
    isCanceled: null,
    isHoliday: null,
    isPriority: null,
    isReceived: null,
    isReturn: null,
    isRush: null,
    isValidOrder: null,
    itemData: null,
    mainProduct: null,
    name: null,
    orderId: null,
    outputRelations: null,
    salesOrderId: null,
    trackingId: null,
  },
  returnData: {
    returnNote: null,
    returnReason: null,
  },
  checkinData: {
    cell: null,
    location: null,
    workflow: null,
  },
};

export const orderInfoActions = {
  SET_TRACKING_ID: setTrackingId,
  SET_ORDER: setOrderInfo,
  SET_RETURN: setReturnData,
  SET_CHECKIN: setCheckinData,
  SET_ORDER_FIELDS: setOrderInfoFields,
};
