import { type ReactNode, useEffect, useState } from "react";

import BaseButton from "../base/button";
import cn from "classnames";

import "./paging-group.scss";

/**
 * Configuration object for PagingGroup
 * @property input - shows last page when a new item is added
 * @property display - shows first page on content change
 */
export const PagingGroupMode = {
  input: 0,
  display: 1,
} as const;

interface PagingGroupProps {
  /** React object array */
  content: ReactNode[];
  /** className of div wrapping paged content */
  contentClassName: string;
  /** page initializer */
  initialPage: number;
  /** # of items per page */
  pageSize: number;
  /** PagingGroupMode configuration */
  mode?: number;
}

export const PagingGroup = ({
  content,
  contentClassName,
  initialPage,
  pageSize,
  mode = PagingGroupMode.display,
}: PagingGroupProps) => {
  const [page, setPage] = useState(initialPage);
  const lastPageIndex = Math.floor((content.length - 1) / pageSize);

  const pagedContent = content.slice(page * pageSize, (page + 1) * pageSize);
  const prevBtnClass = cn("paging-group__nav", {
    hidden: page === 0,
  });
  const nextBtnClass = cn("paging-group__nav", {
    hidden: page === lastPageIndex,
  });

  useEffect(() => {
    if (mode === PagingGroupMode.input) {
      setPage(lastPageIndex);
    }
  }, [content.length, lastPageIndex, mode]);

  useEffect(() => {
    if (mode === PagingGroupMode.display) {
      setPage(initialPage);
    }
  }, [initialPage, mode]);

  useEffect(() => {
    setPage(() => 0);
  }, []);

  if (content.length === 0) {
    return null;
  }

  return (
    <div className="paging-group__wrapper">
      <BaseButton
        children={"<"}
        onClick={(event) => {
          if (page === 0) return;
          setPage((prevPage) => prevPage - 1);
          event.currentTarget.blur();
        }}
        className={prevBtnClass}
      />
      <div className={contentClassName}>{pagedContent}</div>
      <BaseButton
        children={">"}
        onClick={(event) => {
          if (page === lastPageIndex) return;
          setPage((prevPage) => prevPage + 1);
          event.currentTarget.blur();
        }}
        className={nextBtnClass}
      />
    </div>
  );
};

export default PagingGroup;
