import { useDispatch } from "react-redux";
import { setDepartment } from "./state/slice/data";
import TouchButton from "@/ui/components/touch-button/touch-button";

export function SafetyCheckDepartment() {
  const dispatch = useDispatch();
  return (
    <section className="safetycheck__content">
      <h1>Choose your Department</h1>
      <div className="safetycheck__dept-btn-list">
        <TouchButton
          size={"lg"}
          onClick={() => dispatch(setDepartment("Tapes"))}
        >
          Tapes
        </TouchButton>
        <TouchButton
          size={"lg"}
          onClick={() => dispatch(setDepartment("Film"))}
        >
          Film
        </TouchButton>
        <TouchButton
          size={"lg"}
          onClick={() => dispatch(setDepartment("Photos"))}
        >
          Photos
        </TouchButton>
        <TouchButton
          size={"lg"}
          onClick={() => dispatch(setDepartment("Audio"))}
        >
          Audio
        </TouchButton>
      </div>
    </section>
  );
}
