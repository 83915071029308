import { useState, useEffect } from "react";

export const useWindowSize = () => {
  const getWindow = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [windowSize, setWindowSize] = useState(getWindow);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindow());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {});
  return windowSize;
};
