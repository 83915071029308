import BaseButton from "../base/button";
import classNames from "classnames";

import "./yes-no-buttons.scss";

export interface YesNoButtonProps {
  yesText?: string;
  noText?: string;
  isYesDisabled?: boolean;
  yesClick: () => void;
  noClick: () => void;
}

/**
 * Group of two buttons, default YES or NO
 * @param props yes/no display text and eventhandlers
 * @returns button grouping
 */
export const YesNoButtons = ({
  yesText = "YES",
  noText = "NO",
  yesClick,
  noClick,
  isYesDisabled,
}: YesNoButtonProps) => (
  <section className={"yesnobuttons__wrapper"}>
    <BaseButton
      children={yesText}
      className={classNames("yesnobuttons__buttons yes", {
        disabled: isYesDisabled,
      })}
      onClick={(event) => {
        if (isYesDisabled) {
          event.preventDefault();
          return;
        }
        event.currentTarget.blur();
        yesClick();
      }}
    />
    <BaseButton
      children={noText}
      className={"yesnobuttons__buttons no"}
      onClick={(event) => {
        event.currentTarget.blur();
        noClick();
      }}
    />
  </section>
);

export default YesNoButtons;
