import { useRef, useState } from "react";

import { useMountEffect } from "../../../../hooks";
import { useLogin } from "../../../../hooks/context/login";
import { SET_LABEL } from "../../../../hooks/reducers/actions/safetyscans/shipping";
import { printShippingLabel } from "../../../../utils/dymo-print";
import BaseButton from "../../../base/button";
import GoAgain from "../../../groups/go-again";
import { ErrorArea } from "../../safetyscans/ship-order";

const { VITE_APP_API_URI } = import.meta.env;

const PrintLabel = ({ onClick }) => {
  const [printing, setPrinting] = useState(false);
  return (
    <section className={"ship-order__print-label-wrapper"}>
      <h1 className={"ship-order__print-label-header"}>
        This order is ready to ship.
      </h1>
      <BaseButton
        className={`ship-order__print-label-button ${printing && "printing"}`}
        children={"Print Label"}
        onClick={async (event) => {
          event.currentTarget.blur();
          setPrinting(true);
          await onClick();
        }}
      />
    </section>
  );
};
const getShippingLabel = async (state, dispatch, setShipError, usertoken) => {
  try {
    let shipmentType = 3;
    if (state.orderInfo.orderId.toUpperCase().includes("ADD")) {
      shipmentType = 6;
    } else if (state.orderInfo.isReturn) {
      shipmentType = 5;
    }
    const req = await fetch(
      `https://${VITE_APP_API_URI}/shipments/${state.orderInfo.salesOrderId}/label`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          productionOrderId: state.orderInfo.orderId,
          shipmentType,
          signatureRequired: state.shippingInfo.signatureRequired,
        }),
      },
    );
    const payload = await req.json();

    if (!req.ok) {
      throw new Error(payload.errorMessage);
    }

    dispatch({ type: SET_LABEL, payload });
    setShipError(!payload.success);
  } catch (error) {
    console.log(error);
  }
};

const printLabelHandler = async (state, dispatch, usertoken) => {
  try {
    const res = await printShippingLabel(state.labelData.labelImage);

    if (res.printComplete) {
      const req = await fetch(
        `https://${VITE_APP_API_URI}/admin/${state.orderInfo.orderId}/reship`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${usertoken}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            salesOrderId: state.orderInfo.salesOrderId,
          }),
        },
      );
      const payload = await req.json();

      if (!req.ok) {
        throw new Error(payload.errorMessage);
      }
    }
    dispatch({
      type: SET_LABEL,
      payload: { labelPrinted: res.printComplete },
    });
  } catch (error) {
    console.error(error);
  }
};

export const ShipOrder = ({ state, dispatch }) => {
  const [{ usertoken }] = useLogin();
  const [shipError, setShipError] = useState(false);
  const prevShipError = useRef(shipError);
  // Get shipping label
  useMountEffect(() => {
    if (state.labelData.labelImage !== null || prevShipError.current) {
      return;
    }
    getShippingLabel(state, dispatch, setShipError, usertoken);
  });
  return (
    <>
      {state.labelData &&
        state.labelData.success &&
        state.labelData.labelPrinted === null && (
          <PrintLabel
            onClick={() => printLabelHandler(state, dispatch, usertoken)}
          />
        )}
      {state.labelData.labelPrinted === true && (
        <GoAgain
          dispatch={dispatch}
          header={"Would you like to reship another order?"}
        />
      )}
      {state.labelData.labelPrinted === false && (
        <ErrorArea
          errorMessage={"Unable to print shipping label"}
          onClick={() =>
            dispatch({ type: SET_LABEL, payload: { labelPrinted: null } })
          }
          buttonText={"RETRY"}
        />
      )}
    </>
  );
};
export default ShipOrder;
