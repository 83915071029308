import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { picklist, is } from "valibot";
import { type RootState } from "../store";
import { reset } from "../actions/reset";

const steps = ["home", "scan", "review", "complete"] as const;
export const stepSchema = picklist(steps);

export interface PageState {
  page: (typeof steps)[number];
}

const initialState: PageState = {
  page: "home",
};

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<PageState["page"]>) => {
      if (is(stepSchema, action.payload)) {
        state.page = action.payload;
      }
    },
    stepForward: (state) => {
      const currentIndex = steps.indexOf(state.page);
      if (currentIndex === steps.length - 1) {
        return;
      }
      state.page = steps[currentIndex + 1];
    },
    stepBack: (state) => {
      const currentIndex = steps.indexOf(state.page);
      if (currentIndex === 0) {
        return;
      }
      state.page = steps[currentIndex - 1];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState);
  },
});

const { actions, reducer } = pageSlice;

export const { setPage, stepForward, stepBack } = actions;
export default reducer;

export const selectPage = (state: RootState) => state.page.page;
