import { useState, useEffect, useRef, type Dispatch } from "react";

import { InputListItem } from "../../../components/inputs-group";
import BaseButton from "../../../base/button";

import usePager from "../../../../hooks/use-pager";
import {
  ADD_FORMAT_ITEM,
  REMOVE_FORMAT_ITEM,
  type RemoveFormatItemAction,
  type BarcodeItem,
} from "../../../../hooks/reducers/actions/receiving/category";
import type { EditFormatsState } from "../../../../hooks/reducers/admin/edit-formats";
import type {
  ItemTriggerErrorCB,
  ItemClearErrorCB,
} from "../../../../hooks/use-error";
import { isBarcodeMatchingOrderId } from "../../../../hooks/use-key-event-barcode";

const getList = (
  categories: EditFormatsState["categories"],
  selectedCategory: EditFormatsState["selectedCategory"],
  selectedFormat: EditFormatsState["selectedFormat"],
): BarcodeItem[] => {
  const cat = categories.find(({ category }) => category === selectedCategory);
  if (cat === undefined) {
    return [];
  }
  //
  const format = cat.formats.find((i) => i.format === selectedFormat);
  if (format === undefined) {
    return [];
  }

  return format.items;
};

interface UseEventTrigger {
  (
    state: Partial<EditFormatsState>,
    triggerError: ItemListProps["triggerError"],
    dispatch: ItemListProps["dispatch"],
  ): void;
}

const useEventTrigger: UseEventTrigger = (
  state,
  triggerError,
  dispatch,
): void => {
  const { orderId, selectedCategory, selectedFormat, barcodes } = state;
  const [val, setVal] = useState("");

  useEffect(() => {
    const handleChange = (event: KeyboardEvent): void => {
      event.preventDefault();
      const key = event.key;

      if (key === "Enter") {
        const entry = val;
        let item: { code: string; error?: string } = { code: entry };
        setVal("");

        const isValidOrder = isBarcodeMatchingOrderId(entry, orderId);

        if (isValidOrder === "id-mismatch") {
          let msg = "Barcode Order ID mismatch";
          item = { ...item, error: msg };
          triggerError(msg);
        } else if (isValidOrder === "partial-id") {
          let msg = `Scan has partial order ID ${val}`;
          item = { ...item, error: msg };
          triggerError(msg);
        } else if (isValidOrder === "bad-scan") {
          let msg = "Invalid characters in barcode scan";
          item = { ...item, error: msg };
          triggerError(msg);
        } else if (barcodes.findIndex((b) => b.code === entry) !== -1) {
          let msg = "Barcode already scanned";
          item = { ...item, error: msg };
          triggerError(msg);
        }

        dispatch({
          type: ADD_FORMAT_ITEM,
          category: selectedCategory,
          format: selectedFormat,
          item,
        });
        return;
      }
      setVal((v) => `${v}${key}`);
    };
    document.addEventListener("keypress", handleChange);

    return () => {
      document.removeEventListener("keypress", handleChange);
    };
  });
};

const useItemList = (state: ItemListProps["state"]): BarcodeItem[] => {
  const [list, setList] = useState<BarcodeItem[]>([
    { id: "active", code: "scan" },
  ]);
  const { categories, selectedCategory, selectedFormat } = state;
  const items = getList(categories, selectedCategory, selectedFormat);

  useEffect(() => {
    if (items.length >= list.length) {
      setList([...items, { id: "active", code: "scan" }]);
    } else if (list.length > 1 && items.length < list.length) {
      setList([...items, { id: "active", code: "scan" }]);
    }
  }, [items.length, list.length, items]);

  return list;
};

interface ItemListProps {
  state: Partial<EditFormatsState>;
  errState: boolean;
  triggerError: ItemTriggerErrorCB;
  clearError: ItemClearErrorCB;
  dispatch: Dispatch<RemoveFormatItemAction>;
}

export const ItemList = ({
  state,
  errState,
  triggerError,
  clearError,
  dispatch,
}: ItemListProps) => {
  const { selectedCategory, selectedFormat } = state;
  useEventTrigger(state, triggerError, dispatch);
  const items = useItemList(state);
  const itemsRef = useRef(0);
  const pageLength = 12;
  const { page, current, lastPage, previous, next } = usePager(
    items,
    pageLength,
    0,
  );

  useEffect(() => {
    if (itemsRef.current < items.length) {
      if (items.length > pageLength * (page + 1)) {
        next();
      }
    }

    itemsRef.current = items.length;
  }, [items.length, itemsRef, page, pageLength, next]);

  return (
    selectedFormat &&
    selectedFormat.length > 0 && (
      <>
        <h1 className="media-group__header">3. Input Formats</h1>
        <section className="edit-items__item-group">
          <div className="edit-items__page-btns">
            {page > 0 ? (
              <BaseButton className="edit-items__prev" onClick={previous}>
                {"<"}
              </BaseButton>
            ) : (
              ""
            )}
          </div>
          <div className="edit-items__ul">
            {current.map((i) => (
              <InputListItem
                key={i.code}
                textInput={i}
                onClick={(code: string): void => {
                  if (errState) {
                    clearError();
                  }
                  dispatch({
                    type: REMOVE_FORMAT_ITEM,
                    category: selectedCategory,
                    format: selectedFormat,
                    item: code,
                  });
                }}
              />
            ))}
          </div>
          <div className="edit-items__page-btns">
            {page < lastPage ? (
              <BaseButton className="edit-items__next" onClick={next}>
                {">"}
              </BaseButton>
            ) : (
              ""
            )}
          </div>
        </section>
      </>
    )
  );
};

export default ItemList;
