import { useReducer, useEffect } from "react";

// Actions
export const STARTED = "STARTED";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const RESET = "RESET";
export const SET_STATUS = "SET_STATUS";

// Loading states
export const IDLE = "IDLE";
export const PENDING = "PENDING";
export const RESOLVED = "RESOLVED";
export const REJECTED = "REJECTED";

export interface FetchState<T = any, E = any> {
  status: "IDLE" | "PENDING" | "RESOLVED" | "REJECTED";
  responseStatusCode: number | null;
  body?: T;
  error?: E;
}

export type FetchAction<T = any, E = any> =
  | { type: "STARTED" }
  | { type: "SET_STATUS"; payload: number }
  | { type: "SUCCESS"; payload: T }
  | { type: "ERROR"; error: E }
  | { type: "RESET" };

/**
 * @typedef {Object} useFetchStatusBools
 * @property {boolean} isIdle
 * @property {boolean} isPending
 * @property {boolean} isLoading
 * @property {boolean} isResolved
 * @property {boolean} isRejected
 */

export function createFetchReducer<T>() {
  return function fetchReducer(
    state: FetchState<T>,
    action: FetchAction<T>,
  ): FetchState<T> {
    switch (action.type) {
      case STARTED:
        return {
          ...state,
          status: PENDING,
        };
      case SUCCESS:
        return {
          ...state,
          status: RESOLVED,
          body: action.payload,
        };
      case ERROR:
        return {
          ...state,
          status: REJECTED,
          error: action.error,
        };
      case SET_STATUS:
        return {
          ...state,
          responseStatusCode: action.payload,
        };
      case RESET:
        return {
          ...state,
          status: IDLE,
        };
    }
  };
}

export function createInitialState<T>() {
  const initialState: FetchState<T> = {
    status: IDLE,
    responseStatusCode: null,
    body: null,
    error: null,
  };
  return initialState;
}

export default function useFetch<T = any>(
  url: string,
  options: RequestInit = {},
  executeFetch: boolean = true,
) {
  const reducer = createFetchReducer<T>();
  const initialState = createInitialState<T>();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (executeFetch && state.status === IDLE) {
      dispatch({ type: STARTED });
      const fetchData = async () => {
        try {
          const res = await fetch(url, options);
          dispatch({ type: SET_STATUS, payload: res.status });
          if (!res.ok) {
            throw new Error(`Fetch Error [${res.status}]: ${res.statusText}`);
          }
          const json = (await res.json()) as T;
          dispatch({ type: SUCCESS, payload: json });
        } catch (error) {
          dispatch({ type: ERROR, error: error });
        }
      };
      fetchData();
    }
  }, [executeFetch, url, options, state.status]);

  return {
    isIdle: state.status === IDLE,
    isPending: state.status === PENDING,
    isLoading: state.status === IDLE || state.status === PENDING,
    isResolved: state.status === RESOLVED,
    isRejected: state.status === REJECTED,
    fetchDispatch: dispatch,
    ...state,
  };
}
