import { useEffect, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import { getItemCount } from "../../../hooks/reducers/actions/receiving/category";
// Hooks
import { RESET_ORDER } from "../../../hooks/reducers/receiving";
import useError from "../../../hooks/use-error";
import useTimeout from "../../../hooks/use-timeout";
import useWorkCompleted from "../../../hooks/use-work-completed";
// Utilities
import {
  printQRCodeThumbdriveSlipReceiving,
  printSlip,
} from "../../../utils/dymo-print";
import CompleteButton from "../../components/complete-button";
// Components
import ItemList from "../../components/item-list";

import "./review-order.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

const filterCategories = (categories) =>
  categories.reduce(
    (group, current) =>
      current.quantity === 0
        ? group
        : [
            ...group,
            {
              ...current,
              formats: current.formats.filter(({ quantity }) => quantity > 0),
            },
          ],
    [],
  );

const getRandomStr = (length = 6) => {
  const keySpace =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let code = "";
  for (let i = 0; i < length; i++) {
    code += keySpace.charAt(Math.floor(Math.random() * keySpace.length));
  }
  return code;
};

const generateSlipInfo = (
  { orderId, name, isReturn, isCanceled, isPriority, isRush, isHoliday },
  returnData,
  categories,
  barcodes,
  outputs,
) => {
  const receivedDate = new Date();

  // Access code
  const access = getRandomStr(6);

  const formats = categories.flatMap(({ category, formats }) =>
    formats
      .map((format) => ({ category, ...format }))
      .filter(({ quantity }) => quantity > 0),
  );

  const filteredOutputs = outputs.filter(({ quantity }) => quantity > 0);

  return {
    orderId,
    name: name.split(" ")[1],
    receivedDate,
    isCanceled,
    isReturn,
    returnNote: isReturn
      ? `${returnData.returnReason}: ${returnData.returnNote}`
      : "",
    formats,
    barcodes,
    outputs: filteredOutputs,
    access,
    isPriority,
    isRush,
    isHoliday,
  };
};

const sendOrder = async (
  usertoken,
  orderInfo,
  returnData,
  checkinData,
  upgrades,
  categories,
  barcodes,
  outputs,
  setSuccess,
  triggerError,
) => {
  try {
    const newSlipInfo = generateSlipInfo(
      orderInfo,
      returnData,
      categories,
      barcodes,
      outputs,
    );
    console.log(newSlipInfo);

    const endpoint = orderInfo.isCanceled
      ? "canceled"
      : orderInfo.isReturn
        ? "return"
        : "";
    const response = await fetch(
      `https://${VITE_APP_API_URI}/digitization/receiving/print-slip/${endpoint}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          order_info: {
            ...orderInfo,
            ...newSlipInfo,
          },
          upgrades: upgrades,
          order_items: filterCategories(categories),
          cell: checkinData.cell.id,
        }),
      },
    );
    const data = await response.text();
    if (!response.ok) {
      throw new Error("Unable to complete order");
    } else {
      const numberOfTimes =
        outputs?.find((output) => output.textContent === "Thumb Drive")
          ?.quantity ?? null;
      if (numberOfTimes !== null && numberOfTimes > 0) {
        const lastName = orderInfo.name?.split(" ").pop();
        await printQRCodeThumbdriveSlipReceiving(
          orderInfo.orderId,
          lastName,
          numberOfTimes,
        );
      }

      await printSlip(data);
      setSuccess(true);
    }
  } catch (error) {
    triggerError(error.message);
  }
};

const addQty = (total, current) => Number(total) + Number(current.quantity);
export const ReviewOrder = ({
  orderInfo,
  returnData,
  checkinData,
  upgrades,
  categories,
  totalItemCount,
  barcodes,
  dispatch,
}) => {
  const [{ usertoken }] = useLogin();
  const { errState, errMsg, trigger: triggerError } = useError();
  const [completeButtonActive, setCompleteButtonActive] = useState(false);
  const [success, setSuccess] = useState(false);
  const { completeWork } = useWorkCompleted("Receiving");
  const outputList = Object.keys(upgrades).map((type) => {
    let upgrade = { ...upgrades[type] };
    const outputIndex = orderInfo.itemData.findIndex(
      ({ output_type }) => output_type === upgrade.textContent,
    );

    if (outputIndex !== -1) {
      const { quantity, product_name, variant_name } =
        orderInfo.itemData[outputIndex];
      upgrade.quantity = Number(upgrade.quantity) + Number(quantity);
      upgrade.productName = product_name;
      upgrade.textContent = product_name;
      upgrade.variantName = variant_name;
    }
    return upgrade;
  });

  const formatsList = categories.flatMap(({ formats }) =>
    formats.filter(({ quantity }) => quantity > 0),
  );

  let itemCount = formatsList.reduce(addQty, 0);
  let outputCount = outputList.reduce(addQty, 0);

  useEffect(() => {
    const barcodeError = barcodes.findIndex((b) => b.error) !== -1;
    if (
      getItemCount(categories) === totalItemCount &&
      !success &&
      !barcodeError
    ) {
      setCompleteButtonActive(true);
    }
  }, [categories, totalItemCount, barcodes, success]);

  useTimeout(
    () => {
      completeWork(orderInfo.orderId);
      dispatch({ type: RESET_ORDER });
    },
    success,
    500,
  );

  return (
    <>
      <section className="review-order__wrapper">
        <h1 className="review-order__header">Order Summary</h1>
        {totalItemCount !== getItemCount(categories) && (
          <article className="review-order__handcount-msg">
            {`You counted `}
            <span>{totalItemCount || 0}</span>
            {`item${totalItemCount !== 1 ? "s" : ""} in total.`}
          </article>
        )}
        <div className="review-order__lists">
          {!orderInfo.isCanceled ? (
            <div className="review-order__list">
              <h1 className="review-order__list-header">Delivery Options</h1>
              <ItemList
                items={outputList}
                textField="textContent"
                totalQuantity={outputCount}
                pageSize={10}
              />
            </div>
          ) : (
            <div className="review-order__canceled">
              <h1 className="review-order__canceled-header">No Outputs</h1>
              <div className="review-order__canceled-message">
                This order has been canceled.
              </div>
            </div>
          )}
          <div className="review-order__list">
            <h1 className="review-order__list-header">Analog Items</h1>
            <ItemList
              items={formatsList}
              textField={"format"}
              totalQuantity={itemCount}
              pageSize={10}
            />
          </div>
        </div>
      </section>
      {errState ? (
        <div className="review-order__complete-error">{errMsg}</div>
      ) : (
        ""
      )}
      <section className="review-order__complete-button">
        <CompleteButton
          active={completeButtonActive}
          onClick={(event) => {
            setCompleteButtonActive(false);
            sendOrder(
              usertoken,
              orderInfo,
              returnData,
              checkinData,
              upgrades,
              categories,
              barcodes,
              outputList,
              setSuccess,
              triggerError,
            );
            event.currentTarget.blur();
          }}
        />
      </section>
    </>
  );
};

export default ReviewOrder;
