import BaseButton from "../base/button";

import "./order-error.scss";

interface OrderErrorProps {
  /** header text */
  header: string;
  /** error message text */
  message: string;
  /** click handler */
  onClick: () => void;
}

/** Error state for Order page */
export function OrderError({ header, message, onClick }: OrderErrorProps) {
  return (
    <section className="order-error__wrapper">
      <div className="order-error__header">{header}</div>
      <div className="order-error__explainer">{message}</div>
      <BaseButton onClick={onClick} className="order-error__go-back-button">
        Go Back
      </BaseButton>
    </section>
  );
}

export default OrderError;
