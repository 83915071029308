import type { PropsWithChildren } from "react";
import { styled } from "../../../stitches.config";

import OrderHeader from "../../groups/order-header";

export interface IAppContainerProps {
  brand?: string | null;
  name?: string | null;
  orderId?: string | null;
}

export type AppContainerProps = PropsWithChildren<IAppContainerProps>;

const Header = styled("section", {
  gridColumn: "-12/12",
  gridRow: "1/3",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const Content = styled("section", {
  gridColumn: "2/12",
  gridRow: "3/12",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export default function AppContainer({
  brand = null,
  name = null,
  orderId = null,
  children,
}: AppContainerProps) {
  return (
    <>
      <Header>
        <OrderHeader brand={brand} name={name} orderId={orderId} />
      </Header>
      <Content>{children}</Content>
    </>
  );
}
