import { useState, useEffect } from "react";

import "./text-input.scss";

export interface TextInputProps {
  /** Input val from listener */
  val: string;
  /** additional class values */
  className?: string;
  /** Is input in error state */
  error?: boolean;
  /** Error text */
  errorMsg?: string;
}

/**
 * Managed text input for displaying values input from screen listener.
 */
export function TextInput({
  val,
  className = "",
  error = false,
  errorMsg = "",
}: TextInputProps) {
  const [value, setValue] = useState<string>(val);

  useEffect(() => {
    setValue(val);
  }, [val]);

  const klass = `text-input__box${className.length > 0 ? ` ${className}` : ""}${
    error ? ` text-input__error` : ""
  }`;
  return (
    <div className="text-input">
      <input type="text" className={klass} value={value} readOnly={true} />
      <div className="text-input__error-msg">{errorMsg}</div>
    </div>
  );
}

export default TextInput;
