import type { ReactNode } from "react";

import "./app-name.scss";

export const AppName = ({
  children,
  klass = null,
}: {
  children: ReactNode;
  klass?: string | null;
}) => (
  <section className="app-name">
    <div
      className={`app-name__text ${
        klass !== null ? klass : "app-name__text--default"
      }`}
    >
      {children}
    </div>
  </section>
);

export default AppName;
