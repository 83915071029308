// Hooks
import useApi from "../../../../../hooks/use-api";
// Types
import type { TShipBalanceDueState } from "../../../../../hooks/reducers/admin/ship-balance-due";

/** Handle requests to Safetyscans API order complete endpoint */
export const useCompleteOrder = (
  order: TShipBalanceDueState["order"],
  active: boolean,
) => {
  const orderCompleteApi = useApi<any>(
    `safetyscans/order/${order.production_order_id}/complete`,
    "post",
    active,
    { "Content-Type": "application/json" },
    JSON.stringify({
      salesOrderId: order.sales_order_id,
    }),
  );
  return orderCompleteApi;
};
