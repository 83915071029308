import {
  type MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
} from "react";

import BaseButton from "../base/button";
import TextInput from "../base/text-input";

import "./number-pad.scss";

/**
 * Returns an array of numbers from 1-9 and 0
 * @returns list of numbers
 */
export const getKeys = () => {
  const numKeys = Array.from(Array(10).keys());
  numKeys.shift();
  numKeys.push(0);
  return numKeys;
};

/** Input props for NumberPad component */
export interface NumberPadProps {
  /** Number pad header title */
  header: string;
  /** Display for pad input */
  entry: string;
  /** Update entry display property */
  setEntry: (entry: string) => void;
  /** Max length property for entry display */
  maxLength: number;
  /** Actions to take on submit click */
  handleSubmit: () => void;
  /** If error state active */
  errState: boolean;
  /** Display message for error */
  errMsg: string;
}

/** NumberPad component */
export function NumberPad({
  header,
  entry,
  setEntry,
  maxLength,
  handleSubmit,
  errState,
  errMsg,
}: NumberPadProps) {
  const numKeys = getKeys();

  /** Updates number entry on delete click */
  const handleDeleteClick = useCallback(() => {
    const back = entry.slice(0, entry.length - 1);
    setEntry(back);
  }, [entry, setEntry]);

  /** Updates number entry on key click */
  const handleNumberClick = (event: ReactMouseEvent<HTMLButtonElement>) => {
    const target = event.currentTarget.textContent;
    event.currentTarget.blur();

    if (entry.length < maxLength) {
      setEntry(`${entry}${target}`);
    }
  };

  const onSubmit = useCallback(
    (event: ReactMouseEvent<HTMLButtonElement>) => {
      event.currentTarget.blur();
      handleSubmit();
    },
    [handleSubmit],
  );

  useEffect(() => {
    const onChange = (e: KeyboardEvent) => {
      const key = e.key;
      if (["Backspace", "Delete"].includes(key)) {
        return handleDeleteClick();
      } else if (key === "Enter") {
        console.log("enter");
        return handleSubmit();
      } else if (entry.length < maxLength && !isNaN(Number(key))) {
        setEntry(`${entry}${key}`);
      }

      console.log(e);
    };

    document.addEventListener("keydown", onChange);

    return () => {
      document.removeEventListener("keydown", onChange);
    };
  }, [entry, maxLength, setEntry, handleDeleteClick, handleSubmit]);

  return (
    <section className="number-pad-wrapper">
      <article className="number-pad">
        <h1 className="number-pad__header">{header}</h1>
        <div className="number-pad__text-input">
          <TextInput
            className={"number-pad__input-field"}
            val={entry}
            error={errState}
            errorMsg={errMsg}
          />
          <BaseButton
            className={"number-pad__delete"}
            onClick={handleDeleteClick}
          >
            ⌫
          </BaseButton>
        </div>
        <div className="number-pad__numbers">
          {numKeys.map((num, index) => (
            <BaseButton
              key={index}
              className={"number-pad__number"}
              onClick={handleNumberClick}
            >
              {num}
            </BaseButton>
          ))}
          <BaseButton className={"number-pad__enter"} onClick={onSubmit}>
            Submit <span>➡</span>
          </BaseButton>
        </div>
      </article>
    </section>
  );
}

export default NumberPad;
