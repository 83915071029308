// Hooks
import { useState, useEffect } from "react";
import useApi from "../../../../../hooks/use-api";
import { ShipBalanceDueActionCreators } from "../../../../../hooks/reducers/admin/ship-balance-due";
// Types
import type { Api } from "../../../../../types/api";
import type { TShipBalanceDueAction } from "../../../../../hooks/reducers/admin/ship-balance-due";
import type { ScanBalanceDueOrderState } from "../scan-order";
import type { Dispatch } from "react";

/**
 * Get production order data from `productions-orders` API
 * and check entered access code.
 * On success, set order data in state.
 * On access code mismatch, show error message
 * */
export const useGetOrderData = (dispatch: Dispatch<TShipBalanceDueAction>) => {
  const [{ productionOrderId, accessCode }, setOrderData] =
    useState<ScanBalanceDueOrderState>({
      productionOrderId: null,
      accessCode: null,
    });
  /** Get order data from ProductionOrders API when order ID and access code are scanned */
  const { actions, body, isResolved, apiDispatch } =
    useApi<Api.IProductionOrder>(
      `production/order/${productionOrderId}`,
      "get",
      productionOrderId != null,
    );

  useEffect(() => {
    if (!isResolved) {
      return;
    }
    if (body.access_code === accessCode) {
      dispatch(ShipBalanceDueActionCreators.createSetOrderDataAction(body));
      dispatch(
        ShipBalanceDueActionCreators.createSetErrorAction({
          errState: false,
          errMsg: null,
        }),
      );
    } else {
      dispatch(
        ShipBalanceDueActionCreators.createSetErrorAction({
          errState: true,
          errMsg: "Incorrect access code",
        }),
      );
    }
  }, [isResolved, body, dispatch, actions, apiDispatch, accessCode]);

  /** Allow re-scanning order ID, access code */
  const updateOrderData = (state: ScanBalanceDueOrderState) => {
    setOrderData(state);
    apiDispatch({ type: actions.RESET });
  };

  return { updateOrderData };
};
