import { useState, useEffect, useRef } from "react";
import useError from "./use-error";
const PAGE_BTN_ACTIVE = "PAGE_BTN_ACTIVE";

/**
 * Handle error state from scanning items
 * @param {Array.<{code: string, error?: string}>} items
 * @param {Function} dispatch
 */
export const useItemError = (items, totalItemCount, dispatch) => {
  const itemsLength = useRef(items.length);
  const totalItemCountRef = useRef(totalItemCount);
  const [filterErrors, setFilterErrors] = useState(false);
  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError(
    items.length > totalItemCount,
    "More items scanned than counted. Please recount.",
  );

  // Update error message and dispatch Go Button active state
  useEffect(() => {
    if (
      items.length === itemsLength.current &&
      totalItemCount === totalItemCountRef.current
    ) {
      return;
    }
    // No scan errors - clear error message
    if (items.findIndex((i) => i.error) === -1) {
      clearError();
      if (items.length === totalItemCount) {
        dispatch({ type: PAGE_BTN_ACTIVE, active: true });
      } else {
        if (items.length > totalItemCount) {
          triggerError("More items scanned than counted. Please recount.");
        }
        dispatch({ type: PAGE_BTN_ACTIVE, active: false });
      }
    } else {
      // Show latest error message
      const lastErrorIndex = [...items].reverse().findIndex((b) => b.error);
      if (lastErrorIndex !== -1) {
        triggerError(items[items.length - lastErrorIndex - 1].error);
      }
    }
    // No items
    if (items.length === 0) {
      dispatch({ type: PAGE_BTN_ACTIVE, active: false });
    }
  }, [items, items.length, totalItemCount, clearError, triggerError, dispatch]);

  // Remove error filter if no errors
  useEffect(() => {
    if (!errState) {
      setFilterErrors(false);
    }
  }, [errState]);

  useEffect(() => {
    itemsLength.current = items.length;
  }, [items.length]);

  useEffect(() => {
    totalItemCountRef.current = totalItemCount;
  }, [totalItemCount]);

  return {
    errState,
    errMsg,
    triggerError,
    clearError,
    filterErrors,
    setFilterErrors,
  };
};

export default useItemError;
