import BaseButton from "../../base/button";
import {
  discActions,
  IDiscReducerProps,
  TDiscCategory,
} from "../../../hooks/reducers/discs";
interface IFormats {
  type: "Audio-Tapes" | "Audio-Reels" | "Video-Tapes" | "Photo" | "Film-Reels";
  category: TDiscCategory;
}
export const PickFormat = ({ dispatch }: IDiscReducerProps) => {
  const formats: IFormats[] = [
    { type: "Audio-Tapes", category: "Audio" },
    { type: "Audio-Reels", category: "Audio" },
    { type: "Video-Tapes", category: "Tapes" },
    { type: "Photo", category: "Photos" },
    { type: "Film-Reels", category: "Film" },
  ];

  return (
    <section className="discs__pick-format">
      <h1>Pick Format for Disc</h1>
      <section className="discs__pick-format-buttons">
        {formats.map((format, index) => {
          const onClick = () => {
            dispatch({
              type: discActions.CHOOSE_FORMAT,
              discType: format.type,
              discCategory: format.category,
            });
          };
          return (
            <BaseButton
              key={index}
              onClick={onClick}
              className="discs__format-button"
            >
              {format.type}
            </BaseButton>
          );
        })}
      </section>
    </section>
  );
};

export default PickFormat;
