import { type Dispatch, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import {
  COMPLETE_ORDER,
  type OrderInfoState,
} from "../../../hooks/reducers/actions/safetyscans/order-info";
import {
  SET_LABEL,
  type ShippingInfoState,
  type ShippingLabelState,
} from "../../../hooks/reducers/actions/safetyscans/shipping";
import { LOGOUT } from "../../../hooks/reducers/login";
import useError from "../../../hooks/use-error";
import { printAltSlip, printShippingLabel } from "../../../utils/dymo-print";
import BaseButton from "../../base/button";
import ConfirmLabel from "../../groups/confirm-label";
import "./ship-order.scss";

import ConfirmOrderScan from "@pages/safetyscans/ship-order/confirm-order-scan";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

export function ErrorArea(props: {
  errorMessage: string;
  onClick: () => void;
  buttonText?: string;
}) {
  return (
    <section className="ship-order__error-wrapper">
      <h1 className="ship-order__error-header">ERROR:</h1>
      <article className="ship-order__error-message">
        {props.errorMessage}
      </article>
      <BaseButton
        className="ship-order__error-button"
        onClick={(event) => {
          event.currentTarget.blur();
          props.onClick();
        }}
      >
        {props.buttonText ?? "Got it!"}
      </BaseButton>
    </section>
  );
}
/** Step in shipment process to display */
type ShipOrderPageState =
  | "confirm-scan"
  | "ready"
  | "generating"
  | "shipping-issue"
  | "printing"
  | "printed"
  | "print-issue";

/**
 * Get the text to display on the print label button
 * @param state - current page state
 */
export function printButtonText(state: ShipOrderPageState) {
  switch (state) {
    case "generating":
      return "Processing...";
    case "printing":
      return "Printing...";
    default:
      return "Print Label";
  }
}

export interface ShipOrderProps {
  orderInfo: OrderInfoState;
  shippingInfo: ShippingInfoState;
  labelData: ShippingLabelState;
  dispatch: Dispatch<any>;
}

export function ShipOrder(props: ShipOrderProps) {
  const [{ usertoken }, sendLoginEvent] = useLogin();
  /** determine which page screen to show. */
  const [pageState, setPageState] =
    useState<ShipOrderPageState>("confirm-scan");
  const { orderInfo, labelData, dispatch } = props;
  const { errState, errMsg, trigger: triggerError } = useError();
  const productionOrderId = orderInfo.orderId;

  /** possibly not needed in this version ? */
  if (errState) {
    return (
      <ErrorArea
        errorMessage={errMsg}
        onClick={() => sendLoginEvent({ type: LOGOUT })}
      />
    );
  }

  if (pageState === "confirm-scan") {
    const itemList = orderInfo.analogItems.map((item) => item.item_barcode);
    const onConfirm = (confirm: boolean) => {
      if (confirm) {
        setPageState("ready");
      } else {
        triggerError("Item scan does not match order");
      }
    };

    return (
      <ConfirmOrderScan
        itemList={itemList}
        err={{
          state: errState,
          message: errMsg,
        }}
        onConfirm={onConfirm}
      />
    );
  }

  /** label printed, needs to be confirmed */
  if (pageState === "printed") {
    return <ConfirmLabel orderInfo={orderInfo} dispatch={dispatch} />;
  }

  /** recoverable printer failure */
  if (pageState === "print-issue") {
    return (
      <ErrorArea
        errorMessage={"Unable to print shipping label"}
        onClick={() =>
          dispatch({ type: SET_LABEL, payload: { labelPrinted: null } })
        }
        buttonText="RETRY"
      />
    );
  }

  /** handle shipment issues and print error slip */
  const handleShippingError = () => {
    setPageState("shipping-issue");

    const errData = {
      source: "Shippo",
      message: "Unknown Error - service failed to respond",
      ...labelData.err,
    };
    const events = [];

    events.push(
      printAltSlip(
        usertoken,
        "ERROR",
        errData.source,
        orderInfo.orderId,
        orderInfo.accessCode,
        orderInfo.name,
        errData.message,
      ),
    );

    if (errData !== undefined && ![7, 11, 12, 26].includes(errData.id ?? 0)) {
      events.push(
        fetch(
          `https://${VITE_APP_API_URI}/shipments/alerts/${orderInfo.salesOrderId}`,
          {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
              "Content-Type": "application/json",
            }),
            body: JSON.stringify({
              alertReasonId: 3,
            }),
          },
        )
          .then((res) => res.json())
          .then((res) => console.log(res)),
      );
    }

    return Promise.allSettled(events).then(() => {
      triggerError(errData.message);
    });
  };

  const handleOrderComplete = async () => {
    try {
      const response = await fetch(
        `https://${VITE_APP_API_URI}/safetyscans/order/${orderInfo.orderId}/complete`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${usertoken}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            salesOrderId: orderInfo.salesOrderId,
          }),
        },
      );

      const payload = await response.json();

      if (!response.ok) {
        throw new Error(payload.errorMessage);
      }
      dispatch({ type: COMPLETE_ORDER, complete: true });
    } catch (error) {
      console.log(error);
      triggerError(`Unable to complete order: ${error.message}`);
    }
  };

  const printSuccessLabel = async (labelImage: string) => {
    try {
      setPageState("printing");
      const makeLabel = await printShippingLabel(labelImage);
      dispatch({
        type: SET_LABEL,
        payload: { labelPrinted: makeLabel.printComplete },
      });
      setPageState("printed");

      if (makeLabel.printComplete && !orderInfo.completedOrder) {
        await handleOrderComplete();
      }
    } catch (error) {
      setPageState("print-issue");
    }
  };
  /** label is being generated */
  const getLabel = async () => {
    /** fetch label flow */
    try {
      const response = await fetch(
        `https://${VITE_APP_API_URI}/digitization/shipments/${productionOrderId}`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${usertoken}`,
            "Content-Type": "application/json",
          }),
          body: "",
        },
      );
      const payload = await response.json();

      if (!response.ok) {
        throw new Error(payload);
      }

      dispatch({ type: SET_LABEL, payload });
      return printSuccessLabel(payload.labelImage);
    } catch (error) {
      console.log(error);
      return handleShippingError();
    }
  };

  /** default page state, ready to print/printing in progress */
  const buttonActive = pageState !== "ready" ? "printing" : "";
  return (
    <section className="ship-order__print-label-wrapper">
      <h1 className="ship-order__print-label-header">
        This order is ready to ship.
      </h1>
      <BaseButton
        className={`ship-order__print-label-button ${buttonActive}`}
        onClick={(event) => {
          event.currentTarget.blur();
          setPageState("generating");
          return getLabel();
        }}
      >
        {printButtonText(pageState)}
      </BaseButton>
    </section>
  );
}

export default ShipOrder;
