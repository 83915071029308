import { type Dispatch, useEffect, useState } from "react";

import {
  type CategoryState,
  SELECT_CATEGORY,
} from "../../../../hooks/reducers/actions/receiving/category";
import {
  type EditFormatsActions,
  type EditFormatsState,
  STEP_DISPLAY,
} from "../../../../hooks/reducers/admin/edit-formats";
import useError from "../../../../hooks/use-error";
import BaseButton from "../../../base/button";
import CategoryGroup from "../../../components/category-group";
import { Formats } from "../../receiving/analog-items";
import ItemList from "./items";

const useFormats = (
  selectedCategory: CategoryState["selectedCategory"],
  categories: CategoryState["categories"],
): string[] => {
  const [activeFormats, setActiveFormats] = useState([]);

  useEffect(() => {
    if (selectedCategory?.length > 0) {
      const formats = categories.find(
        (cat) => cat.category === selectedCategory,
      )["formats"];
      setActiveFormats(formats);
    }
  }, [selectedCategory, categories]);

  return activeFormats;
};

export interface PickFormatProps {
  state: EditFormatsState;
  dispatch: Dispatch<EditFormatsActions>;
}

export const PickFormats = ({ state, dispatch }: PickFormatProps) => {
  const { orderId, categories, selectedCategory, selectedFormat } = state;
  const { errState, trigger, clear } = useError();
  const activeFormats = useFormats(selectedCategory, categories);
  const itemCount = categories.reduce(
    (total, { quantity }) => total + quantity,
    0,
  );
  console.log({ errState: errState, itemCount: itemCount });

  return (
    <section className="edit-items__edit-formats-wrapper">
      <section className="edit-items__header">
        <h1>
          Scan all items for order <em>{orderId}</em>
        </h1>
      </section>
      <section className="edit-items__sections-wrapper">
        <section className="edit-items__category-wrapper">
          <CategoryGroup
            categoryButtons={categories}
            selected={selectedCategory}
            error={errState}
            onClick={
              errState
                ? () => {}
                : (cat: string) =>
                    dispatch({ type: SELECT_CATEGORY, selectedCategory: cat })
            }
          />
        </section>
        <section className="edit-items__format-wrapper">
          {selectedCategory?.length > 0 && (
            <Formats
              selectedCategory={selectedCategory}
              selectedFormat={selectedFormat}
              activeFormats={activeFormats}
              error={errState}
              onClick={errState ? () => {} : dispatch}
            />
          )}
        </section>
        <section className="edit-items__items-wrapper">
          {selectedCategory?.length > 0 && selectedFormat?.length > 0 && (
            <ItemList
              state={state}
              errState={errState}
              triggerError={trigger}
              clearError={clear}
              dispatch={dispatch}
            />
          )}
          <section className="edit-items__next-wrapper">
            <BaseButton
              className={`edit-items__next-page ${
                errState || itemCount === 0 ? "inactive" : ""
              }`}
              onClick={
                !errState && itemCount > 0
                  ? () => dispatch({ type: STEP_DISPLAY })
                  : undefined
              }
            >
              Next
            </BaseButton>
          </section>
        </section>
      </section>
    </section>
  );
};

export default PickFormats;
