import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useLogin } from "../../../hooks/context/login";
import type { DepartmentInfoState } from "../../../hooks/reducers/actions/safetyscans/departments";
import { DEPTS_COMPLETE } from "../../../hooks/reducers/actions/safetyscans/order-info";
import type { OrderInfoState } from "../../../hooks/reducers/actions/safetyscans/order-info";
// Hooks
import { PAGE_STEP } from "../../../hooks/reducers/actions/safetyscans/page";
import { RESET } from "../../../hooks/reducers/safetyscans";
import useError from "../../../hooks/use-error";
import useKeyEventBarcode from "../../../hooks/use-key-event-barcode";
import usePageStep from "../../../hooks/use-page-step";
import useWorkCompleted from "../../../hooks/use-work-completed";
// Components
import Button from "../../base/button";
import { TrackingInput } from "../arrival/scan-box";
import IncludedPrompt from "./deliverables/included-prompt";
import { NeedsOutputs, OutputsList } from "./deliverables/outputs";

import "./deliverables.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

/** Completes a department for the order */
async function completeDepartment(
  usertoken: string,
  orderId: string | null,
  cellId: string | null,
  body: { salesOrderId: number | null; productId: number | null },
) {
  const response = await fetch(
    `https://${VITE_APP_API_URI}/safetyscans/order/${orderId}/complete/${cellId}`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(body),
    },
  );
  const payload = await response.json();
  return { ok: response.ok, payload };
}

function checkOut(
  usertoken: string,
  body: { scan: string | null; orderId: string; status: "OUT" },
) {
  return fetch(`https://${VITE_APP_API_URI}/departments/checkin`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${usertoken}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(body),
  });
}

export type DeliverableStep =
  | "init"
  | "check-drive"
  | "scan-thumbdrive"
  | "drive-missing"
  | "deliverables";

export const useDeliverables = (
  orderInfo: OrderInfoState,
  departmentsData: DepartmentInfoState,
  dispatch: Dispatch<any>,
) => {
  const [{ usertoken }] = useLogin();
  const [step, setStep] = usePageStep<DeliverableStep>("init");
  const [scanThumbdriveCompleted, setScanThumbdriveCompleted] = useState(
    orderInfo.requireThumbdriveScan !== true,
  );
  const { completeWork } = useWorkCompleted("OrdersCompleted");
  // I think this needs a better solution than ?boolean
  const [hasOutputs, setHasOutputs] = useState<boolean | null>(null);
  // also here
  const [deptProductId, setDeptProductId] = useState<number | null>(null);

  const checkReadyToShip = useCallback(async () => {
    try {
      const [completed] = await Promise.all([
        completeDepartment(
          usertoken,
          orderInfo.orderId,
          departmentsData.cell.id || null,
          { salesOrderId: orderInfo.salesOrderId, productId: deptProductId },
        ),
        checkOut(usertoken, {
          scan: departmentsData.cell.id || null,
          orderId:
            orderInfo.salesOrderId !== null
              ? orderInfo.salesOrderId.toString()
              : "",
          status: "OUT",
        }),
      ]);

      if (!completed.ok) {
        throw new Error(completed.payload.errorMessage);
      }

      completeWork(orderInfo.orderId);
      dispatch({
        type: DEPTS_COMPLETE,
        complete: completed.payload.order_complete,
      });
      dispatch({ type: PAGE_STEP });
    } catch (error) {
      //
    }
  }, [
    usertoken,
    orderInfo.orderId,
    orderInfo.salesOrderId,
    deptProductId,
    departmentsData.cell.id,
    dispatch,
    completeWork,
  ]);

  useEffect(() => {
    if (step === "init") {
      // Does order have download
      const hasDrive =
        orderInfo.outputs.findIndex(
          (o) => o.output_type === "Digital Download",
        ) > -1;

      if (hasDrive && orderInfo.driveLink !== null) {
        setStep("check-drive");
        return;
      } else if (hasDrive && orderInfo.driveLink === null) {
        setStep("drive-missing");
        return;
      }

      // always goes here if no thumbdrive output
      if (scanThumbdriveCompleted) {
        setStep("deliverables");
      } else {
        setStep("scan-thumbdrive");
      }
    }
  }, [
    step,
    setStep,
    orderInfo.outputs,
    orderInfo.driveLink,
    scanThumbdriveCompleted,
  ]);

  // Handle if order is canceled. Skip output check for canceled order
  useEffect(() => {
    if (orderInfo.isCanceled) {
      dispatch({ type: DEPTS_COMPLETE, complete: true });
      dispatch({ type: PAGE_STEP });
    }
  }, [orderInfo.isCanceled, dispatch]);

  // Additional Outputs orders don't have analog items to process
  // Teams orders complete all categories at the same time
  useEffect(() => {
    if (
      departmentsData.departmentName === "Teams" ||
      departmentsData.departmentName === "Digitizing" ||
      orderInfo.additionalOutputs
    ) {
      dispatch({ type: DEPTS_COMPLETE, complete: true });
      setDeptProductId(0);
    }
  }, [
    departmentsData.departmentName,
    orderInfo.additionalOutputs,
    dispatch,
    setDeptProductId,
  ]);

  // If order has only unsupported items
  useEffect(() => {
    const items = orderInfo.analogItems.filter(
      ({ product_name }) => product_name !== "Unsupported",
    );
    if (items.length === 0) {
      dispatch({ type: DEPTS_COMPLETE, complete: true });
      setDeptProductId(51);
      setHasOutputs(true);
    }
  }, [orderInfo.analogItems, dispatch, setDeptProductId, setHasOutputs]);

  // Set dept id
  useEffect(() => {
    let deptName = departmentsData.departmentName;
    if (departmentsData.cell.name.includes("Audio")) {
      deptName = "Audio";
    } else if (deptName === "Photos") {
      deptName = "Photo";
    }

    let productFilterCb = ({
      product_name,
    }: (typeof orderInfo.analogItems)[0]) =>
      new RegExp(deptName, "i").test(product_name);
    if (deptName === "Balance-Due") {
      productFilterCb = () => true;
    }

    const product = orderInfo.analogItems.filter(productFilterCb).shift();

    if (product === undefined) {
      console.log("This order has no analog items for this department.");
      return;
    }
    setDeptProductId(product.product_id);
  }, [departmentsData.cell.name, departmentsData.departmentName, orderInfo]);

  // Complete this dept
  useEffect(() => {
    if (!hasOutputs || deptProductId === null) {
      return;
    }

    // Additional Outputs orders don't have analog items to process
    if (orderInfo.additionalOutputs) {
      dispatch({ type: PAGE_STEP });
    }

    checkReadyToShip();
  }, [
    orderInfo.additionalOutputs,
    hasOutputs,
    deptProductId,
    checkReadyToShip,
    dispatch,
  ]);

  return {
    step,
    setStep,
    hasOutputs,
    setHasOutputs,
    scanThumbdriveCompleted,
    setScanThumbdriveCompleted,
  };
};

interface CheckDriveLinkProps {
  link: string;
  setStep: (check: boolean) => void;
  isGDriveVisited: boolean;
  setIsGDriveVisited: () => void;
}

export const CheckDriveLink = ({
  link,
  setStep,
  isGDriveVisited,
  setIsGDriveVisited,
}: CheckDriveLinkProps) => {
  return (
    <>
      <h2 className="deliverables__subheader">
        Please check that all files are uploaded
      </h2>
      <section className="deliverables__drive-link">
        <a
          href={link}
          onClick={() => setIsGDriveVisited()}
          target="_blank"
          rel="noopener noreferrer"
          className="deliverables__buttons yes"
        >
          Google Drive Folder
        </a>
      </section>
      <IncludedPrompt
        isYesDisabled={isGDriveVisited === false}
        onClick={setStep}
      />
    </>
  );
};

interface DeliverableListProps {
  orderInfo: OrderInfoState;
  hasOutputs: boolean | null;
  setHasOutputs: Dispatch<SetStateAction<boolean>>;
}
export const DeliverableList = ({
  orderInfo,
  hasOutputs,
  setHasOutputs,
}: DeliverableListProps) => {
  if (hasOutputs === null) {
    return (
      <>
        <OutputsList {...orderInfo} />
        <IncludedPrompt onClick={setHasOutputs} />
      </>
    );
  }

  return <div />;
};

const ScanThumbdrive = ({
  orderInfo,
  setScanThumbdriveCompleted,
}: {
  orderInfo: DeliverableListProps["orderInfo"];
  setScanThumbdriveCompleted: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    val,
    filled: scanDone,
    clearInput,
    clearFilled,
    isValidOrder,
  } = useKeyEventBarcode(true, orderInfo.orderId);

  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();

  useEffect(() => {
    if (scanDone) {
      clearError();
      clearInput();
      clearFilled();

      if (isValidOrder === "valid" && val.split("-")[1] === "TD") {
        setScanThumbdriveCompleted(true);
      } else {
        triggerError("Invalid thumbdrive barcode");
      }
    }
  }, [
    scanDone,
    setScanThumbdriveCompleted,
    errState,
    val,
    clearError,
    clearInput,
    clearFilled,
    isValidOrder,
    triggerError,
  ]);

  return (
    <>
      <h1 className="scan-tracking__header">Scan Thumb drive barcode</h1>
      <TrackingInput
        className={""}
        val={val}
        error={errState}
        errorMsg={errMsg}
      />
    </>
  );
};

interface DeliverablesProps {
  orderInfo: OrderInfoState;
  departmentsData: DepartmentInfoState;
  dispatch: Dispatch<any>; // need to clean this up when reducer is ts
}

export const Deliverables = ({
  orderInfo,
  departmentsData,
  dispatch,
}: DeliverablesProps) => {
  const {
    step,
    setStep,
    hasOutputs,
    setHasOutputs,
    scanThumbdriveCompleted,
    setScanThumbdriveCompleted,
  } = useDeliverables(orderInfo, departmentsData, dispatch);

  useEffect(() => {
    if (!orderInfo.requireThumbdriveScan) {
      return;
    }
    if (scanThumbdriveCompleted === true) {
      // scan completed, go back to check-drive screen
      const hasDrive =
        orderInfo.outputs.findIndex(
          (o) => o.output_type === "Digital Download",
        ) > -1;

      if (hasDrive && orderInfo.driveLink !== null) {
        setStep("check-drive");
      } else {
        setStep("deliverables");
      }
    }
  }, [
    scanThumbdriveCompleted,
    orderInfo.requireThumbdriveScan,
    orderInfo.outputs,
    orderInfo.driveLink,
    setStep,
  ]);

  const onClick = (check: boolean) => {
    if (check) {
      setStep("deliverables");
      return;
    }
    setHasOutputs(false);
  };

  return (
    <section className="deliverables__wrapper">
      {step === "init" && <article>Loading...</article>}
      {step === "check-drive" && (
        <CheckDriveLink
          isGDriveVisited={orderInfo.isGDriveVisited}
          setIsGDriveVisited={() => {
            dispatch({
              type: "SET_IS_GDRIVE_VISITED",
              payload: true,
            });

            if (scanThumbdriveCompleted === false) {
              setStep("scan-thumbdrive");
            }
          }}
          link={orderInfo.driveLink}
          setStep={onClick}
        />
      )}
      {step === "scan-thumbdrive" && (
        <ScanThumbdrive
          orderInfo={orderInfo}
          setScanThumbdriveCompleted={setScanThumbdriveCompleted}
        />
      )}
      {step === "deliverables" && (
        <DeliverableList
          orderInfo={orderInfo}
          hasOutputs={hasOutputs}
          setHasOutputs={setHasOutputs}
        />
      )}
      {step === "drive-missing" && (
        <>
          <p className={"confirm-label__subheader error"}>
            This order should have digital download, but cloud link is missing.
            Kindly seek assistance from a manager to resolve the issue.
          </p>
          <Button
            onClick={() => {
              dispatch({ type: RESET });
            }}
            className={`complete-button`}
          >
            Go Back
          </Button>
        </>
      )}
      {hasOutputs === false ? <NeedsOutputs dispatch={dispatch} /> : <></>}
    </section>
  );
};

export default Deliverables;
