/**
 * SVG Icon for KODAK Digitizing.
 * @param {Object} props
 * @param {string} [props.className]
 */
export const Kodak = ({ className = "header-icon" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path id="a" d="M0 3.156h258.185V611.82H0z" />
        <path id="c" d="M2.42 3.156h619.067V611.82H2.42z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect fill="#FFB700" width="1200" height="1200" rx="59.5" />
        <g transform="translate(249.866 293.336)">
          <mask id="b" fill="#fff">
            <use href="#a" />
          </mask>
          <path
            d="M68.659 332.205c-3.843-5.491-9.864-13.732-9.864-21.97 0-6.599 1.623-12.646 4.928-17.59L255.987 26.218c1.638-2.182 2.198-4.924 2.198-8.233 0-8.241-6.592-14.83-14.83-14.83H43.945C19.78 3.155-.002 24.035-.002 48.203v519.132c0 24.711 19.783 44.487 43.946 44.487h199.412a14.78 14.78 0 0 0 14.829-14.836c0-3.276-1.108-6.581-2.746-9.334L68.659 332.205z"
            fill="#EC1C24"
            mask="url(#b)"
          />
        </g>
        <g transform="translate(328.207 293.336)">
          <mask id="d" fill="#fff">
            <use href="#c" />
          </mask>
          <path
            d="M577.56 3.156H379.233c-18.13 0-29.639 8.237-44.494 20.876L4.617 304.193c-1.668 1.642-2.197 3.287-2.197 5.491 0 2.187.53 3.843 2.197 5.492l338.912 284c9.352 7.696 22.54 12.643 35.704 12.643h197.771c24.726 0 44.483-19.773 44.483-44.488V48.203c0-24.17-19.757-45.047-43.927-45.047"
            fill="#EC1C24"
            mask="url(#d)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Kodak;
