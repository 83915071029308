import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useLogin } from "../../hooks/context/login";
import useOrderViewReducer, {
  SET_ALERTS,
  SET_BACKUP_ORDER,
  SET_CHECKINS,
  SET_PRODUCTION_ORDER,
  SET_TIMELINE,
} from "../../hooks/reducers/order-view";
import useFetch from "../../hooks/use-fetch";
import Tabs from "../components/tabs";
import OrderHeader from "../groups/order-header";
import RushLocateModal from "../groups/rush-locate";
import "./order-view.scss";
import BackupOrder from "./order-view/back-up-order";
import ItemView from "./order-view/items";
import Main from "./order-view/main";
import ScanOrderId from "./order-view/scan-order-id";
import Timeline from "./order-view/timeline";

const { VITE_APP_API_URI } = import.meta.env;

export const useOrderFetch = (orderId, dispatch, orderData) => {
  const [{ usertoken }] = useLogin();
  const { isLoading, isResolved, isRejected, body } = useFetch(
    `https://${VITE_APP_API_URI}/production/order/${orderId}`,
    {
      headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
    },
  );

  useEffect(() => {
    if (isResolved && orderData === null) {
      dispatch({ type: SET_PRODUCTION_ORDER, payload: body });
    }
  }, [isResolved, body, dispatch, orderData]);

  return {
    isLoading,
    isResolved: isResolved && orderData !== null,
    isRejected,
  };
};

export const useFetchTimeline = (
  orderId,
  dispatch,
  timelineData,
  orderLoaded = false,
) => {
  const [{ usertoken }] = useLogin();
  const { isLoading, isResolved, isRejected, body } = useFetch(
    `https://${VITE_APP_API_URI}/timeline/order/${orderId}`,
    {
      headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
    },
    orderLoaded,
  );

  useEffect(() => {
    if (isResolved && timelineData === null) {
      dispatch({ type: SET_TIMELINE, payload: body.notes });
    }
  }, [isResolved, body, dispatch, timelineData]);

  return {
    isLoading,
    isResolved: isResolved && timelineData !== null,
    isRejected,
  };
};

export const useFetchLocation = (orderId, dispatch, checkinData) => {
  const [{ usertoken }] = useLogin();
  const { isLoading, isResolved, isRejected, body } = useFetch(
    `https://${VITE_APP_API_URI}/departments/checkin/${orderId}`,
    {
      headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
    },
  );

  useEffect(() => {
    if (isResolved && checkinData === null) {
      dispatch({ type: SET_CHECKINS, payload: body.checkins });
    }
  }, [isResolved, body, dispatch, checkinData]);
  return {
    isLoading,
    isResolved: isResolved && checkinData !== null,
    isRejected,
    body,
  };
};

export const useFetchAlerts = (
  orderId,
  dispatch,
  alertData,
  orderLoaded = false,
) => {
  const [{ usertoken }] = useLogin();
  const { isLoading, isResolved, isRejected, body } = useFetch(
    `https://${VITE_APP_API_URI}/shipments/alerts/${orderId}`,
    {
      headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
    },
    orderLoaded,
  );

  useEffect(() => {
    if (isResolved && alertData === null) {
      if (!import.meta.env.PROD) {
        console.log(body);
      }

      dispatch({ type: SET_ALERTS, payload: body });
    }
  }, [isResolved, body, dispatch, alertData]);
  return {
    isLoading,
    isResolved: isResolved && alertData !== null,
    isRejected,
    body,
  };
};

export const useFetchBackupOrder = (
  orderId,
  customerId,
  dispatch,
  backupOrderData,
  orderLoaded = false,
) => {
  const [{ usertoken }] = useLogin();
  const { isLoading, isResolved, isRejected, body } = useFetch(
    `https://${VITE_APP_API_URI}/production/customers/${customerId}/orders/${orderId}/backup`,
    {
      headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
    },
    orderLoaded,
  );

  useEffect(() => {
    if (isResolved && backupOrderData === null) {
      dispatch({ type: SET_BACKUP_ORDER, payload: body });
    }
  }, [isResolved, body, dispatch, backupOrderData]);

  return {
    isLoading,
    isResolved: isResolved && backupOrderData !== null,
    isRejected,
  };
};

/**
 * @param {Object} props
 * @param {string} props.orderId
 */
export const Order = ({ orderId }) => {
  const [state, dispatch] = useOrderViewReducer(orderId);
  const { isLoading, isResolved, isRejected } = useOrderFetch(
    orderId,
    dispatch,
    state.orderData,
  );

  const backupOrder = useFetchBackupOrder(
    state.salesOrderId,
    state.customerId,
    dispatch,
    state.backupOrderData,
    isResolved,
  );

  const timeline = useFetchTimeline(
    state.salesOrderId,
    dispatch,
    state.timelineData,
    isResolved,
  );

  const checkins = useFetchLocation(
    state.productionOrderId,
    dispatch,
    state.checkinData,
  );
  const alerts = useFetchAlerts(
    state.salesOrderId,
    dispatch,
    state.alertData,
    isResolved,
  );

  return (
    <>
      <section className="order__detail-header">
        <OrderHeader
          brand={isResolved ? state.orderData.brand_name.toLowerCase() : null}
          name={
            isResolved
              ? `${state.orderData.shipping_address.firstname} ${state.orderData.shipping_address.lastname}`
              : null
          }
          orderId={isResolved ? state.productionOrderId : null}
        />
      </section>

      <section className="order__header">
        <h1>Order View</h1>
      </section>

      <main className="order__content">
        {isLoading && <article>Getting Order Data...</article>}
        {isResolved && (
          <Tabs classPrefix="order">
            <Main
              label="Main"
              state={state}
              dispatch={dispatch}
              checkinLoaded={checkins.isResolved}
              alertsLoaded={alerts.isResolved}
            />
            <ItemView label="Items" state={state} dispatch={dispatch} />
            <Timeline
              label="Timeline"
              state={state}
              isLoading={timeline.isLoading}
              isResolved={timeline.isResolved}
            />
            <BackupOrder
              label="Backup"
              state={state}
              isLoading={backupOrder.isLoading}
              isResolved={backupOrder.isResolved}
            />
          </Tabs>
        )}
        {isRejected && (
          <article>
            Something went wrong
            <br />
            TODO make error handler
          </article>
        )}
      </main>
      <RushLocateModal orderData={state.orderData} />
    </>
  );
};

export const OrderView = () => {
  const { id } = useParams();

  if (id === undefined || id === null) {
    return (
      <main className="order__content">
        <ScanOrderId />
      </main>
    );
  }
  return <Order orderId={id} />;
};

export default OrderView;
