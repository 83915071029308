import UpgradeIndicator from "../../../components/upgrade-indicator/upgrade-indicator";
import PassFail from "../../../components/pass-fail/pass-fail";
import type { PassFailToggle } from "../../../components/pass-fail/pass-fail";
import { styled } from "../../../../stitches.config";
import type { QaReducerProps } from "../../../../hooks/reducers/qa-process";

import type { Dispatch, SetStateAction } from "react";

interface OutputPackagingsProps extends QaReducerProps {
  hasOutputs: PassFailToggle;
  setHasOutputs?: Dispatch<SetStateAction<PassFailToggle>>;
}

const OutputSection = styled("section", {
  justifyContent: "space-evenly",
  width: "100%",
  flexWrap: "wrap",

  "> *": {
    margin: "10px",
  },
});

interface IOutputs {
  /** Output name */
  text: string;
  /** Output quantity */
  quantity: number;
}

export default function Outputs({
  state,
  hasOutputs,
  setHasOutputs,
}: OutputPackagingsProps) {
  const outputs = state.orderInfo.order.items.reduce<IOutputs[]>(
    (group, current) => {
      if (current.product_type_id !== 2) {
        return group;
      }
      return [
        ...group,
        {
          text: current.product_name.replace(/Kodak Digitizing/i, ""),
          quantity: current.quantity,
        },
      ];
    },
    [] as IOutputs[],
  );
  return (
    <>
      <OutputSection>
        {outputs.map(({ text, quantity }, idx) => (
          <UpgradeIndicator key={idx} active={true} quantity={quantity}>
            {text}
          </UpgradeIndicator>
        ))}
      </OutputSection>
      <PassFail
        label=""
        toggle={hasOutputs}
        onPass={() => setHasOutputs("pass")}
        onFail={() => setHasOutputs("fail")}
      />
    </>
  );
}
