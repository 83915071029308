import {
  BaseFileViewAudioButton,
  BaseFileViewPhotoButton,
  BaseFileViewVideoButton,
} from "../../base/button";

export const generateFileViewButton = (
  fileType: string,
  barcode: string,
  label: string,
) => {
  switch (fileType) {
    case "video": {
      return (
        <BaseFileViewVideoButton
          key={barcode}
          className={`scan-item scanned`}
          type={label}
        >
          {barcode}
        </BaseFileViewVideoButton>
      );
    }
    case "audio": {
      return (
        <BaseFileViewAudioButton
          key={barcode}
          className={`scan-item scanned`}
          type={label}
        >
          {barcode}
        </BaseFileViewAudioButton>
      );
    }
    default: {
      return (
        <BaseFileViewPhotoButton
          key={barcode}
          className={`scan-item scanned`}
          type={label}
        >
          {barcode}
        </BaseFileViewPhotoButton>
      );
    }
  }
};
export const BackupFileTab = ({
  label,
  files,
}: {
  label: string;
  files?: { barcode: string; fileType: string }[];
}) => {
  return (
    <section className={"scan-items__item-inputs-wrapper"}>
      {files?.map(({ barcode, fileType }) =>
        generateFileViewButton(fileType, barcode, label),
      )}
    </section>
  );
};

export default BackupFileTab;
