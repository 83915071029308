// Hooks
import useApi from "../../../../../hooks/use-api";
// Types
import type { TShipBalanceDueState } from "../../../../../hooks/reducers/admin/ship-balance-due";

/** Handle requests to Shipments API label confirm endpoint */
export const useCheckTracking = (
  order: TShipBalanceDueState["order"],
  tracking: string,
  active: boolean,
) => {
  const trackingApi = useApi<any>(
    `shipments/tracking/${tracking}/confirm`,
    "post",
    active,
    { "Content-Type": "application/json" },
    JSON.stringify({
      salesOrderId: order.sales_order_id,
      productionOrderId: order.production_order_id,
      shipmentLeg: order.is_return ? 5 : 3,
    }),
  );
  return trackingApi;
};
