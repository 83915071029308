import { type MouseEvent as ReactMouseEvent } from "react";

import Button from "../base/button";
import cn from "classnames";

import "./process-step-button.scss";

export interface ProcessStepButtonProps {
  /** Display name of step */
  stepName: string;
  /** Is step active */
  inProgress: boolean;
  /** Is step complete */
  complete: boolean;
  /** click handler function */
  onClick: (event?: ReactMouseEvent<HTMLButtonElement>) => void;
}

export const ProcessStepButton = ({
  stepName,
  inProgress,
  complete,
  onClick,
}: ProcessStepButtonProps) => {
  const classStep = cn("process-step-button", {
    "process-step-button--active": inProgress,
    "process-step-button--complete": !inProgress && complete,
  });
  return (
    <Button onClick={onClick} className={classStep}>
      {stepName}
    </Button>
  );
};

export default ProcessStepButton;
