import Admin from "./pages/admin";
import Arrival from "./pages/arrival";
import Checkin from "./pages/checkin";
import Departure from "./pages/departure";
import Discs from "./pages/discs";
import FetchView from "./pages/fetch-view";
import Fulfillment from "./pages/fulfillment";
import FulfillmenDeparture from "./pages/fulfillment-departure";
import Home from "./pages/home";
import Login from "./pages/login";
import MailCheck from "./pages/mail-check";
import OrderView from "./pages/order-view";
import QaProcessing from "./pages/qa/processing";
import Receiving from "./pages/receiving";
import SafetyScans from "./pages/safetyscans";
import SafetyCheck from "@routes/safety-check";

import AdminHomeIcon from "@images/icons/app-admin.svg?react";
import ArrivalIcon from "@images/icons/app-arrival.svg?react";
import AudioTapesIcon from "@images/icons/app-audio-tapes.svg?react";
import CheckinIcon from "@images/icons/app-checkins.svg?react";
import DepartureIcon from "@images/icons/app-departure.svg?react";
import DiscsIcon from "@images/icons/app-discs.svg?react";
import FetchListIcon from "@images/icons/app-fetch-list.svg?react";
import FilmIcon from "@images/icons/app-film.svg?react";
import FulfillmentIcon from "@images/icons/app-fulfillment.svg?react";
import OrderViewIcon from "@images/icons/app-order-view.svg?react";
import PhotosIcon from "@images/icons/app-photos.svg?react";
import ReceivingIcon from "@images/icons/app-receiving.svg?react";
import TapesIcon from "@images/icons/app-tapes.svg?react";
import VHSIcon from "@images/icons/app-vhs.svg?react";

export interface RouteDef {
  name: string;
  path: string;
  to?: string;
  isPrivateRoute: boolean;
  isAdminRoute?: boolean;
  exact?: boolean;
  page: JSX.Element;
  icon?: any;
}

export const RouteGroup: RouteDef[] = [
  {
    name: "Home",
    path: "/",
    isPrivateRoute: true,
    exact: true,
    page: <Home />,
  },
  {
    name: "Admin",
    path: "/admin/:app?",
    to: "/admin",
    isPrivateRoute: true,
    exact: true,
    isAdminRoute: true,
    page: <Admin />,
    icon: AdminHomeIcon,
  },
  {
    name: "QA - Process",
    path: "/quality-assurance/processing",
    isPrivateRoute: true,
    page: <QaProcessing />,
  },
  {
    name: "Order",
    path: "/order/:id",
    isPrivateRoute: true,
    page: <OrderView />,
    icon: OrderViewIcon,
  },
  {
    name: "Order",
    path: "/order/",
    isPrivateRoute: true,
    page: <OrderView />,
    icon: OrderViewIcon,
  },

  {
    name: "Discs",
    path: "/discs",
    isPrivateRoute: true,
    page: <Discs />,
    icon: DiscsIcon,
  },
  {
    name: "Fetch List",
    path: "/fetch-list",
    isPrivateRoute: true,
    page: <FetchView />,
    icon: FetchListIcon,
  },
  {
    name: "Mail Check",
    path: "/mail-check",
    isPrivateRoute: true,
    page: <MailCheck />,
  },
  {
    name: "Fulfillment",
    path: "/fulfillment",
    isPrivateRoute: true,
    page: <Fulfillment />,
    icon: FulfillmentIcon,
  },
  {
    name: "Fulfillment Departure",
    path: "/fulfillment-departure",
    isPrivateRoute: true,
    page: <FulfillmenDeparture />,
    icon: DepartureIcon,
  },
  {
    name: "Arrival",
    path: "/arrival",
    isPrivateRoute: true,
    page: <Arrival />,
    icon: ArrivalIcon,
  },

  {
    name: "Receiving",
    path: "/receiving",
    isPrivateRoute: true,
    page: <Receiving />,
    icon: ReceivingIcon,
  },
  {
    name: "Checkin",
    path: "/checkin",
    isPrivateRoute: true,
    page: <Checkin />,
    icon: CheckinIcon,
  },
  {
    name: "Departure",
    path: "/departure",
    isPrivateRoute: true,
    page: <Departure />,
    icon: DepartureIcon,
  },

  {
    name: "Tapes",
    path: "/tapes",
    isPrivateRoute: true,
    page: <SafetyScans />,
    icon: TapesIcon,
  },
  {
    name: "Film",
    path: "/film",
    isPrivateRoute: true,
    page: <SafetyScans />,
    icon: FilmIcon,
  },
  {
    name: "Photos",
    path: "/photos",
    isPrivateRoute: true,
    page: <SafetyScans />,
    icon: PhotosIcon,
  },
  {
    name: "Audio",
    path: "/audio",
    isPrivateRoute: true,
    page: <SafetyScans />,
    icon: AudioTapesIcon,
  },
  {
    name: "Balance Due",
    path: "/balance-due",
    isPrivateRoute: true,
    page: <SafetyScans />,
    icon: OrderViewIcon,
  },
  {
    name: "Safety Scans",
    path: "/safetyscans",
    isPrivateRoute: true,
    page: <SafetyCheck />,
    icon: VHSIcon,
  },

  {
    name: "Login",
    path: "/login",
    isPrivateRoute: false,
    page: <Login />,
  },
];
