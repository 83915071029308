/**
 * SVG Icon for Blank Avatar Image.
 * @param {Object} props
 * @param {string} [props.className]
 */
export const Avatar = ({ className = "header-icon" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 288 288"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M156.342 186.707H125.95c-32.052 0-58.057 25.89-58.243 57.899 21.193 16.096 47.627 25.649 76.292 25.649 26.146 0 50.436-7.948 70.585-21.56v-3.744c0-32.167-26.076-58.244-58.243-58.244zM50.068 228.364c7.103-32.646 34.737-57.628 68.698-60.743-15.9-9.216-26.596-26.419-26.596-46.12 0-29.418 23.848-53.266 53.266-53.266s53.267 23.848 53.267 53.266c0 20.395-11.462 38.112-28.295 47.062 32.448 5.937 57.85 32.04 62.74 64.84 22.922-22.857 37.107-54.474 37.107-89.403 0-69.729-56.526-126.255-126.255-126.255C74.271 17.745 17.745 74.271 17.745 144c0 32.43 12.227 62.004 32.323 84.364zM144 287.745C64.612 287.745.255 223.388.255 144S64.612.255 144 .255 287.745 64.612 287.745 144 223.388 287.745 144 287.745zm1.436-130.468c19.759 0 35.776-16.017 35.776-35.776 0-19.758-16.017-35.776-35.776-35.776-19.758 0-35.776 16.018-35.776 35.776 0 19.759 16.018 35.776 35.776 35.776z"
        fill="#C5C5C5"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Avatar;
