import { useMemo, type ReactNode } from "react";
import { AnalogItem, selectBrand, selectOrderId } from "../state/slice/data";
import classNames from "classnames";
import { RiFileDamageLine } from "react-icons/ri";
import { FaGoogleDrive, FaRegQuestionCircle } from "react-icons/fa";
import { CiCloudOff, CiCloudOn } from "react-icons/ci";
import { AiOutlineLoading } from "react-icons/ai";
import {
  ApiItemCloudStatus,
  useGetItemCloudStatusQuery,
} from "../state/services/api";
import { useSelector } from "react-redux";

interface ItemContainerProps {
  department: string;
  children: ReactNode;
}

export function ItemContainer({ department, children }: ItemContainerProps) {
  return (
    <section className="item-container">
      <div className="item-container__header">Scan all {department} items</div>
      {children}
    </section>
  );
}

interface ItemListProps {
  items: AnalogItem[];
}

export function ItemList({ items }: ItemListProps) {
  return (
    <div className="item-container__list">
      {items.map((item) => (
        <Item key={item.item_barcode} item={item} />
      ))}
    </div>
  );
}

interface ItemCloudIconProps {
  service: ApiItemCloudStatus["service"];
  fileReady: boolean;
}

export const ItemCloudIcon = (props: ItemCloudIconProps) => {
  switch (props.service) {
    case "google":
      if (props.fileReady) {
        return <FaGoogleDrive />;
      }
      return <FaRegQuestionCircle />;
    case "cloud":
      if (props.fileReady) {
        return <CiCloudOn strokeWidth={1} />;
      }
      return <CiCloudOff strokeWidth={1} />;
    default:
      return null;
  }
};

interface ItemProps {
  item: AnalogItem;
}

export function Item({ item }: ItemProps) {
  const brand = useSelector(selectBrand);
  const orderId = useSelector(selectOrderId);

  /** Damage types that shouldn't have files */
  const itemNoConvertDamaged = useMemo(
    () =>
      [
        "unfixable",
        "unsupported",
        "blank",
        "graphic",
        "packaging",
        "moldy",
      ].includes(item?.damage_type ?? ""),
    [item],
  );

  const skip = !brand || !orderId || itemNoConvertDamaged;
  const { data, isLoading, isSuccess, isError, isUninitialized } =
    useGetItemCloudStatusQuery(
      {
        orderId,
        brand,
        barcode: item.item_barcode,
      },
      {
        skip,
      },
    );

  const warn = isSuccess && data.fileCount > 0 && !data.fileReady;
  console.log("warn", warn);
  const itemClass = classNames("item-container__item", {
    "item-container__item--scanned": item.scanned,
    "item-container__item--loading":
      item.scanned && (isLoading || isUninitialized),
    "item-container__item--warn": item.scanned && warn,
    "item-container__item--error":
      item.scanned && isError && !itemNoConvertDamaged,
    "item-container__item--disabled": item.scanned && itemNoConvertDamaged,
  });

  /**
   * flag all damaged items
   * (include quality/repair issues that will have files)
   */
  const hasDamage =
    item.damage_type !== undefined &&
    item.damage_type !== null &&
    item.damage_type !== "none";

  const cloudIcon =
    isLoading && item.scanned ? (
      <AiOutlineLoading className="spinner" />
    ) : isError || isUninitialized ? null : item.scanned ? (
      <ItemCloudIcon service={data.service} fileReady={data.fileReady} />
    ) : null;

  const showCount = data && data.fileCount > 0 && item.scanned;
  return (
    <div className={itemClass}>
      <span>{item.item_barcode}</span>
      <span>{cloudIcon}</span>
      <span>{hasDamage && <RiFileDamageLine />}</span>
      {showCount && <span className="count">{data.fileCount}</span>}
    </div>
  );
}
