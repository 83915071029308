import Button from "../base/button";
import cn from "classnames";

import "./media-button.scss";

interface MediaButtonProps {
  /** media format for button display */
  buttonData: { format: string; quantity: number };
  /** active media format */
  selected: string;
  /** error state */
  error: boolean;
  /** click handling function */
  onClick: () => void;
}

export const MediaButton = ({
  buttonData,
  selected,
  error,
  onClick,
}: MediaButtonProps) => {
  const { format, quantity } = buttonData;
  const className = cn("media-button", {
    "media-button--selected": selected === format,
    error,
  });

  return (
    <Button onClick={onClick} className={className}>
      {`${format}${quantity > 0 ? ` (${quantity})` : ""}`}
    </Button>
  );
};

export default MediaButton;
