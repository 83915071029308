// @ts-nocheck
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import {
  type Cell,
  type Column,
  type FilterType,
  type Row,
  useFilters,
  useSortBy,
  useTable,
} from "react-table";

import type { Api } from "../../../types/api";
import BaseButton from "../../base/button";
import TableFilterSelect from "../../components/table/filters/select";
import Table from "../../components/table/table";
import type { IFetchList } from "../fetch-view";

interface FetchListProps {
  // table: TableInstance<Api.IFetchListItem>;
  onDeactivateRush: (id: number, prodId: string) => void;
  list: Api.IFetchListItem[];
  activeList: keyof IFetchList;
}
type ListItemDateFields = Pick<
  Api.IFetchListItem,
  | "arrive_date"
  | "receive_date"
  | "rush_date_due"
  | "resolve_date"
  | "completion_date"
>;

const formatDate = (date: string | null | undefined) => {
  return date == null || date === ""
    ? ""
    : new Date(date).toLocaleDateString(undefined, {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      });
};
type ColumnData = Array<Column<Api.IFetchListItem>>;
const locatedFilterFn: FilterType<Api.IFetchListItem> = (
  rows,
  _,
  filterValue,
) =>
  filterValue !== "any"
    ? rows.filter(({ original: { rush_located } }) => {
        const isLocated = Boolean(rush_located);
        return filterValue === "located" ? isLocated : !isLocated;
      })
    : rows;
const overdueFilterFn: FilterType<Api.IFetchListItem> = (
  rows,
  _,
  filterValue,
) =>
  filterValue !== "any"
    ? rows.filter(({ original: { rush_date_due } }) => {
        if (rush_date_due == null) {
          return false;
        }
        const dueDate = new Date(rush_date_due),
          now = new Date();
        return filterValue === "overdue" ? dueDate <= now : dueDate > now;
      })
    : rows;

const rushTypeFilterFn: FilterType<Api.IFetchListItem> = (
  rows,
  _,
  filterValue,
) =>
  filterValue !== "any"
    ? rows.filter(({ original }) => {
        switch (filterValue) {
          case "none":
            return !original.is_rush;
          case "cs":
            return original.cs_rush;
          case "expedited":
            return original.rush_tier_name === "Expedited";
          case "guaranteed":
            return original.rush_tier_name === "Guaranteed Rush";
          case "legacy_standard":
            return original.rush_tier_name === "5 Day";
          case "postcard":
            return original.in_postcard_window;
          default:
            return true;
        }
      })
    : rows;

const sortDate =
  (field: keyof ListItemDateFields) =>
  (a: Row<Api.IFetchListItem>, b: Row<Api.IFetchListItem>) => {
    if (b.original[field] === null) {
      return 1;
    }
    return new Date(a.original[field]) > new Date(b.original[field]) ? 1 : -1;
  };

interface ColumndDataProps {
  onDeactivateRush: (id: number, prodId: string) => void;
}
const columnDataWithProps = (
  columndDataProps: ColumndDataProps,
): ColumnData => [
  {
    Header: "Order",
    Cell: (props: Cell<Api.IFetchListItem>) => (
      <FetchListOrderLink
        salesOrderId={props.row.original.sales_order_id}
        lastname={props.row.original.lastname}
      />
    ),
  },
  {
    Header: "Arrived",
    accessor: (d) => formatDate(d.arrive_date),
    disableFilters: true,
    sortType: sortDate("arrive_date"),
  },
  {
    Header: "Received",
    accessor: (d) => formatDate(d.receive_date),
    disableFilters: true,
    sortType: sortDate("receive_date"),
  },
  {
    Header: "Resolved",
    accessor: (d) => formatDate(d.resolve_date),
    disableFilters: true,
    sortType: sortDate("resolve_date"),
  },
  {
    Header: "Completed",
    accessor: (d) => formatDate(d.completion_date),
    disableFilters: true,
    sortType: sortDate("completion_date"),
  },
  {
    Header: "Located",

    accessor: (d) => (d.rush_located ? "Yes" : "No"),
    Filter: (props) => (
      <TableFilterSelect<Api.IFetchListItem>
        {...props}
        column={props.column}
        options={[
          { value: "any", text: "--" },
          { value: "located", text: "Yes" },
          { value: "not_located", text: "No" },
        ]}
        label={""}
        name={"fetch-view__table-located-filter"}
      />
    ),
    filter: locatedFilterFn,
  },
  { Header: "Location", accessor: "location_name", disableFilters: true },
  { Header: "Status", accessor: "status", disableFilters: true },
  {
    Header: "Tracking",
    accessor: "last_inbound_tracking",
    disableFilters: true,
  },
  {
    Header: "Product",
    accessor: (d) => `${d.product_name} - ${d.variant_name}`,
    disableFilters: true,
  },
  {
    Header: "Sales Channel",
    accessor: "sales_channel",
    Filter: (props) => (
      <TableFilterSelect<Api.IFetchListItem>
        {...props}
        column={props.column}
        options={[
          { value: "any", text: "--" },
          { value: "web", text: "Web" },
          { value: "wholesale", text: "Wholesale" },
        ]}
        label={""}
        name="fetch-view__table-sales-channel-filter"
      />
    ),
    filter: (rows, _, filterValue) =>
      filterValue !== "any"
        ? rows.filter(({ original }) => {
            if (filterValue === "wholesale") {
              return original.sales_channel !== "web";
            }
            if (filterValue === "web") {
              return original.sales_channel === "web";
            }
            return true;
          })
        : rows,
  },
  {
    Header: "Due Date",
    accessor: (d) => formatDate(d.rush_date_due),
    Cell: (props: Cell<Api.IFetchListItem>) => (
      <FetchListDueDate dueDate={props.value} />
    ),
    Filter: (props) => (
      <TableFilterSelect<Api.IFetchListItem>
        {...props}
        column={props.column}
        options={[
          { value: "any", text: "--" },
          { value: "overdue", text: "Overdue" },
          { value: "not_due", text: "Not Due" },
        ]}
        label={""}
        name={"fetch-view__table-located-filter"}
      />
    ),
    filter: overdueFilterFn,
    sortType: sortDate("rush_date_due"),
  },
  {
    Header: "Rush Type",
    accessor: (d) => {
      if (!d.is_rush) {
        return "None";
      }

      if (d.cs_rush) {
        return `CS`;
      }
      if (d.in_postcard_window) {
        return "Postcard";
      }

      return d.rush_tier_name || "5 Day";
    },
    Filter: (props) => (
      <TableFilterSelect<Api.IFetchListItem>
        {...props}
        column={props.column}
        options={[
          { value: "any", text: "--" },
          { value: "none", text: "None" },
          { value: "cs", text: "CS" },
          { value: "expedited", text: "Expedited" },
          { value: "guaranteed", text: "Guaranteed Rush" },
          { value: "legacy_standard", text: "5 Day" },
          { value: "postcard", text: "Postcard" },
        ]}
        label={""}
        name={"fetch-view__table-rush-type-filter"}
      />
    ),
    filter: rushTypeFilterFn,
  },
  {
    Header: "Actions",
    Cell: (props: Cell<Api.IFetchListItem>) => (
      <BaseButton
        className={"fetch-view__delete-btn"}
        onClick={() =>
          columndDataProps.onDeactivateRush(
            props.row.original.sales_order_id,
            props.row.original.production_order_id,
          )
        }
      >
        X
      </BaseButton>
    ),
  },
];

const FetchListDueDate = ({ dueDate }: { dueDate: string }) => {
  const overdue = dueDate != null && new Date(dueDate) <= new Date();
  return (
    <span className={`fetch-view__list-row--due${overdue ? " overdue" : ""}`}>
      {formatDate(dueDate)}
    </span>
  );
};

export const FetchListOrderLink = ({
  salesOrderId,
  lastname,
}: {
  salesOrderId: number;
  lastname: string;
}) => (
  <section className="fetch-view__list-row--order">
    <NavLink
      className="fetch-view__list-row--salesOrderId"
      to={`order/${salesOrderId}`}
    >
      {salesOrderId}
    </NavLink>
    <span className="fetch-view__list-row--located">{lastname}</span>
  </section>
);

const filterColumnData = (
  activeList: keyof IFetchList,
  columnData: ColumnData,
) => {
  switch (activeList) {
    case "balancedue":
      return columnData.filter(
        (header) =>
          header.Header !== "Located" &&
          header.Header !== "Status" &&
          header.Header !== "Arrived" &&
          header.Header !== "Received" &&
          header.Header !== "Tracking",
      );
    case "queue":
    case "digitizing":
      return columnData.filter(
        (header) =>
          header.Header !== "Tracking" &&
          header.Header !== "Resolved" &&
          header.Header !== "Completed",
      );
    default:
      return columnData.filter(
        (header) =>
          header.Header !== "Resolved" && header.Header !== "Completed",
      );
  }
};

export const FetchList = ({
  onDeactivateRush,
  list,
  activeList,
}: FetchListProps) => {
  const columns = useMemo(() => {
    return filterColumnData(
      activeList,
      columnDataWithProps({ onDeactivateRush }),
    );
  }, [activeList, onDeactivateRush]);
  const data = useMemo(() => list, [list]);

  const table = useTable<Api.IFetchListItem>(
    { columns, data },
    useFilters,
    useSortBy,
  );
  return <Table {...table} classPrefix={"fetch-view__list"} />;
};
export default FetchList;
