///<reference lib="webworker" />
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { NewVersionToast } from "../ui/components/new-version";
import { useRegisterSW } from "virtual:pwa-register/react";

/** Show toast notification and update worker on click */
export const showNotification = (onClick: () => void) =>
  toast(<NewVersionToast onClick={onClick} />, {
    autoClose: false,
  });

export const useServiceWorker = () => {
  const location = useLocation();
  const path = useRef<string>();
  const loadRef = useRef({ offlineReady: false });
  const [swLoaded, setSwLoaded] = useState(false);
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW: (swUrl, registration) => {
      console.log("SW: Registered service worker:", swUrl, registration);
      setSwLoaded(true);
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) return;

        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              showNotification(() => updateServiceWorker(true));
            }
          }
        };
      };
    },
    onRegisterError: (error) =>
      console.error("SW: Error during service worker registration:", error),
    onOfflineReady: () => {
      console.log("SW: App is offline-ready");
      if (loadRef.current.offlineReady) {
        setNeedRefresh(true);
      }
      loadRef.current.offlineReady = true;
    },
  });

  useEffect(() => {
    if (!swLoaded) return;

    if (location.pathname !== path.current) {
      console.log("refresh on navigation");

      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          console.log("SW: registration", registration);
          registration.update().then((update) => {
            console.log("SW: update", update);
          });
        }
      });
    }
  }, [swLoaded, location.pathname, path]);

  useEffect(() => {
    path.current = location.pathname;
  }, [location.pathname]);
};
