import { useCallback, useEffect, useReducer } from "react";

import { HttpError, UnauthorizedError } from "../utils/http-error";
import { useLogin } from "./context/login";

const { VITE_APP_API_URI } = import.meta.env;

type apiMethod = "get" | "post" | "put" | "patch" | "delete";

// Actions
enum apiActionTypes {
  STARTED = "STARTED",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  RESET = "RESET",
  SET_STATUS = "SET_STATUS",
}

// Loading States
enum apiLoadingStates {
  IDLE = "IDLE",
  PENDING = "PENDING",
  RESOLVED = "RESOLVED",
  REJECTED = "REJETED",
}
export interface ApiState<T = any, E = any> {
  status: apiLoadingStates;
  responseStatusCode: number | null;
  body?: T;
  error?: E;
}

export type ApiAction<T = any, E = any> =
  | { type: apiActionTypes.STARTED }
  | { type: apiActionTypes.SET_STATUS; payload: number }
  | { type: apiActionTypes.SUCCESS; payload: T }
  | { type: apiActionTypes.ERROR; error: E }
  | { type: apiActionTypes.RESET };

function createApiReducer<T = any>() {
  return function apiReducer(
    state: ApiState<T>,
    action: ApiAction<T>,
  ): ApiState<T> {
    switch (action.type) {
      case apiActionTypes.STARTED:
        return {
          ...state,
          status: apiLoadingStates.PENDING,
        };
      case apiActionTypes.SUCCESS:
        return {
          ...state,
          status: apiLoadingStates.RESOLVED,
          body: action.payload,
        };
      case apiActionTypes.ERROR:
        return {
          ...state,
          status: apiLoadingStates.REJECTED,
          error: action.error,
        };
      case apiActionTypes.SET_STATUS:
        return {
          ...state,
          responseStatusCode: action.payload,
        };
      case apiActionTypes.RESET:
        return {
          responseStatusCode: null,
          body: null,
          error: null,
          status: apiLoadingStates.IDLE,
        };
    }
  };
}

export default function useApi<T = any>(
  path: string,
  method: apiMethod,
  execute: boolean = true,
  headers: HeadersInit = {},
  body: BodyInit = null,
) {
  const initialState: ApiState<T> = {
    status: apiLoadingStates.IDLE,
    responseStatusCode: null,
    body: null,
    error: null,
  };
  const [{ usertoken }] = useLogin();
  const [state, dispatch] = useReducer(createApiReducer<T>(), initialState);

  const call = useCallback(async () => {
    try {
      const options: RequestInit = {
        method: method.toUpperCase(),
        body,
        headers: new Headers({
          ...headers,
          Authorization: `Bearer ${usertoken}`,
        }),
      };
      const url = `https://${VITE_APP_API_URI}/${path}`;

      const response = await fetch(url, options);
      dispatch({ type: apiActionTypes.SET_STATUS, payload: response.status });

      if (!response.ok) {
        let statusCode = response.status;
        if (statusCode === 401) {
          throw new UnauthorizedError("Request unauthorized");
        }
        throw new HttpError(statusCode, response.statusText);
      }

      if (response.status === 204) {
        dispatch({ type: apiActionTypes.SUCCESS, payload: null });
        return;
      }

      const json = (await response.json()) as T;
      dispatch({ type: apiActionTypes.SUCCESS, payload: json });
    } catch (error) {
      dispatch({ type: apiActionTypes.ERROR, error: error });
    }
  }, [dispatch, path, method, headers, body, usertoken]);

  useEffect(() => {
    if (execute && state.status === apiLoadingStates.IDLE) {
      dispatch({ type: apiActionTypes.STARTED });
      call();
    }
  }, [execute, state.status, dispatch, call]);

  return {
    isIdle: state.status === apiLoadingStates.IDLE,
    isPending: state.status === apiLoadingStates.PENDING,
    isLoading:
      state.status === apiLoadingStates.IDLE ||
      state.status === apiLoadingStates.PENDING,
    isResolved: state.status === apiLoadingStates.RESOLVED,
    isRejected: state.status === apiLoadingStates.REJECTED,
    apiDispatch: dispatch,
    ...state,
    actions: apiActionTypes,
    loadingStates: apiLoadingStates,
  };
}
