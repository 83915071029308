import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./log-damaged-items.scss";

// Components
import YesNoButtons from "../../components/yes-no-buttons";

// Hooks
import { PAGE_STEP } from "../../../hooks/reducers/actions/safetyscans/page";
import { PAGE_NAV } from "../../../hooks/reducers/actions/safetyscans/page";

/** Prompt to scan damaged items or go next */
const DamagePrompt = ({ damagedItems, onClick }) => {
  return damagedItems === null ? (
    <section className={"damaged-items__damage-prompt-wrapper"}>
      <div className={"damaged-items__damage-prompt-header"}>
        Have all supported items been transferred?
      </div>
      <YesNoButtons
        yesText={"ALL GOOD"}
        yesClick={() => {
          onClick(false);
        }}
        noText={"DAMAGED ITEMS"}
        noClick={() => {
          onClick(true);
        }}
      />
    </section>
  ) : (
    <></>
  );
};

export const DamagedItems = ({ orderInfo, dispatch }) => {
  const [scanDamagedItems, setScanDamagedItems] = useState(
    orderInfo.damagedItems.length > 0 ? true : null,
  );
  const location = useLocation();
  const pathname = location.pathname.substr(1);

  useEffect(() => {
    if (scanDamagedItems === false) {
      const checkTeamsCategory = (category) =>
        /team|safetyscans/i.test(pathname) &&
        orderInfo.categories.find(({ name }) => name === category);
      // Go to extra steps for Film, Photos
      if (pathname === "film" || checkTeamsCategory("Film")) {
        dispatch({ type: PAGE_NAV, step: "Scan Film With Sound" });
      } else if (pathname === "photos" || checkTeamsCategory("Photos")) {
        dispatch({ type: PAGE_NAV, step: "Select Photos Quantity" });
      } else {
        dispatch({ type: PAGE_STEP });
      }
    } else if (scanDamagedItems === true) {
      dispatch({ type: PAGE_NAV, step: "Scan Damaged Items" });
    }
  }, [
    scanDamagedItems,
    orderInfo.categories,
    pathname,
    orderInfo.hasLoggedPhotosQuantity,
    dispatch,
  ]);

  return (
    <section className={"damaged-items__wrapper"}>
      {!scanDamagedItems && (
        <DamagePrompt
          damagedItems={scanDamagedItems}
          onClick={setScanDamagedItems}
        />
      )}
    </section>
  );
};

export default DamagedItems;
