import BaseButton, { type BaseButtonClickHandler } from "../../base/button";

export const GetStarted = ({
  onClick,
}: {
  onClick: BaseButtonClickHandler;
}) => (
  <>
    <section className="arrival__title arrival__title--start">
      Ready to start checking in customer orders?
    </section>
    <section className="arrival__tracking-input-wrapper">
      <BaseButton
        onClick={onClick}
        className="arrival__button arrival__button--start"
      >
        Get Started
      </BaseButton>
    </section>
  </>
);

export default GetStarted;
