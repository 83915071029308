import { UnreachableError } from "../../utils/errors/unreachable";
import cn from "classnames";

import "./card.scss";

export const getClassName = (size: CardSize) => {
  switch (size) {
    case "regular":
      return "";
    case "short":
      return "card__box--short";
    case "short-countbycategory":
      return "card__box--short-countbycategory";
    case "med":
      return "card__box--med";
    case "med-analogitems":
      return "card__box--med-analogitems";
    case "full":
      return "card__box--full";
    default:
      throw new UnreachableError(size, "Invalid card size");
  }
};

export type CardSize =
  | "regular"
  | "short"
  | "short-countbycategory"
  | "med"
  | "med-analogitems"
  | "full";

interface CardProps {
  /** header text */
  header: string;
  /** card content */
  children: React.ReactNode;
  /** card size */
  size?: CardSize;
}

export const Card = ({ header, children, size = "regular" }: CardProps) => {
  const className = cn("card__box", getClassName(size));

  return (
    <article className={className}>
      <header className="card__header">{header}</header>
      <section className="card__content">{children}</section>
    </article>
  );
};
export default Card;
