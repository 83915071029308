import "./app.scss";
// Hooks
import {
  ShipBalanceDueSteps,
  useShipBalanceDueReducer,
} from "../../../../hooks/reducers/admin/ship-balance-due";
// Components
import { Passcode } from "./passcode";
import { ScanBalanceDueOrder } from "./scan-order";
import Weigh from "./weigh";
import BalanceDueShipStep from "./ship";
import ConfirmLabel from "./confirm";
import ShipmentAlerts from "./alerts";
// Types
import type {
  TShipBalanceDueState,
  TShipBalanceDueAction,
} from "../../../../hooks/reducers/admin/ship-balance-due";
import type { Dispatch } from "react";

export type ShipBalanceDueComponentProps = {
  state: TShipBalanceDueState;
  dispatch: Dispatch<TShipBalanceDueAction>;
};

export const ShipBalanceDue = () => {
  const [state, dispatch] = useShipBalanceDueReducer();
  switch (state.stepName) {
    /** Regular workflow */
    case ShipBalanceDueSteps.Passcode:
      return <Passcode state={state} dispatch={dispatch} />;
    case ShipBalanceDueSteps.ScanOrder:
      return <ScanBalanceDueOrder state={state} dispatch={dispatch} />;
    case ShipBalanceDueSteps.Weigh:
      return <Weigh state={state} dispatch={dispatch} />;
    case ShipBalanceDueSteps.Ship:
      return <BalanceDueShipStep state={state} dispatch={dispatch} />;
    case ShipBalanceDueSteps.Confirm:
      return <ConfirmLabel state={state} dispatch={dispatch} />;
    /** Error states */
    case ShipBalanceDueSteps.ShipmentAlerts:
      return <ShipmentAlerts state={state} dispatch={dispatch} />;
    default:
      return <section>Error: invalid state.</section>;
  }
};

export default ShipBalanceDue;
