import reactTable from "react-table";
import TableRow from "./row";

export interface TableBodyProps<TableType extends object>
  extends reactTable.TableInstance<TableType> {
  classPrefix: string;
}
export function TableBody<TableType extends object>(
  props: TableBodyProps<TableType>,
) {
  return (
    <tbody
      {...props.getTableBodyProps()}
      className={`${props.classPrefix}__table-body`}
    >
      {props.rows.map((row, index) => {
        props.prepareRow(row);
        return (
          <TableRow<TableType>
            key={index}
            row={row}
            classPrefix={props.classPrefix}
          />
        );
      })}
    </tbody>
  );
}
export default TableBody;
