import { useCallback, useEffect, useReducer, useRef } from "react";

import { useLogin } from "../context/login";
import useBooleanFetch from "../use-boolean-fetch";
import useKeyEvent from "../use-key-event";

export const SCAN_EVENT = "SCAN_EVENT";
export const ITEM_SCANNED = "ITEM_SCANNED";
export const TRACKING_DAMAGED = "TRACKING_DAMAGED";
export const ARRIVAL_FLAG = "ARRIVAL_FLAG";
export const SCAN_SUCCESS = "SCAN_SUCCESS";
export const SCAN_FAILURE = "SCAN_FAILURE";

/**
 * @callback arrivalReducer
 * @param {ArrivalState} state
 * @param {ArrivalAction} action
 * @returns {ArrivalState}
 */

/**
 * @typedef {Object} ArrivalAction
 * @property {string} type
 * @property {any} [payload]
 */

/**
 * @typedef {Object} ArrivalState
 * @property {string} entry
 * @property {number} total
 * @property {number} errors
 * @property {number} scans
 * @property {string} orderFlag
 * @property {?boolean} passScan
 * @property {string[]} list
 */

/** @type ArrivalState */
export const initialState = {
  entry: "",
  total: 0,
  errors: 0,
  scans: 0,
  orderFlag: "",
  passScan: null,
  list: [],
};

export const itemScanned = (state) => {
  const { total, list, entry } = state;
  const duplicate = list.findIndex((item) => item === entry);

  const newState = {
    ...state,
    entry,
  };

  if (duplicate === -1) {
    newState.total = total + 1;
    newState.list = [...list, entry];
  }
  console.log(newState);

  return newState;
};

/**
 *
 * @param {() => void} clearFilled
 * @param {() => void} clearInput
 * @returns {arrivalReducer}
 */
export function makeReducer(clearFilled, clearInput) {
  return function arrivalReducer(state, action) {
    switch (action.type) {
      case SCAN_EVENT:
        return { ...state, entry: action.payload };
      case ITEM_SCANNED:
        return itemScanned(state);
      case TRACKING_DAMAGED:
        clearFilled();
        clearInput();
        return {
          ...state,
          total: state.total + 1,
          errors: state.errors + 1,
          entry: "",
        };
      case ARRIVAL_FLAG:
        return {
          ...state,
          orderFlag: action.payload,
        };
      case SCAN_SUCCESS:
        return { ...state, passScan: true, scans: state.scans + 1 };
      case SCAN_FAILURE:
        return {
          ...state,
          orderFlag:
            action.payload && action.payload.orderFlag
              ? action.payload.orderFlag
              : "",
          passScan: false,
          errors: state.errors + 1,
        };
      default:
        return state;
    }
  };
}

export const checkLocalStorage = (date) => {
  const key = `Arrival_${date}`;

  const expiredVals = Object.keys(localStorage).filter(
    (key) => key.includes("Arrival") && !key.includes(date),
  );
  expiredVals.forEach((key) => localStorage.removeItem(key));

  const data = localStorage.getItem(key);

  return data !== null ? JSON.parse(data) : false;
};

export const useArrivalReducer = (triggerError, clearError) => {
  const [{ usertoken }] = useLogin();
  const date = new Date().toISOString().split("T")[0];
  const storedArrival = checkLocalStorage(date);
  const { val, filled, clearFilled, clearInput } = useKeyEvent(true);
  const reducer = makeReducer(clearFilled, clearInput);

  let initState = { ...initialState };
  if (storedArrival) {
    initState = { ...initState, ...storedArrival };
  }

  const arrivalReducer = useReducer(reducer, initState);
  const [{ list, entry, total, errors, scans }, dispatch] = arrivalReducer;
  const prevListLength = useRef(list.length);
  const urlEntry = encodeURIComponent(entry);

  const onSuccess = useCallback(
    (headers) => {
      const orderFlag = headers.get("x-order-flag");
      if (orderFlag !== null && orderFlag.toUpperCase() !== "NONE") {
        dispatch({ type: ARRIVAL_FLAG, payload: orderFlag.toUpperCase() });
      } else {
        dispatch({ type: ARRIVAL_FLAG, payload: "" });
      }
      clearInput();
      clearError();
      dispatch({ type: SCAN_SUCCESS });
    },
    [dispatch, clearError, clearInput],
  );
  const onFail = useCallback(
    (errMsg, headerOrderFlag) => {
      if (headerOrderFlag) {
        dispatch({
          type: SCAN_FAILURE,
          payload: { orderFlag: headerOrderFlag.toUpperCase() },
        });
        clearInput();
        triggerError(errMsg);

        return;
      }

      dispatch({ type: SCAN_FAILURE });
      clearInput();
      triggerError(errMsg);
    },
    [dispatch, triggerError, clearInput],
  );

  const onError = (errMsg) => {
    dispatch({ type: SCAN_FAILURE });
    clearInput();
    triggerError(errMsg);
  };

  const triggerFetch = useBooleanFetch(
    `arrival/tracking/${urlEntry}`,
    usertoken,
    onSuccess,
    onFail,
    onError,
  );

  // logs value for input
  useEffect(() => {
    dispatch({ type: SCAN_EVENT, payload: val });
  }, [val, dispatch]);

  // adds completed scan input to list.
  useEffect(() => {
    if (filled) {
      if (list.findIndex((item) => item === entry) > -1) {
        triggerError("Item already scanned");
      }
      clearFilled();
      dispatch({ type: ITEM_SCANNED });
    }
  }, [filled, clearFilled, triggerError, entry, list, dispatch]);

  // trigger fetch
  useEffect(() => {
    if (list.length > prevListLength.current) {
      triggerFetch();
    }
  }, [list.length, prevListLength, triggerFetch]);

  // set list ref
  useEffect(() => {
    prevListLength.current = list.length;
  });

  useEffect(() => {
    localStorage.setItem(
      `Arrival_${date}`,
      JSON.stringify({ total, errors, scans }),
    );
  }, [total, errors, scans, date]);

  return arrivalReducer;
};
export default useArrivalReducer;
