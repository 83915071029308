import { OnKeyEventFilled, useKeyEvent } from "../../../../hooks";
import useTimeout from "../../../../hooks/use-timeout";
import ReadonlyInput from "../../../components/text-inputs/readonly";

export interface FindOrderProps {
  error: boolean;
  errorMessage: string;
  callback: OnKeyEventFilled;
  clear: () => void;
}

export default function FindOrder({
  error,
  errorMessage,
  callback,
  clear,
}: FindOrderProps) {
  const { val, filled, clearFilled, clearInput } = useKeyEvent(true, callback);

  useTimeout(
    () => {
      clearFilled();
      clearInput();
      clear();
    },
    error,
    1500,
  );

  return (
    <ReadonlyInput
      value={val}
      onClick={clearFilled}
      isFocus={!filled}
      isError={error}
      errorMsg={errorMessage}
    />
  );
}
