/**
 * SVG Icon for Legacybox.
 * @param {Object} props
 * @param {string} [props.className]
 */
export const Legacybox = ({ className = "header-icon" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path id="a" d="M0 4.214h201.013v340.89H0z" />
        <path id="c" d="M1.142 4.214h235.492v340.89H1.142z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect fill="#666" width="1200" height="1200" rx="59.5" />
        <g transform="translate(308.847 427.087)">
          <mask id="b" fill="#fff">
            <use href="#a" />
          </mask>
          <path
            d="M-.002 13.47c0-4.865 3.884-9.258 9.23-9.258H67.01c4.85 0 9.225 4.393 9.225 9.258v260.05h115.56c5.34 0 9.219 4.387 9.219 9.252v53.08c0 4.878-3.878 9.251-9.218 9.251H9.229c-5.347 0-9.231-4.373-9.231-9.251V13.47z"
            fill="#FEFEFE"
            mask="url(#b)"
          />
        </g>
        <g transform="translate(652.937 427.087)">
          <mask id="d" fill="#fff">
            <use href="#c" />
          </mask>
          <path
            d="M121.077 275.465c18.925 0 34.952-15.583 34.952-35.546 0-19.485-20.877-34.086-39.809-34.086H76.882v69.632h44.195zm-6.313-132.939c18.449 0 33.986-15.59 33.986-35.068 0-19.485-15.537-33.117-33.986-33.117H76.882v68.185h37.882zM1.142 13.47c0-4.865 3.884-9.258 9.231-9.258h113.126c57.291 0 104.398 39.448 104.398 90.583 0 37.504-33.502 64.767-56.331 76.946 25.74 10.711 65.072 34.577 65.072 79.38 0 54.534-48.073 93.982-105.847 93.982H10.373c-5.347 0-9.23-4.38-9.23-9.251V13.47z"
            fill="#FEFEFE"
            mask="url(#d)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Legacybox;
