import { css, styled } from "../../../../stitches.config";
import Emoji from "../../../base/emogi";

import type { Api } from "../../../../types/api";

const listContainer = css({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "80%",
});

const List = styled("ul", {
  width: "90%",
  margin: "0 1rem",
  padding: 0,

  variants: {
    scrollable: {
      true: {
        maxHeight: "150px",
        overflowY: "scroll",
      },
    },
  },
});
const ListItem = styled("li", {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid $lightGray",

  variants: {
    header: {
      true: {
        borderBottom: "2px solid $black",
      },
    },
  },
});

export interface AlertListProps {
  alertsLoaded: boolean;
  alerts: Api.IShipmentAlert[];
}
export function AlertList({ alertsLoaded, alerts }: AlertListProps) {
  if (!alertsLoaded) {
    return (
      <article className={listContainer()}>Getting shipment alerts</article>
    );
  }

  if (alertsLoaded && alerts.length === 0) {
    return (
      <article className={listContainer()}>
        No Shipment alerts for this order
      </article>
    );
  }

  return (
    <section className={listContainer()}>
      <List>
        <ListItem header>
          <b>Reason</b>
          <b>Status</b>
        </ListItem>
      </List>
      <List scrollable>
        {alerts.map((alert) => (
          <ListItem key={alert.alert_id}>
            {alert.reason}{" "}
            <Emoji symbol={alert.active === 1 ? "❌" : "✅"} label="checked" />
          </ListItem>
        ))}
      </List>
    </section>
  );
}
