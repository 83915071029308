import { useEffect, useState, type Dispatch, type SetStateAction } from "react";

import useApi from "../../../hooks/use-api";
import Button from "../../base/button";
import Card from "../card";

import "./add-note.scss";

export interface AddNoteModalProps {
  displayState: [boolean, Dispatch<SetStateAction<boolean>>];
  path: string;
  onComplete?: (arg: boolean) => any;
}
export const AddNoteModal = ({ path, displayState }: AddNoteModalProps) => {
  const [showModal, setShow] = displayState;
  const [text, setText] = useState("");
  const [submitNote, setSubmitNote] = useState(false);
  const { status, loadingStates } = useApi(
    path,
    "post",
    submitNote,
    { "Content-Type": "application/json" },
    JSON.stringify({ note: text }),
  );
  useEffect(() => {
    if (status === loadingStates.RESOLVED) {
      setText("");
      setShow(false);
    }
  }, [status, setShow, loadingStates]);
  return showModal ? (
    <>
      <div className="add-note__overlay"></div>
      <section className="add-note__wrapper">
        <section className="add-note__card">
          <Card header="Add Note" size={"med"}>
            <section className="add-note__content">
              <textarea
                className={"add-note__text"}
                value={text}
                name={"notetext"}
                onChange={(event) => {
                  const text = event.target.value;
                  setText(text);
                }}
              />
              <div className="add-note__content-btns">
                <Button
                  children={"Cancel"}
                  onClick={() => {
                    setShow(false);
                    setText("");
                  }}
                  className="add-note__cancel-btn"
                />
                <Button
                  children={"Submit"}
                  onClick={() => {
                    setSubmitNote(true);
                    setShow(false);
                  }}
                  className="add-note__confirm-btn"
                />
              </div>
            </section>
          </Card>
        </section>
      </section>
    </>
  ) : (
    <></>
  );
};
export default AddNoteModal;
