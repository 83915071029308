import { useKeyEvent } from "../../../../hooks";
// Hooks
import { useLogin } from "../../../../hooks/context/login";
import {
  SET_ORDER,
  SET_ORDER_FIELDS,
} from "../../../../hooks/reducers/actions/safetyscans/order-info";
import { PAGE_STEP } from "../../../../hooks/reducers/actions/safetyscans/page";
import { appendSafetyScansFields } from "../../../../utils/order-helper";
import TextInput from "../../../base/text-input";

const { VITE_APP_API_URI } = import.meta.env;

const checkShippingAlerts = async (dispatch, usertoken, orderId) => {
  try {
    const salesOrderId = orderId.split(/[a-zA-Z]/)[0];
    const alertResult = await fetch(
      `https://${VITE_APP_API_URI}/shipments/alerts/${salesOrderId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
        }),
      },
    );

    const alerts = await alertResult.json();

    if (!alertResult.ok) {
      throw new Error(alerts.errorMessage);
    }

    if (alerts.hasActiveAlert) {
      dispatch({
        type: SET_ORDER_FIELDS,
        payload: { shipmentAlerts: alerts },
      });
    } else {
      await getOrderData(dispatch, usertoken, orderId);
    }
  } catch (error) {
    console.log(error);
  }
};

const getOrderData = async (dispatch, usertoken, orderId) => {
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/production/order/${orderId}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
        }),
      },
    );

    const payload = await response.json();

    if (!response.ok) {
      throw new Error(payload.errorMessage);
    }
    const { name, analogItems, outputs } = appendSafetyScansFields(payload);
    const isShipped = payload.is_final_shipped;

    dispatch({
      type: SET_ORDER,
      payload: { ...payload, analogItems, outputs, name, isShipped },
    });
    dispatch({
      type: PAGE_STEP,
    });
  } catch (error) {
    console.error(error);
  }
};

export const ReshipOrderScan = ({ state, dispatch }) => {
  const [{ usertoken }] = useLogin();
  const { val } = useKeyEvent(true);

  return (
    <section className="reship-order__order-scan">
      <h2>Scan safety barcode to begin.</h2>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          const orderId = val.split("-")[0];
          checkShippingAlerts(dispatch, usertoken, orderId);
        }}
      >
        <label>{"Barcode: "}</label>
        <TextInput
          className={"reship__barcode-input"}
          val={val}
          error={false}
          errorMsg={""}
        />
        <input
          type="submit"
          className={`reship__submit`}
          value={"Find Order"}
        />
      </form>
    </section>
  );
};
export default ReshipOrderScan;
