import { BackUpFromApi, FileTypeName } from "../hooks/reducers/order-view";
import { Api } from "../types/api";

export interface OrderInfoResponse {
  payload: Api.IProductionOrder;
}

type OrderInfoResponsePayload = OrderInfoResponse["payload"];
const getOrderName = (data: OrderInfoResponsePayload): string => {
  const { shipping_address } = data ?? {};
  return `${shipping_address?.firstname ?? ""} ${
    shipping_address?.lastname ?? ""
  }`;
};

const parseOrderItems = (payload: OrderInfoResponsePayload) => {
  const { items: productionOrderItems } = payload;
  const analogItems = productionOrderItems.filter(
    ({ product_type_id }) => product_type_id === 1,
  );

  const outputs = productionOrderItems.filter(
    ({ product_type_id }) => product_type_id === 2,
  );

  const categories = analogItems.reduce((all, curr) => {
    let category = curr.product_name;

    if (category === "Audio") {
      const format = curr.variant_name.includes("Reel") ? "Reels" : "Tapes";
      category = `${category}${format}`;
    }
    if (category === "Photo") {
      category = "Photos";
    }
    const complete = curr.process_completed_by != null;
    if (all.findIndex(({ name }) => name === category) === -1) {
      return [
        ...all,
        {
          name: category,
          complete,
        },
      ];
    }
    return all;
  }, []);

  return {
    analogItems,
    outputs,
    categories,
  };
};

export const isThumbdriveScanRequired = (
  order: OrderInfoResponse["payload"],
): boolean => {
  return (
    undefined !==
    order.items?.find(
      (item) =>
        item.item_barcode !== null && item.output_type === "Thumb Drive",
    )
  );
};

export const appendSafetyScansFields = (
  payload: OrderInfoResponse["payload"],
): Api.ISafetyScansOrderInfo => {
  return {
    ...payload,
    orderId: payload.sales_order_id.toString(),
    accessCode: payload.access_code,
    ...parseOrderItems(payload),
    name: getOrderName(payload),
    requireThumbdriveScan: isThumbdriveScanRequired(payload),
  } as Api.ISafetyScansOrderInfo;
};

interface orderBackupItem {
  fileType: string;
  barcode: string;
}
export const transformBackup = (data: BackUpFromApi): orderBackupItem[] =>
  Object.keys(data?.files ?? {})
    .map((fileType) =>
      data.files[fileType as FileTypeName].files.map((barcode) => {
        return { fileType, barcode };
      }),
    )
    .reduce((acc, item) => {
      return [...acc, ...item];
    }, []);
