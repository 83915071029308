import { useState } from "react";

import { useLogin } from "../../../../hooks/context/login";
import {
  RESET_ORDER,
  STEP_BACK_DISPLAY,
} from "../../../../hooks/reducers/admin/edit-formats";
import useTimeout from "../../../../hooks/use-timeout";
import { reprintSlip } from "../../../../utils/dymo-print";
import BaseButton from "../../../base/button";
import ItemList from "../../../components/item-list";

import "./review.scss";

const { VITE_APP_API_URI } = import.meta.env;

/**
 * @param {Object} props
 * @param {import("../edit-items/review").FlattenedScans[]} props.list - category list
 */
const ReviewPage = ({ list }) => {
  const itemCount = list.reduce((total, { quantity }) => total + quantity, 0);
  return (
    <section className="review-order__list">
      <h1 className="review-order__list-header">Analog Items</h1>
      <ItemList
        items={list}
        textField="format"
        totalQuantity={itemCount}
        pageSize={10}
      />
    </section>
  );
};

/**
 *
 * @param {import("../edit-items/review").FlattenedScans[]} items
 * @param {string} usertoken
 * @param {Object} state
 * @param {Function} dispatch
 * @param {Function} setPrinting
 */
export const handlePost = async (
  items,
  usertoken,
  state,
  dispatch,
  setPrinting,
  setPrintErrorWithTimeout,
) => {
  const { orderId, salesOrderId } = state;
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/admin/split-order/${orderId}/items`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          items,
          salesOrderId,
        }),
      },
    );
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    setPrintErrorWithTimeout(false);
    const printMsg = await reprintSlip(usertoken, orderId);
    if (printMsg?.err?.message) {
      let errMessage = `${response.status}: ${printMsg?.err?.message}`;
      errMessage =
        errMessage?.toLowerCase().indexOf("invalid order id") > -1
          ? "Invalid barcode"
          : errMessage;
      setPrinting(false);
      setPrintErrorWithTimeout(errMessage); // dispatches RESET_ORDER after..
      return;
    }
    dispatch({ type: RESET_ORDER });
  } catch (error) {
    console.log(error.message);
    setPrinting(false);
  }
};

export const Review = ({ state, dispatch }) => {
  const [{ usertoken }] = useLogin();
  const { categories } = state;
  const [printing, setPrinting] = useState(false);
  const [printError, setPrintErrorWithTimeout] = useState(false);

  useTimeout(() => dispatch({ type: RESET_ORDER }), !!printError, 2500);

  /** @type {import("../edit-items/review").FlattenedScans[]} */

  const formatsList = categories.flatMap(({ productId, category, formats }) =>
    formats
      .filter(({ quantity }) => quantity > 0)
      .map((i) => ({ ...i, productId, category })),
  );

  return (
    <section className="split-order__review">
      <ReviewPage list={formatsList} />
      {printError ? (
        <div className="text-input__error-msg">{printError}</div>
      ) : (
        <></>
      )}
      <section className="split-order__review-buttons">
        <BaseButton
          className="split-order__prev-page"
          onClick={() => dispatch({ type: STEP_BACK_DISPLAY })}
        >
          Back
        </BaseButton>
        <BaseButton
          className={`split-order__next-page ${printing ? "inactive" : ""}`}
          onClick={
            !printing
              ? () => {
                  setPrinting(true);
                  handlePost(
                    formatsList,
                    usertoken,
                    state,
                    dispatch,
                    setPrinting,
                    setPrintErrorWithTimeout,
                  );
                }
              : undefined
          }
        >
          Save
        </BaseButton>
      </section>
    </section>
  );
};

export default Review;
