import { useCallback, useState } from "react";
import useError from "../../hooks/use-error";
import NumberPad from "../groups/number-pad";

export interface AppPasswordProps {
  passcode: number;
  onError?: () => void;
  onSuccess: () => void;
}

const useAppPassword = (props: AppPasswordProps) => {
  const [pin, setPin] = useState<string>("");
  const error = useError();
  const { passcode, onError, onSuccess } = props;
  const { trigger } = error;

  const validatePin = useCallback(() => {
    if (pin !== passcode.toString()) {
      trigger("Wrong Pin.");
      onError?.();
    } else {
      onSuccess?.();
    }
  }, [onError, onSuccess, passcode, pin, trigger]);

  return { ...error, pin, setPin, validatePin };
};

export const AppPassword = (props: AppPasswordProps) => {
  const { pin, setPin, errState, errMsg, validatePin } = useAppPassword(props);

  return (
    <section className="scan-order__order">
      <NumberPad
        header={"Enter Pin:"}
        entry={`${pin}`}
        setEntry={setPin}
        maxLength={4}
        handleSubmit={validatePin}
        errState={errState}
        errMsg={errMsg}
      />
    </section>
  );
};

export default AppPassword;
