import { useReducer, Reducer } from "react";
import {
  InitialState,
  Actions,
  EditFormatsState,
  EditFormatsActions,
  ActionNoPayload,
} from "./edit-formats";
import { CategoryActions } from "../actions/receiving/category";

export interface SplitOrderState extends EditFormatsState {
  accessCode?: string;
  salesOrderId: string;
  productionOrderId?: number;
}

interface SplitOrderAction {
  type: "SET_SPLIT_ORDER_DATA";
  payload: SetSplitOrderDataPayload;
}

export type TSplitOrderActions =
  | CategoryActions
  | SplitOrderAction
  | EditFormatsActions;

export const SET_SPLIT_ORDER_DATA = "SET_SPLIT_ORDER_DATA";
export const STEP_DISPLAY = "STEP_DISPLAY";

export const SplitOrderInitialState: SplitOrderState = {
  ...InitialState,
  accessCode: null,
  salesOrderId: null,
};

interface SetSplitOrderDataPayload {
  productionOrderId: string;
  salesOrderId: string;
  accessCode: string;
}

export const setSplitOrderData = (
  state: SplitOrderState,
  {
    payload: { productionOrderId, salesOrderId, accessCode },
  }: {
    payload: SetSplitOrderDataPayload;
  },
): SplitOrderState => ({
  ...state,
  orderId: productionOrderId,
  salesOrderId,
  accessCode,
});

const SplitOrderActions = {
  ...Actions,
  SET_SPLIT_ORDER_DATA: setSplitOrderData,
};

export const reducer: Reducer<SplitOrderState, TSplitOrderActions> = (
  state,
  action,
): SplitOrderState => {
  const act = SplitOrderActions[(action as ActionNoPayload).type];
  const update = act(state, action as any);

  return { ...state, ...update };
};

export const useSplitOrderReducer = () =>
  useReducer<Reducer<SplitOrderState, TSplitOrderActions>>(
    reducer,
    SplitOrderInitialState,
  );
export default useSplitOrderReducer;
