import { Navigate, type PathRouteProps, useLocation } from "react-router-dom";

// Hooks
import { useLogin } from "../hooks/context/login";
// Pages
import Unauthorized from "./pages/unauthorized";

interface PrivateRouteProps extends PathRouteProps {
  children: React.ReactNode;
  isAdminRoute?: boolean;
}

export const PrivateRoute = ({
  children,
  isAdminRoute = false,
}: PrivateRouteProps) => {
  const [{ loggedIn, usergroup, redirectHome }] = useLogin();
  const location = useLocation();

  if (!loggedIn) {
    return (
      <Navigate
        to="/login"
        state={{
          from: redirectHome ? "/" : location,
          isRedirect: true,
        }}
      />
    );
  }

  if (isAdminRoute && usergroup !== "Administrator") {
    return <Unauthorized />;
  }

  return children;
};

export default PrivateRoute;
