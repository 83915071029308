import { useState } from "react";

import "./arrival.scss";

import ScanBox from "./arrival/scan-box";
import GetStarted from "./arrival/get-started";
import AppName from "../components/app-name";

export const Arrival = () => {
  const [active, setActive] = useState(false);

  const activateClick = () => {
    setActive(true);
  };
  const finishedClick = () => {
    setActive(false);
  };

  return (
    <>
      <AppName>Arrival</AppName>
      {active ? (
        <ScanBox onClick={finishedClick} />
      ) : (
        <GetStarted onClick={activateClick} />
      )}
    </>
  );
};

export default Arrival;
