import { Api } from "../../../../types/api";
import { isThumbdriveScanRequired } from "../../../../utils/order-helper";
import { BackUpFromApi } from "../../order-view";

export const SET_ORDER = "SET_ORDER";
export const SCAN_ITEM = "SCAN_ITEM";
export const ADD_DAMAGED_ITEM = "ADD_DAMAGED_ITEM";
export const REMOVE_DAMAGED_ITEM = "REMOVE_DAMAGED_ITEM";
export const NOTIFIED_CUSTOMER = "NOTIFIED_CUSTOMER";
export const DEPTS_COMPLETE = "DEPTS_COMPLETE";
export const COMPLETE_ORDER = "COMPLETE_ORDER";
export const ADD_FILM_WITH_SOUND = "ADD_FILM_WITH_SOUND";
export const REMOVE_FILM_WITH_SOUND = "REMOVE_FILM_WITH_SOUND";
export const SET_ORDER_FIELDS = "SET_ORDER_FIELDS";
export const SET_IS_GDRIVE_VISITED = "SET_IS_GDRIVE_VISITED";
export const SET_BACKUP_FILES = "SET_BACKUP_FILES";

export const getBrand = (brandId: number) => {
  switch (brandId) {
    case 1:
      return "southtree";
    case 2:
      return "legacybox";
    case 3:
      return "kodak";
    default:
      return "southtree";
  }
};

export const setOrderInfo = (
  { orderInfo }: OrderInfo,
  { payload }: { payload: Api.ISafetyScansOrderInfo },
) => {
  return {
    orderInfo: {
      ...orderInfo,
      orderId: payload.production_order_id,
      salesOrderId: payload.sales_order_id,
      accessCode: payload.access_code,
      name: payload.name,
      brand: payload.brand_name?.toLowerCase() ?? "",
      isComplete: payload.is_complete,
      isCanceled: payload.is_canceled,
      isReturn: payload.is_return,
      isRush: payload.is_rush,
      isValidOrder: payload.is_valid && payload.is_canceled === false,
      isShipped: payload.is_final_shipped,
      hasOutputThumbdrive: payload.has_output_thumbdrive,
      requireThumbdriveScan: isThumbdriveScanRequired(payload),
      analogItems: payload.analogItems.map((item) => {
        return { ...item, scanned: false };
      }),
      outputs: payload.outputs,
      additionalOutputs: payload.production_order_id?.includes("ADD"),
      categories: payload.categories,
      driveLink: payload.drive_link || null,
    },
  };
};

export const setIsGDriveVisited = (
  { orderInfo }: OrderInfo,
  { payload }: OrderInfoFieldPayload,
) => ({
  orderInfo: {
    ...orderInfo,
    isGDriveVisited: payload,
  },
});

export const getSafetyscansDisplayPageNum = (isAppQA: boolean) =>
  isAppQA ? 20 : 25;

export const scanItem = (
  { orderInfo }: OrderInfo,
  { scan, isAppQA }: { scan: string; isAppQA?: boolean },
) => {
  const { analogItems } = orderInfo;
  const index = analogItems.findIndex(
    ({ item_barcode }) => item_barcode === scan,
  );
  if (index === -1) {
    return;
  }

  const newItem = {
    ...analogItems[index],
    scanned: true,
  };

  let newItems = [];
  // Reorder items to show unscanned items first
  // if there are multiple pages
  if (analogItems.length > getSafetyscansDisplayPageNum(isAppQA)) {
    const unscannedItems = [
      ...analogItems.slice(0, index),
      ...analogItems.slice(index + 1),
    ].filter(({ scanned }) => !scanned);

    const scannedItems = [
      ...analogItems.filter(({ scanned }) => scanned),
      newItem,
    ].sort((a, b) => (a.item_barcode > b.item_barcode ? 1 : -1));

    newItems = [...unscannedItems, ...scannedItems];
  } else {
    newItems = [
      ...analogItems.slice(0, index),
      newItem,
      ...analogItems.slice(index + 1),
    ];
  }

  return {
    orderInfo: {
      ...orderInfo,
      analogItems: newItems,
    },
  };
};

export const addDamagedItem = (
  { orderInfo }: OrderInfo,
  { barcode, damageReason }: DamagedItem,
) => {
  const { damagedItems } = orderInfo;
  const newItem = {
    barcode,
    damageReason,
  };

  return {
    orderInfo: {
      ...orderInfo,
      damagedItems: [...damagedItems, newItem],
    },
  };
};

export const removeDamagedItem = (
  { orderInfo }: OrderInfo,
  { scan }: OrderScanParam,
) => {
  const { damagedItems } = orderInfo;
  const index = damagedItems.findIndex(
    (item) => item.barcode === scan || item.damageReason === scan,
  );
  if (index === -1) {
    return { orderInfo };
  }

  return {
    orderInfo: {
      ...orderInfo,
      damagedItems: [
        ...damagedItems.slice(0, index),
        ...damagedItems.slice(index + 1),
      ],
    },
  };
};

export const addFilmWithSound = (
  { orderInfo }: OrderInfo,
  { itemBarcode }: FilmWithSoundScanParam,
) => {
  const { filmWithSound, hasLoggedFilmWithSound } = orderInfo;
  if (hasLoggedFilmWithSound) {
    return { orderInfo };
  }
  const existingScanIndex = filmWithSound.findIndex(
    ({ barcode }) => itemBarcode === barcode,
  );
  if (existingScanIndex !== -1) {
    return { orderInfo };
  }
  const newItem = {
    barcode: itemBarcode,
  };
  return {
    orderInfo: {
      ...orderInfo,
      filmWithSound: [...filmWithSound, newItem],
    },
  };
};

export const removeFilmWithSound = (
  { orderInfo }: OrderInfo,
  { scan }: OrderScanParam,
) => {
  const { filmWithSound, hasLoggedFilmWithSound } = orderInfo;
  if (hasLoggedFilmWithSound) {
    return { orderInfo };
  }
  const index = filmWithSound.findIndex((item) => item.barcode === scan);
  if (index === -1) {
    return { orderInfo };
  }

  return {
    orderInfo: {
      ...orderInfo,
      filmWithSound: [
        ...filmWithSound.slice(0, index),
        ...filmWithSound.slice(index + 1),
      ],
    },
  };
};

export const setLoggedDamagedItems = ({ orderInfo }: OrderInfo) => {
  return {
    orderInfo: {
      ...orderInfo,
      hasLoggedDamagedItems: true,
    },
  };
};

export const setDeptsComplete = (
  { orderInfo }: OrderInfo,
  { complete }: SetCompleteParam,
) => {
  return {
    orderInfo: {
      ...orderInfo,
      deptsComplete: complete,
    },
  };
};

export const setOrderComplete = (
  { orderInfo }: OrderInfo,
  { complete }: SetCompleteParam,
) => {
  return {
    orderInfo: {
      ...orderInfo,
      completedOrder: complete,
    },
  };
};

export const setOrderInfoFields = (
  { orderInfo }: OrderInfo,
  { payload }: OrderInfoFieldPayload,
) => {
  return {
    orderInfo: {
      ...orderInfo,
      ...payload,
    },
  };
};
export const setBackupFiles = (
  { orderInfo }: OrderInfo,
  { payload }: { payload: BackUpFromApi },
) => {
  return {
    orderInfo: {
      ...orderInfo,
      backupFiles: payload,
    },
  };
};

export const resetOrder = () => {
  return { ...orderInfoInitialState };
};

export const orderInfoInitialState: OrderInfo = {
  orderInfo: {
    orderId: null,
    salesOrderId: null,
    accessCode: null,
    name: null,
    brand: null,
    isCanceled: null,
    isReturn: null,
    isValidOrder: null,
    isShipped: null,
    isComplete: null,
    isBalanceDue: null,
    hasLoggedBalanceDue: false,
    requireThumbdriveScan: false,
    hasOutputThumbdrive: false,
    analogItems: null,
    outputs: [],
    categories: null,
    hasLoggedDamagedItems: false,
    damagedItems: [],
    hasLoggedFilmWithSound: false,
    filmWithSound: [],
    photosQuantity: null,
    completedOrder: null,
    additionalOutputs: null,
    driveLink: null,
    isGDriveVisited: false,
    backupFiles: null,
  },
};

export const orderInfoActions = {
  SET_ORDER: setOrderInfo,
  SET_IS_GDRIVE_VISITED: setIsGDriveVisited,
  SCAN_ITEM: scanItem,
  ADD_DAMAGED_ITEM: addDamagedItem,
  REMOVE_DAMAGED_ITEM: removeDamagedItem,
  NOTIFIED_CUSTOMER: setLoggedDamagedItems,
  DEPTS_COMPLETE: setDeptsComplete,
  RESET_ORDER: resetOrder,
  COMPLETE_ORDER: setOrderComplete,
  ADD_FILM_WITH_SOUND: addFilmWithSound,
  REMOVE_FILM_WITH_SOUND: removeFilmWithSound,
  SET_ORDER_FIELDS: setOrderInfoFields,
  SET_BACKUP_FILES: setBackupFiles,
};
export type OrderInfo = {
  orderInfo: OrderInfoState;
};
export type OrderInfoFieldPayload = {
  payload: Partial<OrderInfoState>;
};
export type OrderScanParam = {
  scan: string;
};
export type FilmWithSoundScanParam = {
  itemBarcode: string;
};
export type SetCompleteParam = {
  complete: boolean;
};
export interface OrderInfoState {
  accessCode: string;
  additionalOutputs: true;
  analogItems: AnalogItem[];
  brand: string;
  categories: SafetyScansCategory[];
  completedOrder: boolean;
  damagedItems: DamagedItem[];
  deptsComplete?: boolean;
  driveLink: string;
  filmWithSound: FilmWithSound[];
  hasLoggedBalanceDue: boolean;
  hasLoggedDamagedItems: boolean;
  hasLoggedFilmWithSound: boolean;
  hasOutputThumbdrive: boolean;
  requireThumbdriveScan: boolean;
  isBalanceDue: boolean;
  isCanceled: boolean;
  isComplete: boolean;
  isGDriveVisited: boolean;
  isReturn: boolean;
  isShipped: boolean;
  isValidOrder: boolean;
  name: string;
  orderId: string;
  outputs: Api.ISafetyScansOrderItem[];
  photosQuantity: number;
  salesOrderId: number;
  backupFiles: BackUpFromApi;
  shipmentAlerts?: { alerts: Api.IShipmentAlert[]; hasActiveAlert: boolean };
}

export interface SafetyScansCategory {
  name: string;
  complete: boolean;
}
export interface FilmWithSound {
  barcode: string;
}

export interface DamagedItem {
  barcode: string;
  damageReason: string;
}

export interface AnalogItem extends Api.ISafetyScansItem {
  scanned: boolean;
}
