import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";

// Hooks
import { LoginProvider } from "../hooks/context/login";

// Components
import PrivateRoute from "./private-route";
import { RouteGroup } from "./route-group";
import Layout from "@routes/_layout";

const router = createBrowserRouter([
  { element: <Layout />, children: [{ path: "*", Component: AppBody }] },
]);

function AppBody() {
  return (
    <Routes>
      {RouteGroup.map(({ page, isPrivateRoute, isAdminRoute, ...rest }, idx) =>
        isPrivateRoute ? (
          <Route
            key={idx}
            element={
              <PrivateRoute isAdminRoute={isAdminRoute}>{page}</PrivateRoute>
            }
            {...rest}
          />
        ) : (
          <Route key={idx} element={page} {...rest} />
        ),
      )}
    </Routes>
  );
}

export function App() {
  return (
    <LoginProvider>
      <RouterProvider router={router} />
    </LoginProvider>
  );
}
export default App;
