import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Hooks
import { useLogin } from "../../hooks/context/login";
import { LOGOUT } from "../../hooks/reducers/login";
import BaseButton from "../base/button";
import Icon from "../base/icon";
// Components
import AppButton from "../components/app-button";
import { AdminApps } from "../pages/admin";
import { RouteGroup } from "../route-group";

import "./app-drawer.scss";

export interface AppDrawerProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const AppDrawer = ({ show, setShow }: AppDrawerProps) => {
  const location = useLocation();
  const home = location.pathname === "/";
  const [{ username, usergroup }, loginDispatch] = useLogin();
  const isAdmin = usergroup === "Administrator";
  const isAdminPage = location.pathname.startsWith("/admin");
  // const isAdmin = false;
  const [showAdminApps, setShowAdminApps] = useState(isAdminPage);

  const closeDrawer = useCallback(() => setShow(false), [setShow]);
  const logout = () => {
    loginDispatch({ type: LOGOUT });
    closeDrawer();
  };
  const toggleAdmin = () => setShowAdminApps((show) => !show);

  return (
    <>
      <section className={`app-drawer__wrapper ${show ? "show" : ""}`}>
        <BaseButton onClick={closeDrawer} className="app-drawer__close">
          x
        </BaseButton>
        <section className={"app-drawer__main"}>
          <div className={"app-drawer__user-info"}>
            <Icon name="avatar" />
            {username}
          </div>
          <section className={"app-drawer__utility-btns"}>
            {home ? (
              <BaseButton onClick={closeDrawer} className="app-drawer__go-home">
                GO HOME
              </BaseButton>
            ) : (
              <NavLink
                onClick={closeDrawer}
                className="app-drawer__go-home"
                to={"/"}
              >
                GO HOME
              </NavLink>
            )}
            <BaseButton onClick={logout} className="app-drawer__logout">
              LOGOUT
            </BaseButton>
          </section>
          {isAdmin && (
            <BaseButton
              onClick={toggleAdmin}
              className="app-drawer__show-admin-apps"
            >
              {showAdminApps ? "Show All Apps" : "Show Admin Apps"}
            </BaseButton>
          )}
          <section className={"app-drawer__app-btns"}>
            {!showAdminApps
              ? RouteGroup.filter((r) => {
                  if (r.path === "/" || r.path === "/login") {
                    return false;
                  } else if ("isAdminRoute" in r === false) {
                    return true;
                  } else if (isAdmin && !isAdminPage) {
                    return true;
                  }
                  return false;
                }).map((route, idx) => (
                  <AppButton key={idx} {...route} onClick={closeDrawer} />
                ))
              : AdminApps.map((route, idx) => (
                  <AppButton key={idx} {...route} onClick={closeDrawer} />
                ))}
          </section>
        </section>
      </section>
      <section
        className={`app-drawer__overlay ${show ? "show" : ""}`}
      ></section>
    </>
  );
};

export default AppDrawer;
