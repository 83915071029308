import { Dispatch, useReducer } from "react";
import { Api } from "../../types/api/discs";

const CHOOSE_FORMAT = "CHOOSE_FORMAT";
const SET_ORDER = "SET_ORDER";
const ADD_ORDER = "ADD_ORDER";

export const discActions = { CHOOSE_FORMAT, SET_ORDER, ADD_ORDER } as const;

type TBrandName = "southtree" | "legacybox" | "kodak";
export type TDiscCategory = "Audio" | "Tapes" | "Film" | "Photos";

type ChooseFormatAction = {
  type: typeof discActions.CHOOSE_FORMAT;
  discType: string;
  discCategory: TDiscCategory;
};
type AddOrderAction = {
  type: typeof discActions.ADD_ORDER;
  payload: Api.Discs.IDiscs;
};
type SetOrderAction = {
  type: typeof discActions.SET_ORDER;
  orderId: string;
};
type DiscActions = ChooseFormatAction | AddOrderAction | SetOrderAction;
/**
 * Converts brand id to brand name
 * @param brandId numeric brand id
 * @returns brand name
 */
export const getBrand = (brandId: number): TBrandName => {
  switch (brandId) {
    case 1:
      return "southtree";
    case 2:
      return "legacybox";
    case 3:
      return "kodak";
    default:
      return "southtree";
  }
};

interface IDiscState {
  order: {
    orderId: number | null;
    name: string | null;
    brandId: number | null;
    brand: string | null;
  };
  orderNumber: string | null;
  activeStep: number;
  discType: string | null;
  discCategory: TDiscCategory | null;
  discQuantity: number;
  items: any[];
}

export const initialState: IDiscState = {
  order: {
    orderId: null,
    name: null,
    brandId: null,
    brand: null,
  },
  orderNumber: null,
  activeStep: 0,
  discType: null,
  discCategory: null,
  discQuantity: 0,
  items: [],
};

const chooseFormat = (
  state: IDiscState,
  { discType, discCategory }: ChooseFormatAction,
) => {
  let newState = { ...state, discType, discCategory };
  if (state.activeStep > 0) {
    return newState;
  }
  return { ...newState, activeStep: 1 };
};

export const setOrder = (
  state: IDiscState,
  { orderId }: SetOrderAction,
): IDiscState => ({
  ...state,
  orderNumber: orderId,
});

export const addOrder = (state: IDiscState, { payload }: AddOrderAction) => {
  const { orderId, name, brand_id, quantity, items } = payload;
  const order = {
    orderId,
    name,
    brandId: brand_id,
    brand: getBrand(brand_id),
  };
  return { ...state, order, items, discQuantity: quantity, activeStep: 2 };
};

export const reducer = (state: IDiscState, action: DiscActions): IDiscState => {
  console.log(action);
  switch (action.type) {
    case discActions.CHOOSE_FORMAT:
      return chooseFormat(state, action);
    case discActions.SET_ORDER:
      return setOrder(state, action);
    case discActions.ADD_ORDER:
      return addOrder(state, action);
    default:
      throw new Error("No matching action found");
  }
};

export const useDiscReducer = () => useReducer(reducer, initialState);
export interface IDiscReducerProps {
  state: IDiscState;
  dispatch: Dispatch<DiscActions>;
}

export default useDiscReducer;
