import { useEffect, useRef } from "react";

export interface TimeOutCallback {
  (): void;
}
/** Wraps imperitive setTimeout code */
export default function useTimeout(
  callback: TimeOutCallback,
  trigger: boolean = false,
  delay: number = 1000,
) {
  const savedCallback = useRef<TimeOutCallback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (trigger) {
      const timeout = setTimeout(() => savedCallback.current(), delay);
      return () => clearTimeout(timeout);
    }
  }, [trigger, delay]);
}
