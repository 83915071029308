import { ToastContainer } from "react-toastify";
import { useServiceWorker } from "@hooks/use-service-worker";
import UserHeader from "@ui/groups/user-header";
import Icon from "@ui/base/icon";

import "react-toastify/dist/ReactToastify.css";
import "@ui/app.scss";
import { Outlet } from "react-router-dom";
export function Layout() {
  useServiceWorker();

  return (
    <>
      <ToastContainer limit={1} />
      <section className="home__header">
        <UserHeader />
      </section>
      <section className="home__home-icon">
        <Icon name={"home"} />
      </section>
      <Outlet />
    </>
  );
}

export default Layout;
