import { useState, useEffect, Dispatch } from "react";

import "./return-print.scss";

import NumberPad from "../../groups/number-pad";
import useError from "../../../hooks/use-error";
import { PAGE_STEP } from "../../../hooks/reducers/actions/receiving/page";
import { printItemBarcodes } from "../../../utils/dymo-print";
import { useDymoService } from "../../../hooks/use-dymo-service";

const PrintState = {
  IDLE: 0,
  PRINTING: 1,
  PRINT_COMPLETE: 2,
} as const;

export type ReturnPrintProps = {
  productionOrderId: string;
  dispatch: Dispatch<any>;
};
export const ReturnPrint = ({
  productionOrderId,
  dispatch,
}: ReturnPrintProps) => {
  const [quantity, setQuantity] = useState("");
  const [print, setPrint] = useState<number>(PrintState.IDLE);
  const [max, setMax] = useState<number>(1);

  const { errState, errMsg, trigger, clear } = useError();
  useDymoService(() => trigger("Dymo failed to initialize"));

  const submit = () => {
    if (print > PrintState.IDLE) {
      return;
    }
    clear();
    setPrint(PrintState.PRINTING);

    const amount = Number(quantity);
    if (amount < 1) {
      trigger("Must request barcodes");
      setQuantity("");
      return;
    }

    setMax(amount);
  };

  useEffect(() => {
    if (print !== PrintState.PRINTING) {
      return;
    }

    const printCodes = async () => {
      const printed = await printItemBarcodes(1, max, productionOrderId);

      if (printed.printComplete === true) {
        setPrint(PrintState.PRINT_COMPLETE);
        setTimeout(() => {
          dispatch({ type: PAGE_STEP, step: 1 });
        }, 3000);
      } else {
        trigger(printed.err.message);
      }
    };
    printCodes();
  }, [productionOrderId, print, max, trigger, dispatch]);

  return (
    <NumberPad
      header={"Enter # of inputs"}
      entry={quantity}
      setEntry={setQuantity}
      maxLength={3}
      handleSubmit={print === PrintState.IDLE ? submit : () => {}}
      errState={errState}
      errMsg={errMsg}
    />
  );
};

export default ReturnPrint;
