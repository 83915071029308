import { css } from "../../../../stitches.config";
import Card from "../../../groups/card";
import { AlertList } from "./list";

import type { ReactNode } from "react";
import type { Api } from "../../../../types/api";

const alertsContent = css({
  height: "100%",
  width: "100%",
  alignItems: "flex-start",
  marginTop: "2rem",
});

export interface AlertsCardProps {
  alertsLoaded: boolean;
  alerts: Api.IShipmentAlert[];
  children?: ReactNode;
}
export function AlertsCard({
  alertsLoaded,
  alerts,
  children,
}: AlertsCardProps) {
  return (
    <Card header="ShipmentAlerts" size="full">
      <section className={alertsContent()}>
        <AlertList alertsLoaded={alertsLoaded} alerts={alerts} />
        {children}
      </section>
    </Card>
  );
}
