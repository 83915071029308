import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import {
  type OrderInfoState,
  SET_ORDER_FIELDS,
} from "../../../hooks/reducers/actions/safetyscans/order-info";
import { PAGE_NAV } from "../../../hooks/reducers/actions/safetyscans/page";
import { SET_SHIPPING } from "../../../hooks/reducers/actions/safetyscans/shipping";
// Hooks
import { LOGOUT } from "../../../hooks/reducers/login";
// Utils
import { printAltSlip } from "../../../utils/dymo-print";
import BaseButton from "../../base/button";
import YesNoButtons from "../../components/yes-no-buttons";
import GoAgain from "../../groups/go-again";
import AddNoteModal from "../../groups/modal/add-note";
import { ShowPALCheck } from "./show-pal-check";

import "./review-order.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

export interface BalanceDueProps {
  usertoken: string;
  orderId: string;
  accessCode: string;
  name: string;
  username: string;
  dispatch: Dispatch<any>;
}
const BalanceDue = ({
  usertoken,
  orderId,
  accessCode,
  name,
  username,
  dispatch,
}: BalanceDueProps) => {
  const [printing, setPrinting] = useState(false);
  const [printedSlip, setPrintedSlip] = useState(false);

  return !printedSlip ? (
    <>
      <h1 className={"safetyscans-review-order__header"}>
        {username}, your work has been logged.
      </h1>
      <h1 className={"safetyscans-review-order__header"}>
        This order has a balance due.
      </h1>
      <BaseButton
        className={`safetyscans-review-order__buttons${
          printing ? " inactive" : ""
        }`}
        onClick={
          !printing
            ? async () => {
                setPrinting(true);
                const result = await printAltSlip(
                  usertoken,
                  "ALT",
                  "BALANCE DUE",
                  orderId,
                  accessCode,
                  name,
                );
                setPrinting(false);
                if (result.printComplete) {
                  setPrintedSlip(true);
                }
              }
            : null
        }
      >
        GOT IT
      </BaseButton>
    </>
  ) : (
    <GoAgain dispatch={dispatch} />
  );
};

export interface NeedsProcessingProps {
  username: string;
  dispatch: Dispatch<any>;
  isRunning: boolean;
}

const NeedsProcessing = ({
  username,
  dispatch,
  isRunning,
}: NeedsProcessingProps) => (
  <>
    <h1 className={"safetyscans-review-order__header"}>
      {username}, your work has been logged.
    </h1>
    <h2 className={"safetyscans-review-order__subheader"}>
      Send to the next department for processing.
    </h2>
    <GoAgain dispatch={dispatch} isRunning={isRunning} />
  </>
);
export interface ReadyToShipProps {
  username: string;
  onClick: Dispatch<SetStateAction<boolean>>;
  loginDispatch: Dispatch<any>;
  dispatch: Dispatch<any>;
  hasTape: number;
  sendReorder: boolean;
}

const ReadyToShip = ({
  username,
  loginDispatch,
  dispatch,
  hasTape,
  sendReorder,
}: ReadyToShipProps) => {
  const [isPALCheck, setisPALCheck] = useState(false);
  if (isPALCheck && hasTape > 0) {
    return (
      <>
        <ShowPALCheck dispatch={dispatch} sendReorder={sendReorder} />
      </>
    );
  }
  return (
    <>
      <h1 className={"safetyscans-review-order__header"}>
        {username}, your work has been logged.
      </h1>
      <h2 className={"safetyscans-review-order__subheader"}>
        Do you want to ship this order now?
      </h2>
      <YesNoButtons
        yesClick={() => {
          if (hasTape > 0) setisPALCheck(true);
          else {
            if (sendReorder === true)
              dispatch({ type: PAGE_NAV, step: "Reorder Activate" });
            else dispatch({ type: PAGE_NAV, step: "Ship Order" });
          }
        }}
        noClick={() => loginDispatch({ type: LOGOUT })}
      />
    </>
  );
};

export interface ReviewOrderProps {
  orderInfo: OrderInfoState;
  dispatch: Dispatch<any>;
}
export const ReviewOrder = ({ orderInfo, dispatch }: ReviewOrderProps) => {
  const { deptsComplete } = orderInfo;
  const [{ username, usertoken }, loginDispatch] = useLogin();
  const [balanceDue, setBalanceDue] = useState(orderInfo.isBalanceDue);
  const [goToShipping, setGotoShipping] = useState(null);
  const addNoteModalState = useState(false);
  const [showNoteModal, setShowNoteModal] = addNoteModalState;

  // Check Balance Due on load
  useEffect(() => {
    if (!deptsComplete || balanceDue !== null) {
      return;
    }

    const checkBalanceDue = async () => {
      try {
        const invoiceResult = await fetch(
          `https://${VITE_APP_API_URI}/balancedue/${orderInfo.salesOrderId}`,
          {
            method: "GET",
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
            }),
          },
        );

        const invoices = await invoiceResult.json();

        if (!invoiceResult.ok) {
          throw new Error(invoices.errorMessage);
        }

        setBalanceDue(invoices.balance_due);
        dispatch({
          type: SET_ORDER_FIELDS,
          payload: { isBalanceDue: invoices.balance_due },
        });
      } catch (error) {
        console.log(error);
      }
    };

    checkBalanceDue();
  }, [
    deptsComplete,
    balanceDue,
    orderInfo.salesOrderId,
    setBalanceDue,
    usertoken,
    dispatch,
  ]);

  // Get shipping info and go to shipping pages
  useEffect(() => {
    if (goToShipping !== true) {
      return;
    }

    const getShippingInfo = async () => {
      try {
        const req = await fetch(
          `https://${VITE_APP_API_URI}/shipments/${orderInfo.salesOrderId}`,
          {
            method: "GET",
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
            }),
          },
        );
        const payload = await req.json();
        if (!req.ok) {
          throw new Error(payload.errorMessage);
        }
        dispatch({ type: SET_SHIPPING, payload });
        dispatch({ type: PAGE_NAV, step: "Ship Order" });
      } catch (error) {
        console.log(error);
      }
    };

    getShippingInfo();
  }, [goToShipping, orderInfo.salesOrderId, usertoken, dispatch]);

  // Mark as failed to ship because of balance due
  useEffect(() => {
    if (
      balanceDue !== true ||
      deptsComplete !== true ||
      orderInfo.hasLoggedBalanceDue
    ) {
      return;
    }

    const failedToShip = async () => {
      try {
        const req = await fetch(
          `https://${VITE_APP_API_URI}/balancedue/${orderInfo.salesOrderId}/shipping`,
          {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${usertoken}`,
              "Content-Type": "application/json",
            }),
          },
        );
        const payload = await req.json();

        if (!req.ok) {
          throw new Error(payload.errorMessage);
        }
        dispatch({
          type: SET_ORDER_FIELDS,
          payload: { hasLoggedBalanceDue: true },
        });
      } catch (error) {
        console.log(error);
      }
    };

    failedToShip();
  }, [
    balanceDue,
    deptsComplete,
    orderInfo.salesOrderId,
    usertoken,
    orderInfo.hasLoggedBalanceDue,
    dispatch,
  ]);
  const tapeItems = orderInfo.analogItems.filter(
    (item: any) => item?.product_name === "Tapes",
  );
  return (
    <section className={"safetyscans-review-order__wrapper"}>
      {!deptsComplete && (
        <NeedsProcessing
          username={username}
          dispatch={dispatch}
          isRunning={!showNoteModal}
        />
      )}
      {deptsComplete && balanceDue === false && (
        <ReadyToShip
          username={username}
          {...orderInfo}
          onClick={setGotoShipping}
          loginDispatch={loginDispatch}
          dispatch={dispatch}
          hasTape={tapeItems?.length}
          sendReorder={
            orderInfo.brand.toLowerCase() === "legacybox" && !orderInfo.isReturn
              ? true
              : false
          }
        />
      )}
      {deptsComplete && balanceDue === true && (
        <BalanceDue
          {...orderInfo}
          usertoken={usertoken}
          username={username}
          dispatch={dispatch}
        />
      )}
      <AddNoteModal
        displayState={addNoteModalState}
        path={`timeline-v2/safetyscans/${orderInfo.salesOrderId}/custom`}
      />
      <button
        className="safetyscans-review-order__add-note-btn"
        onClick={() => {
          setShowNoteModal(true);
        }}
      >
        {"Add Note"}
      </button>
    </section>
  );
};

export default ReviewOrder;
