import {
  ADD_UPGRADE_CLICK,
  REMOVE_UPGRADE,
} from "../../hooks/reducers/actions/receiving/upgrades";
import BaseButton from "../base/button";
import TextInput from "../base/text-input";
import UpgradeIndicator from "./upgrade-indicator";

import "./upgrade-container.scss";

interface UpgradeContainerProps {
  /** Title marker */
  textContent: string;
  /** Total number of Upgrade/Outputs of type */
  quantity: number;
  /** Notes if upgrades were scanned */
  upgrade: boolean;
  /** Assigned barcode value for input display */
  val: string;
  /** Short code for output (upgrades keys: 'dd', 'td, 'dv) */
  upgradeType: string;
  /** dispatch function for reducer */
  dispatch: (action: { type: string; upgradeType: string }) => void;
  /** Array of upgrade outputs */
  outputs: { output_type: string; quantity: number }[];
}

export const UpgradeContainer = ({
  outputs,
  textContent,
  quantity,
  upgrade,
  val,
  upgradeType,
  dispatch,
}: UpgradeContainerProps) => {
  const outputIndex = outputs.findIndex(
    ({ output_type }) => output_type === textContent,
  );

  let displayQuantity = Number(quantity);
  if (outputIndex > -1) {
    displayQuantity += Number(outputs[outputIndex].quantity);
  }

  return (
    <>
      <UpgradeIndicator
        textContent={textContent}
        quantity={displayQuantity}
        upgrade={upgrade}
      />
      {upgrade ? (
        <div className="upgrade-container__text-input-wrapper">
          <BaseButton
            className="upgrade-container__text-input-btn"
            onClick={(event) => {
              event.currentTarget.blur();
              dispatch({ type: REMOVE_UPGRADE, upgradeType });
            }}
          >
            X
          </BaseButton>
          <TextInput val={val} className="upgrade-container__text-input" />
        </div>
      ) : (
        <BaseButton
          children={"Add"}
          onClick={(event) => {
            event.currentTarget.blur();
            dispatch({ type: ADD_UPGRADE_CLICK, upgradeType });
          }}
          className={"upgrade-container__add-button"}
        />
      )}
    </>
  );
};

export default UpgradeContainer;
