import "./print-error.scss";

export const PrintError = () => (
  <section className="print-error__wrapper">
    <div className="print-error__header">DYMO printers failed to connect</div>
    <div className="print-error__explainer-group">
      <div className="print-error__explainer-item">
        Check the Dymo print service is running and reload the page.
      </div>
    </div>
  </section>
);

export default PrintError;
