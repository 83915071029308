import { useCallback, useState } from "react";

import { useKeyEvent } from "../../../hooks";
import { useLogin } from "../../../hooks/context/login";
import useBooleanFetch from "../../../hooks/use-boolean-fetch";
// Hooks
import useError from "../../../hooks/use-error";
import upsGround from "../../../images/ups-ground.png";
import upsSurepost from "../../../images/ups-surepost.png";
import BaseButton from "../../base/button";
import TextInput from "../../base/text-input";
import IndicatorLight from "../../components/indicator-light";

interface TrackingInputProps {
  className?: string;
  error: boolean;
  val: string;
  errorMsg?: string;
}
export const TrackingInput = ({
  className,
  error,
  errorMsg,
  val,
}: TrackingInputProps) => {
  return (
    <div className="departure__tracking-input-wrapper">
      <TextInput
        className={className}
        val={val}
        error={error}
        errorMsg={errorMsg}
      />
    </div>
  );
};

interface ScanBoxProps {
  onClick: () => void;
}

const ScanBox = ({ onClick }: ScanBoxProps) => {
  const [good, setGood] = useState(0);
  const [fail, setFail] = useState(0);
  const [pass, setPass] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [shipmentAlert, setShipmentAlert] = useState("");
  const [list, setList] = useState(new Set<string>());
  const [{ usertoken }] = useLogin();
  const onFilled = (val: string) => {
    clearFilled();
    if (list.has(val)) {
      onDuplicateScan();
      return;
    }
    setList((currentList) => currentList.add(val));
    triggerFetch();
  };
  const { val, clearInput, clearFilled } = useKeyEvent(true, onFilled);
  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();

  const onSuccess = (headers: Headers) => {
    const flag = headers?.get?.("x-order-flag");
    if (!flag) {
      setShipmentAlert("");

      setGood(good + 1);
      clearError();
      clearInput();
      clearFilled();
      setPass(true);

      return;
    }

    setShipmentAlert("Shipment Alert");
    onFail();

    triggerError("");
  };

  const onFailShowModal = () => {
    setShowModal(true);
  };

  const onScanAgain = () => {
    setShowModal(false);
    clearInput();
    clearFilled();
  };

  interface OnFailParams {
    type?: string;
  }
  const onFail = ({ type }: OnFailParams = {}) => {
    if (type === "showmodal") {
      return onFailShowModal();
    }
    setFail(fail + 1);
    triggerError("Tracking number not found");
    setPass(false);
    clearInput();
    clearFilled();
  };

  // On duplicate scan, remove from list to prevent showing
  // wrong error for tracking numbers that don't match
  const onDuplicateScan = useCallback(() => {
    setFail((fail) => fail + 1);
    triggerError("Tracking number has been scanned already");
    setPass(false);
    clearInput();
    clearFilled();
  }, [setPass, setFail, triggerError, clearInput, clearFilled]);

  const onError = (msg: string) => {
    setPass(false);
    triggerError(msg);
  };
  const triggerFetch = useBooleanFetch(
    `departure/fulfillment/${val}`,
    usertoken,
    onSuccess,
    onFail,
    onError,
  );

  return (
    <>
      {showModal ? (
        <>
          <div className="departure__overlay"></div>
          <section className="departure__modal">
            <section className="departure__modal-error__wrapper">
              <div className="departure__modal-error__header">Stop</div>
              <div className="departure__modal-error__explainer">
                This order has had outputs added since it was marked complete
              </div>

              <BaseButton
                className="go-again__buttons again"
                onClick={onScanAgain}
              >
                SCAN AGAIN
              </BaseButton>
            </section>
          </section>
        </>
      ) : (
        <></>
      )}
      <section className="departure__title">
        Log that these orders are going on the truck
        <h1>SCAN TRACKING #</h1>
      </section>
      <IndicatorLight
        light="fail"
        symbol={"✖"}
        active={errState}
        wrapper="departure__fail"
      />
      <TrackingInput
        val={val}
        className="departure__scan-input"
        error={errState}
        errorMsg={errMsg}
      />
      <IndicatorLight
        light="pass"
        symbol={"✓"}
        active={pass}
        wrapper="departure__pass"
      />
      <section className="arrival__order-flag">{shipmentAlert}</section>
      <section className="departure__label-examples">
        <img src={upsGround} alt="UPS Ground Label" />
        <img src={upsSurepost} alt="UPS Surepost Label" />
      </section>
      <section className="departure__total-scanned">
        <span className="departure__scan departure__scan--good">
          Scans: {good}
        </span>
        <span className="departure__scan departure__scan--fail">
          Errors: {fail}
        </span>
        <span className="departure__scan departure__scan--total">
          Total: {fail + good}
        </span>
      </section>
      <section className="departure__done-scanning">
        <BaseButton onClick={onClick} className="departure__button">
          Finished Scanning
        </BaseButton>
      </section>
    </>
  );
};

export default ScanBox;
