import Button from "../base/button";
import CategoryIcon from "./category-icon";
import cn from "classnames";

import "./category-button.scss";

interface CategoryButtonProps {
  category: string;
  quantity: number;
  selected: string;
  error?: boolean;
  onClick: () => void;
}

export const CategoryButton = ({
  category,
  quantity,
  selected,
  error = false,
  onClick,
}: CategoryButtonProps) => {
  const buttonActive = cn("category-button", {
    "category-button--selected": selected === category,
    error,
  });
  const numberActive = cn("category-button__number", {
    "category-button__number--active": quantity > 0,
  });
  const unsupported = cn({ "space-around-icon": category !== "Unsupported" });
  return (
    <>
      <Button onClick={onClick} className={buttonActive}>
        <CategoryIcon category={category} />
        <span className={unsupported}>{category}</span>
      </Button>
      <span className={numberActive}>{quantity}</span>
    </>
  );
};

export default CategoryButton;
