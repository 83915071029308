import { useEffect, useState } from "react";

import { fromEvent } from "rxjs";
import { buffer, filter, map } from "rxjs/operators";

/**
 * Checks if the key pressed in a keyboard event is an alphanumeric character or a hyphen or underscore.
 *
 * @param event - The keyboard event.
 * @returns A boolean indicating whether the key is an alphanumeric character or a hyphen or underscore.
 */
export const screenAlphaNumeric = (event: KeyboardEvent) =>
  /[a-zA-Z0-9-_]/.test(event.key);

/**
 * Joins the keys from a group of keyboard events into a single string,
 * excluding the "Enter" key.
 *
 * @param group - The group of keyboard events.
 * @returns The joined string of keys.
 */
export const joinBuffer = (group: KeyboardEvent[]) =>
  group
    .filter((single) => single.code !== "Enter")
    .map(({ key }) => key)
    .join("");

/** input stream from keyboard/barcode-scanner */
const inputStream$ = fromEvent<KeyboardEvent>(document, "keypress").pipe(
  filter(screenAlphaNumeric),
);

interface ScannedItem {
  id: number;
  code: string;
}

interface Scanner {
  scan: string;
  list: ScannedItem[];
  clearScan: () => void;
  removeScan: (index: number) => void;
  isDuplicateEntry: (entry: string) => boolean;
}

export const useScanner = (): Scanner => {
  const [list, setList] = useState<ScannedItem[]>([]);
  const [scan, setScan] = useState("");

  /**
   * Clears the scan.
   */
  const clearScan = () => setScan("");

  /**
   * Removes an item from the list at the specified index.
   * @param index - The index of the item to remove.
   */
  const removeIndex = (index: number) => {
    const lastIndex = list.length - 1;

    if (index === 0) {
      const newList = [...list.slice(1)];
      return setList(newList);
    } else if (index === lastIndex) {
      const newList = [...list.slice(0, lastIndex)];
      return setList(newList);
    }

    const newList = [...list.slice(0, index), ...list.slice(index + 1)];
    setList(newList);
  };

  /**
   * Checks if the list contains a duplicate entry.
   * @param entry - The entry to check for.
   * @returns A boolean indicating whether the list contains a duplicate entry.
   */
  const isDuplicateEntry = (entry: string) => {
    const checkDuplicate = list.filter(({ code }) => code === entry);
    return checkDuplicate.length > 1;
  };

  /** scan event synchronizer */
  useEffect(() => {
    const input$ = inputStream$
      .pipe(filter((event) => event.code !== "Enter"))
      .subscribe((event) => {
        setScan((prevScan) => prevScan + event.key);
      });

    return () => input$.unsubscribe();
  }, []);

  /** list event synchronizer */
  useEffect(() => {
    const boundary$ = inputStream$.pipe(filter((key) => key.code === "Enter"));
    const scan$ = inputStream$
      .pipe(
        buffer(boundary$),
        map(joinBuffer),
        filter((str) => str.length > 0),
      )
      .subscribe((str) => {
        setList((prevList) => {
          const barcode = str.split("-")[1] ?? "0";
          let idx = Number(barcode);
          // handle upgrade barcodes
          if (isNaN(idx)) {
            idx = prevList.length;
          }
          return [...prevList, { id: idx, code: str }];
        });
      });

    return () => scan$.unsubscribe();
  }, []);

  return { scan, list, clearScan, removeScan: removeIndex, isDuplicateEntry };
};

export default useScanner;
