import { useState } from "react";

import { useInterval } from "../../hooks/use-interval";

/**
 * Formats elapsed seconds
 * @param time time in seconds
 * @returns formatted time in mm:ss format
 */
const formatMinutes = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

/** Order Countdown timer props */
interface CountdownTimerProps {
  isRunning: boolean;
  startTime: number;
  className: string;
}

/** Order countdown timer element. */
export const CountdownTimer = ({
  isRunning,
  startTime,
  className,
}: CountdownTimerProps) => {
  const [count, setCount] = useState(startTime);

  useInterval(
    () => {
      setCount(count - 1);
    },
    isRunning && count > 0 ? 1000 : null,
  );
  const formatted = formatMinutes(count);

  return (
    <div className={className}>
      <span>{isRunning && formatted}</span>
    </div>
  );
};

export default CountdownTimer;
