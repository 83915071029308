import { useEffect, useState } from "react";
// Components
import BaseButton from "../../base/button";
import SlidePanel from "../../groups/slide-panel";
// Hooks
import { useLogin } from "../../../hooks/context/login";
import useApi from "../../../hooks/use-api";
import type { Dispatch, SetStateAction } from "react";

const useAdminManuallyClearFromDataListApi = (
  productionOrderId: string,
  execute: boolean,
) => useApi(`admin/${productionOrderId}/mark-as-shipped`, "post", execute);

type ManuallyClearFromDataListPanelProps = {
  productionOrderId: string;
  setState: Dispatch<SetStateAction<ManuallyClearFromDataListState>>;
};
/** Slide out panel content for marking an order as shipped */
function ManuallyClearFromDataListPanel(
  props: ManuallyClearFromDataListPanelProps,
) {
  const { setState } = props;
  const [sendRequest, setSendRequest] = useState(false);
  const shippedApi = useAdminManuallyClearFromDataListApi(
    props.productionOrderId,
    sendRequest,
  );
  const { actions, apiDispatch, isRejected, isResolved } = shippedApi;

  /** Reset state when request completes */
  useEffect(() => {
    if (isResolved) {
      setSendRequest(false);
      setState({ orderMarkedAsShipped: true, showPanel: true });
      apiDispatch({ type: actions.RESET });
    }
  }, [actions, apiDispatch, isResolved, setState]);

  return (
    <section className="order-main__mark-as-shipped__panel">
      {!isRejected ? (
        <>
          <header>Are you sure you want to mark this order as shipped?</header>
          <section>
            <BaseButton
              onClick={() => setSendRequest(true)}
              className="order-main__mark-as-shipped__btn"
            >
              Confirm
            </BaseButton>
          </section>
        </>
      ) : (
        <>
          <header>Error: unable to mark order as shipped</header>
          <section>
            <BaseButton
              onClick={() => apiDispatch({ type: actions.RESET })}
              className="order-main__mark-as-shipped__btn"
            >
              Retry
            </BaseButton>
          </section>
        </>
      )}
    </section>
  );
}

const ManuallyClearFromDataListInitialState = {
  /** Shoe or hide slide out panel */
  showPanel: false,
  /** Successfully marked order as shipped */
  orderMarkedAsShipped: false,
};
type ManuallyClearFromDataListState =
  typeof ManuallyClearFromDataListInitialState;

export function ManuallyClearFromDataList(props: any) {
  const [state, setState] = useState(ManuallyClearFromDataListInitialState);
  const [{ usergroup }] = useLogin();
  const isAdminUser = usergroup === "Manager" || usergroup === "Administrator";
  const isOrderNotShipped = props.orderData?.is_final_shipped === false;
  const showManuallyClearFromDataListButton =
    isAdminUser && isOrderNotShipped && !state.orderMarkedAsShipped;
  return (
    <>
      {showManuallyClearFromDataListButton && (
        <>
          <BaseButton
            onClick={() => setState((state) => ({ ...state, showPanel: true }))}
            className="order-main__mark-as-shipped__btn"
          >
            Manually clear from data list
          </BaseButton>

          <SlidePanel
            show={state.showPanel}
            setShow={(showPanel: boolean) =>
              setState((state) => ({ ...state, showPanel }))
            }
          >
            <ManuallyClearFromDataListPanel
              productionOrderId={props.productionOrderId}
              setState={setState}
            />
          </SlidePanel>
        </>
      )}
    </>
  );
}
