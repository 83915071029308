/**
 * SVG Icon for HomeIcon.
 * @param {Object} props
 * @param {string} [props.className]
 */
export const HomeIcon = ({ className = "home-icon-svg" }) => {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 408 411.6"
    >
      <g>
        <g>
          <path
            fill="#0096B1"
            d="M195.1,101.2c23.3,0,19.5,56.4,0.3,56.4C175.9,157.6,176.9,101.2,195.1,101.2z M223.2,143.6
          c11.1,11.2,26.2-27.4,8.4-19.6C228.6,125.2,214.4,134.7,223.2,143.6z M173.1,171.7c15.6-11.5-10.2-57.3-25-42.1
          C134,144,161.2,180.5,173.1,171.7z M234.3,182.9c16.5,12.1,50.7-38.8,22.3-39.3C242.2,143.3,220.2,172.5,234.3,182.9z M222,154.3
          c-11.2-4.9-25.1,30.5-12.7,34.3C223.5,192.8,231.8,158.6,222,154.3z M145.5,185.7c6.3-5.2,2-14.6-4.7-19.1
          c-7.2-4.8-16.6-6.3-18.6-3.2C118.7,168.8,138.8,191.2,145.5,185.7z M272.2,173.5c-4.1,3.3-6.7,8.9-4.5,12.1
          c1.6,2.3,8,1.5,12.5-2.1c4.1-3.3,6.4-9.4,4.2-12C282.4,169.2,276.5,170.1,272.2,173.5z M181.5,213.8c9.8-9.9-16.4-39.3-25.1-25.3
          C148.9,200.6,173.3,222.1,181.5,213.8z M135.1,191.3c-11.1-5.5-26.6-4.6-28.7,0c-2,4.4,3.3,17.2,14,23c11.5,6.1,28.6,4.9,30.5-0.5
          C152.7,208.6,146.1,196.8,135.1,191.3z M239.1,196.3c-5.6,4.4-9.3,12.1-7.5,14.7c1.8,2.8,10.2,1.2,16.2-3.2
          c5.2-3.9,8.5-10.4,6.1-13.6C251.3,190.9,244.3,192.1,239.1,196.3z M253.8,219.4c15.9,32.6,74.4-34.1,19.5-19.7
          C264.6,202,248.6,208.7,253.8,219.4z M159.2,239c3.1-14.3-17.8-15-27.8-11.2C101,239.2,155.5,256.3,159.2,239z M276,253.1
          c8.1-12.9-18.4-18.5-27.8-14C226.3,249.5,268.8,264.8,276,253.1z M160.4,254.4c2,2.9,14.7,2.9,12.7-4.1
          C171.1,243.2,158.4,251.4,160.4,254.4z"
          />
          <path
            fill="#0096B1"
            d="M204,5.4C94.3,5.4,5.4,95.1,5.4,205.8C5.4,316.4,94.3,406.2,204,406.2c109.7,0,198.6-89.7,198.6-200.4
          C402.6,95.1,313.7,5.4,204,5.4z M307.7,310.4c-11.9,12-25.9,22-41.3,29.3c-27.1,12.8-59.6-23.2-46.6-123.5l-23.5-17.4l2,114.9
          c-0.5,34.6-15.9,38.8-58.9,24.9c-13.4-4.3-27.8-16.8-39.2-28.3c-26.6-26.9-43-63.7-43-104.6c0-40.9,16.4-77.8,43-104.7
          c26.6-26.8,63.1-43.3,103.7-43.3c40.6,0,77.1,16.5,103.7,43.3c26.6,26.9,43,63.7,43,104.7C350.7,246.8,334.3,283.6,307.7,310.4z"
          />
        </g>
        <g>
          <path
            fill="#0096B1"
            d="M376.3,353.1c0,4.7-3.7,8.3-8.4,8.3c-4.7,0-8.5-3.7-8.5-8.3c0-4.6,3.8-8.2,8.5-8.2
          C372.7,344.9,376.3,348.6,376.3,353.1z M361.6,353.1c0,3.7,2.7,6.6,6.4,6.6c3.6,0,6.3-2.9,6.3-6.5c0-3.7-2.7-6.6-6.3-6.6
          C364.3,346.6,361.6,349.5,361.6,353.1z M366.6,357.4h-1.9v-8.2c0.8-0.2,1.8-0.3,3.2-0.3c1.6,0,2.3,0.3,2.9,0.6
          c0.4,0.3,0.8,1,0.8,1.8c0,0.9-0.7,1.6-1.7,1.9v0.1c0.8,0.3,1.3,0.9,1.5,2c0.3,1.3,0.4,1.8,0.6,2.1h-2.1c-0.2-0.3-0.4-1.1-0.7-2
          c-0.1-0.9-0.7-1.3-1.7-1.3h-0.9V357.4z M366.7,352.8h0.9c1,0,1.9-0.4,1.9-1.2c0-0.8-0.5-1.3-1.8-1.3c-0.5,0-0.9,0-1.1,0.1V352.8z"
          />
        </g>
      </g>
    </svg>
  );
};

export default HomeIcon;
