import { useLogin } from "../../../../hooks/context/login";
import {
  SET_SPLIT_ORDER_DATA,
  STEP_DISPLAY,
} from "../../../../hooks/reducers/admin/split-order";
// Hooks
import useError from "../../../../hooks/use-error";
import useKeyEvent from "../../../../hooks/use-key-event";
import BaseButton from "../../../base/button";
// Components
import TextInput from "../../../base/text-input";

import "./scan-order.scss";

const { VITE_APP_API_URI } = import.meta.env;

const createSplitOrder = async (usertoken, orderId, triggerError, dispatch) => {
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/admin/split-order/${orderId}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
      },
    );
    const payload = await response.json();
    if (!response.ok) {
      throw new Error(payload.errorMessage);
    }
    dispatch({
      type: SET_SPLIT_ORDER_DATA,
      payload,
    });
    dispatch({ type: STEP_DISPLAY });
  } catch (error) {
    console.log(error.message);
    triggerError(error.message);
  }
};

export const ScanOrder = ({ dispatch }) => {
  const { errState, errMsg, trigger, clear: clearError } = useError();
  const { val, clearInput } = useKeyEvent(!errState);
  const [{ usertoken }] = useLogin();
  const resetForm = () => {
    clearError();
    clearInput();
  };

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
      event.currentTarget.blur();
    }
    const orderId = val.split("-")[0];
    if (orderId == null) {
      trigger(`Invalid safety barcode: ${val}`);
      return;
    }
    createSplitOrder(usertoken, orderId, trigger, dispatch);
  };

  return (
    <section className={"split-order__scan-order-wrapper"}>
      <header className={"split-order__scan-order-header"}>
        Scan safety barcode from order
      </header>
      <header className={"split-order__scan-order-subheader"}>
        If the original order is still in plant, please use the Edit Items app
      </header>
      <form
        className={"split-order__scan-order-create-form"}
        onSubmit={onSubmit}
      >
        <label>
          Barcode: <TextInput val={val} error={errState} errorMsg={errMsg} />
        </label>
        {!errState ? (
          <>
            <input
              type="submit"
              className={"split-order__scan-order-create-btn"}
              value={"Create Order Split"}
            />
          </>
        ) : (
          <section className={"split-order__scan-order-err-area"}>
            <BaseButton
              children={"Clear Error"}
              onClick={() => resetForm()}
              className={"split-order__scan-order-err-btn"}
            />
          </section>
        )}
      </form>
    </section>
  );
};

export default ScanOrder;
