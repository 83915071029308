import { useDispatch, useSelector } from "react-redux";

import {
  selectOrderId,
  selectDepartment,
  selectAnalogItems,
  setActiveScan,
  selectUnscannedItemQty,
  selectErrorState,
  clearError,
  scanItemAsync,
  selectReadyScan,
} from "./state/slice/data";
import { useCallback, useEffect } from "react";
import { KeyActions, useKeyContext } from "@/hooks/context/scan";
import usePager from "@/hooks/use-pager";
import PagerButtons from "@/ui/components/pager-buttons";
import BaseButton from "@/ui/base/button";
import GoButton from "@/ui/components/go-button";
import { setPage } from "./state/slice/page";
import { handleUpdates } from "./state/services/updates";
import { useLogin } from "@/hooks/context/login";
import { AppDispatch } from "./state/store";
import { ItemContainer, ItemList } from "./components/items";
import { reset } from "./state/actions/reset";
import { api } from "./state/services/api";

const ErrorArea = ({
  error = { errState: false, errMsg: "" },
  onClick = () => {},
  stopOnError = false,
}: {
  error?: {
    errState?: boolean;
    errMsg?: string;
  };
  id: string;
  onClick?: () => void;
  stopOnError?: boolean;
}) => {
  const { errState, errMsg } = error;

  return errState ? (
    <div className="scan-items__error-area">
      <div className="scan-items__error-message">
        Oops! You scanned <strong>{errMsg}</strong>. That item doesn&apos;t
        belong to this order.
      </div>
      <BaseButton onClick={onClick} className="scan-items__error-button">
        {stopOnError ? "TRY AGAIN" : "CLEAR ERROR"}
      </BaseButton>
    </div>
  ) : (
    <></>
  );
};

export function SafetyCheckScanItems() {
  const [{ usertoken }, loginDispatch] = useLogin();
  const subscribe = useKeyContext();
  const orderId = useSelector(selectOrderId);
  const department = useSelector(selectDepartment);
  const items = useSelector(selectAnalogItems);
  const itemError = useSelector(selectErrorState);
  const unscanned = useSelector(selectUnscannedItemQty);
  const ready = useSelector(selectReadyScan);

  const dispatch = useDispatch<AppDispatch>();

  const pager = usePager(items, 25);

  useEffect(() => {
    const unsubscribe = subscribe((event) => {
      switch (event.type) {
        case KeyActions.key:
          return dispatch(setActiveScan(event.key));
        case KeyActions.enter:
          return dispatch(scanItemAsync());
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleErrorFn = () => {
    if (itemError.status) {
      if (!itemError.recoverable) {
        window.location.reload();
        return;
      }
      dispatch(clearError());
    }
  };

  const handleClick = useCallback(() => {
    console.log("click");

    if (!ready) {
      return;
    }

    handleUpdates(usertoken, orderId, department, items).then((res) => {
      console.log(res);
      if (!res) {
        // error flow?
        return;
      }
      dispatch(setPage("complete"));
    });
  }, [ready, usertoken, orderId, department, items]);

  const handleFailClick = () => dispatch(setPage("review"));

  return (
    <>
      <ItemContainer department={department}>
        <ItemList items={pager.current} />
        <PagerButtons {...pager} displayPageNum={items.length > 25} />
      </ItemContainer>
      <ErrorArea
        id={orderId}
        error={{ errState: itemError.status, errMsg: itemError.msg }}
        onClick={handleErrorFn}
        stopOnError={!itemError.recoverable}
      />
      {unscanned === 0 && ready && (
        <section className="safetyscans-next-button">
          <GoButton onClick={handleClick} />
        </section>
      )}
      {unscanned === 0 && !ready && (
        <section className="safetyscans-next-button">
          <h2 className="safetycheck__heading">Waiting on Cloud files</h2>

          <BaseButton
            className="safetycheck__cloud-fail-btn"
            onClick={handleFailClick}
          >
            Review
          </BaseButton>
        </section>
      )}
    </>
  );
}
