import "./home.scss";

import AppButton from "../components/app-button";
import { useLogin } from "../../hooks/context/login";
import { RouteGroup } from "../route-group";

export const Home = () => {
  const [{ usergroup }] = useLogin();
  const adminUser = usergroup === "Administrator";

  return (
    <>
      <section className="home__apps-wrapper">
        {RouteGroup.filter(
          (r) => !["/", "/login", "/order/:id"].includes(r.path),
        )
          .filter(
            (r) =>
              "isAdminRoute" in r === false || (r.isAdminRoute && adminUser),
          )
          .map((route, idx) => (
            <AppButton key={idx} {...route} />
          ))}
      </section>
    </>
  );
};

export default Home;
