import type { Dispatch } from "react";

// Hooks
import { useLogin } from "../../hooks/context/login";
import { LOGOUT, LoginAction } from "../../hooks/reducers/login";
import { RESET } from "../../hooks/reducers/safetyscans";
import useTimeout from "../../hooks/use-timeout";
// Components
import BaseButton from "../base/button";
import CountdownTimer from "../components/countdown-timer";

import "./go-again.scss";

interface ResetOrderAction {
  type: "RESET" | "PAGE_STEP";
}

export type GoAgainActions = ResetOrderAction | LoginAction;

export interface GoAgainProps {
  dispatch: Dispatch<GoAgainActions>;
  header?: string;
  isRunning?: boolean;
  startTime?: number;
}

export const GoAgain = ({
  dispatch,
  header = "Would you like to safety scan another order?",
  isRunning = true,
  startTime = 20,
}: GoAgainProps) => {
  const loginDispatch = useLogin()[1];

  useTimeout(
    () => loginDispatch({ type: LOGOUT }),
    isRunning,
    startTime * 1000,
  );

  return (
    <>
      <h2 className={"go-again__header"}>{header}</h2>
      <CountdownTimer
        isRunning={isRunning}
        startTime={startTime}
        className={"go-again__timer"}
      />
      <section className={"go-again__buttons-wrapper"}>
        <BaseButton
          className={"go-again__buttons again"}
          children={"GO AGAIN"}
          onClick={() => dispatch({ type: RESET })}
        />
        <BaseButton
          className={"go-again__buttons done"}
          children={"LOG OUT"}
          onClick={() => loginDispatch({ type: LOGOUT })}
        />
      </section>
    </>
  );
};

export default GoAgain;
