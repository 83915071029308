import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useLogin } from "../../hooks/context/login";
import { PAGE_STEP } from "../../hooks/reducers/actions/safetyscans/page";
// Hooks
import useSafetyScansReducer from "../../hooks/reducers/safetyscans";
import { useDymoService } from "../../hooks/use-dymo-service";
import useFetch from "../../hooks/use-fetch";
import useWorkCompleted from "../../hooks/use-work-completed";
// Components
import GoButton from "../components/go-button";
import AlertsList from "../groups/alerts-list";
import OrderHeader from "../groups/order-header";
import ProgressBar from "../groups/progress-bar";
import RushLocateModal from "../groups/rush-locate";
import "./safetyscans.scss";
import ActivateReorderBooklet from "./safetyscans/activate-reorder-booklet";
import { BackupFiles } from "./safetyscans/backup-file";
import Deliverables from "./safetyscans/deliverables";
import DamagedItems from "./safetyscans/log-damaged-items";
import PhotosQuantity from "./safetyscans/photos-quantity";
import ReviewOrder from "./safetyscans/review-order";
import ScanDamagedItems from "./safetyscans/scan-damaged-items";
import FilmWithAudio from "./safetyscans/scan-film-with-sound";
import ScanItems from "./safetyscans/scan-items";
import ScanOrder from "./safetyscans/scan-order";
import ShipOrder from "./safetyscans/ship-order";
import ShowError from "./safetyscans/show-error";
import StationSelect from "./safetyscans/station-select/station-select";

const { VITE_APP_API_URI } = import.meta.env;

const getActivePage = (state, dispatch) => {
  const { activePage, orderInfo, departmentsData } = state;

  if (departmentsData.cell === null) {
    return <StationSelect dispatch={dispatch} />;
  }
  if (departmentsData.validForOrder === false || orderInfo.isShipped === true) {
    return <ShowError {...state} dispatch={dispatch} />;
  }
  if (orderInfo.shipmentAlerts) {
    return (
      <AlertsList
        alerts={orderInfo.shipmentAlerts.alerts}
        dispatch={dispatch}
      />
    );
  }

  switch (activePage) {
    case "Get Started":
      return <ScanOrder {...state} dispatch={dispatch} />;
    case "Scan Items":
      return <ScanItems {...state} dispatch={dispatch} />;
    case "Backup Files":
      return <BackupFiles {...state} dispatch={dispatch} />;
    case "Log Damaged Items":
      return <DamagedItems {...state} dispatch={dispatch} />;
    case "Scan Damaged Items":
      return <ScanDamagedItems {...state} dispatch={dispatch} />;
    case "Scan Film With Sound":
      return <FilmWithAudio {...state} dispatch={dispatch} />;
    case "Select Photos Quantity":
      return <PhotosQuantity {...state} dispatch={dispatch} />;
    case "Confirm All Delivery Methods":
      return <Deliverables {...state} dispatch={dispatch} />;
    case "Review Order":
      return <ReviewOrder {...state} dispatch={dispatch} />;
    case "Reorder Activate":
      return <ActivateReorderBooklet {...state} dispatch={dispatch} />;
    case "Ship Order":
      return <ShipOrder {...state} dispatch={dispatch} />;
    default:
      return <></>;
  }
};

export const DisplayGoButton = ({ visible, active, dispatch }) => {
  if (!visible) {
    // If not visible (last page) send back empty fragment
    return <></>;
  }

  return (
    <section className="safetyscans-next-button">
      <GoButton
        onClick={(event) => {
          dispatch({ type: PAGE_STEP });
          event.currentTarget.blur();
        }}
        active={active}
      />
    </section>
  );
};

const getCellDisplayName = ({ cell, departmentName }) => {
  if (cell === null || departmentName === null) {
    return;
  }
  return `${departmentName} ${cell.name}`;
};

export const useBalanceDueStatusCheck = (
  cellDisplayName,
  orderId,
  isOrderComplete,
) => {
  const [{ usertoken }] = useLogin();
  const [toFetchBalanceDue, setToFetchBalanceDue] = useState(false);
  const [hasBalanceDueStatusError, setHasBalanceDueStatusError] = useState(
    cellDisplayName === "Balance-Due",
  );
  const [balanceDueStatusError, setBalanceDueStatusError] = useState(null);
  const { isResolved, body: invoiceResult } = useFetch(
    `https://${VITE_APP_API_URI}/balancedue/${orderId}`,
    {
      headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
    },
    toFetchBalanceDue,
  );
  const forBalanceDue = invoiceResult?.invoices.length > 0;
  const allResolved = invoiceResult?.invoices.every(
    (invoice) => invoice.resolved === 1,
  );
  const allResolvedAndStage0 =
    allResolved && invoiceResult?.invoices.every((invoice) => !invoice.stage);
  const hasStage1 =
    invoiceResult?.invoices.findIndex((invoice) => invoice.stage === 1) > -1;

  useEffect(() => {
    if (cellDisplayName !== "Balance-Due") {
      return;
    } else if (orderId && !toFetchBalanceDue) {
      setToFetchBalanceDue(true);
    } else if (isResolved) {
      if (isOrderComplete && forBalanceDue && allResolved && hasStage1) {
        return setHasBalanceDueStatusError(false);
      } else {
        // errMsg (default) order incomplete
        // Or no invoice at all,
        // Or !allResolved (invoices not paid)
        // Or allResolvedAndStage0 (should ship on normal dept but not here)
        let errMsg = "This order isn't ready to ship.";

        if (!isOrderComplete) {
          errMsg = "Can't process an incomplete order in Balance Due App.";
        } else if (allResolvedAndStage0) {
          errMsg = "This order should not be shipped in Balance Due App.";
        } else if (!hasStage1) {
          errMsg = "This order is not a Balance Due order.";
        }
        setHasBalanceDueStatusError(true);
        return setBalanceDueStatusError({
          errMsg,
        });
      }
    }
  }, [
    cellDisplayName,
    toFetchBalanceDue,
    orderId,
    isResolved,
    forBalanceDue,
    hasStage1,
    allResolved,
    isOrderComplete,
    allResolvedAndStage0,
  ]);

  return {
    isResolved: orderId && isResolved,
    hasBalanceDueStatusError,
    balanceDueStatusError,
  };
};

const WorkflowIndicator = () => {
  const location = useLocation();
  const classname = "safetyscans__workflow-indicator";
  if (location?.pathname === "/safetyscans") {
    return (
      <>
        <div className={`${classname} ${classname}__right`}></div>
        <div className={`${classname} ${classname}__left`}></div>
      </>
    );
  }
  return <></>;
};

export const SafetyScans = () => {
  const [state, dispatch] = useSafetyScansReducer();
  const { completedCount } = useWorkCompleted();
  const { activePage, steps, orderInfo, departmentsData } = state;
  const cellDisplayName = getCellDisplayName(departmentsData);
  const { isResolved, hasBalanceDueStatusError, balanceDueStatusError } =
    useBalanceDueStatusCheck(
      cellDisplayName?.trim(),
      orderInfo.orderId,
      orderInfo?.isComplete === true,
    );

  const page = getActivePage(state, dispatch);
  let active = false,
    visible = false;
  useDymoService(() => console.log("we should have an error flow here"));

  const currentStep = steps.find((s) => s.name === activePage);
  if (currentStep !== undefined) {
    active = currentStep.canAdvance;
    visible = currentStep.showGoBtn;
  }

  if (hasBalanceDueStatusError) {
    if (isResolved) {
      return (
        <ShowError
          {...state}
          errMsg={balanceDueStatusError.errMsg}
          dispatch={dispatch}
        />
      );
    }

    // handle timeline fetch isLoading if necessary
  }

  const inShippingClass = activePage === "Ship Order" ? "shipping" : "";

  return (
    <>
      <WorkflowIndicator />
      <RushLocateModal productionOrderId={state.orderInfo.orderId} />
      <section className="safetyscans-header">
        <OrderHeader {...orderInfo} />
      </section>
      {departmentsData.cell !== null && (
        <section className="safetyscans-dept-data">{cellDisplayName}</section>
      )}
      <section className={`safetyscans-progress-bar ${inShippingClass}`}>
        <ProgressBar steps={steps} dispatch={dispatch} />
      </section>
      {page}
      <DisplayGoButton visible={visible} active={active} dispatch={dispatch} />
      {completedCount != null ? (
        <section className="user-header__work-completed">
          <article className="user-header__work-completed-count">
            {completedCount}
          </article>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default SafetyScans;
