import { useEffect, useRef } from "react";

import usePager from "../../hooks/use-pager";
import BaseButton from "../base/button";
import TextInput from "../base/text-input";

import "./inputs-group.scss";

interface InputListItemTextInput {
  id: string;
  code: string;
  error?: boolean | string;
  errorMsg?: string;
}

interface InputListItemProps {
  textInput: InputListItemTextInput;
  onClick: (code: string) => void;
}

/**
 * Renders a single input item.
 *
 * @param props - The props for the InputListItem component.
 * @returns The rendered InputListItem component.
 */
export const InputListItem = ({
  textInput: { id, code, error, errorMsg = "" },
  onClick,
}: InputListItemProps) => {
  const removeItem = (event) => {
    onClick(code);
    event.currentTarget.blur();
  };
  return (
    <div className="inputs-group__text-input-wrapper">
      <TextInput
        className={`inputs-group__text-input-${
          id !== "active" ? "full" : "empty"
        }`}
        val={code}
        error={typeof error === "boolean" ? error : Boolean(error)}
        errorMsg={errorMsg}
      />
      <BaseButton className="inputs-group__text-input-btn" onClick={removeItem}>
        X
      </BaseButton>
    </div>
  );
};

interface InputsGroupProps {
  /** scanned objects */
  textInputs: InputListItemTextInput[];
  /** initial page */
  initialPage: number;
  /** page size */
  pageSize: number;
  /** header text of pager group */
  groupHeader: string;
  /** string prefix for className */
  classPrefix?: string;
  /** Error State for group */
  error?: boolean;
  /** Optional click handler for error state */
  onClick?: () => void;
}

/**
 * Renders a group of input items with pagination functionality.
 *
 * @param props The props for the InputsGroup component.
 * @returns The rendered InputsGroup component.
 */
export const InputsGroup = ({
  textInputs,
  initialPage,
  pageSize,
  groupHeader,
  classPrefix = "",
  error = false,
  onClick = undefined,
}: InputsGroupProps) => {
  const inputsRef = useRef(0);
  const { page, current, lastPage, next, previous } = usePager(
    textInputs,
    pageSize,
    initialPage,
  );

  useEffect(() => {
    if (inputsRef.current < textInputs.length) {
      if (textInputs.length > pageSize * (page + 1)) {
        next();
      }
    }
    inputsRef.current = textInputs.length;
  }, [textInputs.length, inputsRef, page, pageSize, next]);

  const pageBtnClass = "inputs-group__paging-btn";
  return (
    <>
      {groupHeader.length > 0 && (
        <h1
          className={`${classPrefix}inputs-group__header${
            error ? " error" : ""
          }`}
        >
          {groupHeader}
        </h1>
      )}
      <section className="inputs-group__paging-wrapper">
        <BaseButton
          children={"<"}
          onClick={page > 0 ? () => previous() : undefined}
          className={`${pageBtnClass} ${page > 0 ? "active" : ""}`}
        />
        <section className="inputs-group__items">
          {current.map((textInput, index) => (
            <InputListItem
              key={index}
              textInput={textInput}
              onClick={onClick}
            />
          ))}
        </section>
        <BaseButton
          children={">"}
          onClick={page < lastPage ? () => next() : undefined}
          className={`${pageBtnClass} ${page < lastPage ? "active" : ""}`}
        />
      </section>
    </>
  );
};

export default InputsGroup;
