import { useReducer } from "react";
import {
  orderInfoInitialState,
  orderInfoActions,
} from "../actions/safetyscans/order-info";
import {
  shippingInitialState,
  shippingActions,
} from "../actions/safetyscans/shipping";
import { pageActions } from "../actions/safetyscans/page";
import {
  SafetyScansErrorInitialState,
  SafetyScansErrorActions,
} from "../actions/safetyscans/error";

export const pageInitialState = {
  activePage: "Find Order",
  steps: [
    {
      name: "Find Order",
      order: 1,
      inProgress: true,
      canAdvance: false,
      complete: false,
      showGoBtn: false,
    },
    {
      name: "Scan Items",
      order: 2,
      inProgress: false,
      canAdvance: false,
      complete: false,
      showGoBtn: true,
    },
    {
      name: "Confirm All Delivery Methods",
      order: 3,
      inProgress: false,
      canAdvance: false,
      complete: false,
      showGoBtn: false,
    },
    {
      name: "Ship Order",
      order: 4,
      inProgress: false,
      canAdvance: false,
      complete: false,
      showGoBtn: false,
    },
  ],
};

export const InitialState = {
  ...orderInfoInitialState,
  ...shippingInitialState,
  ...pageInitialState,
  ...SafetyScansErrorInitialState,
};

export const Actions = {
  ...orderInfoActions,
  ...shippingActions,
  ...pageActions,
  ...SafetyScansErrorActions,
};

export const reducer = (state, action) => {
  // Completed safety scans, reset for another order.
  if (action.type === "RESET") {
    console.log("Resetting...");
    return {
      ...state,
      ...InitialState,
    };
  }

  const act = Actions[action.type];
  const update = act(state, action);

  return { ...state, ...update };
};

export const useReshipOrderReducer = () => useReducer(reducer, InitialState);
export default useReshipOrderReducer;
