import { StrictMode } from "react";
import { render } from "react-dom";

import "./index.scss";
import App from "./ui/app";

const domNode = document.getElementById("root");

render(
  <StrictMode>
    <App />
  </StrictMode>,
  domNode,
);
