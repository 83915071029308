import { useCallback, useEffect } from "react";

import { OnKeyEventFilled, useKeyEvent } from "../../../hooks";
import { useLogin } from "../../../hooks/context/login";
import { IDiscReducerProps, discActions } from "../../../hooks/reducers/discs";
import useError, { ItemTriggerErrorCB } from "../../../hooks/use-error";
import type { Api } from "../../../types/api/discs";
import BaseButton from "../../base/button";
import TextInput from "../../base/text-input";

const { VITE_APP_API_URI } = import.meta.env;

/**
 * Requests order data from api
 * @param orderId unique order number
 * @param category disc category
 * @param token user request token
 * @param dispatch send to disc reducer
 * @param triggerError handles errors
 */
const requestOrderData = async (
  orderId: string,
  category: string,
  token: string,
  dispatch: IDiscReducerProps["dispatch"],
  triggerError: ItemTriggerErrorCB,
) => {
  const url = `https://${VITE_APP_API_URI}/discs/titles/${category}/${orderId}`;
  try {
    const res = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });

    if (res.ok === false) {
      const errBody = await res.json();
      triggerError(errBody.message);
    }
    const body = (await res.json()) as Api.Discs.IDiscs;

    dispatch({ type: discActions.ADD_ORDER, payload: body });
  } catch (error) {
    console.log(error);
  }
};

export const ScanOrderId = ({ state, dispatch }: IDiscReducerProps) => {
  const [{ usertoken }] = useLogin();
  const { errState, errMsg, trigger, clear } = useError();
  const { val, filled, clearInput, clearFilled } = useKeyEvent(true);

  const handleEvent: OnKeyEventFilled = useCallback(
    async (value) => {
      dispatch({ type: discActions.SET_ORDER, orderId: value });
      requestOrderData(value, state.discCategory, usertoken, dispatch, trigger);
      clearFilled();
    },
    [state.discCategory, usertoken, dispatch, trigger, clearFilled],
  );

  useEffect(() => {
    if (filled) {
      handleEvent(val);
    }
  }, [val, filled, handleEvent]);

  const clearError = () => {
    clear();
    clearInput();
    clearFilled();
  };
  return (
    <section className="discs__scan-order">
      <h1>Scan Order #</h1>
      <TextInput val={val} error={errState} errorMsg={errMsg} />
      {errState && (
        <BaseButton className="discs__clear-error" onClick={clearError}>
          Clear
        </BaseButton>
      )}
    </section>
  );
};

export default ScanOrderId;
