import { Dispatch } from "react";

import { useLogin } from "../../../../hooks/context/login";
import {
  EditFormatsActions,
  SET_ORDER_ID,
  SET_ORDER_ITEMS,
  STEP_DISPLAY,
} from "../../../../hooks/reducers/admin/edit-formats";
import { Api } from "../../../../types/api";
import ScanOrderElement from "../scan-order";
import type { AdminScanOrderRESTErrorMap } from "../scan-order";

const { VITE_APP_API_URI } = import.meta.env;

interface ScanOrderSubmitHandler {
  (order: string, access: string): Promise<void | AdminScanOrderRESTErrorMap>;
}
export interface ScanOrderProps {
  submitFn: ScanOrderSubmitHandler;
  header?: string;
  buttonText?: string;
}

const requestOrderData = async (
  usertoken: string,
  orderId: string,
  accessCode: string,
  dispatch: Dispatch<EditFormatsActions>,
): Promise<void> => {
  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/production/order/${orderId}`,
      {
        headers: new Headers({ Authorization: `Bearer ${usertoken}` }),
      },
    );
    if (!response.ok) {
      let message;
      switch (response.status) {
        case 404:
          message = "Order not found";
          break;
        case 500:
        default:
          message = "Something went wrong";
      }
      throw new Error(message);
    }
    const data: Api.IProductionOrder = await response.json();
    if (data.access_code !== accessCode) {
      throw new Error("Access code mismatch");
    }

    dispatch({ type: SET_ORDER_ID, payload: orderId });
    dispatch({ type: SET_ORDER_ITEMS, payload: data.items });
    dispatch({ type: STEP_DISPLAY });
  } catch (error) {
    console.log(error);
  }
};

const ScanOrder = ({
  dispatch,
}: {
  dispatch: Dispatch<EditFormatsActions>;
}) => {
  const [{ usertoken }] = useLogin();
  const submitHandler: ScanOrderSubmitHandler = (
    order: string,
    access: string,
  ) => requestOrderData(usertoken, order, access, dispatch);

  return <ScanOrderElement submitFn={submitHandler} />;
};

export default ScanOrder;
