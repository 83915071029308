import { useCallback } from "react";
import { useState, useEffect } from "react";
import {
  KeyActions,
  KeyStreamEvent,
  useKeyContext,
} from "../../../hooks/context/scan";
import { styled, css } from "../../../stitches.config";

import Button from "../../components/button/button";
import ReadonlyInput from "../../components/text-inputs/readonly";

export const NumberButton = styled(Button, {
  width: "100%",
  height: "60px",
  margin: "auto",
  backgroundColor: "$darkGray",
  color: "$white",
  fontSize: "$lg",
});

export const SubmitButton = styled(Button, {
  backgroundColor: "$green",
  gridColumn: "2/-1",
  width: "100%",
  height: "60px",
  margin: "auto",
  color: "$white",
  fontSize: "$lg",
});

const numberPad = css({
  width: "310px",
  height: "500px",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
});

const numberPadHeader = css({
  fontSize: "$md",
  textTransform: "uppercase",
  textAlign: "center",
  minHeight: "24px",
});

const numberPadInput = css({
  position: "relative",
  width: "80%",
  margin: "2px auto",
});

const numberPadButtons = css({
  display: "grid",
  width: "80%",
  margin: "1px auto 0",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "repeat(3, 1fr)",
  gridRowGap: "10px",
  gridColumnGap: "10px",
});

/**
 * Generates a list of numeric keys for pin pad
 * @returns number key values
 */
export function getKeys() {
  const numKeys = Array.from(Array(10).keys());
  numKeys.shift();
  numKeys.push(0);
  return numKeys;
}

export interface NumberPadProps {
  /** Number pad header */
  header: string;
  /** Pad input display */
  value: string;
  /** Update entry function */
  onNumberClick: (entry: string) => void;
  /** Fire on submit click */
  onSubmit: () => void;
  /** Fire on clear input click */
  onClear: () => void;
  /** Pad is in error state */
  errState: boolean;
  /** Display message for error state */
  errMsg: string;
}

export function NumberPad({
  header,
  value,
  onNumberClick,
  onSubmit,
  onClear,
  errState,
  errMsg,
}: NumberPadProps) {
  const keys = getKeys();

  return (
    <section className={numberPad()}>
      <h1 className={numberPadHeader()}>{header}</h1>
      <article className={numberPadInput()}>
        <ReadonlyInput
          value={value}
          isClearButton={true}
          onClearClick={onClear}
          isError={errState}
          errorMsg={errMsg}
        />
      </article>
      <article className={numberPadButtons()}>
        {keys.map((key) => (
          <NumberButton key={key} onClick={() => onNumberClick(key.toString())}>
            {key}
          </NumberButton>
        ))}
        <SubmitButton onClick={onSubmit}>
          Submit <span>➡</span>
        </SubmitButton>
      </article>
    </section>
  );
}

export function isEntryValid(
  maxLength: number,
  entry: string,
  key: unknown,
): boolean {
  return maxLength > entry.length && !isNaN(Number(key));
}

type StatefulNumberPadProps = Partial<
  Pick<NumberPadProps, "header" | "errMsg" | "errState">
> & {
  maxLength?: number;
  onSubmit: (value: string) => void;
  onClear?: () => void;
};

export default function StatefulNumberPad({
  onSubmit,
  onClear,
  header = "",
  maxLength = 4,
  errState = false,
  errMsg = "",
}: StatefulNumberPadProps) {
  // map to existing number pad state
  const subscribe = useKeyContext();
  const [entry, setEntry] = useState("");

  const handleNumberClick = (value: string) => {
    return setEntry((e) => {
      if (!isEntryValid(maxLength, e, value)) {
        return e;
      }
      return `${e}${value}`;
    });
  };
  const handleClearClick = () => {
    if (onClear !== undefined) {
      onClear();
    }
    setEntry("");
  };
  const handleSubmit = useCallback(() => {
    onSubmit(entry);
  }, [entry, onSubmit]);

  const passProps = { header, errState, errMsg };

  useEffect(() => {
    const keyHandler = (event: KeyStreamEvent) => {
      switch (event.type) {
        case KeyActions.key:
          return setEntry((e) => {
            if (!isEntryValid(maxLength, e, event.key)) {
              return e;
            }
            return `${e}${event.key}`;
          });
        case KeyActions.delete:
          return setEntry((e) => e.slice(0, e.length - 1));
        case KeyActions.enter:
          return handleSubmit();
        default:
          throw new Error(`unrecognized key action: ${JSON.stringify(event)}`);
      }
    };
    const unsubscribe = subscribe(keyHandler);

    return () => {
      unsubscribe();
    };
  }, [subscribe, handleSubmit, maxLength]);

  return (
    <NumberPad
      {...passProps}
      onSubmit={handleSubmit}
      value={entry}
      onNumberClick={handleNumberClick}
      onClear={handleClearClick}
    />
  );
}
