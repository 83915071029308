import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDymoService } from "../../hooks/use-dymo-service";
import AdminHome from "./admin/admin-home";
import CreateFolder from "./admin/create-folder";
import EditItems from "./admin/edit-items";
import ReprintBarcodes from "./admin/reprint-barcodes";
import ReprintLabel from "./admin/reprint-label";
import ReprintSlip from "./admin/reprint-slip";
import Reship from "./admin/reship";
import Rts from "./admin/rts";
import ShipBalanceDue from "./admin/ship-balance-due/app";
import SplitOrder from "./admin/split-order";
import CreateFolderIcon from "@images/icons/app-admin-create-folder.svg?react";
import EditItemsIcon from "@images/icons/app-admin-edit-items.svg?react";
import ReprintBarcodeIcon from "@images/icons/app-admin-reprint-barcode.svg?react";
import ReprintSlipIcon from "@images/icons/app-admin-reprint-slip.svg?react";
import RtsIcon from "@images/icons/app-admin-return-to-sender.svg?react";
import SplitOrderIcon from "@images/icons/app-admin-split-order.svg?react";
import AdminHomeIcon from "@images/icons/app-admin.svg?react";

import "./admin.scss";

export const AdminApps = [
  {
    name: "Admin-Home",
    app: "",
    path: "/admin",
    element: <AdminHome />,
    icon: AdminHomeIcon,
  },
  {
    name: "Edit-Items",
    app: "edit-items",
    path: "/admin/edit-items",
    element: <EditItems />,
    icon: EditItemsIcon,
  },
  {
    name: "Reprint-Slip",
    app: "reprint-slip",
    path: "/admin/reprint-slip",
    element: <ReprintSlip />,
    icon: ReprintSlipIcon,
  },
  {
    name: "Fulfillment-Label",
    app: "fulfillment-label",
    path: "/admin/fulfillment-label",
    element: <ReprintLabel />,
    icon: ReprintSlipIcon,
  },
  {
    name: "Return-To-Sender",
    app: "return-to-sender",
    path: "/admin/return-to-sender",
    element: <Rts />,
    icon: RtsIcon,
  },
  {
    name: "Split Order",
    app: "split-order",
    path: "/admin/split-order",
    element: <SplitOrder />,
    icon: SplitOrderIcon,
  },
  {
    name: "Reprint Barcodes",
    app: "reprint-barcodes",
    path: "/admin/reprint-barcodes",
    element: <ReprintBarcodes />,
    icon: ReprintBarcodeIcon,
  },
  {
    name: "Create Folder",
    app: "create-folder",
    path: "/admin/create-folder",
    element: <CreateFolder />,
    icon: CreateFolderIcon,
  },
  {
    name: "Reship Order",
    app: "reship-order",
    path: "/admin/reship-order",
    element: <Reship />,
  },
  {
    name: "Ship Balance Due",
    app: "ship-balance-due",
    path: "/admin/ship-balance-due",
    element: <ShipBalanceDue />,
  },
] as const;

type AdminAppName = (typeof AdminApps)[number]["app"];
type AdminRoute = {
  name: string;
  app: AdminAppName;
  path: string;
  element: JSX.Element;
  icon?: any;
};

const useAdminPage = (app?: string) => {
  const [active, setActive] = useState<AdminRoute>(AdminApps[0]);

  useEffect(() => {
    if (app !== undefined) {
      const page = AdminApps.find((a) => a.app === app);
      if (page !== undefined) {
        setActive(page);
      }
    } else if (app === undefined) {
      setActive(AdminApps[0]);
    }
  }, [app, setActive]);

  return active;
};

export const Admin = () => {
  const { app } = useParams<{ app?: string }>();
  const active = useAdminPage(app);
  useDymoService(() => console.log(`Printer didn't connect`));

  return (
    <>
      <section className="admin__active-app">
        <h1>{active.name}</h1>
      </section>

      <main className="admin__content">{active.element}</main>
    </>
  );
};

export default Admin;
