import { useReducer } from "react";
import { format } from "date-fns/format";

import type { Api } from "../../types/api";
import { transformBackup } from "../../utils/order-helper";

export const SET_PRODUCTION_ORDER = "SET_PRODUCTION_ORDER";
export const SET_CHECKINS = "SET_CHECKINS";
export const SET_TIMELINE = "SET_TIMELINE";
export const SET_ALERTS = "SET_ALERTS";
export const RESOLVE_ALERT = "RESOLVE_ALERT";
export const SET_BACKUP_ORDER = "SET_BACKUP_ORDER";

export interface OrderViewState {
  productionOrderId: string;
  salesOrderId: number;
  customerId: string;
  orderData: Api.IProductionOrder;
  alertData: Api.IShipmentAlerts;
  checkinData: any;
  timelineData: any;
  backupOrderData: Api.IBackupOrder[];
}

export type FileTypeName = "video" | "audio" | "photo";
interface FileItem {
  files: string[];
}

type FilesObj = {
  [key in FileTypeName]: FileItem;
};

export interface BackUpFromApi {
  metadata: any;
  files: FilesObj;
}

export type OrderViewActions =
  | {
      type: typeof SET_PRODUCTION_ORDER;
      payload: any;
    }
  | {
      type: typeof SET_CHECKINS;
      payload: Api.ICheckin[];
    }
  | {
      type: typeof SET_TIMELINE;
      payload: Api.ITimelineNote[];
    }
  | {
      type: typeof SET_ALERTS;
      payload: Api.IShipmentAlerts;
    }
  | {
      type: typeof SET_BACKUP_ORDER;
      payload: BackUpFromApi;
    }
  | { type: typeof RESOLVE_ALERT; id: number };
export function getInitialState(orderId: string): OrderViewState {
  return {
    productionOrderId: orderId,
    salesOrderId: null,
    customerId: null,
    orderData: null,
    alertData: null,
    checkinData: null,
    timelineData: null,
    backupOrderData: null,
  };
}

const resolveAlert = (id: number, alertData: Api.IShipmentAlerts) => {
  const index = alertData.alerts.findIndex(({ alert_id }) => alert_id === id);
  if (index !== -1) {
    alertData.alerts[index].active = 0;
  }
  alertData.hasActiveAlert =
    alertData.alerts.findIndex(({ active }) => active) !== -1;
  return alertData;
};

export function orderReducer(state: OrderViewState, action: OrderViewActions) {
  switch (action.type) {
    case SET_PRODUCTION_ORDER:
      return {
        ...state,
        salesOrderId: action.payload.sales_order_id,
        customerId: action.payload.customer_id,
        orderData: { ...action.payload },
      };
    case SET_CHECKINS:
      // pulling this out in case I need to sort.
      const checkins = action.payload;
      checkins.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
      return {
        ...state,
        checkinData: { checkins, lastCheckin: checkins.pop() },
      };
    case SET_TIMELINE:
      const timeline = action.payload
        .filter((item) => item.note_text !== null || item.custom_text !== null)
        .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
        .map((item) => ({
          ...item,
          displayDate: format(new Date(item.created_at), "MM/dd/yyyy"),
        }));
      return { ...state, timelineData: timeline };
    case SET_BACKUP_ORDER:
      const backup = transformBackup(action.payload);
      return { ...state, backupOrderData: backup };
    case SET_ALERTS:
      return {
        ...state,
        alertData: action.payload,
      };
    case RESOLVE_ALERT:
      return {
        ...state,
        alertData: resolveAlert(action.id, state.alertData),
      };
    default:
      throw new Error(`Unhandled action type: ${JSON.stringify(action)}`);
  }
}

export default function useOrderViewReducer(orderId: string) {
  const initialState = getInitialState(orderId);
  return useReducer(orderReducer, initialState);
}

export type OrderViewReducer = ReturnType<typeof useOrderViewReducer>;
