import type { PassFailToggle } from "../../../../ui/components/pass-fail/pass-fail";
import { RESET_STATE, ResetAction } from "../reset-action";

// Action types
export const QA_PROCESS = "QA_PROCESS";

export type QaProcessingAction = {
  type: typeof QA_PROCESS;
  payload: Partial<QaProcessingState>;
};

interface IStringKeyState {
  [key: string]: PassFailToggle;
}

export interface QaProcessingState extends IStringKeyState {
  /** Tracking label applied correctly */
  label: PassFailToggle;
  /** Box correctly taped closed */
  tape: PassFailToggle;
  /** Box packed securely and neatly */
  packed: PassFailToggle;
  /** Item count matches */
  itemCount: PassFailToggle;
  /** All output options present */
  outputs: PassFailToggle;
  /** Optional check for if thumb drive files are on device */
  thumbDriveFiles?: PassFailToggle;
  /** Optional check of cloud folder */
  cloudFiles?: PassFailToggle;
}

export const QaProcessInitialState: QaProcessingState = {
  label: "none",
  tape: "none",
  packed: "none",
  // packaging: "none",
  itemCount: "fail",
  outputs: "none",
};

export const setOutside = (
  label: PassFailToggle,
  tape: PassFailToggle,
  packed: PassFailToggle,
): QaProcessingAction => ({
  type: QA_PROCESS,
  payload: {
    label,
    tape,
    packed,
  },
});

export const setItemCount = (
  itemCount: PassFailToggle,
): QaProcessingAction => ({
  type: QA_PROCESS,
  payload: { itemCount },
});

export const setOutputs = (outputs: PassFailToggle): QaProcessingAction => ({
  type: QA_PROCESS,
  payload: { outputs },
});

type FileKeys = keyof Pick<QaProcessingState, "cloudFiles" | "thumbDriveFiles">;
export const setFiles = (files: {
  [key in FileKeys]: PassFailToggle;
}): QaProcessingAction => ({
  type: QA_PROCESS,
  payload: files,
});

export default function reducer(
  state: QaProcessingState,
  action: QaProcessingAction | ResetAction,
): QaProcessingState {
  switch (action.type) {
    case QA_PROCESS:
      return { ...state, ...action.payload };
    case RESET_STATE:
      return QaProcessInitialState;
    default:
      return state;
  }
}
