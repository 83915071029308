import { useCallback, useEffect, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import { useDymoService } from "../../../hooks/use-dymo-service";
import useKeyEvent from "../../../hooks/use-key-event";
import { printShippingLabel } from "../../../utils/dymo-print";
import classNames from "classnames";

import "./reprint-label.scss";

const { VITE_APP_FULFILLMENT_API_URI } = import.meta.env;

interface OrderData {
  orderId: string;
  kitName: string;
  shippingType: string;
  tracking: string;
  scannedTracking: string;
}
const OrderDataInitialState: OrderData = {
  orderId: "",
  kitName: "",
  shippingType: "",
  tracking: "",
  scannedTracking: "",
};

const getShippingLabel = async (
  scan: string,
  usertoken: string,
  onComplete: (data: OrderData) => void,
  onError: (errorMessage: string) => void,
): Promise<void> => {
  try {
    let orderId: string, name: string, variant: string, channel: string;
    try {
      const {
        salesOrderId,
        boxProductName,
        boxVariantName,
        channel: channelHold,
      } = JSON.parse(scan);
      orderId = salesOrderId;
      name = boxProductName;
      variant = boxVariantName;
      channel = channelHold;
    } catch (error) {
      console.error(error);
      throw new Error(`Invalid Scan: '${scan}'. Retry QR code`);
    }

    const req = await fetch(
      `${VITE_APP_FULFILLMENT_API_URI}/shipment/${orderId}/reprint-label`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          channel,
        }),
      },
    );

    const payload = await req.json();

    if (!req.ok) {
      throw new Error(payload.errorMessage);
    }
    const printResult = await printShippingLabel(payload.labelImage);
    if (!printResult.printComplete) {
      throw new Error("Unable to print shipping label.");
    }

    onComplete({
      orderId,
      kitName: `${name} - ${variant}`,
      tracking: payload.tracking,
      shippingType: payload.shippingType,
      scannedTracking: "",
    });
  } catch (error) {
    console.error(error);
    onError(error.message);
  }
};

const trimQuotes = (scan: string) => {
  const startIndex = Number(scan[0] === `"`),
    endIndex = scan.length - Number(scan[scan.length - 1] === `"`);
  return scan.substring(startIndex, endIndex);
};

const useReprintLabel = () => {
  const [{ usertoken }] = useLogin();
  const { val, filled: scanDone, clearInput, clearFilled } = useKeyEvent(true);
  const [orderData, setOrderData] = useState<OrderData>(OrderDataInitialState);
  const [errorMessage, setError] = useState<string>(null);

  const resetKeyEvent = useCallback(() => {
    clearInput();
    clearFilled();
  }, [clearInput, clearFilled]);

  useDymoService(() => {});

  useEffect(() => {
    if (!scanDone || val === "") {
      return;
    }
    getShippingLabel(
      trimQuotes(val),
      usertoken,
      (data) => {
        resetKeyEvent();
        setOrderData(data);
        setError(null);
      },
      (errorMessage: string) => {
        resetKeyEvent();
        setError(errorMessage);
        setOrderData(OrderDataInitialState);
      },
    );
  }, [
    val,
    scanDone,
    usertoken,
    clearInput,
    clearFilled,
    orderData.orderId,
    orderData.tracking,
    resetKeyEvent,
  ]);
  const reset = () => {
    resetKeyEvent();
    setOrderData(OrderDataInitialState);
  };

  return {
    orderData,
    errorMessage,
    isError: errorMessage !== null && errorMessage.length > 0,
    val,
    reset,
  };
};

export const ReprintLabel = () => {
  const { orderData, errorMessage } = useReprintLabel();

  return (
    <main className="reprint-label__main">
      <h1>Scan Welcome Guide</h1>
      <h2>Scan the QR code on the back cover to print a shipping label</h2>
      <section className="reprint-label__scan-wrapper">
        <section className={"reprint-label__kit-data-header"}>
          <h3>Order ID</h3>
        </section>
        <section className="scan-row__wrapper">
          <section className={"scan-row__input-row"}>
            <div
              className={classNames("scan-row__scan-input", {
                valid: !errorMessage,
              })}
            >
              {orderData.orderId}
            </div>
            <div
              className={classNames("scan-row__checkmark", {
                inactive: !!errorMessage,
              })}
            >
              ✓
            </div>
          </section>
        </section>
      </section>
      {errorMessage && (
        <article className="reprint-label__error">{errorMessage}</article>
      )}
      <article className="reprint-label__kit">{orderData.kitName}</article>
      {orderData?.shippingType === "express" && (
        <article className="reprint-label__shipping-type">
          Express Shipping
        </article>
      )}
    </main>
  );
};

export default ReprintLabel;
