export const SET_CELL = "SET_CELL";
export const CHECK_VALID_DEPT = "CHECK_VALID_DEPT";

/**
 * @typedef {Object} DepartmentInfoState
 * @property {?{id:string, name:string}} cell Cell location ID
 * @property {?string} departmentName Department display name ('Tapes', 'Teams', etc.)
 * @property {?boolean} validForOrder
 */

const checkValidDept = (state, { analogItems }) => {
  const {
    departmentName,
    cell: { name: cellName },
  } = state.departmentsData;
  if (/teams|balance-due|digitizing/i.test(departmentName)) {
    return state;
  }
  const items = analogItems.filter(
    ({ product_name }) => product_name !== "Unsupported",
  );
  if (items.length === 0) {
    return state;
  }
  const currentDeptItems = items.filter(({ product_name }) =>
    cellName.includes(product_name),
  );

  if (currentDeptItems.length === 0) {
    return {
      departmentsData: {
        ...state.departmentsData,
        validForOrder: false,
      },
    };
  }
  return state;
};

const setDeptData = (_, { cell }) => {
  // If old checkin location, use department name (Tapes, Film, etc.)
  const locationData = cell.id.split("_");
  const departmentName = locationData[1];
  return { departmentsData: { cell, departmentName } };
};

/** @type {{departmentsData: DepartmentInfoState}} */
export const departmentsInitialState = {
  departmentsData: {
    cell: null,
    departmentName: null,
    validForOrder: null,
  },
};

export const departmentsActions = {
  SET_CELL: setDeptData,
  CHECK_VALID_DEPT: checkValidDept,
};
