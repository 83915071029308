import { useEffect, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import {
  RESET_ITEMS,
  SET_ITEM_COUNT,
} from "../../../hooks/reducers/actions/receiving/category";
import { PAGE_STEP } from "../../../hooks/reducers/actions/receiving/page";
import useError from "../../../hooks/use-error";
import BaseButton from "../../base/button";
import NumberPad from "../../groups/number-pad";

import "./count-items.scss";

const { VITE_APP_API_URI } = import.meta.env;

const logCount = async (usertoken, orderId, count, boxSize) => {
  try {
    await fetch(
      `https://${VITE_APP_API_URI}/timeline/order/${orderId}/receivingitemcount`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ orderId, count, boxSize }),
      },
    );
  } catch (error) {
    console.error(error.message);
  }
};

export const ItemCount = ({ orderId, boxSize, dispatch }) => {
  const [entry, setEntry] = useState("");
  const [{ usertoken }] = useLogin();
  const [qty, setQty] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const error = useError();
  const handleSubmit = () => {
    if (entry === "" || entry === "0") {
      error.trigger("Invalid count");
      return;
    }
    const qty = Number(entry);
    if (qty >= boxSize) {
      setConfirm(true);
      logCount(usertoken, orderId, qty, boxSize);
    }
    setQty(qty);
    setEntry("");
    error.clear();
  };
  useEffect(() => {
    if (qty !== null && confirm === true) {
      dispatch({ type: SET_ITEM_COUNT, count: qty });
      dispatch({ type: PAGE_STEP });
    } else if (qty !== null && confirm === false) {
      setQty(null);
      setConfirm(null);
      setEntry("");
    }
  }, [qty, confirm, dispatch]);
  return (
    <section className="count-items__count--wrapper">
      {qty === null && (
        <NumberPad
          entry={entry}
          setEntry={setEntry}
          header={"Count total # of items"}
          maxLength={5}
          handleSubmit={handleSubmit}
          {...error}
        />
      )}
      {qty !== null && confirm === null && (
        <>
          <h1 className="count-items__count--header">
            Received fewer items than box size
          </h1>
          <article className="count-items__count--msg">
            This box could have up to <span>{boxSize}</span> items.
          </article>
          <article className="count-items__count--msg">
            Please double check we&apos;ve received <span>{qty}</span>{" "}
            {`  item${qty !== 1 ? "s" : ""}.`}
          </article>
          <section className="count-items__count--btns">
            <BaseButton
              onClick={() => setConfirm(false)}
              className="count-items__count--btn no"
            >
              GO BACK
            </BaseButton>
            <BaseButton
              onClick={() => {
                logCount(usertoken, orderId, qty, boxSize);
                setConfirm(true);
              }}
              className="count-items__count--btn yes"
            >
              CONFIRM
            </BaseButton>
          </section>
        </>
      )}
    </section>
  );
};

export const CountItems = ({ totalItemCount, orderInfo, dispatch }) => {
  return (
    <>
      {totalItemCount === null ? (
        <ItemCount
          boxSize={orderInfo.mainProduct.size}
          orderId={orderInfo.salesOrderId}
          dispatch={dispatch}
        />
      ) : (
        <section className="count-items__recount--wrapper">
          <article className="count-items__recount--msg">
            {`You counted `}
            <span>{totalItemCount}</span>
            {`item${totalItemCount !== 1 ? "s" : ""} in total.`}
          </article>
          <BaseButton
            className="count-items__recount--btn"
            onClick={() => {
              dispatch({ type: RESET_ITEMS });
            }}
          >
            Recount
          </BaseButton>
        </section>
      )}
    </>
  );
};

export default CountItems;
