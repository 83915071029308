import { type ReactNode, useEffect, useState } from "react";

import {
  nextPage,
  setPage,
} from "../../../../hooks/reducers/actions/page-step";
import { setFiles as sendFiles } from "../../../../hooks/reducers/actions/qa/processing";
import type {
  QaReducerProps,
  TQaProcessPages,
} from "../../../../hooks/reducers/qa-process";
import { styled } from "../../../../stitches.config";
import PassFail, {
  usePassFailToggle,
} from "../../../components/pass-fail/pass-fail";
import TouchButton from "../../../components/touch-button/touch-button";

export interface IFindOutputs {
  disc: boolean;
  drive: boolean;
  download: boolean;
}

export function findOutputs(state: QaReducerProps["state"]): IFindOutputs {
  return state.orderInfo.order.items.reduce<IFindOutputs>(
    (group, current) => {
      if (current.product_type_id !== 2) {
        return group;
      }

      switch (current.output_type) {
        case "DVD Set":
          return { ...group, disc: true };
        case "Thumb Drive":
          return { ...group, drive: true };
        case "Digital Download":
          return { ...group, download: true };
        default:
          return group;
      }
    },
    { disc: false, drive: false, download: false },
  );
}

interface WrapperProps {
  flexdirection?: "row" | "column";
  children?: ReactNode;
}
const styledWrapper = (props?: WrapperProps) =>
  styled("section", {
    marginTop: "20px",
    flexDirection: props?.flexdirection ?? "column",

    "> *": {
      marginTop: "20px",
    },
  });

const Wrapper = (props: WrapperProps) => {
  const StyledWrapper = styledWrapper(props);
  return <StyledWrapper {...props} />;
};

export default function Files({ state, dispatch }: QaReducerProps) {
  const outputs = findOutputs(state);
  const { processing } = state;
  const [drive, setDrive] = usePassFailToggle(
    processing?.thumbDriveFiles ?? "none",
  );
  const [files, setFiles] = usePassFailToggle(processing?.cloudFiles ?? "none");
  const [next, setNext] = useState<boolean>(false);

  // skip page if this order is discs only
  useEffect(() => {
    // if no files to check go next
    if (outputs.drive === false && outputs.download === false) {
      dispatch(nextPage());
    }
  }, [outputs, dispatch]);

  // Handle when to enable next button
  useEffect(() => {
    // if no thumbdrive but has download and download is chosen
    if (
      outputs.drive === false &&
      outputs.download === true &&
      files !== "none"
    ) {
      setNext(true);
    }
    // if no download but has thumbdrive and thumbdrive is chosen
    else if (
      outputs.download === false &&
      outputs.drive === true &&
      drive !== "none"
    ) {
      setNext(true);
    }
    // if both and both chosen
    else if (
      outputs.download === true &&
      outputs.drive === true &&
      drive !== "none" &&
      files !== "none"
    ) {
      setNext(true);
    }
  }, [outputs, files, drive]);

  return (
    <>
      {outputs.drive && (
        <PassFail
          label="Plug in thumb drive and check files"
          toggle={drive}
          onPass={() => setDrive("pass")}
          onFail={() => setDrive("fail")}
        />
      )}
      {outputs.download && (
        <>
          <Wrapper>
            <TouchButton
              as="a"
              href={state.orderInfo.order.drive_link}
              target="_blank"
            >
              Cloud Link
            </TouchButton>
            <PassFail
              label="Check drive link"
              toggle={files}
              onPass={() => setFiles("pass")}
              onFail={() => setFiles("fail")}
            />
          </Wrapper>
        </>
      )}
      <Wrapper flexdirection="row">
        <TouchButton
          size="lg"
          onClick={() => {
            dispatch(setPage<TQaProcessPages>("outputs"));
          }}
        >
          Back
        </TouchButton>
        <span style={{ width: "2em" }}></span>
        <TouchButton
          size="lg"
          disabled={!next}
          onClick={() => {
            dispatch(sendFiles({ cloudFiles: files, thumbDriveFiles: drive }));
            dispatch(nextPage());
          }}
        >
          Next
        </TouchButton>
      </Wrapper>
    </>
  );
}
