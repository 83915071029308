import { useCallback } from "react";

import useArrivalReducer, {
  TRACKING_DAMAGED,
} from "../../../hooks/reducers/arrival";
// Hooks
import useError from "../../../hooks/use-error";
import upsGround from "../../../images/ups-ground.png";
import upsSurepost from "../../../images/ups-surepost.png";
import BaseButton, { type BaseButtonClickHandler } from "../../base/button";
import TextInput from "../../base/text-input";
import IndicatorLight from "../../components/indicator-light";

interface TrackingInputProps {
  className: string;
  error: boolean;
  errorMsg: string;
  val: string;
}

export const TrackingInput = ({
  className,
  error,
  errorMsg,
  val,
}: TrackingInputProps) => {
  return (
    <div className="arrival__tracking-input-wrapper">
      <TextInput
        className={className}
        val={val}
        error={error}
        errorMsg={errorMsg}
      />
    </div>
  );
};

const ScanBox = ({ onClick }: { onClick: () => void }) => {
  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();
  const [state, dispatch] = useArrivalReducer(triggerError, clearError);
  const isOrderFlagCyberWeek = state.orderFlag === "CYBER WEEK";

  const handleTrackingDamage: BaseButtonClickHandler = useCallback(
    (event) => {
      event.currentTarget.blur();
      dispatch({ type: TRACKING_DAMAGED });
    },
    [dispatch],
  );

  return (
    <>
      <section className="arrival__title">
        Let the customer know their order has arrived
        <h1>SCAN TRACKING #</h1>
      </section>
      <IndicatorLight
        light={isOrderFlagCyberWeek ? "fail-cyber-week" : "fail"}
        symbol={"✖"}
        active={errState}
        wrapper="arrival__fail"
      />
      <TrackingInput
        val={state.entry}
        className="arrival__scan-input"
        error={errState}
        errorMsg={errMsg}
      />
      <IndicatorLight
        light="pass"
        symbol={"✓"}
        active={state.passScan && !errState}
        wrapper="arrival__pass"
      />
      <section className="arrival__order-flag">{state.orderFlag}</section>
      <section className="arrival__label-examples">
        <img src={upsGround} alt="UPS Ground Label" />
        <img src={upsSurepost} alt="UPS Surepost Label" />
      </section>
      <section className="arrival__total-scanned">
        <span className="arrival__scan arrival__scan--good">
          Scans: {state.scans}
        </span>
        <span className="arrival__scan arrival__scan--fail">
          Errors: {state.errors}
        </span>
        <span className="arrival__scan arrival__scan--total">
          Total: {state.total}
        </span>
      </section>
      <section className="arrival__done-scanning">
        <BaseButton onClick={onClick} className="arrival__button">
          Finished Scanning
        </BaseButton>
      </section>
      <section className="arrival__tracking-damaged">
        <BaseButton
          onClick={handleTrackingDamage}
          className="arrival__button arrival__button--fail"
        >
          Tracking Damaged
        </BaseButton>
      </section>
    </>
  );
};

export default ScanBox;
