import { type Dispatch, useEffect, useState } from "react";

import { RESET } from "../../../hooks";
import { useLogin } from "../../../hooks/context/login";
import type { OrderInfoState } from "../../../hooks/reducers/actions/safetyscans/order-info";
import { PAGE_NAV } from "../../../hooks/reducers/actions/safetyscans/page";
import useError from "../../../hooks/use-error";
import useKeyEvent from "../../../hooks/use-key-event";
import YesNoButtons from "../../components/yes-no-buttons";
import { TrackingInput } from "../arrival/scan-box";

import "../admin/scan-order.scss";
import "./show-error.scss";

const { VITE_APP_API_URI } = import.meta.env;

export interface ActivateReorderBookletProps {
  orderInfo: OrderInfoState;
  dispatch: Dispatch<any>;
}
export const ActivateReorderBooklet = ({
  orderInfo,
  dispatch,
}: ActivateReorderBookletProps) => {
  const [{ usertoken }] = useLogin();
  const [isActivateReorderBooklet, setisActivateReorderBooklet] =
    useState(false);
  const {
    errState: orderIDError,
    errMsg: orderErrMsg,
    trigger: triggerError,
  } = useError();
  const { val, filled, clearAll } = useKeyEvent(true);
  const [isFocused, setFocus] = useState(!filled);

  useEffect(() => {
    if (filled) {
      setFocus(false);
    }
  }, [filled]);

  const handleFocus = () => {
    clearAll();
    setFocus(true);
  };

  const activateBooklet = async (input: string) => {
    try {
      const qrData = JSON.parse(input);
      const response = await fetch(
        `https://${VITE_APP_API_URI}/production/reorder/${orderInfo.salesOrderId}/activate`,
        {
          method: "PUT",
          headers: new Headers({
            Authorization: `Bearer ${usertoken}`,
          }),
          body: JSON.stringify({
            access_code: qrData.accessCode,
            reorder_id: qrData.salesOrderId,
          }),
        },
      );

      const body = await response.json();

      if (!response.ok) {
        const { message } = body;
        triggerError(message);
        return;
      }

      return dispatch({ type: PAGE_NAV, step: "Ship Order" });
    } catch (error) {
      console.log(error);
      triggerError("Failed to fetch.");
    }
  };

  const orderClass = `scan-order__order-input${
    isFocused ? " scan-order__order-input--active" : ""
  }`;

  if (!isActivateReorderBooklet) {
    return (
      <section className={"show-error__wrapper"}>
        <>
          <article className="upgrades__explainer">
            Do you want to activate reorder booklet?
          </article>
        </>
        <YesNoButtons
          yesText="Yes"
          noText="No"
          yesClick={() => {
            setisActivateReorderBooklet(true);
          }}
          noClick={() => dispatch({ type: PAGE_NAV, step: "Ship Order" })}
        />
      </section>
    );
  }

  return (
    <>
      <section className="deliverables__wrapper">
        <h1>Activate Reorder Booklet</h1>
        <label onClick={() => handleFocus()}>
          Reorder:{" "}
          <TrackingInput
            error={orderIDError}
            errorMsg={orderErrMsg}
            className={orderClass}
            val={val}
          />
        </label>
        <YesNoButtons
          isYesDisabled={isFocused}
          yesText="Activate Reorder"
          noText="Go Again!"
          yesClick={() => {
            activateBooklet(val);
          }}
          noClick={() => dispatch({ type: RESET })}
        />
      </section>
    </>
  );
};

export default ActivateReorderBooklet;
