import { useCallback, useEffect, useState } from "react";
import type { Dispatch, SetStateAction } from "react";

// Hooks
import useApi from "../../hooks/use-api";
import type { Api } from "../../types/api";
// Components
import Button from "../base/button";
import { FetchList } from "./fetch-list/fetch-list";
import classnames from "classnames";
import deepEqual from "deep-equal";

import "./fetch-view.scss";

export type ListItem = Api.IFetchListItem;
export interface IFetchList extends Api.IFetchList {
  all: Api.IFetchListItem[];
  today: Api.IFetchListItem[];
}

interface FetchListRush extends Omit<Api.IFetchList, "balancedue"> {}
type FetchListBalanceDue = Api.IFetchListBalanceDue;

const getAllList = (lists: Api.IFetchList): ListItem[] => {
  if (lists == null) {
    return [];
  }
  const keys = Object.keys(lists) as Array<keyof Api.IFetchList>;
  return keys.reduce<ListItem[]>(
    (all: ListItem[], key: keyof Api.IFetchList) => {
      if (key === "inbound") {
        return all;
      }
      return [...all, ...lists[key]];
    },
    [],
  );
};

const getFetchListUrlPerList = (activeList: keyof IFetchList): string => {
  switch (activeList) {
    case "balancedue":
      return "production/fetchlist/balancedue";
    case "digitizing":
      return "production/fetchlist/digitizing";
    case "postcard":
      return "production/fetchlist/postcard";
    case "arrived":
      return "production/fetchlist/arrived";
    case "queue":
      return "production/fetchlist/queue";
    case "today":
      return "production/fetchlist/today";
    default:
      return "production/fetchlist/rush";
  }
};

const isListsEqual = (lists: IFetchList, newList: IFetchList) => {
  const isLengthEqual = (Object.keys(newList) as (keyof IFetchList)[]).reduce(
    (isPrevEqual, key) => {
      return isPrevEqual === false
        ? isPrevEqual
        : newList[key]?.length === lists[key]?.length;
    },
    true,
  );

  if (!isLengthEqual) return false;

  return deepEqual(lists, newList);
};

const useFetchListByActiveList = (
  forActiveList: keyof IFetchList,
  shouldExecute: boolean,
) => {
  const [lists, setLists] = useState<
    FetchListBalanceDue | Partial<FetchListRush>
  >(null);
  const [execute, setExecute] = useState(lists === null && shouldExecute);
  const {
    body,
    isIdle,
    isLoading,
    isResolved,
    isRejected,
    actions,
    apiDispatch,
  } = useApi<Api.IFetchList>(
    getFetchListUrlPerList(forActiveList),
    "get",
    execute,
  );

  useEffect(() => {
    if (isIdle || isLoading) {
      return;
    }
    if (isResolved) {
      setLists(body);
      return;
    }
    if (isRejected) {
      setExecute(false);
      return;
    }
  }, [body, isIdle, isLoading, isResolved, isRejected]);

  const reload = () => {
    setExecute(true);
    apiDispatch({ type: actions.RESET });
  };

  return {
    lists,
    isLoading,
    isIdle,
    reload,
  };
};

const useFetchListByActiveListCombined = (activeList: keyof IFetchList) => {
  const rush = useFetchListByActiveList("today", true);
  const balancedue = useFetchListByActiveList("balancedue", false);
  const digitizing = useFetchListByActiveList("digitizing", false);
  const postcard = useFetchListByActiveList("postcard", false);
  const arrived = useFetchListByActiveList("arrived", false);
  const queue = useFetchListByActiveList("queue", false);

  switch (activeList) {
    case "balancedue":
      return { ...balancedue, lists: balancedue?.lists ?? {} };
    case "digitizing":
      return { ...digitizing, lists: digitizing?.lists ?? {} };
    case "postcard":
      return { ...postcard, lists: postcard?.lists ?? {} };
    case "arrived":
      return { ...arrived, lists: arrived?.lists ?? {} };
    case "queue":
      return { ...queue, lists: queue?.lists ?? {} };
    default:
      return { ...rush, lists: rush?.lists ?? {} };
  }
};

const useFetchLists = (
  activeList: keyof IFetchList,
  isTodayVisited: boolean,
  setIsTodayVisited: Dispatch<SetStateAction<boolean>>,
  isBalanceDueVisited: boolean,
  setIsBalanceDueVisited: Dispatch<SetStateAction<boolean>>,
  isDigitizingVisited: boolean,
  setIsDigitizingVisited: Dispatch<SetStateAction<boolean>>,
  isPostcardVisited: boolean,
  setIsPostcardVisited: Dispatch<SetStateAction<boolean>>,
  isArrivedVisited: boolean,
  setIsArrivedVisited: Dispatch<SetStateAction<boolean>>,
  isQueueVisited: boolean,
  setIsQueueVisited: Dispatch<SetStateAction<boolean>>,
) => {
  const [lists, setLists] = useState<IFetchList>({
    all: [],
    arrived: [],
    balancedue: [],
    digitizing: [],
    inbound: [],
    postcard: [],
    queue: [],
    today: [],
    unknown: [],
  } as IFetchList);
  const isDefaultList = activeList === "today";
  const {
    lists: fromApiLists,
    isLoading,
    isIdle,
    reload,
  } = useFetchListByActiveListCombined(activeList);

  const visitedFn = () => {
    switch (activeList) {
      case "balancedue":
        return isBalanceDueVisited;
      case "digitizing":
        return isDigitizingVisited;
      case "postcard":
        return isPostcardVisited;
      case "arrived":
        return isArrivedVisited;
      case "queue":
        return isQueueVisited;
      default:
        return isTodayVisited;
    }
  };
  const isVisited = visitedFn();
  const setIsVisited = useCallback(
    (bool: boolean) => {
      switch (activeList) {
        case "balancedue":
          return setIsBalanceDueVisited(bool);
        case "digitizing":
          return setIsDigitizingVisited(bool);
        case "postcard":
          return setIsPostcardVisited(bool);
        case "arrived":
          return setIsArrivedVisited(bool);
        case "queue":
          return setIsQueueVisited(bool);
        default:
          return setIsTodayVisited(bool);
      }
    },
    [
      activeList,
      setIsBalanceDueVisited,
      setIsDigitizingVisited,
      setIsPostcardVisited,
      setIsTodayVisited,
      setIsArrivedVisited,
      setIsQueueVisited,
    ],
  );

  // for some reason, lists changes everytime causing infinite useEffect call
  const diffThenSetLists = useCallback(
    (newList) => {
      // so to prevent unnecessary setList when lists doesn't change
      if (isListsEqual(lists, newList)) {
        return;
      }

      setLists(newList);
    },
    [lists],
  );

  useEffect(() => {
    if (Object.keys(fromApiLists).length === 0) {
      return;
    }

    let allLists: IFetchList = {
      ...lists,
      ...fromApiLists,
    };

    const { all, today, ...noAllAndToday } = allLists;

    allLists = {
      ...allLists,
      all: getAllList(noAllAndToday as Api.IFetchList),
    } as IFetchList;

    diffThenSetLists(allLists);
  }, [
    activeList,
    isVisited,
    setIsVisited,
    lists,
    fromApiLists,
    diffThenSetLists,
  ]);

  // fetch api if first time visit
  useEffect(() => {
    if (isDefaultList || isVisited || !isIdle) return;

    setIsVisited(true);
    reload();
  }, [isDefaultList, isVisited, setIsVisited, reload, isIdle]);

  return { lists, setLists, reload, isLoading };
};

const Tabs: { title: string; field: keyof IFetchList }[] = [
  { title: "Added Today", field: "today" },
  { title: "Pull from Mail", field: "arrived" },
  { title: "Pull from Queue", field: "queue" },
  { title: "Pull from Digitizing", field: "digitizing" },
  { title: "Balance Due", field: "balancedue" },
  { title: "Postcard", field: "postcard" },
];

const useDeactivateRushOrder = () => {
  const [execute, setExecute] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [productionOrderId, setProductionOrderId] = useState("");
  const [success, setSuccess] = useState(null);
  const {
    body,
    isIdle,
    isLoading,
    isResolved,
    isRejected,
    actions,
    apiDispatch,
  } = useApi<Api.IFetchList>(
    `production/deactivate-rush/${orderId}`,
    "post",
    execute,
    {
      "Content-Type": "application/json",
    },
    JSON.stringify({ productionOrderId }),
  );

  useEffect(() => {
    if (isIdle || isLoading) {
      return;
    }
    if (isResolved) {
      setSuccess(true);
      return;
    }
    if (isRejected) {
      setSuccess(false);
      setExecute(false);
      return;
    }
  }, [body, isIdle, isLoading, isResolved, isRejected]);

  const sendDeactivateRush = (id: number, prodId: string) => {
    setOrderId(id);
    setProductionOrderId(prodId);
    setSuccess(null);
    setExecute(true);
    apiDispatch({ type: actions.RESET });
  };

  const resetDeactivateRushHook = () => {
    setOrderId(null);
    setProductionOrderId("");
    setSuccess(null);
  };

  return {
    isRejected,
    isLoading,
    isIdle,
    sendDeactivateRush,
    resetDeactivateRushHook,
    isResolveSuccess: success,
  };
};

interface GetTitleWithStatsArgs {
  tab: {
    field: keyof IFetchList;
    title: string;
  };
  lists: IFetchList;
  isBalanceDueVisited: boolean;
  isDigitizingVisited: boolean;
  isPostcardVisited: boolean;
  isArrivedVisited: boolean;
}

const getTitleWithStats = (vars: GetTitleWithStatsArgs): string => {
  const {
    tab: { field, title },
    lists,
  } = vars;
  const {
    isBalanceDueVisited,
    isDigitizingVisited,
    isPostcardVisited,
    isArrivedVisited,
  } = vars;

  const list = lists?.[field] || [];

  const isTabVisited = (currentList: keyof IFetchList) => {
    switch (currentList) {
      case "balancedue":
        return isBalanceDueVisited;
      case "digitizing":
        return isDigitizingVisited;
      case "postcard":
        return isPostcardVisited;
      case "arrived":
        return isArrivedVisited;
      default:
        return true;
    }
  };

  const overDueCount = list.filter(({ rush_date_due }) => {
    if (rush_date_due == null) {
      return false;
    }
    const dueDate = new Date(rush_date_due),
      now = new Date();
    return dueDate <= now;
  }).length;

  const titleText = `${title}: (${list.length}/${overDueCount})`;

  return isTabVisited(field) ? titleText : title;
};

export const FetchView = () => {
  const { isResolveSuccess, resetDeactivateRushHook, sendDeactivateRush } =
    useDeactivateRushOrder();
  const [activeList, setActiveList] = useState<keyof IFetchList>("today");
  const [isBalanceDueVisited, setIsBalanceDueVisited] = useState(false);
  const [isTodayVisited, setIsTodyVisited] = useState(false);
  const [isDigitizingVisited, setIsDigitizingVisited] = useState(false);
  const [isPostcardVisited, setIsPostcardVisited] = useState(false);
  const [isArrivedVisited, setIsArrivedVisited] = useState(false);
  const [isQueueVisited, setIsQueueVisited] = useState(false);

  const { lists, isLoading, reload } = useFetchLists(
    activeList,
    isTodayVisited,
    setIsTodyVisited,
    isBalanceDueVisited,
    setIsBalanceDueVisited,
    isDigitizingVisited,
    setIsDigitizingVisited,
    isPostcardVisited,
    setIsPostcardVisited,
    isArrivedVisited,
    setIsArrivedVisited,
    isQueueVisited,
    setIsQueueVisited,
  );

  const onDeactivateRush = async (orderId: number, prodId: string) => {
    sendDeactivateRush(orderId, prodId);
  };

  useEffect(() => {
    if (isResolveSuccess === true) {
      // success
      reload();
      resetDeactivateRushHook(); // prevent rerender loop
    } else if (isResolveSuccess === false) {
      // failed
    }
  }, [reload, isResolveSuccess, resetDeactivateRushHook]);

  return (
    <>
      <header className="fetch-view__header">Fetch List</header>
      <Button
        children={"Print"}
        onClick={() => {
          window.print();
        }}
        className="fetch-view__mode-btn"
      />
      <Button
        children={"Reload"}
        onClick={reload}
        className="fetch-view__reload-btn"
      />
      <main className={`fetch-view__main${isLoading ? " loading" : ""}`}>
        <ol className={`fetch-view__main__tab-list`}>
          {Tabs.map((tab) => (
            <li
              key={tab.field}
              className={classnames(`fetch-view__main__tab-list-item`, {
                active: tab.field === activeList,
              })}
              onClick={() => setActiveList(tab.field)}
            >
              {getTitleWithStats({
                tab,
                lists,
                isBalanceDueVisited,
                isDigitizingVisited,
                isPostcardVisited,
                isArrivedVisited,
              })}
            </li>
          ))}
        </ol>
        <section className="fetch-view__list">
          <FetchList
            onDeactivateRush={onDeactivateRush}
            activeList={activeList}
            list={lists?.[activeList] || []}
          />
        </section>
      </main>
    </>
  );
};
export default FetchView;
