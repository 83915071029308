export enum SafetyScansErrorActionTypes {
  SET_ERROR = "SAFETY_SCANS_SET_ERROR",
  CLEAR_ERROR = "SAFETY_SCANS_CLEAR_ERROR",
}
interface SafetyScansError {
  error: SafetyScansErrorState;
}
interface SafetyScansErrorState {
  errState: boolean;
  errMsg: string;
}
export const SafetyScansErrorInitialState: SafetyScansError = {
  error: {
    errState: false,
    errMsg: null,
  },
};

const setError = (
  _: SafetyScansError,
  { errState, errMsg }: SafetyScansErrorState,
): SafetyScansError => {
  console.log({ errState, errMsg });

  return {
    error: {
      errState,
      errMsg,
    },
  };
};
const clearError = (): SafetyScansError => SafetyScansErrorInitialState;

type SafetyScansErrorStateActionFn = (
  state: SafetyScansError,
  newState: SafetyScansErrorState,
) => SafetyScansError;
type SafetyScansErrorActionsMap = {
  [key in SafetyScansErrorActionTypes]: SafetyScansErrorStateActionFn;
};
export const SafetyScansErrorActions: SafetyScansErrorActionsMap = {
  [SafetyScansErrorActionTypes.SET_ERROR]: setError,
  [SafetyScansErrorActionTypes.CLEAR_ERROR]: clearError,
};
