import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useLogin } from "../../../hooks/context/login";
import {
  ADD_FILM_WITH_SOUND,
  REMOVE_FILM_WITH_SOUND,
  SET_ORDER_FIELDS,
} from "../../../hooks/reducers/actions/safetyscans/order-info";
import {
  COMPLETE_STEPS,
  PAGE_NAV,
} from "../../../hooks/reducers/actions/safetyscans/page";
import useError from "../../../hooks/use-error";
// Hooks
import useKeyEvent from "../../../hooks/use-key-event";
// Components
import GoButton from "../../components/go-button";
import ScanItem from "../../components/scan-item";
import YesNoButtons from "../../components/yes-no-buttons";

import "./scan-film-with-sound.scss";

// Globals
const { VITE_APP_API_URI } = import.meta.env;

const ItemInputs = ({ itemScans, active, dispatch }) => {
  return itemScans ? (
    <section className={"scan-film-with-sound__items-wrapper"}>
      {itemScans.map((item) => (
        <ScanItem
          key={item.barcode}
          text={item.barcode}
          active={true}
          activeClass={"active-film"}
          onClick={() =>
            dispatch({ type: REMOVE_FILM_WITH_SOUND, scan: item.barcode })
          }
        />
      ))}
      {active && <ScanItem text={"SCAN"} onClick={() => {}} />}
    </section>
  ) : (
    <></>
  );
};

const ErrorArea = ({ errState, errMsg }) =>
  errState ? (
    <section className="scan-film-with-sound__error-wrapper">
      <header className={"scan-film-with-sound__error-header"}>{errMsg}</header>
    </section>
  ) : (
    <></>
  );

const ScanPrompt = ({ appPath, categories, onClick, dispatch }) => {
  return (
    <section className="scan-film-with-sound__prompt-wrapper">
      <header className={"scan-film-with-sound__prompt-header"}>
        Are there any film reels with sound lines?
      </header>
      <YesNoButtons
        yesClick={() => onClick(true)}
        noClick={() => completeScanFilmStep(appPath, categories, dispatch)}
      />
    </section>
  );
};

const completeScanFilmStep = (appPath, categories, dispatch) => {
  const hasPhotos = categories.find(({ name }) => name === "Photos");
  if (/team|safetyscans/i.test(appPath) && hasPhotos) {
    dispatch({ type: PAGE_NAV, step: "Select Photos Quantity" });
  } else {
    dispatch({
      type: COMPLETE_STEPS,
      step: "Confirm All Delivery Methods",
    });
  }
};

const logFilmReels = async (
  orderInfo,
  triggerError,
  usertoken,
  appPath,
  dispatch,
) => {
  const { hasLoggedFilmWithSound, salesOrderId, filmWithSound, categories } =
    orderInfo;

  if (hasLoggedFilmWithSound === true || filmWithSound.length === 0) {
    completeScanFilmStep(appPath, categories, dispatch);
    return;
  }

  try {
    const response = await fetch(
      `https://${VITE_APP_API_URI}/timeline/order/${salesOrderId}/filmsoundlines`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          items: filmWithSound,
        }),
      },
    );

    const payload = await response.json();

    if (!response.ok) {
      throw new Error(payload.errorMessage);
    }

    dispatch({
      type: SET_ORDER_FIELDS,
      payload: { hasLoggedFilmWithSound: true },
    });
    completeScanFilmStep(appPath, categories, dispatch);
  } catch (error) {
    console.log(error);
    triggerError(error.message);
  }
};

export const FilmWithAudio = ({ orderInfo, dispatch }) => {
  const [{ usertoken }] = useLogin();
  const { pathname } = useLocation();
  const appPath = pathname.substr(1).toLowerCase();
  const { val, filled, clearInput, clearFilled } = useKeyEvent(true);
  const [reelsWithSound, setReelsWithSound] = useState(
    orderInfo.hasLoggedFilmWithSound ? true : null,
  );
  const { errState, errMsg, trigger: triggerError } = useError();

  useEffect(() => {
    if (!filled) {
      return;
    }
    clearFilled();
    clearInput();
    if (!val.includes(orderInfo.orderId)) {
      return;
    }
    dispatch({ type: ADD_FILM_WITH_SOUND, itemBarcode: val });
  }, [orderInfo.orderId, val, filled, clearInput, clearFilled, dispatch]);

  return (
    <section className={"scan-film-with-sound__wrapper"}>
      {reelsWithSound === null && (
        <ScanPrompt
          {...orderInfo}
          appPath={appPath}
          onClick={setReelsWithSound}
          dispatch={dispatch}
        />
      )}
      {reelsWithSound === true && (
        <>
          <header className={"scan-film-with-sound__header"}>
            Scan film reels that have sound lines
          </header>
          <ItemInputs
            active={!orderInfo.hasLoggedFilmWithSound}
            itemScans={orderInfo.filmWithSound}
            dispatch={dispatch}
          />
          <GoButton
            onClick={() =>
              logFilmReels(
                orderInfo,
                triggerError,
                usertoken,
                appPath,
                dispatch,
              )
            }
          />
        </>
      )}
      <ErrorArea errState={errState} errMsg={errMsg} />
    </section>
  );
};

export default FilmWithAudio;
