export const Emoji = ({
  label = "",
  symbol,
}: {
  label: string;
  symbol: string;
}) => (
  <span
    className="emoji"
    role="img"
    aria-label={label}
    aria-hidden={label.length > 0 ? "false" : "true"}
  >
    {symbol}
  </span>
);

export default Emoji;
