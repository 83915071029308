import { createContext, useContext, useEffect } from "react";
import type { ReactNode } from "react";

export const KeyActions = {
  key: "KEY_EVENT",
  enter: "ENTER_EVENT",
  delete: "DELETE_EVENT",
} as const;

/** Key entered event */
export interface KeyEvent {
  type: typeof KeyActions.key;
  key: string;
}

/** Enterevent  */
export interface EnterEvent {
  type: typeof KeyActions.enter;
}

/** Delete event */
export interface DeleteEvent {
  type: typeof KeyActions.delete;
}

export type KeyStreamEvent = KeyEvent | EnterEvent | DeleteEvent;

type TKeydownHandler = (event: KeyStreamEvent) => void;

/**
 * Converts keydown events on the document into a
 * subscribable event stream that emits structured
 * events
 */
export function keydownStream() {
  let listeners = new Set<TKeydownHandler>();

  /**
   * Filters and Maps keydown events into structured
   * emittable events
   * @param event - keydown event from DOM listener
   */
  const eventHandler = (event: KeyboardEvent) => {
    if (["Shift", "CapsLock"].includes(event.key)) {
      return;
    }

    let evt: KeyStreamEvent = { type: "KEY_EVENT", key: event.key };

    if (["Backspace", "DELETE"].includes(event.key)) {
      evt = { type: "DELETE_EVENT" };
    } else if ("Enter" === event.key) {
      evt = { type: "ENTER_EVENT" };
    } else if (1 !== event.key.length) {
      return;
    }

    listeners.forEach((listener) => listener(evt));
  };

  /** Starts keydown listener */
  const init = () => document.addEventListener("keydown", eventHandler);
  /** Stops event listener */
  const close = () => document.removeEventListener("keydown", eventHandler);

  /**
   * Subscribes a callback function to the event stream
   * @param listener function that takes a KeyStreamEvent arg
   * @returns unsubscribe cleanup function
   */
  function subscribe(listener: TKeydownHandler) {
    listeners.add(listener);

    return function unsubscribe() {
      return listeners.delete(listener);
    };
  }

  return { subscribe, init, close };
}
export type TKeydownStream = ReturnType<typeof keydownStream>;

/** Stream object */
const stream$ = keydownStream();

const KeyContext = createContext<TKeydownStream | undefined>(undefined);

/**
 * Context wrapper for keydown stream listener
 * @param props passes through react child elements
 * @returns child elements wrapped in context
 */
export function KeyContextProvider({ children }: { children: ReactNode }) {
  /** Start stream listener on mount, close on unmount */
  useEffect(() => {
    stream$.init();

    return () => stream$.close();
  }, []);

  return <KeyContext.Provider value={stream$}>{children}</KeyContext.Provider>;
}

/**
 * Keydown context subscriber
 * @returns stream subscribe function
 */
export function useKeyContext(): TKeydownStream["subscribe"] {
  const ctx = useContext(KeyContext);

  if (undefined === ctx) {
    throw new Error("'useKeyContext' hook must be used in scope of a provider");
  }

  return ctx.subscribe;
}
