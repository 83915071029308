import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useLogin } from "../../hooks/context/login";
import { LOGIN } from "../../hooks/reducers/login";
import useCustomRedirect from "../../hooks/use-custom-redirect";
import useError from "../../hooks/use-error";
import NumberPad from "../groups/number-pad";
import "./login.scss";

/**
 * Gets user display reason for error in login fetch.
 * @param {number} status - HTTP status code.
 *
 * @returns {string} Display error message.
 * @throws {Error}
 */
export const getFetchErrorMsg = (status) => {
  switch (status) {
    case 404:
      return "PIN not found";
    default:
      throw new Error("Unmatched Error Status Code");
  }
};

export const Login = () => {
  const [{ loggedIn }, dispatch] = useLogin();
  const location = useLocation();
  const [pin, setPin] = useState("");
  const { redirectPath, redirectState } = useCustomRedirect(loggedIn, location);
  const {
    errState,
    errMsg,
    trigger: triggerError,
    clear: clearError,
  } = useError();

  const { VITE_APP_API_URI } = import.meta.env;

  /** Submits PIN for validation */
  const handleSubmit = async () => {
    clearError();

    if (pin.length < 4) {
      triggerError("PIN must be 4 digits");
      return;
    }
    try {
      const response = await fetch(`https://${VITE_APP_API_URI}/auth/pin`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ pin }),
      });

      if (!response.ok) {
        const fetchErrMsg = getFetchErrorMsg(response.status);
        triggerError(fetchErrMsg);
        return;
      }
      const body = await response.json();
      dispatch({
        type: LOGIN,
        payload: body,
      });
    } catch (err) {
      triggerError("Request Failed");
    }
  };

  if (redirectState === true) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <NumberPad
      header={"Please enter your ID Pin #"}
      entry={pin}
      setEntry={setPin}
      maxLength={4}
      handleSubmit={handleSubmit}
      errState={errState}
      errMsg={errMsg}
    />
  );
};

export default Login;
