import { useEffect, useReducer } from "react";

import { useLogin } from "../context/login";
import useBooleanFetch from "../use-boolean-fetch";
import useKeyEvent from "../use-key-event";

export const SCAN_EVENT = "SCAN_EVENT";
export const ENTER_EVENT = "ENTER_EVENT";
export const SCAN_FLAG = "SCAN_FLAG";
export const SCAN_SUCCESS = "SCAN_SUCCESS";
export const SCAN_FAILURE = "SCAN_FAILURE";

export type DepartureActionTypes =
  | typeof SCAN_EVENT
  | typeof ENTER_EVENT
  | typeof SCAN_FLAG
  | typeof SCAN_SUCCESS
  | typeof SCAN_FAILURE;

export interface DepartureState {
  scans: number;
  errors: number;
  total: number;
  entry: string;
  scannedList: string[];
  light: "pass" | "fail" | "neutral";
  flag: string;
}

export type DepartureAction =
  | {
      type: typeof SCAN_EVENT | typeof SCAN_FLAG;
      payload: string;
    }
  | {
      type: typeof ENTER_EVENT | typeof SCAN_SUCCESS | typeof SCAN_FAILURE;
    };

type DepartureReducer = (
  state: DepartureState,
  action: DepartureAction,
) => DepartureState;

export const initialState: DepartureState = {
  scans: 0,
  errors: 0,
  total: 0,
  entry: "",
  scannedList: [],
  light: "neutral",
  flag: "",
};

const checkLocalStorage = (
  date: string,
): Pick<DepartureState, "scans" | "errors" | "total"> | false => {
  const key = `Departure_${date}`;

  const expiredVals = Object.keys(localStorage).filter(
    (key) => key.includes("Departure") && !key.includes(date),
  );
  expiredVals.forEach((key) => localStorage.removeItem(key));

  const data = localStorage.getItem(key);

  return data !== null ? JSON.parse(data) : false;
};

const departureReducer: DepartureReducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case SCAN_EVENT:
      return { ...state, entry: action.payload };
    case ENTER_EVENT:
      const { entry, scannedList } = state;
      const duplicate = scannedList.findIndex((item) => item === entry);
      const newState = {
        ...state,
        entry,
      };

      if (duplicate === -1) {
        newState.scannedList = [...scannedList, entry];
      }

      return newState;
    case SCAN_FLAG:
      return { ...state, flag: action.payload };
    case SCAN_SUCCESS:
      return {
        ...state,
        light: "pass",
        scans: state.scans + 1,
        total: state.total + 1,
      };
    case SCAN_FAILURE:
      return {
        ...state,
        light: "fail",
        errors: state.errors + 1,
        total: state.total + 1,
      };
    default:
      return state;
  }
};

export function useDepartureReducer(
  triggerError: (msg: string) => void,
  clearError: () => void,
  openAlertModal: () => void,
  openReturnModal: () => void,
) {
  const [{ usertoken }] = useLogin();
  const date = new Date().toISOString().split("T")[0];
  const storedDeparture = checkLocalStorage(date);
  const { val, filled, clearInput, clearAll } = useKeyEvent(true);

  let init: DepartureState = initialState;
  if (storedDeparture) {
    init = { ...init, ...storedDeparture };
  }
  const [state, dispatch] = useReducer(departureReducer, init);
  const { entry, scannedList, total, errors, scans } = state;

  const onSuccess = (headers?: Headers) => {
    const orderFlag = headers?.get?.("x-order-flag") ?? "";
    console.log(orderFlag);
    dispatch({ type: SCAN_FLAG, payload: orderFlag });

    if (orderFlag.length === 0) {
      clearInput();
      clearError();
      return dispatch({ type: SCAN_SUCCESS });
    }

    // deal with flags
    onFail("Alert", orderFlag);
  };

  const onFail = (msg?: string, orderFlag?: string) => {
    dispatch({ type: SCAN_FAILURE });

    if (!orderFlag || orderFlag.length === 0) {
      clearInput();
      triggerError(msg ?? "Tracking number not found");
      return;
    }

    if (orderFlag === "Return Order") {
      return openReturnModal();
    } else {
      openAlertModal();
    }
  };

  const onError = (msg: string) => {
    dispatch({ type: SCAN_FAILURE });
    clearInput();
    triggerError(msg);
  };

  const triggerFetch = useBooleanFetch(
    `departure/tracking/${entry}`,
    usertoken,
    onSuccess,
    onFail,
    onError,
  );

  useEffect(() => {
    dispatch({ type: SCAN_EVENT, payload: val });
  }, [val, dispatch]);

  useEffect(() => {
    if (filled) {
      const duplicateScan =
        scannedList.findIndex((item) => item === entry) > -1;
      if (duplicateScan) {
        dispatch({ type: SCAN_FAILURE });
        triggerError("Tracking number scanned already");
        clearAll();
        return;
      }

      dispatch({ type: ENTER_EVENT });
      clearAll();
      triggerFetch();
    }
  }, [
    filled,
    scannedList,
    entry,
    triggerError,
    triggerFetch,
    clearAll,
    dispatch,
  ]);

  useEffect(() => {
    localStorage.setItem(
      `Arrival_${date}`,
      JSON.stringify({ total, errors, scans }),
    );
  }, [total, errors, scans, date]);

  const manageModalReset = () => {
    dispatch({ type: SCAN_FAILURE });
    clearAll();
  };

  return {
    state,
    dispatch,
    manageModalReset,
  };
}
