import { type Dispatch, type SetStateAction, useState } from "react";

import { useLogin } from "../../../hooks/context/login";
import Button from "../../base/button";
import RushLocateModal from "../../groups/rush-locate";
import ScanOrder from "./scan-order";

import "./create-folder.scss";

const { VITE_APP_API_URI } = import.meta.env;

interface FileData {
  productionOrderId: string;
  file: {
    sharing_link: string;
  };
  errorMessage: string;
}

const initialData: FileData = {
  productionOrderId: null,
  file: null,
  errorMessage: null,
};

type SetFileData = Dispatch<SetStateAction<FileData>>;

const createFolderReq = async (
  usertoken: string,
  productionOrderId: string,
  accessCode: string,
  setFn: SetFileData,
) => {
  let fileData = { ...initialData, productionOrderId };
  try {
    const res = await fetch(
      `https://${VITE_APP_API_URI}/admin/${productionOrderId}/gdrive`,
      {
        body: JSON.stringify({ accessCode }),
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        method: "POST",
      },
    );
    const json = await res.json();
    fileData = {
      ...fileData,
      ...json,
    };
    setFn(fileData);
  } catch (error: any) {
    setFn({ ...initialData, errorMessage: error.message });
  }
};

interface FileResultProps {
  fileData: FileData;
  setFileData: SetFileData;
}

const FileResult = ({ fileData, setFileData }: FileResultProps) => {
  return (
    <section className="create-folder__file-result">
      {fileData.errorMessage !== null && (
        <>
          <header className="create-folder__error-hdr">
            {fileData.errorMessage}
          </header>
        </>
      )}
      <section className="create-folder__file-actions">
        {fileData.file && (
          <a
            className="create-folder__file-link"
            href={fileData.file.sharing_link}
          >
            View Folder
          </a>
        )}
        <Button
          children={"Reset"}
          onClick={() => setFileData(initialData)}
          className="create-folder__reset-btn"
        />
      </section>
    </section>
  );
};

export const CreateFolder = () => {
  const [{ usertoken }] = useLogin();
  const [orderId, setOrderId] = useState(null);
  const [fileData, setFileData] = useState(initialData);

  return (
    <>
      <section className="create-folder">
        {fileData.productionOrderId === null ? (
          <ScanOrder
            submitFn={async (productionOrderId, access): Promise<void> => {
              if (!productionOrderId || !access) {
                return;
              }
              setOrderId(productionOrderId);
              createFolderReq(
                usertoken,
                productionOrderId,
                access,
                setFileData,
              );
            }}
            header={
              fileData.errorMessage ||
              "Scan order ID and access code to create cloud folder"
            }
            buttonText={"Create Folder"}
          />
        ) : (
          <FileResult fileData={fileData} setFileData={setFileData} />
        )}
      </section>
      <RushLocateModal productionOrderId={orderId} />
    </>
  );
};

export default CreateFolder;
