import { PAGE_STEP } from "../../../../hooks/reducers/actions/safetyscans/page";
import { RESET } from "../../../../hooks/reducers/safetyscans";
import IncludedPrompt from "../../safetyscans/deliverables/included-prompt";
import { OutputsList } from "../../safetyscans/deliverables/outputs";
import type { ReshipActivePageProps } from "../reship";

export const ReshipOutputs = ({ state, dispatch }: ReshipActivePageProps) => {
  return (
    <>
      <OutputsList
        name={state.orderInfo.name}
        outputs={state.orderInfo.outputs}
      />
      <IncludedPrompt
        onClick={(valid: boolean) =>
          valid
            ? dispatch({
                type: PAGE_STEP,
              })
            : dispatch({
                type: RESET,
              })
        }
      />
    </>
  );
};
export default ReshipOutputs;
