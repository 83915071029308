// Components
import UpgradeIndicator from "../../../components/upgrade-indicator";
import GoAgain from "../../../groups/go-again";

/**
 * Show a list of order outputs
 * @param {Object} props
 * @param {string} props.name
 * @param {Object[]} props.outputs
 */
export const OutputsList = ({ name: customerName, outputs }) => (
  <>
    <h2 className="deliverables__subheader">
      {customerName} ordered these delivery methods
    </h2>
    <section className="deliverables__outputs-wrapper">
      {outputs.map((item) => (
        <UpgradeIndicator
          key={item.product_variant_id}
          textContent={
            item?.product_name?.replace(/Kodak Digitizing/i, "") ??
            item.output_type ??
            "Unknown"
          }
          quantity={item.quantity}
          upgrade={true}
        />
      ))}
    </section>
  </>
);

export const NeedsOutputs = ({ dispatch }) => (
  <>
    <h2 className={"deliverables__header"}>
      Please send this order for missing outputs
    </h2>
    <GoAgain dispatch={dispatch} />
  </>
);
