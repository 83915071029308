import { Api } from "@/types/api";

const { VITE_APP_API_URI } = import.meta.env;

export async function completeDepartment(
  usertoken: string,
  orderId: string,
  department: string,
  body: { salesOrderId: string | number; productId: number },
) {
  const response = await fetch(
    `https://${VITE_APP_API_URI}/safetyscans/order/${orderId}/complete/${department}`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(body),
    },
  );
  return response.ok;
}

export async function legacyCheckout(
  usertoken: string,
  body: { scan: string; orderId: string; status: "OUT" },
) {
  const response = await fetch(
    `https://${VITE_APP_API_URI}/departments/checkin`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(body),
    },
  );
  return response.ok;
}

export async function handleUpdates(
  usertoken: string,
  orderId: string,
  department: string,
  items: Api.ISafetyScansItem[],
) {
  const scanout = `Digitizing_${department}_${department}`;
  const [complete] = await Promise.allSettled([
    completeDepartment(usertoken, orderId, scanout, {
      salesOrderId: orderId,
      productId: items[0].product_id,
    }),
    legacyCheckout(usertoken, { scan: scanout, orderId, status: "OUT" }),
  ]);
  //dev
  // return true;

  if (complete.status === "rejected" || complete.value === false) {
    console.error(complete);
    return false;
  }

  return complete.value;
}

export async function createAlert(
  id: string,
  usertoken: string,
  errMsg: string,
) {
  try {
    const note = `Mismatched SafetyScan: ${errMsg}`;
    await Promise.all([
      fetch(`https://${VITE_APP_API_URI}/shipments/alerts/${id}`, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          alertReasonId: 8,
        }),
      }),
      fetch(
        `https://${VITE_APP_API_URI}/timeline-v2/safetyscans/${id}/custom`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${usertoken}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ note }),
        },
      ),
    ]);
  } catch (e) {
    console.error(e);
  }
}
