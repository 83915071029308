import { useState } from "react";

import AppName from "../components/app-name";
import GetStarted from "./departure/get-started";
import ScanBox from "./departure/scan-box";

import "./departure.scss";

export const Departure = () => {
  const [active, setActive] = useState(false);

  const activateClick = () => {
    setActive(true);
  };
  const finishedClick = () => {
    setActive(false);
  };

  return (
    <>
      <AppName klass="departure__app-name">Departure</AppName>
      {active ? (
        <ScanBox onClick={finishedClick} />
      ) : (
        <GetStarted onClick={activateClick} />
      )}
    </>
  );
};

export default Departure;
