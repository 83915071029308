import type { ReactNode } from "react";

import { styled } from "../../../stitches.config";

export const ListItem = styled("li", {
  borderBottom: "2px solid $lightGray",
  display: "flex",
  width: "100%",
  flexFlow: "row wrap",
  textTransform: "uppercase",
  padding: "10px 0",
  fontSize: "$md",
});

export const UnorderedList = styled("ul", {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});

export interface ListProps<T extends ReactNode> {
  list: T[];
}
export function List<T extends ReactNode = any>({ list }: ListProps<T>) {
  return (
    <UnorderedList>
      {list.map((item, idx) => (
        <ListItem key={idx}>{item}</ListItem>
      ))}
    </UnorderedList>
  );
}
