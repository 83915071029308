import { useReducer, Reducer } from "react";
import type { Api } from "../../../types/api";

import type { Dispatch } from "react";

export type TShipBalanceDueState = {
  app: {
    passcode: number;
  };
  error: {
    errState: boolean;
    errMsg: string;
  };
  labelData: Api.IFinalShippingLabel;
  order: Api.IProductionOrder;
  shippingInfo: Api.IFinalShippingInfo;
  shippingAlerts: Api.IShipmentAlerts;
  stepName: ShipBalanceDueStepNames;
};

export const ShipBalanceDueSteps = {
  /** Normal workflow, in order */
  Passcode: "passcode",
  ScanOrder: "scan-order",
  Weigh: "weigh",
  Ship: "ship",
  Confirm: "confirm",
  /** Error states */
  ShipmentAlerts: "shipmentAlerts",
} as const;
export type ShipBalanceDueStepNames =
  (typeof ShipBalanceDueSteps)[keyof typeof ShipBalanceDueSteps];

export const ShipBalanceDueInitialState: TShipBalanceDueState = {
  order: null,
  stepName: ShipBalanceDueSteps.Passcode,
  app: {
    passcode: 7413,
  },
  shippingInfo: null,
  shippingAlerts: null,
  error: {
    errState: false,
    errMsg: "",
  },
  labelData: null,
};

export const setField =
  <T extends keyof TShipBalanceDueState>(field: T) =>
  (
    state: TShipBalanceDueState,
    payload: TShipBalanceDueState[T],
  ): TShipBalanceDueState => ({
    ...state,
    [field]: payload,
  });

const ShipBalanceDueActionTypes = {
  SET_ORDER_DATA: "SET_ORDER_DATA",
  SET_STEP_NAME: "SET_STEP_NAME",
  SET_SHIPPING_INFO: "SET_SHIPPING_INFO",
  SET_SHIPPING_ALERTS: "SET_SHIPPING_ALERTS",
  SET_ERROR: "SET_ERROR",
  SET_LABEL_DATA: "SET_LABEL_DATA",
  /** Reset all state to initial stete */
  RESET: "RESET",
  /** Clear order state without requiring passcode */
  RESTART: "RESTART",
} as const;
const ShipBalanceDueActions = {
  SET_ORDER_DATA: setField("order"),
  SET_STEP_NAME: setField("stepName"),
  SET_SHIPPING_INFO: setField("shippingInfo"),
  SET_SHIPPING_ALERTS: setField("shippingAlerts"),
  SET_ERROR: setField("error"),
  SET_LABEL_DATA: setField("labelData"),
  RESET: () => ShipBalanceDueInitialState,
  RESTART: () => ({
    ...ShipBalanceDueInitialState,
    stepName: ShipBalanceDueSteps.ScanOrder,
  }),
} as const;
export type TShipBalanceDueAction = {
  type: TShipBalanceDueActionTypeNames;
  payload?: any;
};
export type TShipBalanceDueActions = typeof ShipBalanceDueActions;
export type TShipBalanceDueActionTypeNames = keyof TShipBalanceDueActions;

export const ShipBalanceDueActionCreators = {
  createSetOrderDataAction: (payload: TShipBalanceDueState["order"]) => ({
    type: ShipBalanceDueActionTypes.SET_ORDER_DATA,
    payload,
  }),
  createSetStepNameAction: (payload: TShipBalanceDueState["stepName"]) => ({
    type: ShipBalanceDueActionTypes.SET_STEP_NAME,
    payload,
  }),
  createSetShippingInfoAction: (
    payload: TShipBalanceDueState["shippingInfo"],
  ) => ({
    type: ShipBalanceDueActionTypes.SET_SHIPPING_INFO,
    payload,
  }),
  createSetShippingAlertsAction: (
    payload: TShipBalanceDueState["shippingAlerts"],
  ) => ({
    type: ShipBalanceDueActionTypes.SET_SHIPPING_ALERTS,
    payload,
  }),
  createSetErrorAction: (payload: TShipBalanceDueState["error"]) => ({
    type: ShipBalanceDueActionTypes.SET_ERROR,
    payload,
  }),
  createSetLabelDataAction: (payload: TShipBalanceDueState["labelData"]) => ({
    type: ShipBalanceDueActionTypes.SET_LABEL_DATA,
    payload,
  }),
  createResetAction: () => ({
    type: ShipBalanceDueActionTypes.RESET,
  }),
  createRestartAction: () => ({
    type: ShipBalanceDueActionTypes.RESTART,
  }),
};

export const reducer: Reducer<TShipBalanceDueState, any> = (
  state,
  action: TShipBalanceDueAction,
): TShipBalanceDueState => {
  const act = ShipBalanceDueActions[action.type];
  const update = act(state, action.payload);

  return { ...state, ...update };
};
export type TShipBalanceDueReducer = [
  TShipBalanceDueState,
  Dispatch<TShipBalanceDueAction>,
];
export const useShipBalanceDueReducer = () =>
  useReducer<Reducer<TShipBalanceDueState, TShipBalanceDueAction>>(
    reducer,
    ShipBalanceDueInitialState,
  );
export default useShipBalanceDueReducer;
