import { useCallback } from "react";
import Frame from "../../components/container/container";
import type { IAppContainerProps } from "../../components/container/container";
import { OnKeyEventFilled } from "../../../hooks";
import useQa, { TQaProcessPages } from "../../../hooks/reducers/qa-process";
import type { QaReducerProps } from "../../../hooks/reducers/qa-process";
import {
  resetScan,
  setScan,
} from "../../../hooks/reducers/actions/qa/set-scan";
import { clear as clearError } from "../../../hooks/reducers/actions/error";
import { styled } from "../../../stitches.config";
import type { OrderInfoState } from "../../../hooks/reducers/actions/order-info";
import FindOrder from "./screens/find-order";
import Outside from "./screens/outside";
import OutputsSafetyscan from "./screens/outputs-safetyscan";
import Files from "./screens/files";
import Summary from "./screens/summary";

function ConnectedFindOrder({ state, dispatch }: QaReducerProps) {
  const {
    error: { error, message },
  } = state;

  const find = useCallback<OnKeyEventFilled>(
    (val) => dispatch(setScan(val)),
    [dispatch],
  );

  const clear = useCallback(() => {
    dispatch(clearError());
    dispatch(resetScan());
  }, [dispatch]);

  return (
    <FindOrder
      error={error}
      errorMessage={message}
      callback={find}
      clear={clear}
    />
  );
}

function getHeading(page: TQaProcessPages) {
  switch (page) {
    case "scan":
      return "Scan tracking or barcode # to begin";
    case "outside":
      return "Check the outside of the shipper";
    // case "packaging":
    //   return "Check that order is packaged correctly";
    case "outputs":
      return "Make sure all of the delivery options are present";
    case "files":
      return "Check the files";
    case "summary":
      return "Review order";
    default:
      return "Something has gone wrong";
  }
}

const Heading = styled("header", {
  marginBottom: "2rem",
  paddingBottom: "0.5rem",
  fontSize: "$md",
  fontWeight: "$bold",
  borderBottom: "1px solid $lightGray",
  width: "100%",
  justifyContent: "center",
});

export function buildFrameProps(
  order: OrderInfoState["order"],
): IAppContainerProps {
  if (order === null) {
    return;
  }

  return {
    brand: order.brand_name,
    name: `${order.shipping_address.firstname} ${order.shipping_address.lastname}`,
    orderId: order.production_order_id,
  };
}

export function CurrentPage(props: QaReducerProps) {
  const {
    page: { pageStep: current },
  } = props.state;

  switch (current) {
    case "scan":
      return <ConnectedFindOrder {...props} />;
    case "outside":
      return <Outside {...props} />;
    case "outputs":
      return <OutputsSafetyscan {...props} />;
    case "files":
      return <Files {...props} />;
    case "summary":
      return <Summary {...props} />;
    default:
      return <></>;
  }
}

export function QaProcessing() {
  const props = useQa();
  const { orderInfo, page } = props.state;

  return (
    <Frame {...buildFrameProps(orderInfo.order)}>
      <Heading>{getHeading(page.pageStep)}</Heading>
      <CurrentPage {...props} />
    </Frame>
  );
}

export default QaProcessing;
