import { useEffect, useState } from "react";

/**
 * @typedef {Object} KeyEventHookData
 * @property {string} val - combined key down value of active listener
 * @property {boolean} filled - marks whether enter key is pressed
 * @property {() => void} clearInput - resets value to ""
 * @property {() => void} clearFilled - resets filled to false
 */

export interface OnKeyEventFilled {
  (value: string): void;
}

export interface IKeyEventHook {
  val: string;
  filled: boolean;
  clearInput: () => void;
  clearFilled: () => void;
  clearAll: () => void;
}

/**
 * When active adds a listener for keydown events to
 * mock normal input behavior for applications using
 * both barcode scanners and keyboards
 */
export const useKeyEvent = (
  active: boolean,
  onFilled: OnKeyEventFilled = undefined,
): IKeyEventHook => {
  const [val, setVal] = useState<string>("");
  const [filled, setFilled] = useState<boolean>(false);

  useEffect(() => {
    const handleChange = (event: KeyboardEvent) => {
      event.preventDefault();
      const key = event.key;

      if (["Backspace", "Delete"].includes(key)) {
        setVal((v) => v.slice(0, v.length - 1));
        return;
      } else if (["Shift", "CapsLock"].includes(key)) {
        return;
      } else if (key === "Enter") {
        setFilled(true);
        return;
      } else if (key.length !== 1) {
        return;
      }
      setVal((v) => `${v}${key}`);
    };

    if (active) {
      document.addEventListener<"keydown">("keydown", handleChange);

      return () => {
        document.removeEventListener<"keydown">("keydown", handleChange);
      };
    }
  }, [active]);

  useEffect(() => {
    if (onFilled !== undefined && filled) {
      onFilled(val);
    }
  }, [onFilled, filled, val]);

  const clearInput = () => setVal("");
  const clearFilled = () => setFilled(false);
  const clearAll = () => {
    setVal("");
    setFilled(false);
  };

  return { val, filled, clearInput, clearFilled, clearAll };
};

export default useKeyEvent;
