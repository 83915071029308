// Hooks
import { useEffect } from "react";
import {
  ShipBalanceDueActionCreators,
  ShipBalanceDueSteps,
} from "../../../../../hooks/reducers/admin/ship-balance-due";
import useApi from "../../../../../hooks/use-api";
// Types
import type {
  TShipBalanceDueState,
  TShipBalanceDueAction,
} from "../../../../../hooks/reducers/admin/ship-balance-due";
import type { Api } from "../../../../../types/api";
import type { Dispatch } from "react";

/**
 * Retrieve shipment alerts for an order and
 * trigger going to alerts page if there is an active alert
 * */
export const useGetShippingAlerts = (
  state: TShipBalanceDueState,
  dispatch: Dispatch<TShipBalanceDueAction>,
) => {
  const shippingAlertsApi = useApi<Api.IShipmentAlerts>(
    `shipments/alerts/${state.order?.sales_order_id}`,
    "get",
    state.order?.sales_order_id != null,
  );
  /** Set shipment alert data in app state  */
  useEffect(() => {
    if (shippingAlertsApi.isResolved) {
      /** Set shipping alerts in state */
      dispatch(
        ShipBalanceDueActionCreators.createSetShippingAlertsAction(
          shippingAlertsApi.body,
        ),
      );
      /** Go to shipment alerts list page if we have an active alert */
      if (shippingAlertsApi.body.hasActiveAlert === true) {
        dispatch(
          ShipBalanceDueActionCreators.createSetStepNameAction(
            ShipBalanceDueSteps.ShipmentAlerts,
          ),
        );
      }
    }
  }, [shippingAlertsApi.isResolved, shippingAlertsApi.body, dispatch]);
  /** Handle shipment alert API failure  */
  useEffect(() => {
    if (shippingAlertsApi.isRejected) {
      /** Set error state */
      dispatch(
        ShipBalanceDueActionCreators.createSetErrorAction({
          errMsg: "Unable to retrieve shipment alert data",
          errState: true,
        }),
      );
    }
  }, [shippingAlertsApi.isRejected, dispatch]);
};
