import type { Dispatch } from "react";

import useFormatReducer, {
  EditFormatsActions,
  EditFormatsState,
} from "../../../hooks/reducers/admin/edit-formats";
import Formats from "./edit-items/formats";
import Review from "./edit-items/review";
import ScanOrder from "./edit-items/scan-order";

import "./edit-items.scss";

interface StepDisplayProps {
  state: EditFormatsState;
  dispatch: Dispatch<EditFormatsActions>;
}

const StepDisplay = (props: StepDisplayProps) => {
  const { activeStep } = props.state;

  const steps = [
    <ScanOrder dispatch={props.dispatch} />,
    <Formats {...props} />,
    <Review {...props} />,
  ];

  return steps[activeStep];
};

const EditItems = () => {
  const [state, dispatch] = useFormatReducer();

  return (
    <section className="edit-items">
      <StepDisplay state={state} dispatch={dispatch} />
    </section>
  );
};

export default EditItems;
