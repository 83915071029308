import { useEffect, useState } from "react";
import type { Dispatch } from "react";

import {
  ShipBalanceDueActionCreators,
  ShipBalanceDueSteps,
  TShipBalanceDueAction,
  TShipBalanceDueState,
} from "../../../../hooks/reducers/admin/ship-balance-due";
import useApi from "../../../../hooks/use-api";
import type { ShipBalanceDueComponentProps } from "./app";
import { useCalculateShippingWeight } from "./hooks/use-calculate-shipping-weight";

const useWeighBalanceDue = (
  state: TShipBalanceDueState,
  dispatch: Dispatch<TShipBalanceDueAction>,
) => {
  const [packageWeightOver, setPackageWeightOver] = useState<boolean>(null);
  const [generatePacketWeight, setGeneratePacketWeight] =
    useState<boolean>(true);
  /** Ground or faster shipping does not need package weight checked */
  const hasExpeditedShipping =
    Number(state?.shippingInfo?.final_shipping_method) > 5;
  const updateShippingMethod =
    packageWeightOver === true && !hasExpeditedShipping;
  const { isResolved, isRejected } = useApi<any>(
    `shipments/${state.order.sales_order_id}`,
    "patch",
    updateShippingMethod,
    {
      "Content-Type": "application/json",
    },
    JSON.stringify({
      orderShippingMethod: 22,
    }),
  );
  /**
   * Go to ship step if:
   * • order has expedited shipping (ground or above)
   * • package weight is below 9+ lbs
   */
  useEffect(() => {
    if (hasExpeditedShipping || packageWeightOver === false) {
      dispatch(
        ShipBalanceDueActionCreators.createSetStepNameAction(
          ShipBalanceDueSteps.Ship,
        ),
      );
    }
  }, [hasExpeditedShipping, dispatch, packageWeightOver]);

  /**
   * For packages over 9lbs, go to ship step
   * when shipping method has finished updating to ground
   */
  useEffect(() => {
    if (isResolved) {
      dispatch(
        ShipBalanceDueActionCreators.createSetStepNameAction(
          ShipBalanceDueSteps.Ship,
        ),
      );
    }
  }, [isResolved, dispatch]);

  const isPacketWeightOver = useCalculateShippingWeight(
    state.order?.sales_order_id,
  );

  useEffect(() => {
    if (generatePacketWeight && isPacketWeightOver !== undefined) {
      setGeneratePacketWeight(false);
      setPackageWeightOver(isPacketWeightOver);
    }
  }, [generatePacketWeight, isPacketWeightOver, state]);

  return {
    isRejected,
    setGeneratePacketWeight,
  };
};

export function Weigh(props: ShipBalanceDueComponentProps) {
  const { isRejected } = useWeighBalanceDue(props.state, props.dispatch);
  return (
    <section className="ship-balance-due__weigh">
      {!isRejected ? (
        <></>
      ) : (
        <article>Error: unable to update order shipping method</article>
      )}
    </section>
  );
}
export default Weigh;
