import { useCallback } from "react";

// Components
import BaseButton from "../base/button";
import cn from "classnames";

import "./slide-panel.scss";

interface SlidePanelProps {
  /** slide panel content */
  children: React.ReactNode;
  /** show/hide state */
  show: boolean;
  /** show/hide handler */
  setShow: (show: boolean) => void;
}

export function SlidePanel({ children, show, setShow }: SlidePanelProps) {
  const closeDrawer = useCallback(() => setShow(false), [setShow]);
  const wrapper = cn("slide-panel__wrapper", { show });
  const overlay = cn("slide-panel__overlay", { show });

  return (
    <>
      <section className={wrapper}>
        <BaseButton onClick={closeDrawer} className="slide-panel__close">
          x
        </BaseButton>
        <section className="slide-panel__main">{children}</section>
      </section>
      <section className={overlay} />
    </>
  );
}

export default SlidePanel;
