import { styled } from "../../../stitches.config";

/** Base button component */
export const Button = styled("button", {
  border: "none",
  borderRadius: "10px",
  textTransform: "uppercase",
  fontSize: "$md",
  fontWeight: "$bold",
  fontFamily: "$gotham",

  ":disabled": {
    backgroundColor: "$darkGray",
    color: "$lightGray",
  },
  ":focus": {
    outline: 0,
  },
});

export default Button;
