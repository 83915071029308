import type { Api, PartialBy } from "../../../types/api";
import { ResetAction, RESET_STATE } from "./reset-action";
import { scanItem } from "./safetyscans/order-info";
import type { OrderInfo } from "./safetyscans/order-info";

export interface OrderInfoState {
  fetchOrder: "idle" | "resolved" | "error";
  order?: PartialBy<
    Api.ISafetyScansOrderInfo,
    "analogItems" | "categories" | "outputs" | "name"
  >;
}

export const OrderInitialState: OrderInfoState = {
  fetchOrder: "idle",
  order: null,
};

export const SET_STATUS = "SET_STATUS";
export const RESET_ORDER = "RESET_ORDER";
export const ORDER_FOUND = "ORDER_FOUND";
export const ORDER_NOT_FOUND = "ORDER_NOT_FOUND";
export const SCAN_ITEM_QA = "SCAN_ITEM_QA";

export type OrderInfoAction =
  | { type: typeof SET_STATUS; payload: OrderInfoState["fetchOrder"] }
  | { type: typeof RESET_ORDER }
  | {
      type: typeof ORDER_FOUND;
      payload: Api.IProductionOrder | OrderInfoState["order"];
    }
  | { type: typeof SCAN_ITEM_QA; isAppQA: boolean; scan: string }
  | { type: typeof ORDER_NOT_FOUND };

export const setStatus = (
  status: OrderInfoState["fetchOrder"],
): OrderInfoAction => ({
  type: SET_STATUS,
  payload: status,
});

export const orderFound = (body: Api.IProductionOrder): OrderInfoAction => ({
  type: ORDER_FOUND,
  payload: body,
});

export const orderNotFound = (): OrderInfoAction => ({
  type: ORDER_NOT_FOUND,
});

export const reset = (): OrderInfoAction => ({ type: RESET_ORDER });

export default function reducer(
  state: OrderInfoState,
  action: OrderInfoAction | ResetAction,
): OrderInfoState {
  switch (action.type) {
    case SET_STATUS:
      return { ...state, fetchOrder: action.payload };
    case RESET_ORDER:
    case RESET_STATE:
      return OrderInitialState;
    case ORDER_FOUND:
      return { ...state, fetchOrder: "resolved", order: action.payload };
    case SCAN_ITEM_QA:
      const orderInfoState: OrderInfo = {
        orderInfo: state.order as unknown as OrderInfo["orderInfo"],
      };
      return {
        ...state,
        order: scanItem(orderInfoState, {
          isAppQA: action.isAppQA,
          scan: action.scan,
        }).orderInfo as unknown as OrderInfoState["order"],
      };
    case ORDER_NOT_FOUND:
      return { ...state, fetchOrder: "error" };
    default:
      return state;
  }
}
