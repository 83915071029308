import type { ReactNode } from "react";

import usePager from "../../../hooks/use-pager";
import { styled } from "../../../stitches.config";
import Button from "../button/button";
import { List, ListProps } from "../list/list";

const Wrapper = styled("section", {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "90%",
  margin: "0 auto",
  justifyContent: "center",
});

const ArrowButton = styled(Button, {
  color: "$black",
  background: "transparent",
  margin: "1em 2em",

  ":disabled": {
    color: "transparent",
    background: "transparent",
    display: "none",
  },
});

const PageNumber = styled("article", {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "$md",
  fontWeight: "$bold",
});

export interface PagedListProps<T extends ReactNode> extends ListProps<T> {
  pageLength?: number;
  startPage?: number;
  displayPageNumber?: boolean;
}

export default function PagedList<T extends ReactNode = any>({
  list,
  pageLength = 10,
  startPage = 0,
  displayPageNumber = false,
}: PagedListProps<T>) {
  const { page, current, lastPage, next, previous } = usePager(
    list,
    pageLength,
    startPage,
  );
  return (
    <Wrapper>
      <ArrowButton
        onClick={page > 0 ? previous : undefined}
        disabled={page === 0}
      >
        &lt;
      </ArrowButton>
      <List list={current} />
      <ArrowButton
        onClick={page < lastPage ? next : undefined}
        disabled={page === lastPage}
      >
        &gt;
      </ArrowButton>
      {displayPageNumber && <PageNumber>{page + 1}</PageNumber>}
    </Wrapper>
  );
}
