import { useEffect, useState } from "react";
import "./station-scan.scss";

// Components
import TextInput from "../../../base/text-input";

// Hooks
import { useKeyEvent } from "../../../../hooks";
import useError from "../../../../hooks/use-error";
import useApi from "../../../../hooks/use-api";
import { PAGE_GOTO } from "../../../../hooks/reducers/actions/safetyscans/page";
import { SET_CELL } from "../../../../hooks/reducers/actions/safetyscans/departments";
import type { Api } from "../../../../types/api";
import type { Dispatch } from "react";

export type StationScanProps = {
  dispatch: Dispatch<any>;
};

type StationScanState = {
  executeLocationCheck: boolean;
};
const StationScanInitialState: StationScanState = {
  executeLocationCheck: false,
};

/** Test if a location scan station name is like 'Digitizing' for a pod workflow */
function testDigitizingLocationScan(val: string) {
  const station = val.split("_").pop();
  return /digitizing|audio/i.test(station);
}

/** Use the Departments API to check this is a valid location */
function useLocationCheck(locationScan: string, execute: boolean) {
  const [location, workflow, cell] = locationScan.split("_");
  const departmentsApi = useApi<Api.IDepartmentsCell>(
    `departments/${location}/${workflow}/${cell}`,
    "get",
    execute,
  );
  return departmentsApi;
}

function useStationScan(dispatch: Dispatch<any>) {
  const [state, setState] = useState<StationScanState>(StationScanInitialState);
  const keyEvent = useKeyEvent(true);
  const { val, filled, clearInput, clearFilled } = keyEvent;
  const error = useError(false, "");
  const { errState, errMsg, trigger } = error;
  const locationCheck = useLocationCheck(val, state.executeLocationCheck);
  const { apiDispatch, actions, body, isRejected, isResolved } = locationCheck;

  /** Trigger fetch if scan completes with a valid barcode */
  useEffect(() => {
    if (!filled) {
      return;
    }
    /** Check if location barcode is for a pod Digitizing location */
    if (testDigitizingLocationScan(val) === false) {
      trigger(`Invalid location scan: ${val}`);
      clearFilled();
      clearInput();
      return;
    }

    setState((state) => ({ ...state, executeLocationCheck: true }));
  }, [val, filled, clearInput, clearFilled, trigger]);

  /** Trigger error if fetch is rejected for an invalid location ID */
  useEffect(() => {
    if (!isRejected) {
      return;
    }
    trigger(`Invalid location: ${val}`);
    clearInput();
    clearFilled();
    setState((state) => ({ ...state, executeLocationCheck: false }));
    apiDispatch({ type: actions.RESET });
  }, [val, apiDispatch, actions, isRejected, clearInput, clearFilled, trigger]);

  /** If location scan is valid, set in Safety Scans state and continue */
  useEffect(() => {
    if (!isResolved) {
      return;
    }

    dispatch({
      type: SET_CELL,
      cell: { id: body.Location, name: body.cell },
    });
    dispatch({ type: PAGE_GOTO, step: 1 });
  }, [body, dispatch, isResolved]);

  return {
    stationScanValue: keyEvent.val,
    error: {
      errState,
      errMsg,
    },
  };
}

export function StationScan(props: StationScanProps) {
  const state = useStationScan(props.dispatch);
  return (
    <section className="station-scan__wrapper">
      <header>Scan station number barcode</header>
      <TextInput
        className="station-scan__text-input"
        error={state.error.errState}
        errorMsg={state.error.errMsg}
        val={state.stationScanValue}
      />
    </section>
  );
}

export default StationScan;
