import CategoryButton from "./category-button";

import "./category-group.scss";

/**
 * @typedef CategoryGroup
 * @type {Object}
 * @property {Array} categoryButtons - Media category buttons
 * @property {string} selected - currently selected category
 * @property {boolean} error - error state
 * @property {Function} onClick - onClick handler for buttons
 */
interface CategoryGroupProps {
  categoryButtons: Array<{
    category: string;
    quantity: number;
    productId: number;
  }>;
  selected: string;
  error: boolean;
  onClick: (val?: string) => void;
}

export const CategoryGroup = ({
  categoryButtons,
  selected,
  error,
  onClick,
}: CategoryGroupProps) => {
  const listItems = categoryButtons.map((categoryButton) => (
    <section key={categoryButton.category}>
      <CategoryButton
        {...categoryButton}
        selected={selected}
        error={error}
        onClick={() => onClick(categoryButton.category)}
      />
    </section>
  ));
  return (
    <>
      <h1 className="category-group__header">1. Select Media</h1>
      <section className="category-group__list">{listItems}</section>
    </>
  );
};

export default CategoryGroup;
