import type { MouseEvent as rme } from "react";

import BaseButton from "../base/button";

import "./scan-item.scss";

interface ItemScan {
  item_barcode: string;
  scanned: boolean;
}
interface ItemInputsProps {
  itemScans: Array<ItemScan>;
}

export const ItemInputs = ({ itemScans }: ItemInputsProps) =>
  itemScans ? (
    <section className={"scan-items__item-inputs-wrapper"}>
      {itemScans.map(({ item_barcode, scanned }) => (
        <ScanItem key={item_barcode} text={item_barcode} active={scanned} />
      ))}
    </section>
  ) : (
    <></>
  );

/** Button for scannable items  */
type ScanItemProps = {
  /** Display value */
  text: string;
  /** Click handler function */
  onClick?: (text: string) => void;
  /** Error state */
  error?: boolean;
  /** Active or complete state */
  active?: boolean;
  /** Class name for active state */
  activeClass?: string;
};

export const ScanItem = ({
  text,
  onClick = () => {},
  error = false,
  active = false,
  activeClass = "scanned",
}: ScanItemProps) => {
  const stateClass = error ? "error" : active ? activeClass : "";
  const clickFn = (event: rme<HTMLButtonElement, MouseEvent>) => {
    event.currentTarget.blur();
    onClick(text);
  };
  return (
    <BaseButton
      children={text}
      className={`scan-item ${stateClass}`}
      onClick={clickFn}
    />
  );
};

export default ScanItem;
