import { useReducer, Reducer } from "react";
import { Api } from "../../../types/api";

import {
  categoryInitialState,
  categoryActions,
  SelectCategoryAction,
  CategoryActions,
  CategoryState,
} from "../actions/receiving/category";

export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_ORDER_ITEMS = "SET_ORDER_ITEMS";
export const STEP_DISPLAY = "STEP_DISPLAY";
export const STEP_BACK_DISPLAY = "STEP_BACK_DISPLAY";
export const RESET_ORDER = "RESET_ORDER";

interface ExistingItem {
  item_id: number;
  product: string;
  variant: string;
  product_id: number;
  variant_id: number;
  quantity: number;
  item_barcode: string;
}
export const InitialState: EditFormatsState = {
  ...categoryInitialState,
  orderId: null,
  activeStep: 0,
  currentItems: null,
  outputs: null,
};

export interface EditFormatsState extends CategoryState {
  // this was never used
  // categoryInitialState: CategoryState

  orderId?: string;
  activeStep?: number;
  currentItems?: ExistingItem[];
  outputs: ExistingItem[];
}

export interface SetOrderIdAction {
  type: "SET_ORDER_ID";
  payload: string;
}
export interface SetOrderItemsAction {
  type: "SET_ORDER_ITEMS";
  payload: any /** once order type is ready change this */;
}

/** no paylaod
 * type StepDisplayAction = void;
 * type StepBackDisplayAction = void;
 * type startOver = void;
 * type resetItems = void
 */

export type ActionsKeys = keyof typeof Actions;
export type ActionsConstruct = {
  [k in ActionsKeys]: (typeof Actions)[k];
};

export interface ActionNoPayload {
  type: ActionsKeys;
}

export type EditFormatsActions =
  | void
  | ActionNoPayload
  | CategoryActions
  | SetOrderIdAction
  | SetOrderItemsAction
  | SelectCategoryAction;

export const setOrderId = (
  _: CategoryState,
  action: SetOrderIdAction,
): Partial<EditFormatsState> => ({ orderId: action.payload });

export const setOrderItems = (
  _: any,
  action: SetOrderItemsAction,
): Partial<EditFormatsState> => ({
  currentItems: action.payload.filter(
    (item: Api.IProductionOrderItem) => item.product_type_id === 1,
  ),
  outputs: action.payload.filter(
    (item: Api.IProductionOrderItem) => item.product_type_id === 2,
  ),
});

export const stepDisplay = ({
  activeStep,
}: {
  activeStep: number;
}): { activeStep: number } => ({
  activeStep: activeStep + 1,
});

export const stepBackDisplay = ({
  activeStep,
}: {
  activeStep: number;
}): { activeStep: number } => ({
  activeStep: activeStep - 1,
});

export const startOver = () => ({ ...InitialState });

export const Actions = {
  ...categoryActions,
  SET_ORDER_ID: setOrderId,
  SET_ORDER_ITEMS: setOrderItems,
  STEP_DISPLAY: stepDisplay,
  STEP_BACK_DISPLAY: stepBackDisplay,
  RESET_ORDER: startOver,
};

export const reducer = (
  state: EditFormatsState,
  action: EditFormatsActions,
) => {
  const actReducer = Actions[(action as ActionNoPayload).type];
  /** use discriminated unions as below:
   * if (action.type === 'SET_ORDER_ID') {
   * } and so on (continue to all action.type values)
   * that is if the code grows
   * or if 'action as any' below is no longer ideal
   */
  const update = actReducer(state, action as any);

  return { ...state, ...update };
};

export const useFormatReducer = () =>
  useReducer<Reducer<EditFormatsState, EditFormatsActions>>(
    reducer,
    InitialState,
  );
export default useFormatReducer;
