import { styled } from "../../../stitches.config";

export interface TouchButtonVariants {
  color: "primary" | "secondary" | "accent" | "warn" | "error" | "idle";
  size: "sm" | "md" | "lg";
}

export const TouchButton = styled("button", {
  color: "$white",
  height: "60px",
  border: "none",
  borderRadius: "10px",
  textTransform: "uppercase",
  fontSize: "$md",
  fontWeight: "$bold",
  fontFamily: "$gotham",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  ":disabled": {
    backgroundColor: "$darkGray",
    color: "$lightGray",
  },
  ":focus": {
    outline: 0,
  },

  variants: {
    color: {
      primary: {
        backgroundColor: "$green",
      },
      secondary: {
        backgroundColor: "$blue",
      },
      accent: {
        backgroundColor: "$darkPink",
      },
      warn: {
        backgroundColor: "$yellow",
      },
      error: {
        backgroundColor: "$red",
      },
      idle: {
        backgroundColor: "$darkGray",
      },
    },
    size: {
      sm: {
        minWidth: "150px",
      },
      md: {
        width: "350px",
      },
      lg: {
        height: "70px",
        width: "400px",
      },
    },
  },
});

TouchButton.defaultProps = {
  color: "primary",
  size: "md",
};

export default TouchButton;
