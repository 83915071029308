import { useLogin } from "../../../hooks/context/login";
import {
  printQRCodeThumbdriveSlip,
  reprintSlip,
} from "../../../utils/dymo-print";
import type { AdminScanOrderRESTErrorMap } from "./scan-order";
import ScanOrderReprintSlip from "./scan-order-reprint-slip";

import "./reprint-slip.scss";

export const ReprintSlip = () => {
  const [{ usertoken }] = useLogin();
  const printSlip = async (
    order: string,
    access: string,
  ): Promise<void | AdminScanOrderRESTErrorMap> => {
    const printMsg = await reprintSlip(usertoken, order);

    if (!printMsg.printComplete) {
      return {
        ...printMsg,
        restEndpoint: "RECEIVING_REPRINT",
        errorMsg: printMsg?.err?.message,
      };
    }

    await printQRCodeThumbdriveSlip(usertoken, order);
  };

  return (
    <section className="reprint-slip">
      <ScanOrderReprintSlip
        submitFn={printSlip}
        header="Scan order and item, then input pin to print receiving slip"
      />
    </section>
  );
};

export default ReprintSlip;
