import { NavLink } from "react-router-dom";

import "./app-button.scss";

export interface AppButtonProps {
  name: string;
  path: string;
  icon?: any;
  to?: string;
  onClick?: () => void;
}

/** Icon button for apps */
export function AppButton({
  name,
  icon,
  path,
  to = undefined,
  onClick = undefined,
}: AppButtonProps) {
  const loc = to === undefined ? path : to;
  const [, klass] = loc.split("/");
  return (
    <>
      <div className="app-button__wrapper">
        <NavLink
          className={`app-button__navlink ${klass}`}
          to={loc}
          onClick={onClick}
        >
          {icon && (
            <img src={icon} alt={`${name}-Icon`} className="app-button__icon" />
          )}
        </NavLink>
        <div className="app-button__name">{name}</div>
      </div>
    </>
  );
}

export default AppButton;
