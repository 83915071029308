import { createStitches } from "@stitches/react";

function normalizeRGBVal(num: number) {
  if (num > 255) {
    num = 255;
  } else if (num < 0) {
    num = 0;
  }
  return num;
}

export const { styled, css } = createStitches({
  prefix: "",
  theme: {
    colors: {
      white: "#FFF",
      black: "#000000",
      darkGray: "#898989",
      lightGray: "#C5C5C5",
      blue: "#0096b1",
      darkBlue: "#007185",
      green: "#01b093",
      yellow: "#fcb536",
      darkPink: "#aa0e6e",
      lightPink: "#d70d8a",
      red: "#ff0707",
    },
    fontSizes: {
      sm: "0.75rem",
      reg: "1rem",
      md: "1.5rem",
      lg: "2em",
    },
    fontWeights: {
      light: "300",
      regular: "400",
      bold: "600",
    },
    fonts: {
      gotham: "Gotham, Arial, sans-serif",
      gothamBook: "'Gotham-Book', Helvetica, sans-serif",
    },
  },
});
